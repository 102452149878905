import { RawData } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";

import { MotorCarrier } from "../MotorCarrier";
import { Units } from "../Units/Units";
import { Violation } from "../Violation/Violation";
import { Violations } from "../Violations/Violations";

export class Unit extends RawData<Unit.Raw> {
    // ========================================================================
    static from(unit: Unit, violations?: Violations): Unit {
        const newUnit = new Unit({
            vin: unit.vin,
            carrier: unit.#carrier,
            rawUnit: unit.raw(),
            units: unit.#units,
            fmcsa: {
                license: unit.license,
                make: unit.make,
                number: unit.number,
                type: unit.#type,
            },
        });
        if (violations) {
            newUnit.#violations = Violations.from(violations, Array.from(violations.filterByUnit(newUnit)));
        }
        return newUnit;
    }

    // ========================================================================
    static new(options: Required<Unit.Options>): Unit {
        return new Unit(options);
    }

    // ========================================================================
    #vin: string;
    #carrier: MotorCarrier;
    #license: string;
    #make: string;
    #number: string;
    #type: FMCSA.UnitType;
    #units: Units;
    private constructor(options: Required<Unit.Options>) {
        super(options.rawUnit);
        this.#vin = options.vin;
        this.#units = options.units;
        this.#carrier = options.carrier;
        this.#license = options.fmcsa.license || "";
        this.#make = this.#setMakeFormatted(options.fmcsa.make);
        this.#number = options.fmcsa.number ? String(options.fmcsa.number) : "";
        this.#type = options.fmcsa.type || FMCSA.UnitType.UNKNOWN;
    }

    // ========================================================================
    #setMakeFormatted(fmcsaMake?: string): string {
        const makeFromApi = this.get("Make");
        if (!makeFromApi) {
            return fmcsaMake || "";
        }
        return makeFromApi
            .toLowerCase()
            .split(" ")
            .map((part) => `${part.charAt(0).toUpperCase()}${part.slice(1)}`)
            .join(" ");
    }

    // ========================================================================
    get key(): string {
        return `${this.#vin}-${this.#license}`;
    }

    // ========================================================================
    get vin(): string {
        return this.#vin;
    }

    // ========================================================================
    get year(): string {
        return this.get("ModelYear") || "";
    }

    // ========================================================================
    get license(): string {
        return this.#license ?? "";
    }

    // ========================================================================
    get number(): string {
        return this.#number;
    }

    // ========================================================================
    get make(): string {
        return this.#make;
    }

    // ========================================================================
    get type(): string {
        return this.#type || this.get("BodyCabType") || "";
    }

    // ========================================================================
    get category(): Unit.Category {
        switch (this.#type) {
            case FMCSA.UnitType.STRAIGHT_TRUCK:
            case FMCSA.UnitType.TRUCK_TRACTOR:
            case FMCSA.UnitType.BUS:
            case FMCSA.UnitType.LIMO:
            case FMCSA.UnitType.MOTOR_COACH:
            case FMCSA.UnitType.SCHOOL_BUS:
            case FMCSA.UnitType.VAN:
                return Unit.Category.TRACTOR;
            case FMCSA.UnitType.FULL_TRAILER:
            case FMCSA.UnitType.POLE_TRAILER:
            case FMCSA.UnitType.SEMI_TRAILER:
            case FMCSA.UnitType.DOLLY_CONVERTER:
            case FMCSA.UnitType.INTERMODAL_CHASSIS:
            case FMCSA.UnitType.CRIB_LOG_TRAILER:
                return Unit.Category.TRAILER;
            default:
                return Unit.Category.UNKNOWN;
        }
    }

    // ========================================================================
    #violations: Violations | undefined;
    #calculateViolations(): Violations {
        this.#violations = Violations.from(this.#carrier.violations, Array.from(this.#carrier.violations.filterByUnit(this)));
        return this.#violations;
    }
    get violations(): Violations {
        return this.#violations || this.#calculateViolations();
    }

    // ========================================================================
    includeInvalid(): this {
        const { violations } = this.#carrier;
        violations.includeInvalid();
        this.#violations = Violations.from(violations, Array.from(violations.filterByUnit(this)));
        violations.excludeInvalid();
        this.#violations.includeInvalid();
        return this;
    }

    // ========================================================================
    excludeInvalid(): this {
        this.#calculateViolations();
        return this;
    }

    // ========================================================================
    /**
     * Returns a snippet of the VIN number, or null of no VIN associated with Violations.
     * @param length snip length, default is 6
     */
    getVinSnip(length = 6): string {
        if (!this.vin) return "";
        return this.vin.slice(this.vin.length - length);
    }

    // ========================================================================
    #percentOfTotalScore: number | undefined;
    /**
     * Calculates the percentage the `Unit` contributes to the `MotorCarrier`'s Total Score
     */
    #calculatePercentOfTotalScore(): number {
        const totalScore = this.#carrier.violations.getTotalWeight();
        const unitTotalScore = this.violations.getTotalWeight();
        const percent = unitTotalScore / totalScore;
        this.#percentOfTotalScore = globalThis.isNaN(percent) ? 0 : percent;
        return this.#percentOfTotalScore;
    }
    get percentOfTotalScore(): number {
        return this.#percentOfTotalScore ?? this.#calculatePercentOfTotalScore();
    }

    // ========================================================================
    hasViolationType(violationType: Violation.Type): boolean {
        return Array.from(this.violations).some((violation) => violation.type === violationType);
    }

    // ========================================================================
    #isTopContributor: boolean | undefined;
    #calculateIsTopContributor(): boolean {
        this.#isTopContributor = this.#carrier.units
            .sortByPercentOfScore("DESC")
            .take(this.#carrier.config.primaryScoreContributorThresholdAmount)
            .has(this);
        return this.#isTopContributor;
    }
    /**
     * Returns a boolean indicating if the Unit is a top contributor toward the `MotorCarrier`'s
     *  overall BASIC scores
     */
    get isTopContributor(): boolean {
        return this.#isTopContributor ?? this.#calculateIsTopContributor();
    }

    // ========================================================================
    raw(): Unit.Raw {
        return super.json();
    }

    // ========================================================================
    json(): Unit.JSON {
        return {
            DOT: this.#carrier.dot,
            VIN: this.vin,
            Type: this.type,
            Year: this.year,
            License: this.#license,
            Number: this.number,
            Make: this.make,
            Category: this.category,
            TotalViolations: this.violations.total,
            PercentOfTotalScoreFormatted: `${Math.round(this.percentOfTotalScore * 100)}%`,
            PercentOfTotalScore: this.percentOfTotalScore,
            IsTopContributor: this.isTopContributor,
        };
    }

    // ========================================================================
    toString(): string {
        return `${this.vin}, ${this.license}`;
    }
}

// ========================================================================
export namespace Unit {
    export enum Category {
        TRACTOR,
        TRAILER,
        UNKNOWN,
    }

    export interface JSON {
        DOT: number;
        VIN: string;
        Type: string;
        Year: string;
        License: string;
        Number: string;
        Make: string;
        Category: Unit.Category;
        TotalViolations: number;
        PercentOfTotalScoreFormatted: string;
        PercentOfTotalScore: number;
        IsTopContributor: boolean;
    }

    interface FMCSAOptions {
        license?: string;
        make?: string;
        number?: string;
        type?: FMCSA.UnitType;
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    export interface Options {
        vin: string;
        rawUnit: Unit.Raw;
        carrier: MotorCarrier;
        units: Units;
        fmcsa?: FMCSAOptions;
    }
    export interface Raw {
        VIN: string;
        BodyCabType?: string;
        BodyClass?: string;
        DriveType?: string;
        EngineModel?: string;
        FuelTypePrimary?: string;
        FuelTypeSecondary?: string;
        GVWR?: string;
        Make?: string;
        MakeID?: string;
        Manufacturer?: string;
        ManufacturerId?: string;
        Model?: string;
        ModelID?: string;
        ModelYear?: string;
        VehicleType?: string;
    }

    export namespace Raw {
        export interface FMCSA {
            UnitVin: string;
            UnitDecalNumber: string | undefined;
            UnitLicense: string;
            UnitMake: string;
            UnitType: FMCSA.UnitType;
        }
    }

    export class Vin {
        static isValid(vin: string): boolean {
            return vin.length === 17 && /[(A-Z0-9)]{17}/i.test(vin);
        }
    }
}
