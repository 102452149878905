import { BusinessAuto, InlandMarineEquipment, Policy, Tractor, Trailer } from "@deathstar/types/northstar";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import Unauthorized from "../../components/error-screens/Unauthorized";
import UncaughtException from "../../components/error-screens/UncaughtException";
import BusinessAutosTable from "./BusinessAutosTable";
import EquipmentHeader from "./EquipmentHeader";
import MonthlyReports from "./monthly-reports/MonthlyReports";
import ToolsTable from "./ToolsTable";
import TractorsTable from "./TractorsTable";
import TrailersTable from "./TrailersTable";

export default function Equipment() {
    const accountId = useAccountId();
    const tractors = api.equipment.useFind<Tractor>("tractors", accountId!, {
        enabled: !!accountId,
        select: (rows) => rows.filter((row) => !row.policyOwnerOperator),
    });
    const trailers = api.equipment.useFind<Trailer>("trailers", accountId!, {
        enabled: !!accountId,
        select: (rows) => rows.filter((row) => !row.policyOwnerOperator),
    });
    const autos = api.equipment.useFind<BusinessAuto>("autos", accountId!, { enabled: !!accountId });
    const tools = api.equipment.useFind<InlandMarineEquipment>("tools", accountId!, { enabled: !!accountId });

    const policies = api.policies.useFind(accountId!, "active");
    const showMonthlyReport = policies.data?.some((policy) => Policy.isMonthlyReporter(policy));

    return (
        <Routes>
            <Route path="/reporting/*" element={<MonthlyReports />} />
            <Route
                element={
                    <div className="space-y-4 p-4 lg:p-12">
                        <EquipmentHeader />
                        <Outlet />
                    </div>
                }
            >
                <Route index element={<Navigate to="tractors" />} />
                <Route
                    path="tractors"
                    element={
                        tractors.isError ? (
                            tractors.error.status === 403 ? (
                                <Unauthorized />
                            ) : (
                                <UncaughtException />
                            )
                        ) : (
                            <TractorsTable data={tractors.data} hideMonthlyReport={!showMonthlyReport} ownerOperators={false} />
                        )
                    }
                />
                <Route
                    path="trailers"
                    element={
                        trailers.isError ? (
                            trailers.error.status === 403 ? (
                                <Unauthorized />
                            ) : (
                                <UncaughtException />
                            )
                        ) : (
                            <TrailersTable data={trailers.data} hideMonthlyReport={!showMonthlyReport} ownerOperators={false} />
                        )
                    }
                />
                <Route
                    path="autos"
                    element={
                        autos.isError ? (
                            autos.error.status === 403 ? (
                                <Unauthorized />
                            ) : (
                                <UncaughtException />
                            )
                        ) : (
                            <BusinessAutosTable data={autos.data} />
                        )
                    }
                />
                <Route
                    path="tools"
                    element={
                        tools.isError ? (
                            tools.error.status === 403 ? (
                                <Unauthorized />
                            ) : (
                                <UncaughtException />
                            )
                        ) : (
                            <ToolsTable data={tools.data} />
                        )
                    }
                />
            </Route>
        </Routes>
    );
}
