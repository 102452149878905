import { CoverageOption } from "@deathstar/types/northstar";
import { DriverDto } from "@deathstar/types/waypoint";
import { Checkbox, InputField, PrimaryButton, useSnackbar } from "@deathstar/ui";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { Dialog } from "../../components/dialog/Dialog";
import { States } from "../../util/constants";
import CoverageSelector from "../equipment/CoverageSelector";

export function NewDriverDialog({ open, onClose, ownerOperator }: { open: boolean; onClose(): void; ownerOperator: boolean }) {
    return (
        <Dialog open={open} onClose={onClose} className="w-screen overflow-y-auto p-4 pb-0 md:max-w-sm">
            <NewDriverForm onClose={onClose} ownerOperator={ownerOperator} />
        </Dialog>
    );
}

function NewDriverForm({ onClose, ownerOperator }: { onClose(): void; ownerOperator: boolean }) {
    const form = useForm<DriverDto>({
        defaultValues: {
            coverages: [],
            ownerOperator,
            requestDate: moment().format("YYYY-MM-DD"),
        },
    });

    const accountId = useAccountId();
    const { mutate: create, isLoading } = api.drivers.useCreate(accountId!, {
        onSuccess: () => {
            onClose();
        },
        onError: (error) => {
            if (error.status === 403) {
                useSnackbar.add("You do not have permission to manage drivers.", { variant: "error" });
            } else {
                useSnackbar.add("Could not add driver. Please try again later.", { variant: "error" });
            }
        },
    });

    return (
        <form
            onSubmit={form.handleSubmit((data) => {
                if (!isLoading) {
                    create(data);
                }
            })}
            className="w-full space-y-4 text-sm"
        >
            <InputField required label="Name" {...form.register("name")} classes={{ inputContainer: "py-2" }} />
            <div>
                <label htmlFor="state">
                    State<span className="ml-1 text-sm text-red-600">*</span>
                </label>
                <select required id="state" {...form.register("state")} className="form-select w-full rounded-lg border-stone-300 text-sm">
                    <option value="">Select a state</option>
                    {States.map((state) => (
                        <option key={state.abbr} value={state.abbr}>
                            {state.name}
                        </option>
                    ))}
                </select>
            </div>
            <InputField required label="License" {...form.register("license")} classes={{ inputContainer: "py-2" }} />
            <InputField required label="Date of birth" {...form.register("dob")} type="date" classes={{ inputContainer: "py-2" }} />
            <InputField required label="Date of hire" {...form.register("doh")} type="date" classes={{ inputContainer: "py-2" }} />
            <InputField
                required
                label="Years experience"
                {...form.register("exp", { setValueAs: (value) => (value ? parseInt(value) : null) })}
                type="number"
                min={0}
                max={100}
                classes={{ inputContainer: "py-2" }}
            />
            <Checkbox
                classes={{ input: "w-4 h-4", root: "text-sm hidden" }}
                label="Driver is an owner operator"
                {...form.register("ownerOperator")}
            />

            <Checkbox
                classes={{ input: "w-4 h-4", root: "text-sm opacity-60" }}
                label={ownerOperator ? "Driver is an owner operator" : "Driver is a company driver"}
                checked={true}
                disabled
            />
            <Checkbox
                classes={{ input: "w-4 h-4", root: "text-sm" }}
                label="Driver meets driver guidelines"
                {...form.register("wpUserConfirmationMeetsGuidelines")}
            />

            <Controller
                rules={{ required: "At least one coverage is required" }}
                name="coverages"
                control={form.control}
                render={({ field }) => (
                    <CoverageSelector
                        existingCoverages={field.value}
                        onChange={field.onChange}
                        dataType="drivers"
                        onLoad={(data) => {
                            if (data.includes(CoverageOption.Id.TRUCKERS_AUTO_LIABILITY)) {
                                field.onChange([CoverageOption.Id.TRUCKERS_AUTO_LIABILITY]);
                            }
                        }}
                    />
                )}
            />

            <InputField
                type="date"
                label="Date added"
                {...form.register("requestDate", {
                    required: true,
                    validate: (value) => {
                        const m = moment(value, "YYYY-MM-DD", true);
                        if (!m.isValid()) {
                            return "Invalid date";
                        }
                        if (m.isAfter(moment(), "day")) {
                            return "Date cannot be in the future";
                        }

                        if (moment().date() >= 15 && m.isBefore(moment(), "month")) {
                            return "Date is too far in the past";
                        }

                        if (moment().date() < 15 && m.isBefore(moment().subtract(1, "month"), "month")) {
                            return "Date is too far in the past";
                        }

                        return true;
                    },
                })}
            />

            <p className="text-red-600">
                {Object.values(form.formState.errors)
                    .map((error) => error.message)
                    .join("; ")}
            </p>

            <div className="flex justify-end pb-4">
                <PrimaryButton disabled={isLoading} type="submit" className="w-32">
                    {isLoading ? (
                        <>
                            <span>Loading</span>
                            <CircularProgress size="1rem" classes={{ circle: "text-white" }} />
                        </>
                    ) : (
                        <>
                            <span>Submit</span>
                            <ArrowRightCircleIcon className="h-4 w-4" />
                        </>
                    )}
                </PrimaryButton>
            </div>
        </form>
    );
}
