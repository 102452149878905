import { Property } from "@deathstar/types/northstar";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const properties = {
    queryKeys: {
        find: (accountId: string) => [accountId, "properties"] as QueryKey,
    },

    find: (accountId: string) => {
        return blazar.fetchJson<Property[]>(`/waypoint/orgs/${accountId}/properties`);
    },

    useFind: <T = Property[]>(accountId: string, config?: UseQueryOptions<Property[], ResponseError, T>) =>
        useQuery<Property[], ResponseError, T>(properties.queryKeys.find(accountId), () => properties.find(accountId), {
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            ...config,
        }),
};
