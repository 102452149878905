import { Tractor } from "@deathstar/types/northstar";
import { EquipmentRow } from "@deathstar/types/waypoint";
import { ActionButton, DataTable, fuzzyFilter } from "@deathstar/ui";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import Loader from "../../components/loader/Loader";
import EquipmentColumns from "./EquipmentColumns";
import { NewUnitDialog } from "./NewUnitDialog";

const columns = [
    EquipmentColumns.mobileTractorCell,
    EquipmentColumns.pending,
    EquipmentColumns.upcomingDate,
    EquipmentColumns.unitNumber,
    EquipmentColumns.year,
    EquipmentColumns.make,
    EquipmentColumns.type,
    EquipmentColumns.vin,
    EquipmentColumns.value,
    EquipmentColumns.coverages,
    EquipmentColumns.additionalInterests,
    EquipmentColumns.tractorActions,
] as ColumnDef<EquipmentRow<Tractor>>[];

const AnimatedDataTable = animated(DataTable<EquipmentRow<Tractor>>);

export default function TractorsTable({
    data,
    hideMonthlyReport,
    ownerOperators,
}: {
    data: EquipmentRow<Tractor>[] | undefined;
    hideMonthlyReport?: boolean;
    ownerOperators: boolean;
}) {
    const [adding, setAdding] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([]);

    const memoizedData = useMemo(() => data || [], [data]);

    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    const sortingState = useMemo(() => {
        return [{ id: "pending", desc: true }, ...sorting];
    }, [sorting]);

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: fuzzyFilter,
        state: {
            sorting: sortingState,
        },
        initialState: {
            pagination: {
                pageSize: 30,
            },
        },
        onSortingChange: setSorting,
    });

    return (
        <div>
            {data ? (
                <>
                    <AnimatedDataTable
                        style={spring}
                        table={table}
                        actions={[
                            {
                                label: "Add tractor",
                                onClick() {
                                    setAdding(true);
                                },
                                icon: <PlusIcon className="h-5 w-5" />,
                            },
                            !hideMonthlyReport && (
                                <Link to="./../reporting">
                                    <ActionButton>Monthly reporting</ActionButton>
                                </Link>
                            ),
                        ]}
                    />
                    <NewUnitDialog open={adding} onClose={() => setAdding(false)} unitType="tractors" ownerOperator={ownerOperators} />
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
