import { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";
import { classNames } from "@deathstar/ui";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip, Typography } from "@material-ui/core";

import React from "react";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";

export function IssScores({ className }: { className?: string }): JSX.Element | null {
    const { motorCarrier } = React.useContext(Context);
    const issResult = motorCarrier.issResults.latest;

    if (!issResult.algorithmUsed) return null;

    return (
        <div className={classNames("bg-inherit ", className)}>
            <TitleGroup title={<Typography variant="h5">ISS Score</Typography>} />
            <div className="flex flex-col py-4">
                <LightSection issResult={issResult} inspectionRecommendation={FMCSA.IssInspectionRecommendation.INSPECT} red />
                <LightSection issResult={issResult} inspectionRecommendation={FMCSA.IssInspectionRecommendation.OPTIONAL} yellow />
                <LightSection issResult={issResult} inspectionRecommendation={FMCSA.IssInspectionRecommendation.PASS} green />
            </div>
        </div>
    );
}

function LightSection({
    issResult,
    inspectionRecommendation,
    red,
    yellow,
    green,
}: {
    issResult: MotorCarrier.IIssResult;
    inspectionRecommendation: FMCSA.IssInspectionRecommendation;
    red?: boolean;
    yellow?: boolean;
    green?: boolean;
}): JSX.Element {
    const ref = React.useRef(null);

    const selected = issResult.inspectionRecommendation === inspectionRecommendation;
    const inspectionRecommendationString = React.useMemo(
        () => getInspectionRecommendation(issResult.inspectionRecommendation),
        [issResult.inspectionRecommendation]
    );

    return (
        <div
            ref={ref}
            className={classNames("grid grid-cols-[max-content_min-content_min-content] gap-4 rounded border-2 border-transparent p-1", {
                "bg-slate-100": selected,
                "!border-[#86202b]": selected && red,
                "!border-[#b49939]": selected && yellow,
                "!border-[#28672c]": selected && green,
            })}
        >
            <div
                className={classNames("flex items-center gap-2", {
                    "justify-left": !issResult.score,
                    invisible: issResult.inspectionRecommendation !== inspectionRecommendation,
                })}
            >
                <Typography variant="h5">{issResult.score}</Typography>
                <Typography
                    className={classNames({
                        "text-gray-400": typeof issResult.score === "number",
                    })}
                >
                    {inspectionRecommendationString}
                </Typography>
            </div>
            <Light red={red} yellow={yellow} green={green} active={selected} />
            {selected && (
                <Tooltip title={issResult.reason || ""} placement="right-end">
                    <InformationCircleIcon className="inline h-4 w-4" />
                </Tooltip>
            )}
        </div>
    );
}

function Light({ red, yellow, green, active }: { red?: boolean; yellow?: boolean; green?: boolean; active?: boolean }): JSX.Element {
    return (
        <div
            className={classNames("h-[25px] w-[25px] rounded-full ", {
                "bg-[#86202b]": red,
                "bg-[#b49939]": yellow,
                "bg-[#28672c]": green,
                "opacity-30": !active,
            })}
        ></div>
    );
}


function getInspectionRecommendation(inspectionRecommendation: FMCSA.IssInspectionRecommendation | null): string | null {
    if (!inspectionRecommendation) return null;
    if (inspectionRecommendation === FMCSA.IssInspectionRecommendation.OPTIONAL) return "Warning";
    return inspectionRecommendation;
}