import { Img, PrimaryButton } from "@deathstar/ui";
import { ArrowRightCircleIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { blazar } from "../../api/util/blazar";
import jillSrc from "../../assets/images/jill_hendrickson.jpg";
import mikeSrc from "../../assets/images/mike_dantuma.jpg";
import Title from "../../components/title/Title";
import { AGENT_BIOS } from "./agentBios";

export default function AdviceLanding() {
    const accountId = useAccountId();
    const { data: organization } = api.organization.useById(accountId!);
    const { data: license } = api.billing.useLicense(accountId!);

    const benefitsSpring = useSpring({
        from: { opacity: 0 },
        to: {
            opacity: 1,
        },
    });

    const professionalsSpring = useSpring({
        from: { opacity: 0 },
        to: {
            opacity: 1,
        },
        delay: 300,
    });

    return (
        <div>
            <Title>Ask Anything</Title>

            <div className="mt-6 flex flex-col justify-between gap-24 xl:flex-row">
                <animated.div style={benefitsSpring}>
                    <div>
                        <p className="text-lg font-medium text-stone-700">What we offer</p>
                        <ul className="my-4 space-y-2">
                            <li>
                                <ArrowRightCircleIcon className="-mt-1 mr-2 inline-block h-6 w-6 text-waypoint-orange" />
                                Legal and compliance answers within 48 business hours
                            </li>
                            <li>
                                <ArrowRightCircleIcon className="-mt-1 mr-2 inline-block h-6 w-6 text-waypoint-orange" />
                                Contract reviews
                            </li>
                            <li>
                                <ArrowRightCircleIcon className="-mt-1 mr-2 inline-block h-6 w-6 text-waypoint-orange" />
                                Crash DataQs (
                                <a
                                    href="https://www.fmcsa.dot.gov/crash-preventability-determination-program"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-waypoint-blue hover:text-waypoint-blue-dark"
                                >
                                    CPDP
                                    <ArrowTopRightOnSquareIcon className="-mt-1 ml-1 inline-block h-3 w-3" />
                                </a>
                                )
                            </li>
                            <li>
                                <ArrowRightCircleIcon className="-mt-1 mr-2 inline-block h-6 w-6 text-waypoint-orange" />
                                Violation DataQs
                            </li>
                            <li>
                                <ArrowRightCircleIcon className="-mt-1 mr-2 inline-block h-6 w-6 text-waypoint-orange" />
                                Driver file review
                            </li>
                            <li>
                                <ArrowRightCircleIcon className="-mt-1 mr-2 inline-block h-6 w-6 text-waypoint-orange" />
                                Conditional rating repair
                            </li>
                        </ul>
                    </div>
                    <div className="flex w-full flex-col items-center gap-4">
                        {license ? (
                            <Link to="./requests">
                                <PrimaryButton>Get started</PrimaryButton>
                            </Link>
                        ) : (
                            <>
                                <p className="max-w-prose space-y-4 text-stone-500">These services are only available to subscribers</p>
                                <Link to="../settings/subscription">
                                    <PrimaryButton>Activate</PrimaryButton>
                                </Link>
                            </>
                        )}
                    </div>
                </animated.div>
                <animated.div style={professionalsSpring}>
                    <p className="mb-4 text-center text-lg font-medium text-stone-700">Your team</p>
                    <ul className="space-y-12">
                        {organization?.agent.upn && organization.agent.upn in AGENT_BIOS && (
                            <li>
                                <div className="flex flex-col gap-4 lg:flex-row">
                                    <p className="max-w-prose text-stone-700">
                                        {AGENT_BIOS[organization.agent.upn as keyof typeof AGENT_BIOS]}
                                    </p>
                                    <Img
                                        src={`${blazar.getBaseUrl()}api/waypoint/personnel/${organization?.agent.upn}/img`}
                                        className="h-36 w-36 shrink-0 rounded"
                                        title={organization?.agent.displayName || undefined}
                                    >
                                        <span>No image</span>
                                    </Img>
                                </div>
                            </li>
                        )}
                        <li>
                            <div className="flex flex-col gap-4 lg:flex-row">
                                <p className="max-w-prose text-stone-700">
                                    Jill Hendrickson has been a Certified Insurance Representative (CISR-Elite) for the trucking industry
                                    for over a decade. She currently focuses on the inner workings of the FMCSA's DataQs and CPDP programs.
                                    She is equipped with years of experience as a project manager and process engineer. Jill leads the
                                    Agency's team that handles the complex data research required for Motor Carriers to improve their SMS
                                    Scores.
                                </p>
                                <img src={jillSrc} className="h-36 w-36 shrink-0 rounded" alt="Jill Hendrickson" />
                            </div>
                        </li>
                        <li>
                            <div className="flex flex-col gap-4 lg:flex-row">
                                <p className="max-w-prose text-stone-700">
                                    Michael Dantuma's legal practice focuses on commercial and corporate litigation, with an emphasis on the
                                    commercial motor vehicle industry. As a licensed practitioner in State and Federal Court jurisdictions
                                    in Michigan, Mike is excited to utilize his 30+ year of industry knowledge and experience to best serve
                                    our clients.
                                </p>
                                <img src={mikeSrc} className="h-36 w-36 shrink-0 rounded" alt="Mike Dantuma" />
                            </div>
                        </li>
                    </ul>
                </animated.div>
            </div>
        </div>
    );
}
