import { PriceWithProduct } from "@deathstar/types/waypoint";
import Stripe from "stripe";
import { getAmountOff, unitAmountToCurrency } from "./billingUtil";

export default function PlanPriceWithDiscount({ price, coupon }: { price: PriceWithProduct; coupon?: Stripe.Coupon | null }) {
    const amountOff = coupon ? getAmountOff(price.unit_amount!, coupon) : 0;
    return (
        <span className="self-end text-4xl font-bold">
            {amountOff > 0 && (
                <span className="text-2xl line-through opacity-80">{unitAmountToCurrency(price.unit_amount, price.currency)}</span>
            )}
            <span>{unitAmountToCurrency(Math.max(0, price.unit_amount! - amountOff), price.currency)} </span>
            <span className="text-lg opacity-90">/ {price.recurring?.interval}</span>
        </span>
    );
}
