import { MasterCertificate } from "@deathstar/types/northstar";
import { classNames } from "@deathstar/ui";
import { ArrowDownTrayIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-ui/core";
import { useState } from "react";
import { useMutation } from "react-query";
import api from "../../api/api";
import { blazar } from "../../api/util/blazar";
import { ActionButton } from "../../components/action-button/ActionButton";
import { Dialog } from "../../components/dialog/Dialog";

interface CertificatePreviewProps {
    certificate?: MasterCertificate;
}
export default function CertificatePreview({ certificate }: CertificatePreviewProps) {
    const [open, setOpen] = useState(false);
    const accountId = certificate?.accountId;
    const id = certificate?.id;
    const { data: previewUrl, isLoading: isLoadingPreview } = api.certificates.usePreviewUrl(accountId!, id!, {
        enabled: !!accountId && !!id,
    });

    return (
        <div className="flex flex-col items-center gap-2">
            <Tooltip title="Click to preview">
                <button
                    className="h-min"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <iframe
                        src={previewUrl ? previewUrl + "#view=fitH&toolbar=0&navpanes=0" : undefined}
                        title="Certificate preview"
                        className={classNames("pointer-events-none w-full rounded", isLoadingPreview && "animate-pulse bg-stone-200")}
                        style={{ aspectRatio: 8.5 / 11 }}
                    />
                </button>
            </Tooltip>
            <CertificateDownloadButton certificate={certificate} />
            <PreviewDialog open={open} onClose={() => setOpen(false)} certificate={certificate} />
        </div>
    );
}

export function CertificateDownloadButton({ certificate, className }: { certificate: MasterCertificate | undefined; className?: string }) {
    const mutation = useMutation(async () => {
        if (!certificate) return;
        const res = await blazar.fetch(`waypoint/orgs/${certificate?.accountId}/certificates/${certificate?.id}/insured-copy`);
        const blob = await res.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = `COI - ${certificate?.coveragesString}.pdf`;
        a.href = url;
        a.click();
        a.remove();
    });
    return (
        <ActionButton onClick={() => mutation.mutate()} className={classNames(className)}>
            <ArrowDownTrayIcon className="h-4 w-4 shrink-0" />
            Download
        </ActionButton>
    );
}

function PreviewDialog({ open, onClose, certificate }: { open: boolean; onClose(): void; certificate?: MasterCertificate }) {
    const accountId = certificate?.accountId;
    const id = certificate?.id;
    const { data: previewUrl, isLoading: isLoadingPreview } = api.certificates.usePreviewUrl(accountId!, id!, {
        enabled: !!accountId && !!id,
    });
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="relative overflow-auto">
                <object
                    data={previewUrl + "#view=fitH&toolbar=0&navpanes=0"}
                    title="Certificate preview"
                    className={classNames(
                        "pointer-events-none w-screen overflow-auto rounded md:w-[50vw]",
                        isLoadingPreview && "animate-pulse bg-stone-200"
                    )}
                    style={{ aspectRatio: 8.5 / 11 }}
                />
                <button
                    onClick={onClose}
                    title="Close preview"
                    className="sticky bottom-12 left-1/2 flex -translate-x-1/2 items-center gap-2 rounded-lg bg-waypoint-blue p-2 text-white shadow-lg"
                >
                    <XMarkIcon className="h-6 w-6" />
                    Close preview
                </button>
            </div>
        </Dialog>
    );
}
