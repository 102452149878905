import { Outlet, Route, Routes } from "react-router-dom";
import SolPageNotFound from "../../../components/error-screens/SolPageNotFound";
import { PolicyWithBillingInfo } from "../../policies/policies-list/PoliciesList";
import MonthlyReportPolicySelector from "./MonthlyReportPolicySelector";
import MonthlyReportSelector from "./MonthlyReportSelector";
import MonthlyReportView from "./MonthlyReportView";

export default function MonthlyReports({
    getFilteredPolicies,
}: {
    getFilteredPolicies?: (policies: PolicyWithBillingInfo[]) => PolicyWithBillingInfo[];
}) {
    return (
        <Routes>
            <Route
                element={
                    <div className="rounded bg-white p-4">
                        <p className="mb-4 text-xl font-light text-navigator-blue">Monthly reports</p>
                        <Outlet />
                    </div>
                }
            >
                <Route index element={<MonthlyReportPolicySelector getFilteredPolicies={getFilteredPolicies} />} />
                <Route path=":policyId" element={<MonthlyReportSelector />} />
                <Route path=":policyId/:year/:month" element={<MonthlyReportView />} />
                <Route path="*" element={<SolPageNotFound />} />
            </Route>
        </Routes>
    );
}
