import { Expose } from "class-transformer";
import { IsBoolean, IsDateString, IsInt, IsOptional, IsString, Length, Min } from "class-validator";
import { Driver } from "../northstar";
import { EquipmentDriverChangeDto } from "./Equipment";

export type DriverRow = Omit<Driver, "exp" | "doh" | "ownerOperator">;

export class DriverDto extends EquipmentDriverChangeDto {
    @Expose()
    @IsString()
    name: string;

    @Expose()
    @IsString()
    @Length(2, 2)
    state: string;

    @Expose()
    @IsString()
    @Length(0, 25)
    license: string;

    @Expose()
    @IsString()
    @IsDateString()
    @Length(10, 10)
    dob: string;

    @Expose()
    @IsString()
    @IsDateString()
    @Length(10, 10)
    doh: string;

    @Expose()
    @IsOptional()
    @IsInt()
    @Min(0)
    exp: number;

    @Expose()
    @IsBoolean()
    ownerOperator: boolean;

    @Expose()
    @IsBoolean()
    wpUserConfirmationMeetsGuidelines: boolean;
}
