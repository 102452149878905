import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { DataTable } from "@deathstar/ui";
import { Tooltip } from "@material-ui/core";
import { AccessorColumnDef, ColumnDef } from "@tanstack/react-table";
import DataQIcon from "../../assets/icons/dataq-icon.png";
import ExpiringIcon from "../../assets/icons/expiring-inspection-icon.png";
import NewIcon from "../../assets/icons/new-inspection-icon.png";

const ascDateSorter = DateTime.Sorter("ASC");

const isDataQ: AccessorColumnDef<MotorCarrier.IViolation, boolean> = {
    id: "isDataQ",
    header: "",
    accessorFn: (row) => row.isValid,
    cell: (row) => {
        if (!row.getValue()) return <img src={DataQIcon} alt="DataQ Icon" className="m-[1px] h-[20px] w-[20px]" />;
        if (row.row.original.isNew) return <img src={NewIcon} alt="New Icon" className="m-[1px] h-[20px] w-[20px]" />;
        if (row.row.original.hasExpiringWeight) return <img src={ExpiringIcon} alt="Expiring Icon" className="m-[1px] h-[20px] w-[20px]" />;
        if (row.row.original.carrier.inspections.filterByInvalid().total > 0) return <div className="m-[1px] h-[20px] w-[20px]"></div>;
        return null;
    },
    meta: { classes: { cell: "tabular-nums p-0 min-w-[45px]" } },
    enableSorting: false,
};

const addDate: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "addDate",
    header: "Added",
    accessorFn: (row) => row.date.format("mm/dd/YYYY"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};
const removalDate: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "removalDate",
    header: "Removal",
    accessorFn: (row) => DateTime.addYears(row.date, 2).format("mm/dd/YYYY"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};

//#region
const unitVin: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "unitVin",
    header: "VIN",
    accessorFn: (row) => row.unit?.vin,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitYear: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "unitYear",
    header: "Year",
    accessorFn: (row) => row.unit?.year,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitMake: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "unitMake",
    header: "Make",
    accessorFn: (row) => row.unit?.make,
    meta: { classes: { cell: "max-w-[17ch] overflow-hidden text-ellipsis whitespace-nowrap tabular-nums" } },
    cell: (row) => {
        return (
            <Tooltip title={(row.getValue() as string) || ""}>
                <span>{row.getValue() as string}</span>
            </Tooltip>
        );
    },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitLicenseNumber: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "unitLicenseNumber",
    header: "License Number",
    accessorFn: (row) => row.unit?.license,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

// const unitNumber: AccessorColumnDef<MotorCarrier.IViolation> = {
//     id: "unitNumber",
//     header: "Type",
//     accessorFn: (row) => row.get("UnitNumber"),
//     meta: { classes: { cell: "tabular-nums" } },
//     sortingFn: "alphanumeric",
// };

const unitType: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "unitType",
    header: "Type",
    accessorFn: (row) => row.unit?.type,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const offender: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "offender",
    accessorFn: (row) => row.get("ViolationOffender"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

//#endregion

const basic: AccessorColumnDef<MotorCarrier.IViolation, FMCSA.BasicName> = {
    id: "basic",
    header: "BASIC",
    accessorFn: (row) => row.get("Basic"),
    cell: (row) => (
        <Tooltip title={row.getValue() || ""}>
            <span>{FMCSA.BasicName.getAbbreviation(row.getValue())}</span>
        </Tooltip>
    ),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: DataTable.ColumnOptionFilterButton.filterFn,
    enableGlobalFilter: false,
};

const oosIndicator: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "oosIndicator",
    header: "OOS",
    accessorFn: (row) => row.isOutOfService,
    cell: (row) => {
        if (row.getValue()) {
            return (
                <div className="flex justify-center rounded-xl bg-danger px-2 text-white">
                    <span>OOS</span>
                </div>
            );
        }
        return null;
    },
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        if (a.original.isOutOfService && !b.original.isOutOfService) return -1;
        if (!a.original.isOutOfService && b.original.isOutOfService) return 1;
        return 0;
    },
};

const groupDescription: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "groupDescription",
    header: "Group",
    accessorFn: (row) => row.get("GroupDescription"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: DataTable.ColumnOptionFilterButton.filterFn,
};
const sectionDescription: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "sectionDescription",
    header: "Section",
    accessorFn: (row) => row.get("SectionDescription"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const description: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "description",
    accessorFn: (row) => row.get("GroupDescription"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const severityWeight: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "severityWeight",
    header: "Severity Weight",
    accessorFn: (row) => row.severityWeight,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const timeWeight: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "timeWeight",
    header: "Time Weight",
    accessorFn: (row) => row.getTimeWeight(),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalWeight: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "totalWeight",
    header: "Total Weight",
    accessorFn: (row) => row.getTotalWeight(),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const weightCalculation: AccessorColumnDef<MotorCarrier.IViolation, { severityWeight: number; timeWeight: number; totalWeight: number }> = {
    id: "weightCalculation",
    header: "Weight Calculation",
    accessorFn: (row) => {
        return {
            severityWeight: row.severityWeight,
            timeWeight: row.getTimeWeight(),
            totalWeight: row.getTotalWeight(),
        };
    },
    cell: (row) => {
        const { severityWeight, timeWeight, totalWeight } = row.getValue();
        return (
            <div className="flex gap-2">
                <span>{severityWeight}</span>
                <span>x</span>
                <span>{timeWeight}</span>
                <span>=</span>
                <span className="w-[2ch] text-right">{totalWeight}</span>
            </div>
        );
    },
    meta: { classes: { cell: "tabular-nums" } },
    enableSorting: false,
};

const violationCode: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "violationCode",
    header: "Violation Code",
    accessorFn: (row) => row.get("ViolationCode"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

// const citationNumber: AccessorColumnDef<MotorCarrier.IViolation> = {
//     id: "citationNumber",
//     header: "Citation Number",
//     accessorFn: (row) => row.get("CitationNumber"),
//     meta: { classes: { cell: "tabular-nums" } },
//     sortingFn: "alphanumeric",
// };

const county: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "county",
    accessorFn: (row) => row.inspection.county,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const latitude: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "latitude",
    accessorFn: (row) => row.inspection.get("CountyLatitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const longitude: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "longitude",
    accessorFn: (row) => row.inspection.get("CountyLongitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

// const defectVerification: AccessorColumnDef<MotorCarrier.IViolation> = {
//     id: "defectVerification",
//     header: "Defect Verification",
//     accessorFn: (row) => row.get("DefectVerification"),
//     meta: { classes: { cell: "tabular-nums" } },
//     sortingFn: "alphanumeric",
// };

// const violationCategory: AccessorColumnDef<MotorCarrier.IViolation> = {
//     id: "violationCategory",
//     header: "Category",
//     accessorFn: (row) => row.get(""),
//     meta: { classes: { cell: "tabular-nums" } },
//     sortingFn: "alphanumeric",
// };

// const violationCategoryDescription: AccessorColumnDef<MotorCarrier.IViolation> = {
//     id: "violationCategoryDescription",
//     header: "Category Description",
//     accessorFn: (row) => row.get("ViolationCategoryDescription"),
//     meta: { classes: { cell: "tabular-nums" } },
//     sortingFn: "alphanumeric",
// };

export const columns = [
    isDataQ,
    addDate,
    removalDate,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    // unitNumber,
    unitType,
    offender,

    basic,
    oosIndicator,
    groupDescription,
    sectionDescription,
    description,

    severityWeight,
    timeWeight,
    totalWeight,
    weightCalculation,

    violationCode,
    // citationNumber,
    county,
    latitude,
    longitude,

    // defectVerification,
    // violationCategory,
    // violationCategoryDescription,
] as ColumnDef<MotorCarrier.IViolation>[];

export default {
    isDataQ,
    addDate,
    removalDate,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    // unitNumber,
    unitType,
    offender,

    basic,
    oosIndicator,
    groupDescription,
    sectionDescription,
    description,

    severityWeight,
    timeWeight,
    totalWeight,
    weightCalculation,

    violationCode,
    // citationNumber,
    county,
    latitude,
    longitude,

    // defectVerification,
    // violationCategory,
    // violationCategoryDescription,
};
