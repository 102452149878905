/* eslint-disable react-hooks/rules-of-hooks */
import { useAuth0 } from "@auth0/auth0-react";
import { OrganizationInvitation, Permission, User } from "@deathstar/types/waypoint";
import QueryString from "qs";
import { useQuery, UseQueryOptions } from "react-query";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError, UnauthenticatedError } from "../util/exceptions";

const userApi = {
    queryKeys: {
        me: () => ["user"],
    },
    useMe<T = User & { invitations: OrganizationInvitation[] }>(
        options?: UseQueryOptions<User & { invitations: OrganizationInvitation[] }, Error | UnauthenticatedError, T>
    ) {
        const { user, isAuthenticated } = useAuth0();
        const navigate = useNavigate();

        return useQuery<User & { invitations: OrganizationInvitation[] }, Error | UnauthenticatedError, T>(
            userApi.queryKeys.me(),
            async () => {
                if (user) {
                    return await blazar.fetchJson<User & { invitations: OrganizationInvitation[] }>(`waypoint/users/me`);
                } else {
                    throw new UnauthenticatedError();
                }
            },
            {
                onError: (e) => {
                    if (e instanceof ResponseError && e.status === 404 && !window.location.pathname.startsWith("/member-setup")) {
                        navigate({
                            pathname: "/member-setup",
                            search: QueryString.stringify({ redirect: window.location.pathname }),
                        });
                    }
                },
                staleTime: 5000,
                retry: false,
                ...options,
                enabled: isAuthenticated,
            }
        );
    },

    async hasPermission(accountId: string, permissionId: Permission["id"]): Promise<boolean> {
        const { granted } = await blazar.fetchJson<{ userId: string; accountId: string; permissionId: Permission["id"]; granted: boolean }>(
            `waypoint/users/me/orgs/${accountId}/permissions/${permissionId}`
        );
        return granted;
    },

    useHasPermission(accountId: string, permissionId: Permission["id"], config?: UseQueryOptions<boolean, ResponseError>) {
        return useQuery<boolean, ResponseError>(
            ["user", "has-permission", accountId, permissionId],
            () => userApi.hasPermission(accountId, permissionId),
            config
        );
    },

    async update(updates: Partial<Pick<User, "displayName" | "lastVisitedAccountId">>) {
        await blazar.fetch(`waypoint/users/me`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updates),
        });
    },

    async joinTestOrg() {
        await blazar.fetch(`waypoint/users/me/join-test-org`, {
            method: "POST",
        });
    },

    invalidateQueries() {
        queryClient.invalidateQueries(["user"]);
    },
};

export const user = userApi;
