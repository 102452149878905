import { DateTime } from "@deathstar/reuse";
import { NumberFormatter } from "@deathstar/types/NumberFormatter";
import { classNames } from "@deathstar/ui";
import { Link, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import BuildingIcon from "../../assets/icons/building-icon.png";
import CleanInspectionsIcon from "../../assets/icons/clean-inspections-icon.png";
import CrashIcon from "../../assets/icons/crash-icon.png";
import DriverIcon from "../../assets/icons/driver-icon.png";
import FmcsaIcon from "../../assets/icons/fmcsa-icon.png";
import TractorIcon from "../../assets/icons/tractor-icon.png";
import TrailerIcon from "../../assets/icons/trailer-icon.png";
import ViolationIcon from "../../assets/icons/violation-icon.png";
import VmtIcon from "../../assets/icons/vmt-icon.png";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { Transition } from "../Transition";
import { IssScores } from "./IssScores";
import { OutOfServiceRates } from "./OutOfServiceRates";

function pluralize(value: number, base: string, plural: "es" | "s"): string {
    return value !== 1 ? base + plural : base;
}

export function FmcsaSnapshot(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const formatter = NumberFormatter.getFormatter({
        maximumFractionDigits: 0,
    });

    const from = motorCarrier.dateRange.from.format("Mmmm YYYY");
    const to = motorCarrier.dateRange.to.format("Mmmm YYYY");

    const blocks: {
        primary: IBlockOption[];
        secondary: IBlockOption[];
    } = React.useMemo(() => {
        return {
            primary: [
                {
                    src: TractorIcon,
                    alt: "Power Units Icon",
                    data: motorCarrier.details.TotalPowerUnits,
                    label: pluralize(motorCarrier.details.TotalPowerUnits, "Power Unit", "s"),
                    className: "sm:order-2 md:order-3 xl:order-1",
                    tooltip: motorCarrier.isHazmat ? "Count does not include Hazmat rated equipment" : null,
                },
                {
                    src: TrailerIcon,
                    alt: "Trailer Icon",
                    data: motorCarrier.details.TotalTrailers,
                    label: pluralize(motorCarrier.details.TrailersOwned, "Trailer", "s"),
                    className: "sm:order-3 md:order-5 xl:order-3",
                    tooltip: motorCarrier.isHazmat ? "Count does not include Hazmat rated equipment" : null,
                },
                {
                    src: DriverIcon,
                    alt: "Driver Icon",
                    data: motorCarrier.details.TotalDrivers,
                    label: pluralize(motorCarrier.details.TotalDrivers, "Driver", "s"),
                    className: "sm:order-4 md:order-7 xl:order-5",
                },
                {
                    src: ViolationIcon,
                    alt: "Violation Icon",
                    data: motorCarrier.violations.total,
                    label: pluralize(motorCarrier.violations.total, "Violation", "s"),
                    className: "sm:order-5 md:order-2 xl:order-2",
                },
                {
                    src: CleanInspectionsIcon,
                    alt: "Clean Inspections Icon",
                    data: motorCarrier.inspections.totalClean,
                    label: pluralize(motorCarrier.inspections.totalClean, "Clean Inspection", "s"),
                    className: "sm:order-8 md:order-8 xl:order-4",
                },

                {
                    src: CrashIcon,
                    alt: "Crash Icon",
                    data: motorCarrier.crashes.total,
                    label: pluralize(motorCarrier.crashes.total, "Crash", "es"),
                    className: "sm:order-6 md:order-4 xl:order-6",
                },
            ],
            secondary: [
                {
                    src: BuildingIcon,
                    alt: "Building Icon",
                    data: motorCarrier.name,
                    label: "Company Name",
                },
                {
                    src: FmcsaIcon,
                    alt: "DOT",
                    data: motorCarrier.dot,
                    label: "DOT",
                },
                {
                    alt: "Calendar Icon",
                    data: motorCarrier.getMcs150UpdateDueDate().format("mm/dd/YYYY"),
                    label: "MCS-150 due date",
                    avatar: "MCS 150",
                },
                {
                    alt: "Calendar Icon",
                    data: motorCarrier.details.Mcs150Date ? DateTime.fromDate(motorCarrier.details.Mcs150Date).format("mm/dd/YYYY") : "n/a",
                    label: "MCS-150 Record date",
                    avatar: "MCS 150",
                },
                {
                    src: VmtIcon,
                    alt: "Vehicle Miles Traveled Icon",
                    data: formatter.format(motorCarrier.averageMilesTraveledPerUnit),
                    label: "Average Vehicle Miles Traveled",
                },
            ],
        };
    }, [formatter, motorCarrier]);

    const description = React.useMemo(() => {
        return `The data within this report comes from your MCS-150, inspections, violations, and crashes that are reported to the FMCSA from ${from} through ${to}.`;
    }, [from, to]);

    return (
        <Section>
            <div className="mb-8 flex items-center gap-4">
                <TitleGroup
                    title={
                        <div className="flex gap-4">
                            <Typography variant="h4">FMCSA Insights</Typography>
                            {motorCarrier.isOutOfService && (
                                <Link
                                    href={`https://li-public.fmcsa.dot.gov/LIVIEW/pkg_oos_process.prc_list?pv_vpath=LIVIEW&pv_show_all=N&pn_dotno=${motorCarrier.dot}`}
                                    target="_blank"
                                    className="contents"
                                    title="View OOS Orders"
                                >
                                    <div className="flex animate-[wiggle-intermittent_10s_ease-in-out_infinite_5s] items-center rounded-full bg-danger px-6 text-white hover:border-2 hover:border-danger hover:bg-white hover:text-danger">
                                        <Typography className="text-2xl ">OOS</Typography>
                                    </div>
                                </Link>
                            )}
                        </div>
                    }
                    description={description}
                    descriptionProps={{
                        className: "max-w-[1000px]",
                    }}
                    icon={<img src={FmcsaIcon} alt="FMCSA Icon" className="h-[30px]" />}
                />
            </div>
            <div className="flex flex-col gap-16">
                <div className="grid grid-cols-2 gap-4 sm:flex-col xl:flex-row">
                    <ul className="grid grid-cols-2 gap-x-4 gap-y-1 self-start justify-self-start">
                        {blocks.primary.map((options, i) => (
                            <MajorBlock key={options.label} {...options} delay={i * 200} />
                        ))}
                    </ul>
                    <div className="flex-cols flex gap-20">
                        <OutOfServiceRates className="" />
                        <IssScores className="" />
                    </div>
                </div>
                <ul className="flex sm:flex-col sm:gap-4 md:flex-row md:gap-8 lg:gap-12">
                    {blocks.secondary.map((options, i) => (
                        <MinorBlock key={options.label} secondary {...options} delay={i * 200} />
                    ))}
                </ul>
            </div>
        </Section>
    );
}

interface IBlockOption {
    data: number | string;
    label: string;
    avatar?: string;
    src?: string;
    alt?: string;
    delay?: number;
    className?: string;
    secondary?: boolean;
    tooltip?: string | null;
}
function MinorBlock({ src, alt, tooltip, avatar, data, label, delay, className, secondary }: IBlockOption): JSX.Element {
    return (
        <li className={classNames("flex items-center gap-4", className)}>
            {!secondary && <Image src={src} alt={alt} avatar={avatar} />}
            <Tooltip title={tooltip || ""}>
                <div>
                    <Transition.Fade delay={delay}>
                        <Typography className={classNames("", {})}>{data}</Typography>
                    </Transition.Fade>
                    <Typography
                        className={classNames("text-md text-gray-400", {
                            "text-sm": secondary,
                        })}
                    >
                        {label}
                    </Typography>
                </div>
            </Tooltip>
        </li>
    );
}

function MajorBlock({ src, alt, tooltip, avatar, data, label, delay, className, secondary }: IBlockOption): JSX.Element {
    return (
        <li className={classNames("grid grid-cols-[25px_3ch_max-content] items-center gap-4", className)}>
            {!secondary && <Image src={src} alt={alt} avatar={avatar} />}
            <Tooltip title={tooltip || ""}>
                <>
                    <Transition.Fade delay={delay} className="justify-self-end">
                        <Typography className={classNames("", {})}>{data}</Typography>
                    </Transition.Fade>
                    <Typography
                        className={classNames("text-md text-gray-400", {
                            "text-sm": secondary,
                        })}
                    >
                        {label}
                    </Typography>
                </>
            </Tooltip>
        </li>
    );
}

function Image({ src, alt, avatar }: Pick<IBlockOption, "src" | "alt" | "avatar">): JSX.Element {
    if (src) return <img src={src} alt={alt} className="h-[25px] w-[25px]" />;
    if (avatar) {
        return (
            <div className="flex h-[25px] w-[25px] items-center justify-center rounded-full bg-gray-100 text-center text-sm text-gray-500">
                {avatar}
            </div>
        );
    }
    return <div className="h-[25px] w-[25px] rounded-full bg-gray-100" />;
}
