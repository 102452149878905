import { PriceWithProduct, unitAmountToCurrency } from "./billingUtil";
import ProductIncluded from "./ProductIncluded";

export default function FlatPrice({ price }: { price: PriceWithProduct }) {
    return price.unit_amount ? (
        <span>
            {unitAmountToCurrency(price?.unit_amount, price?.currency)}
            {price.recurring?.usage_type === "metered" && `per ${price?.product?.unit_label || "unit"}`}
        </span>
    ) : (
        <ProductIncluded />
    );
}
