import { DriverRow } from "@deathstar/types/waypoint";
import { Alert, InputField, Popover, PrimaryButton, PrimaryCell, SecondaryButton, useSnackbar } from "@deathstar/ui";
import { EllipsisVerticalIcon, MinusCircleIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { AccessorColumnDef, CellContext, DisplayColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import { Dialog } from "../../components/dialog/Dialog";
import equipmentColumns from "../equipment/EquipmentColumns";

const mobileCell: DisplayColumnDef<DriverRow> = {
    id: "mobile-only",
    cell: function MobileDriverCell(info) {
        const row = info.row.original;

        const [changeDialogOpen, setChangeDialogOpen] = useState(false);
        const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

        return (
            <>
                <Popover>
                    <Popover.Content>
                        <Popover.Item
                            onClick={() => {
                                setChangeDialogOpen(true);
                            }}
                            disabled={info.row.original.requiresApproval}
                        >
                            <PencilSquareIcon className="h-5 w-5" />
                            <span>Edit{info.row.original.requiresApproval ? " (pending)" : ""}</span>
                        </Popover.Item>
                        <Popover.Item
                            disabled={info.row.original.requiresApproval}
                            onClick={() => {
                                setRemovalDialogOpen(true);
                            }}
                        >
                            <MinusCircleIcon className="h-5 w-5" />
                            <span>Remove</span>
                        </Popover.Item>
                    </Popover.Content>
                    <Popover.Button className="w-full space-y-1 text-left text-stone-600">
                        <div className="flex justify-between gap-2">
                            <p className="line-clamp-1 font-medium text-stone-800">{row.name}</p>
                            {row.requiresApproval && (
                                <p className="whitespace-nowrap rounded-full bg-yellow-50 px-2 text-yellow-700">
                                    Pending {row.coverageOptions?.length ? "change" : "addition"}
                                </p>
                            )}
                        </div>
                        <p className="tabular-nums">{row.license || "[No license provided]"}</p>
                        <div className="flex gap-4">
                            <p className="text-sm">Born {row.dob ? moment(row.dob).format("M/D/YY") : "[Unknown]"}</p>
                            <span>•</span>
                            <p className="text-sm">Hired {row.policyDoh ? moment(row.policyDoh).format("M/D/YY") : "[Unknown]"}</p>
                        </div>
                        <div className="flex gap-4">
                            <p className="text-sm tabular-nums">{row.policyExp} years of experience</p>
                            {row.policyOwnerOperator && (
                                <>
                                    <span>•</span>
                                    <p className="text-sm">Owner operator</p>
                                </>
                            )}
                        </div>
                        <div className="flex gap-2">
                            {row.coverageOptions?.length ? (
                                <span className="text-stone-600">
                                    {row.coverageOptions
                                        ?.map((c) => c.name)
                                        .sort()
                                        .join(", ")}
                                </span>
                            ) : (
                                <span className="text-stone-500">No coverages</span>
                            )}
                        </div>
                    </Popover.Button>
                </Popover>
                <ChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={info.row.original} />
                <RemovalDialog open={removalDialogOpen} onClose={() => setRemovalDialogOpen(false)} row={info.row.original} />
            </>
        );
    },
    meta: { responsive: true },
};

const nameColumn: AccessorColumnDef<DriverRow> = {
    accessorFn: (row) => row.name,
    cell: PrimaryCell,
    header: "Name",
};

const licenseColumn: AccessorColumnDef<DriverRow> = {
    accessorFn: (row) => row.license,
    header: "License",
};

const dobColumn: AccessorColumnDef<DriverRow> = {
    accessorFn: (row) => row.dob,
    id: "dob",
    header: () => <p className="text-right">DOB</p>,
    cell: (info) => <span>{info.getValue() ? moment.utc(info.getValue() as string).format("MM/DD/YYYY") : null}</span>,
    enableGlobalFilter: false,
    meta: {
        align: "right",
        classes: { cell: "tabular-nums" },
    },
    sortingFn: "datetime",
};

const dohColumn: AccessorColumnDef<DriverRow> = {
    accessorFn: (row) => row.policyDoh,
    id: "doh",
    header: () => <p className="text-right">Date hired</p>,
    cell: (info) => <span>{info.getValue() ? moment.utc(info.getValue() as string).format("MM/DD/YYYY") : null}</span>,
    enableGlobalFilter: false,
    meta: {
        align: "right",
        classes: { cell: "tabular-nums" },
    },
    sortingFn: "datetime",
};

const expColumn: AccessorColumnDef<DriverRow> = {
    id: "exp",
    accessorFn: (row) => row.policyExp,
    header: () => <p className="text-right">Experience (yrs)</p>,
    meta: {
        align: "right",
        classes: { cell: "tabular-nums" },
    },
    enableGlobalFilter: false,
    sortingFn: "alphanumeric",
};

const actionsColumn: DisplayColumnDef<DriverRow> = {
    id: "actions",
    cell: function ActionsCell(info: CellContext<DriverRow, void>) {
        const [changeDialogOpen, setChangeDialogOpen] = useState(false);
        const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

        return (
            <>
                <Popover>
                    <Popover.Content>
                        <Popover.Item
                            onClick={() => {
                                setChangeDialogOpen(true);
                            }}
                            disabled={info.row.original.requiresApproval}
                        >
                            <PencilSquareIcon className="h-5 w-5" />
                            <span>Edit{info.row.original.requiresApproval ? " (pending)" : ""}</span>
                        </Popover.Item>
                        <Popover.Item
                            disabled={info.row.original.requiresApproval}
                            onClick={() => {
                                setRemovalDialogOpen(true);
                            }}
                        >
                            <MinusCircleIcon className="h-5 w-5" />
                            <span>Remove</span>
                        </Popover.Item>
                    </Popover.Content>
                    <Popover.Button className="rounded p-1 hover:bg-stone-100">
                        <EllipsisVerticalIcon className="h-5 w-5" />
                    </Popover.Button>
                </Popover>
                <ChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={info.row.original} />
                <RemovalDialog open={removalDialogOpen} onClose={() => setRemovalDialogOpen(false)} row={info.row.original} />
            </>
        );
    },
};

export function RemovalDialog({ row, open, onClose }: { row: DriverRow; open: boolean; onClose(): void }) {
    const [removalDate, setRemovalDate] = useState(moment().format("YYYY-MM-DD"));

    const removeDriver = api.drivers.useRequestRemoval(row.accountId, {
        onSuccess: () => {
            onClose();
        },
    });

    const removalDateError = useMemo(() => {
        const m = moment(removalDate);
        if (!m.isValid()) {
            return <Alert variant="warning">Invalid date</Alert>;
        }
        if (m.isAfter(moment(), "day")) {
            return (
                <Alert variant="warning">
                    If you want to remove this driver at a future date, either come back here when you are ready or call our office
                </Alert>
            );
        }

        if (moment().date() >= 15 && m.isBefore(moment(), "month")) {
            return <Alert variant="warning">Date is too far in the past</Alert>;
        }

        if (moment().date() < 15 && m.isBefore(moment().subtract(1, "month"), "month")) {
            return <Alert variant="warning">Date is too far in the past</Alert>;
        }

        return null;
    }, [removalDate]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                //noop
            }}
        >
            <div className="space-y-8 p-4">
                <h1 className="text-xl font-bold">Are you sure?</h1>
                <p className="text-stone-700">Please confirm that you want to remove the following driver from your coverages:</p>
                <div className="ml-6 flex items-center gap-2">
                    <MinusCircleIcon className="h-4 w-4 text-red-600" />
                    <p>
                        {row.name} {row.license}
                    </p>
                </div>
                <InputField value={removalDate} onChange={(e) => setRemovalDate(e.target.value)} type="date" label="Date removed" />
                {removalDateError}
                <div className="flex w-full justify-end gap-6">
                    <SecondaryButton onClick={() => onClose()} disabled={removeDriver.isLoading}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            removeDriver.mutate({ id: row.id, requestDate: removalDate });
                        }}
                        disabled={removeDriver.isLoading || !!removalDateError}
                    >
                        {removeDriver.isLoading ? (
                            <>
                                <CircularProgress size="1rem" classes={{ circle: "text-white" }} />
                                <span>Removing...</span>
                            </>
                        ) : (
                            "Remove"
                        )}
                    </PrimaryButton>
                </div>
            </div>
        </Dialog>
    );
}

export function ChangeDialog({ row, open, onClose }: { row: DriverRow; open: boolean; onClose(): void }) {
    const { mutate: requestChange, isLoading } = api.drivers.useRequestChange(row.accountId, {
        onSuccess: () => {
            onClose();
            useSnackbar.add("Request submitted", { variant: "success" });
        },
        onError: (error) => {
            if (error.status === 403) {
                useSnackbar.add("You do not have permission to manage drivers.", {
                    variant: "error",
                });
            } else {
                useSnackbar.add("Could not submit your request. Please try again later.", {
                    variant: "error",
                });
            }
        },
    });

    const form = useForm({ defaultValues: { requestComment: "", requestDate: new Date().toISOString().substring(0, 10) } });

    return (
        <Dialog open={open} onClose={onClose} className="w-screen max-w-[95vw] sm:max-w-sm">
            <form
                className="w-full space-y-4 p-4"
                onSubmit={form.handleSubmit((data) => {
                    requestChange({ ...data, id: row.id });
                })}
            >
                <p className="text-stone-500">Change request for driver {row.name}</p>
                <InputField
                    label="Date of change"
                    type="date"
                    {...form.register("requestDate", {
                        required: true,
                        validate: (value) => (value && moment(value).isSameOrAfter(moment(), "day")) || "Date must be today or later",
                    })}
                    required
                />
                <InputField
                    label="Change description"
                    {...form.register("requestComment", {
                        required: true,
                        maxLength: { value: 256, message: "Description cannot be more than 256 characters" },
                    })}
                    required
                    list="reason-list"
                />
                <datalist id="reason-list">
                    <option value="Correct an error..."></option>
                </datalist>
                <p className="text-sm text-stone-500">
                    Please be as verbose and clear as possible, providing all the necessary information to make the requested change.
                </p>
                <p className="text-red-600">
                    {Object.values(form.formState.errors)
                        .map((error) => error.message)
                        .join("; ")}
                </p>
                <PrimaryButton type="submit" className="float-right" disabled={isLoading || row.requiresApproval}>
                    Submit request
                </PrimaryButton>
            </form>
        </Dialog>
    );
}

export const columns: (AccessorColumnDef<DriverRow> | DisplayColumnDef<DriverRow>)[] = [
    mobileCell,
    equipmentColumns.pending as AccessorColumnDef<DriverRow>,
    equipmentColumns.upcomingDate as AccessorColumnDef<DriverRow>,
    nameColumn,
    licenseColumn,
    dobColumn,
    dohColumn,
    expColumn,
    equipmentColumns.coverages as AccessorColumnDef<DriverRow>,
    actionsColumn,
];

export const previewColumns: (AccessorColumnDef<DriverRow> | DisplayColumnDef<DriverRow>)[] = [
    equipmentColumns.pending as AccessorColumnDef<DriverRow>,
    nameColumn,
    licenseColumn,
    dobColumn,
    dohColumn,
    expColumn,
    equipmentColumns.coverages as AccessorColumnDef<DriverRow>,
];
