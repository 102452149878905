import { DateTime, memoize } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { BasicName } from "@deathstar/types/fmcsa";
import { Inspection } from "../Inspection/Inspection";
import { MotorCarrier } from "../MotorCarrier";
import { Unit } from "../Unit/Unit";
import { Units } from "../Units/Units";
import { Violation } from "../Violation/Violation";
import { Violations } from "../Violations/Violations";

export class Inspections {
    // ========================================================================
    static sorter(order: "ASC" | "DESC"): (i1: Inspection, i2: Inspection) => number {
        return (i1: Inspection, i2: Inspection) => {
            if (i1.get("InspectionDate") < i2.get("InspectionDate")) {
                return order === "ASC" ? -1 : 1;
            }
            if (i1.get("InspectionDate") > i2.get("InspectionDate")) {
                return order === "ASC" ? 1 : -1;
            }
            return 0;
        };
    }

    // ========================================================================
    static sort(inspections: Inspection[], order: "ASC" | "DESC"): Inspection[] {
        const sortFn = Inspections.sorter(order);
        return inspections.sort(sortFn);
    }

    // ========================================================================
    static includeInvalid(inspections: Inspections): Inspections {
        return Inspections.#of(inspections.carrier, [...inspections.#inspections], { keepInvalidInspections: true });
    }

    // ========================================================================
    static of(carrier: MotorCarrier, inspections?: Inspection[]): Inspections {
        const newInspections = new Inspections(carrier);
        if (inspections) {
            newInspections.#addInspections(inspections);
        }
        return newInspections;
    }

    // ========================================================================
    static #of(carrier: MotorCarrier, inspections?: Inspection[], options?: { keepInvalidInspections: boolean }): Inspections {
        const newInspections = Inspections.of(carrier, inspections);
        if (options?.keepInvalidInspections) {
            newInspections.#keepInvalidInspections = options.keepInvalidInspections;
        }
        return newInspections;
    }

    // ========================================================================
    static from(inspections: Inspections, rawInspections?: Inspection[]): Inspections {
        const newInspections = new Inspections(inspections.#carrier);
        if (rawInspections) {
            newInspections.#addInspections(rawInspections);
        }
        return newInspections;
    }

    // ========================================================================
    static new(carrier: MotorCarrier, rawViolationsMap: Map<number, Violation.Raw[]>): Inspections {
        const { rawInspections } = MotorCarrier.getCachedFetcherResults(carrier);
        if (!rawInspections) throw new Error("Raw Inspections not found");
        const newInspections = new Inspections(carrier);
        newInspections.#addInspections(rawInspections.map((ri) => Inspection.new(carrier, ri, rawViolationsMap.get(ri.UniqueId))));
        return newInspections;
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #inspectionIds = new Set<number>();
    #inspections: Inspection[] = [];
    #keepInvalidInspections = false;
    private constructor(carrier: MotorCarrier) {
        this.#carrier = carrier;
    }

    // ========================================================================
    *[Symbol.iterator](): IterableIterator<Inspection> {
        for (const inspection of this.#inspections) {
            yield inspection;
        }
    }

    // ========================================================================
    #recalculateValidInspections = true;
    #validInspections?: Inspection[];
    #calculateValidInspections(): Inspection[] {
        this.#validInspections = this.#inspections.filter((v) => v.isValid);
        this.#recalculateValidInspections = false;
        return this.#validInspections;
    }
    /**
     * The Violations that are included in the latest BASIC scores (based on the applicable date range)
     */
    get #valid(): Inspection[] {
        if (this.#keepInvalidInspections) return this.#inspections;
        return !this.#recalculateValidInspections && this.#validInspections ? this.#validInspections : this.#calculateValidInspections();
    }
    /**
     * The Violations that are included in the latest BASIC scores (based on the applicable date range)
     */
    set #valid(violations: Inspection[]) {
        this.#validInspections = violations.filter((v) => v.isValid);
    }

    // ========================================================================
    get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    get units(): Units {
        const units = this.#valid.reduce((unitsAcc, insp) => {
            if (insp.primaryUnit) unitsAcc.push(insp.primaryUnit);
            if (insp.secondaryUnit) unitsAcc.push(insp.secondaryUnit);
            return unitsAcc;
        }, [] as Unit[]);
        return Units.of(this.#carrier, units, this.violations);
    }

    // ========================================================================
    #addInspections(inspections: Inspection[]): void {
        inspections.forEach((inspection) => {
            if (this.#inspectionIds.has(inspection.id)) return;
            this.#inspectionIds.add(inspection.id);
            this.#inspections.push(inspection);
            this.#recalculateValidInspections = true;
        });
    }

    // ========================================================================
    includeInvalid(): this {
        this.#keepInvalidInspections = true;
        this.#calculateValidInspections();
        return this;
    }

    // ========================================================================
    excludeInvalid(): this {
        this.#keepInvalidInspections = false;
        this.#calculateValidInspections();
        return this;
    }

    // ========================================================================
    getById(inspectionId: number): Inspection {
        const inspection = this.#inspections.find((insp) => insp.id === inspectionId);
        if (!inspection) {
            throw new Error("Inspection not found");
        }
        return inspection;
    }

    // ========================================================================
    get total(): number {
        return this.#valid.length;
    }

    // ========================================================================
    get totalDriver(): number {
        return this.filterByType(Inspection.Type.DRIVER).total;
    }

    // ========================================================================
    get totalVehicle(): number {
        return this.filterByType(Inspection.Type.VEHICLE).total;
    }

    // ========================================================================
    #timeWeightMap = new Map<string, number>();
    getTotalTimeWeight(date = this.carrier.dateRange.to): number {
        if (this.#timeWeightMap.has(date.format("YYYYMMDD"))) {
            return this.#timeWeightMap.get(date.format("YYYYMMDD"))!;
        }

        const timeWeight = this.#valid.reduce((total, inspection) => {
            total += inspection.getTimeWeight(date);
            return total;
        }, 0);
        this.#timeWeightMap.set(date.format("YYYYMMDD"), timeWeight);

        return timeWeight;
    }

    // ========================================================================
    getTotalWeight(): number {
        return this.#valid.reduce((total, inspection) => {
            return total + inspection.getTotalWeight();
        }, 0);
    }

    // ========================================================================
    #totalClean?: number | undefined;
    #calculateTotalClean(): number {
        this.#totalClean = this.filterClean().total;
        return this.#totalClean;
    }
    get totalClean(): number {
        return this.#totalClean ?? this.#calculateTotalClean();
    }

    // ========================================================================
    #totalDirty?: number | undefined;
    #calculateTotalDirty(): number {
        this.#totalDirty = this.filterDirty().total;
        return this.#totalDirty;
    }
    get totalDirty(): number {
        return this.#totalDirty ?? this.#calculateTotalDirty();
    }

    // ========================================================================
    #violationRate?: number | undefined;
    #calculateViolationRate(): number {
        let totalInspections = 0;
        let totalInspectionsWithViolations = 0;
        this.#valid.forEach((inspection) => {
            totalInspections += 1;
            if (inspection.violations.total > 0) {
                totalInspectionsWithViolations += 1;
            }
        });
        return totalInspectionsWithViolations / totalInspections;
    }
    get violationRate(): number {
        return this.#violationRate ?? this.#calculateViolationRate();
    }

    // ========================================================================
    /**
     * All of the `Violation` objects contained within the Inspections
     */
    get violations(): Violations {
        const violations = this.#inspections
            .map((insp) => insp.violations)
            .flat()
            .map((violations) => Array.from(violations))
            .flat();
        return Violations.of(this.#carrier, violations);
    }

    // ========================================================================
    get totalRelevantInspectionsByBasic(): {
        vehicleMaintenance: number;
        unsafeDriving: number;
        hos: number;
        controlledSubstances: number;
        hazmat: number;
        driverFitness: number;
    } {
        return Array.from(this).reduce(
            (totals, insp) => {
                if (insp.isVehicleMaintenanceInspection) {
                    totals.vehicleMaintenance += 1;
                }
                if (insp.isControlledSubstancesInspection) {
                    totals.controlledSubstances += 1;
                }
                if (insp.isDriverFitnessInspection) {
                    totals.driverFitness += 1;
                }
                if (insp.isUnsafeDrivingInspection) {
                    totals.unsafeDriving += 1;
                }
                if (insp.isHoursOfServiceInspection) {
                    totals.unsafeDriving += 1;
                }
                if (insp.isHazmatInspection) {
                    totals.hazmat += 1;
                }
                return totals;
            },
            {
                vehicleMaintenance: 0,
                unsafeDriving: 0,
                hos: 0,
                controlledSubstances: 0,
                hazmat: 0,
                driverFitness: 0,
            }
        );
    }

    // ========================================================================
    /**
     * Returns an Inspections object of inspections expiring within N days
     */
    filterExpiring(): Inspections {
        const expiring = this.#valid.reduce((violations, insp) => {
            if (insp.isExpiring) {
                violations.push(insp);
            }
            return violations;
        }, [] as Inspection[]);
        return Inspections.of(this.#carrier, expiring);
    }

    // ========================================================================
    filterNew(): Inspections {
        const newInspections = this.#valid.reduce((violations, insp) => {
            if (insp.isNew) {
                violations.push(insp);
            }
            return violations;
        }, [] as Inspection[]);
        return Inspections.of(this.#carrier, newInspections);
    }

    // ========================================================================
    filterExpiringWeight(): Inspections {
        this.violations.filterExpiringWeight();
        const inspectionsWithExpiringPoints = this.#valid.filter((insp) => {
            if (insp.hasExpiringWeight) return insp;
            return null;
        });
        return Inspections.of(this.#carrier, inspectionsWithExpiringPoints);
    }

    // ========================================================================
    sortByDate(order: "ASC" | "DESC" = "DESC"): this {
        Inspections.sort(this.#valid, order);
        Inspections.sort(this.#inspections, order);
        return this;
    }

    // ========================================================================
    /**
     * Filters the Inspections by date.
     * @param date the date used in the filter
     */
    filterOccurredAfterDate(date: Date): Inspections {
        const inspections = this.#valid.filter((insp) => {
            if (insp.occurredAfterDate(date)) return insp;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    /**
     * Filters the Inspections by date.
     * @param date the date used in the filter
     */
    filterOccurredBeforeDate(date: Date): Inspections {
        const inspections = this.#valid.filter((insp) => {
            if (insp.occurredBeforeDate(date)) return insp;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    /**
     * Filters the Inspections by date.
     * @param date the date used in the filter
     */
    filterOccurredOnDate(date: Date): Inspections {
        const inspections = this.#valid.filter((insp) => {
            if (insp.occurredOnDate(date)) return insp;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    #filteredByDateRangeMap: Map<string, Inspections> = new Map();
    #calculateFilteredByDateRange({ from, to }: { from: DateTime; to: DateTime }): Inspections {
        const dateRangeId = `${from.format("YYYYMMDD")}-${to.format("YYYYMMDD")}`;
        if (this.#filteredByDateRangeMap.has(dateRangeId)) {
            return this.#filteredByDateRangeMap.get(dateRangeId)!;
        }
        const inspections = Inspections.of(
            this.#carrier,
            this.#inspections.filter((insp) => {
                if (insp.date.isBetween(from, to)) return insp;
                return null;
            })
        );
        this.#filteredByDateRangeMap.set(dateRangeId, inspections);
        return inspections;
    }
    /**
     * Filters the Inspections by date range.
     */
    filterByDateRange({ from, to }: { from: DateTime; to: DateTime }): Inspections {
        return this.#calculateFilteredByDateRange({ from, to });
    }

    // ========================================================================
    /**
     * Filters the Inspections by their "IsValid" indicator. This can be used to
     * filter out inspections that were potentially DataQ'd.
     */
    @memoize()
    filterByInvalid(): Inspections {
        const inspections = this.#inspections.filter((insp) => {
            if (!insp.isValid) return insp;
            return null;
        });
        return Inspections.#of(this.#carrier, inspections, { keepInvalidInspections: true });
    }

    // ========================================================================
    /**
     * Filters and returns only "Clean" inspections (Inspections with 0 violations).
     */
    filterClean(): Inspections {
        const inspections = this.#valid.filter((inspection) => {
            if (inspection.isClean) return inspection;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    /**
     * Filters and returns only "Clean" inspections (Inspections with 0 violations).
     */
    filterDirty(): Inspections {
        const inspections = this.#valid.filter((inspection) => {
            if (inspection.isDirty) return inspection;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    filterByLevel(options: Inspections.IFilterInspectionLevelsOptions): Inspections {
        let inspections: Inspection[];
        if (options.levels) {
            inspections = this.#valid.filter((inspection) => {
                if (options.levels.includes(inspection.get("InspectionLevel"))) return inspection;
                return null;
            });
        } else {
            inspections = this.#valid.filter((inspection) => {
                if (options.levelIds.includes(inspection.get("InspectionLevelId"))) return inspection;
                return null;
            });
        }
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    filterByType(type: Inspection.Type): Inspections {
        const inspections = this.#valid.filter((inspection) => {
            if (
                (type === Inspection.Type.DRIVER && inspection.type.driver) ||
                (type === Inspection.Type.VEHICLE && inspection.type.vehicle) ||
                (type === Inspection.Type.HAZMAT && inspection.type.hazmat)
            )
                return inspection;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    filterByOutOfService(): Inspections {
        const inspections = this.#valid.filter((inspection) => {
            if (inspection.isOutOfService) return inspection;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    filterByTypeOutOfService(type: Inspection.Type): Inspections {
        const inspections = this.#valid.filter((inspection) => {
            if (type === Inspection.Type.DRIVER && inspection.isDriverOutOfService) return inspection;
            if (type === Inspection.Type.VEHICLE && inspection.isVehicleOutOfService) return inspection;
            if (type === Inspection.Type.HAZMAT && inspection.isHazmatOutOfService) return inspection;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    filterByHazmatPlacardRequired(): Inspections {
        const inspections = this.#valid.filter((inspection) => {
            if (inspection.get("HazmatPlacardRequired")) return inspection;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    filterByBasicViolation(basic: BasicName): Inspections {
        const inspections = this.#valid.filter((inspection) => inspection.hasBasicViolation(basic));
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    /**
     * Filters the Inspections by the given `Inspection` field and value.
     * @param field `Inspection` field to filter by
     * @param value Value to filter by
     * @returns `Inspections` object with only the `Inspection` objects that match the filter
     */
    filterByField<Field extends keyof Inspection.Raw>(field: Field, value: Inspection.Raw[Field]): Inspections {
        const inspections = this.#valid.filter((insp) => {
            if (insp.get(field) === value) return insp;
            return null;
        });
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    json(): Inspection[] {
        return this.#inspections;
    }

    // ========================================================================
    raw(): Inspection.Raw[] {
        return this.#inspections.map((insp) => insp.raw());
    }

    // ========================================================================
    array(options?: { valid?: boolean }): Inspection[] {
        if (options?.valid) return Array.from(this.#valid) ?? [];
        return Array.from(this.#inspections) ?? [];
    }

    // ========================================================================
    take(amount: number): Inspections {
        const newInspections = new Inspections(this.#carrier);
        newInspections.#addInspections(Array.from(this).slice(0, amount));
        return newInspections;
    }
}

// ========================================================================
export namespace Inspections {
    interface IFilterInspectionLevelsByLevelIds {
        levels?: never;
        levelIds: FMCSA.InspectionLevelId[] | number[];
    }
    interface IFilterInspectionLevelsByLevelNames {
        levels: FMCSA.InspectionLevel[];
        levelIds?: never;
    }
    export type IFilterInspectionLevelsOptions = IFilterInspectionLevelsByLevelIds | IFilterInspectionLevelsByLevelNames;
    export interface InitOptions {
        carrier: MotorCarrier;
        fetcher: MotorCarrier.IFetcher;
        options: {
            targetDate: Date;
        };
    }
}
