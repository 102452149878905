import { AttributeOption } from "./AttributeOption";
import { Policy } from "./Policy/Policy";

export interface CoverageOptionAttachments {
    rates: boolean;
    tractors: boolean;
    trailers: boolean;
    drivers: boolean;
    businessAutos: boolean;
    tools: boolean;
    attributes: boolean;
    modifications: boolean;
    locations: boolean;
    underlyingCoverages: boolean;
}

export class CoverageOption {
    static sortMetadata<T extends CoverageOption.IMetadata>(metadata: T[]): T[] {
        return metadata.sort((a, b) => a.defaultSortOrder - b.defaultSortOrder);
    }

    static getMetadata(coverageOptionId: CoverageOption.Id): CoverageOption.IMetadata {
        return idToMetadataMap.get(coverageOptionId);
    }

    static getMetadataFromAbbreviation(abbreviation: CoverageOption.Abbreviation): CoverageOption.IMetadataWithAbbreviation {
        const metadata = abbrToMetadataMap.get(abbreviation);
        return {
            ...metadata,
            abbreviation,
        };
    }

    static getMetadataFromPolicyDescription(description: Policy["description"]): CoverageOption.IMetadataWithAbbreviation[] {
        const metadata: CoverageOption.IMetadataWithAbbreviation[] = [];
        const descriptionParts = description.split(",");
        descriptionParts.forEach((part) => {
            const abbr = part.trim() as CoverageOption.Abbreviation;
            metadata.push({
                ...CoverageOption.getMetadataFromAbbreviation(abbr),
                abbreviation: abbr,
            });
        });
        return metadata;
    }

    static getMetadataFromPolicyDescriptions(hasDecriptions: Pick<Policy, "description">[]): CoverageOption.IMetadataWithAbbreviation[] {
        return hasDecriptions
            .map((p) => p.description)
            .reduce((acc, desc) => {
                const metadata = CoverageOption.getMetadataFromPolicyDescription(desc);
                acc.push(...metadata);
                return acc;
            }, [] as CoverageOption.IMetadataWithAbbreviation[]);
    }

    static getAvailableAttachments(
        coverageOptionId: CoverageOption.Id,
        policy?: Policy.IHasAttributes & { coverages: { linkedDrivers: unknown[] }[] }
    ): CoverageOptionAttachments {
        let attachmentOptions: CoverageOptionAttachments = null;

        switch (coverageOptionId) {
            case CoverageOption.Id.TRUCKERS_AUTO_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: true,
                    trailers: true,
                    drivers: true,
                    businessAutos: true,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE:
                attachmentOptions = {
                    rates: false,
                    tractors: true,
                    trailers: true,
                    drivers: false,
                    businessAutos: true,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.MOTOR_TRUCK_CARGO:
                attachmentOptions = {
                    rates: false,
                    tractors: true,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.GENERAL_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.PROPERTY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.NON_TRUCKING_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: true,
                    trailers: true,
                    drivers: true,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.OCCUPATIONAL_ACCIDENT:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: true,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.BONDS:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: false,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.BUSINESS_AUTO_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: true,
                    businessAutos: true,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.CONTINGENT_AUTO_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: true,
                };
                break;
            case CoverageOption.Id.CONTINGENT_CARGO:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: true,
                };
                break;
            case CoverageOption.Id.CRIME:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.CYBER:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.DIRECTORS_AND_OFFICERS_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.EMPLOYERS_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.EMPLOYMENT_PRACTICE_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.EXCESS_CARGO:
                attachmentOptions = {
                    rates: false,
                    tractors: true,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: true,
                };
                break;
            case CoverageOption.Id.EXCESS_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: true,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: true,
                };
                break;
            case CoverageOption.Id.FLOOD:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.GARAGE:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.INLAND_MARINE:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: true,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.LIQUOR_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.POLLUTION:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.PASSENGER:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: false,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.PROFESSIONAL_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.UNDERGROUND_STORAGE_TANK:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.WAREHOUSE_LEGAL_LIABILITY:
                attachmentOptions = {
                    rates: false,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            case CoverageOption.Id.WORKERS_COMPENSATION:
                attachmentOptions = {
                    rates: true,
                    tractors: false,
                    trailers: false,
                    drivers: false,
                    businessAutos: false,
                    tools: false,
                    attributes: true,
                    modifications: false,
                    locations: true,
                    underlyingCoverages: false,
                };
                break;
            default:
                attachmentOptions = {
                    rates: false,
                    tractors: true,
                    trailers: true,
                    drivers: false,
                    businessAutos: true,
                    tools: true,
                    attributes: true,
                    modifications: true,
                    locations: true,
                    underlyingCoverages: true,
                };
                break;
        }

        if (
            policy?.attributes &&
            Policy.hasSomeAttribute(policy, [
                AttributeOption.Id.DRIVER_REQUIREMENTS,
                AttributeOption.Id.DRIVER_SURCHARGES,
                AttributeOption.Id.SCHEDULED_DRIVER,
            ])
        ) {
            attachmentOptions.drivers = true;
        }

        if (policy?.coverages && !attachmentOptions.drivers) {
            policy.coverages.forEach((coverage) => {
                if (coverage.linkedDrivers?.length) {
                    attachmentOptions.drivers = true;
                }
            });
        }

        return attachmentOptions;
    }

    id: number;
    name: CoverageOption.Name;
    type: CoverageOption.Type;
    amsCode: string;
    abbreviation?: CoverageOption.Abbreviation | null;
}

export namespace CoverageOption {
    export type Name =
        | "Agency Allocated Expense"
        | "Auto Liability"
        | "Bond"
        | "Business Auto Liability"
        | "Contingent Auto Liability"
        | "Contingent Cargo"
        | "Contingent Liability"
        | "Crime"
        | "Cyber Liability"
        | "Directors and Officers Liability"
        | "Employer's Liability"
        | "Employment Practice Liability"
        | "Excess Cargo"
        | "Excess Liability"
        | "Flood"
        | "Garage"
        | "General Liability"
        | "Inland Marine"
        | "Liquor Liability"
        | "Motor Truck Cargo"
        | "Non-Trucking Liability"
        | "Occupational Accident"
        | "Passenger"
        | "Physical Damage"
        | "Pollution Liability"
        | "Professional Liability"
        | "Property"
        | "Underground Storage Tank"
        | "Warehouse Legal Liability"
        | "Workers Compensation";

    export type Abbreviation =
        | "AAE"
        | "AL"
        | "APD"
        | "BA"
        | "BAL"
        | "BAPD"
        | "BOND"
        | `BOND (${string})`
        | "Crime"
        | "CTAL"
        | "CTCG"
        | "CTL"
        | "Cyber"
        | "D&O"
        | "EPLI"
        | "EL"
        | "FLOOD"
        | "GARAGE"
        | "GL"
        | "HAPD"
        | "INLM"
        | "LL"
        | "MTC"
        | "NTL"
        | "OCAC"
        | "PASS"
        | "PL"
        | "POLL"
        | "PROP"
        | "UST"
        | "WARE"
        | "WC"
        | "XS"
        | "XSAL"
        | "XSGL"
        | "XSMTC";

    export enum Type {
        AGENCY_ALLOCATED_EXPENSE = "AGENCY_ALLOCATED_EXPENSE",
        BONDS = "BONDS",
        BUSINESS_AUTO_LIABILITY = "BUSINESS_AUTO_LIABILITY",
        CONTINGENT_AUTO_LIABILITY = "CONTINGENT_AUTO_LIABILITY",
        CONTINGENT_CARGO = "CONTINGENT_CARGO",
        CONTINGENT_LIABILITY = "CONTINGENT_LIABILITY",
        CRIME = "CRIME",
        CYBER = "CYBER",
        DIRECTORS_AND_OFFICERS_LIABILITY = "DIRECTORS_AND_OFFICERS_LIABILITY",
        EMPLOYERS_LIABILITY = "EMPLOYERS_LIABILITY",
        EMPLOYMENT_PRACTICE_LIABILITY = "EMPLOYMENT_PRACTICE_LIABILITY",
        EXCESS_CARGO = "EXCESS_CARGO",
        EXCESS_LIABILITY = "EXCESS_LIABILITY",
        FLOOD = "FLOOD",
        GARAGE = "GARAGE",
        GENERAL_LIABILITY = "GENERAL_LIABILITY",
        INLAND_MARINE = "INLAND_MARINE",
        LIQUOR_LIABILITY = "LIQUOR_LIABILITY",
        MOTOR_TRUCK_CARGO = "MOTOR_TRUCK_CARGO",
        NON_TRUCKING_LIABILITY = "NON_TRUCKING_LIABILITY",
        OCCUPATIONAL_ACCIDENT = "OCCUPATIONAL_ACCIDENT",
        PASSENGER = "PASSENGER",
        POLLUTION = "POLLUTION",
        PROFESSIONAL_LIABILITY = "PROFESSIONAL_LIABILITY",
        PROPERTY = "PROPERTY",
        TRUCKERS_AUTO_LIABILITY = "TRUCKERS_AUTO_LIABILITY",
        TRUCKERS_PHYSICAL_DAMAGE = "TRUCKERS_PHYSICAL_DAMAGE",
        UNDERGROUND_STORAGE_TANK = "UNDERGROUND_STORAGE_TANK",
        WAREHOUSE_LEGAL_LIABILITY = "WAREHOUSE_LEGAL_LIABILITY",
        WORKERS_COMPENSATION = "WORKERS_COMPENSATION",
    }

    export enum Id {
        AGENCY_ALLOCATED_EXPENSE = 1,
        TRUCKERS_AUTO_LIABILITY = 2,
        BONDS = 3,
        BUSINESS_AUTO_LIABILITY = 4,
        CONTINGENT_AUTO_LIABILITY = 5,
        CONTINGENT_CARGO = 6,
        CONTINGENT_LIABILITY = 30,
        CRIME = 7,
        CYBER = 8,
        DIRECTORS_AND_OFFICERS_LIABILITY = 9,
        EMPLOYERS_LIABILITY = 10,
        EMPLOYMENT_PRACTICE_LIABILITY = 11,
        EXCESS_CARGO = 12,
        EXCESS_LIABILITY = 13,
        FLOOD = 14,
        GARAGE = 15,
        GENERAL_LIABILITY = 16,
        INLAND_MARINE = 17,
        LIQUOR_LIABILITY = 18,
        MOTOR_TRUCK_CARGO = 19,
        NON_TRUCKING_LIABILITY = 20,
        OCCUPATIONAL_ACCIDENT = 21,
        PASSENGER = 22,
        TRUCKERS_PHYSICAL_DAMAGE = 23,
        POLLUTION = 24,
        PROFESSIONAL_LIABILITY = 25,
        PROPERTY = 26,
        UNDERGROUND_STORAGE_TANK = 27,
        WAREHOUSE_LEGAL_LIABILITY = 28,
        WORKERS_COMPENSATION = 29,
    }

    export interface IMetadata {
        id: Id;
        defaultSortOrder: number;
        defaultAbbreviation: Abbreviation;
        name: Name;
    }

    export interface IMetadataWithAbbreviation extends IMetadata {
        abbreviation: Abbreviation;
    }
}

const idToMetadataMap = new Map<CoverageOption.Id, CoverageOption.IMetadata>([
    [
        CoverageOption.Id.AGENCY_ALLOCATED_EXPENSE,
        {
            id: CoverageOption.Id.AGENCY_ALLOCATED_EXPENSE,
            defaultSortOrder: 0,
            defaultAbbreviation: "AAE",
            name: "Agency Allocated Expense",
        },
    ],
    [
        CoverageOption.Id.GENERAL_LIABILITY,
        {
            id: CoverageOption.Id.GENERAL_LIABILITY,
            defaultSortOrder: 1,
            defaultAbbreviation: "GL",
            name: "General Liability",
        },
    ],
    [
        CoverageOption.Id.TRUCKERS_AUTO_LIABILITY,
        {
            id: CoverageOption.Id.TRUCKERS_AUTO_LIABILITY,
            defaultSortOrder: 2,
            defaultAbbreviation: "AL",
            name: "Auto Liability",
        },
    ],
    [
        CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE,
        {
            id: CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE,
            defaultSortOrder: 3,
            defaultAbbreviation: "APD",
            name: "Physical Damage",
        },
    ],
    [
        CoverageOption.Id.MOTOR_TRUCK_CARGO,
        {
            id: CoverageOption.Id.MOTOR_TRUCK_CARGO,
            defaultSortOrder: 4,
            defaultAbbreviation: "MTC",
            name: "Motor Truck Cargo",
        },
    ],
    [
        CoverageOption.Id.CONTINGENT_CARGO,
        {
            id: CoverageOption.Id.CONTINGENT_CARGO,
            defaultSortOrder: 5,
            defaultAbbreviation: "CTCG",
            name: "Contingent Cargo",
        },
    ],
    [
        CoverageOption.Id.EXCESS_CARGO,
        {
            id: CoverageOption.Id.EXCESS_CARGO,
            defaultSortOrder: 6,
            defaultAbbreviation: "XSMTC",
            name: "Excess Cargo",
        },
    ],
    [
        CoverageOption.Id.PROPERTY,
        {
            id: CoverageOption.Id.PROPERTY,
            defaultSortOrder: 7,
            defaultAbbreviation: "PROP",
            name: "Property",
        },
    ],
    [
        CoverageOption.Id.WORKERS_COMPENSATION,
        {
            id: CoverageOption.Id.WORKERS_COMPENSATION,
            defaultSortOrder: 8,
            defaultAbbreviation: "WC",
            name: "Workers Compensation",
        },
    ],
    [
        CoverageOption.Id.WAREHOUSE_LEGAL_LIABILITY,
        {
            id: CoverageOption.Id.WAREHOUSE_LEGAL_LIABILITY,
            defaultSortOrder: 9,
            defaultAbbreviation: "WARE",
            name: "Warehouse Legal Liability",
        },
    ],
    [
        CoverageOption.Id.BONDS,
        {
            id: CoverageOption.Id.BONDS,
            defaultSortOrder: 10,
            defaultAbbreviation: "BOND",
            name: "Bond",
        },
    ],
    [
        CoverageOption.Id.BUSINESS_AUTO_LIABILITY,
        {
            id: CoverageOption.Id.BUSINESS_AUTO_LIABILITY,
            defaultSortOrder: 11,
            defaultAbbreviation: "BA",
            name: "Business Auto Liability",
        },
    ],
    [
        CoverageOption.Id.CONTINGENT_AUTO_LIABILITY,
        {
            id: CoverageOption.Id.CONTINGENT_AUTO_LIABILITY,
            defaultSortOrder: 12,
            defaultAbbreviation: "CTAL",
            name: "Contingent Auto Liability",
        },
    ],
    [
        CoverageOption.Id.CONTINGENT_LIABILITY,
        {
            id: CoverageOption.Id.CONTINGENT_LIABILITY,
            defaultSortOrder: 13,
            defaultAbbreviation: "CTL",
            name: "Contingent Liability",
        },
    ],
    [
        CoverageOption.Id.CRIME,
        {
            id: CoverageOption.Id.CRIME,
            defaultSortOrder: 14,
            defaultAbbreviation: "Crime",
            name: "Crime",
        },
    ],
    [
        CoverageOption.Id.CYBER,
        {
            id: CoverageOption.Id.CYBER,
            defaultSortOrder: 15,
            defaultAbbreviation: "Cyber",
            name: "Cyber Liability",
        },
    ],
    [
        CoverageOption.Id.DIRECTORS_AND_OFFICERS_LIABILITY,
        {
            id: CoverageOption.Id.DIRECTORS_AND_OFFICERS_LIABILITY,
            defaultSortOrder: 16,
            defaultAbbreviation: "D&O",
            name: "Directors and Officers Liability",
        },
    ],
    [
        CoverageOption.Id.EMPLOYERS_LIABILITY,
        {
            id: CoverageOption.Id.EMPLOYERS_LIABILITY,
            defaultSortOrder: 17,
            defaultAbbreviation: "EL",
            name: "Employer's Liability",
        },
    ],
    [
        CoverageOption.Id.EMPLOYMENT_PRACTICE_LIABILITY,
        {
            id: CoverageOption.Id.EMPLOYMENT_PRACTICE_LIABILITY,
            defaultSortOrder: 18,
            defaultAbbreviation: "EPLI",
            name: "Employment Practice Liability",
        },
    ],
    [
        CoverageOption.Id.EXCESS_LIABILITY,
        {
            id: CoverageOption.Id.EXCESS_LIABILITY,
            defaultSortOrder: 19,
            defaultAbbreviation: "XS",
            name: "Excess Liability",
        },
    ],
    [
        CoverageOption.Id.FLOOD,
        {
            id: CoverageOption.Id.FLOOD,
            defaultSortOrder: 20,
            defaultAbbreviation: "FLOOD",
            name: "Flood",
        },
    ],
    [
        CoverageOption.Id.GARAGE,
        {
            id: CoverageOption.Id.GARAGE,
            defaultSortOrder: 21,
            defaultAbbreviation: "GARAGE",
            name: "Garage",
        },
    ],
    [
        CoverageOption.Id.INLAND_MARINE,
        {
            id: CoverageOption.Id.INLAND_MARINE,
            defaultSortOrder: 22,
            defaultAbbreviation: "INLM",
            name: "Inland Marine",
        },
    ],
    [
        CoverageOption.Id.LIQUOR_LIABILITY,
        {
            id: CoverageOption.Id.LIQUOR_LIABILITY,
            defaultSortOrder: 23,
            defaultAbbreviation: "LL",
            name: "Liquor Liability",
        },
    ],
    [
        CoverageOption.Id.NON_TRUCKING_LIABILITY,
        {
            id: CoverageOption.Id.NON_TRUCKING_LIABILITY,
            defaultSortOrder: 24,
            defaultAbbreviation: "NTL",
            name: "Non-Trucking Liability",
        },
    ],
    [
        CoverageOption.Id.OCCUPATIONAL_ACCIDENT,
        {
            id: CoverageOption.Id.OCCUPATIONAL_ACCIDENT,
            defaultSortOrder: 25,
            defaultAbbreviation: "OCAC",
            name: "Occupational Accident",
        },
    ],
    [
        CoverageOption.Id.PASSENGER,
        {
            id: CoverageOption.Id.PASSENGER,
            defaultSortOrder: 26,
            defaultAbbreviation: "PASS",
            name: "Passenger",
        },
    ],
    [
        CoverageOption.Id.POLLUTION,
        {
            id: CoverageOption.Id.POLLUTION,
            defaultSortOrder: 27,
            defaultAbbreviation: "POLL",
            name: "Pollution Liability",
        },
    ],
    [
        CoverageOption.Id.PROFESSIONAL_LIABILITY,
        {
            id: CoverageOption.Id.PROFESSIONAL_LIABILITY,
            defaultSortOrder: 28,
            defaultAbbreviation: "PL",
            name: "Professional Liability",
        },
    ],
    [
        CoverageOption.Id.UNDERGROUND_STORAGE_TANK,
        {
            id: CoverageOption.Id.UNDERGROUND_STORAGE_TANK,
            defaultSortOrder: 29,
            defaultAbbreviation: "UST",
            name: "Underground Storage Tank",
        },
    ],
]);

const abbrToMetadataMap = new Map<CoverageOption.Abbreviation, CoverageOption.IMetadata>([
    ["AAE", idToMetadataMap.get(CoverageOption.Id.AGENCY_ALLOCATED_EXPENSE)],
    ["AL", idToMetadataMap.get(CoverageOption.Id.TRUCKERS_AUTO_LIABILITY)],
    ["APD", idToMetadataMap.get(CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE)],
    ["BA", idToMetadataMap.get(CoverageOption.Id.BUSINESS_AUTO_LIABILITY)],
    ["BAL", idToMetadataMap.get(CoverageOption.Id.TRUCKERS_AUTO_LIABILITY)],
    ["BAPD", idToMetadataMap.get(CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE)],
    ["BOND", idToMetadataMap.get(CoverageOption.Id.BONDS)],
    ["Crime", idToMetadataMap.get(CoverageOption.Id.CRIME)],
    ["CTAL", idToMetadataMap.get(CoverageOption.Id.CONTINGENT_AUTO_LIABILITY)],
    ["CTCG", idToMetadataMap.get(CoverageOption.Id.CONTINGENT_CARGO)],
    ["CTL", idToMetadataMap.get(CoverageOption.Id.CONTINGENT_LIABILITY)],
    ["Cyber", idToMetadataMap.get(CoverageOption.Id.CYBER)],
    ["D&O", idToMetadataMap.get(CoverageOption.Id.DIRECTORS_AND_OFFICERS_LIABILITY)],
    ["EPLI", idToMetadataMap.get(CoverageOption.Id.EMPLOYMENT_PRACTICE_LIABILITY)],
    ["EL", idToMetadataMap.get(CoverageOption.Id.EMPLOYERS_LIABILITY)],
    ["FLOOD", idToMetadataMap.get(CoverageOption.Id.FLOOD)],
    ["GARAGE", idToMetadataMap.get(CoverageOption.Id.GARAGE)],
    ["GL", idToMetadataMap.get(CoverageOption.Id.GENERAL_LIABILITY)],
    ["HAPD", idToMetadataMap.get(CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE)],
    ["INLM", idToMetadataMap.get(CoverageOption.Id.INLAND_MARINE)],
    ["LL", idToMetadataMap.get(CoverageOption.Id.LIQUOR_LIABILITY)],
    ["MTC", idToMetadataMap.get(CoverageOption.Id.MOTOR_TRUCK_CARGO)],
    ["NTL", idToMetadataMap.get(CoverageOption.Id.NON_TRUCKING_LIABILITY)],
    ["OCAC", idToMetadataMap.get(CoverageOption.Id.OCCUPATIONAL_ACCIDENT)],
    ["PASS", idToMetadataMap.get(CoverageOption.Id.PASSENGER)],
    ["PL", idToMetadataMap.get(CoverageOption.Id.PROFESSIONAL_LIABILITY)],
    ["POLL", idToMetadataMap.get(CoverageOption.Id.POLLUTION)],
    ["PROP", idToMetadataMap.get(CoverageOption.Id.PROPERTY)],
    ["UST", idToMetadataMap.get(CoverageOption.Id.UNDERGROUND_STORAGE_TANK)],
    ["WARE", idToMetadataMap.get(CoverageOption.Id.WAREHOUSE_LEGAL_LIABILITY)],
    ["WC", idToMetadataMap.get(CoverageOption.Id.WORKERS_COMPENSATION)],
    ["XS", idToMetadataMap.get(CoverageOption.Id.EXCESS_LIABILITY)],
    ["XSAL", idToMetadataMap.get(CoverageOption.Id.EXCESS_LIABILITY)],
    ["XSGL", idToMetadataMap.get(CoverageOption.Id.EXCESS_LIABILITY)],
    ["XSMTC", idToMetadataMap.get(CoverageOption.Id.EXCESS_CARGO)],
]);
