import { MotorCarrier } from "@deathstar/motor-carrier";
import { DataTable, fuzzyFilter } from "@deathstar/ui";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { animated, useSpring } from "react-spring";
import { useLocalStorage } from "web-api-hooks";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import Columns, { columns } from "./Columns";

const hideableColumns: DataTable.HideableColumn[] = [
    {
        label: "DataQ",
        ids: [Columns.isDataQ.id!],
    },
    {
        label: "Add Date",
        ids: [Columns.addDate.id!],
    },
    {
        label: "Removal Date",
        ids: [Columns.removalDate.id!],
    },
    {
        label: "Report Number",
        ids: [Columns.reportNumber.id!],
    },
    {
        label: "Status",
        ids: [Columns.status.id!],
    },
    {
        label: "State",
        ids: [Columns.state.id!],
    },
    {
        label: "Start Time",
        ids: [Columns.inspStartTime.id!],
    },
    {
        label: "End Time",
        ids: [Columns.inspEndTime.id!],
    },
    {
        label: "Time Duration",
        ids: [Columns.inspDuration.id!],
    },
    {
        label: "Facility",
        ids: [Columns.inspectionFacility.id!],
    },
    {
        label: "Location",
        ids: [Columns.locationDescription.id!],
    },
    {
        label: "DOT Match",
        ids: [Columns.inspConfidenceLevel.id!],
    },
    {
        label: "Locally Enforced",
        ids: [Columns.isLocalEnforcementJurisdiction.id!],
    },
    {
        label: "Traffic Enforced",
        ids: [Columns.trafficEnforced.id!],
    },
    {
        label: "Size/Weight Enforced",
        ids: [Columns.sizeWeightEnforced.id!],
    },
    {
        label: "Shipper Name",
        ids: [Columns.shipperName.id!],
    },
    {
        label: "Shipping Paper Number",
        ids: [Columns.shippingPaperNumber.id!],
    },
    {
        label: "Unit: VIN",
        ids: [Columns.unitVin.id!],
    },
    {
        label: "Unit: Year",
        ids: [Columns.unitYear.id!],
    },
    {
        label: "Unit: Make",
        ids: [Columns.unitMake.id!],
    },
    {
        label: "Unit: License",
        ids: [Columns.unitLicenseNumber.id!],
    },
    {
        label: "Unit: State",
        ids: [Columns.unitLicenseState.id!],
    },
    {
        label: "Unit: Type",
        ids: [Columns.unitType.id!],
    },
    {
        label: "Unit: Decal Number",
        ids: [Columns.unitDecalNumber.id!],
    },
    {
        label: "Unit: Combined Weight",
        ids: [Columns.unitWeight.id!],
    },
    {
        label: "Unit 2: VIN",
        ids: [Columns.unit2Vin.id!],
    },
    {
        label: "Unit 2: Year",
        ids: [Columns.unit2Year.id!],
    },
    {
        label: "Unit 2: Make",
        ids: [Columns.unit2Make.id!],
    },
    {
        label: "Unit 2: License",
        ids: [Columns.unit2LicenseNumber.id!],
    },
    {
        label: "Unit 2: State",
        ids: [Columns.unit2LicenseState.id!],
    },
    {
        label: "Unit 2: Type",
        ids: [Columns.unit2Type.id!],
    },
    {
        label: "Unit 2: Decal Number",
        ids: [Columns.unit2DecalNumber.id!],
    },
    {
        label: "HM Placard Required",
        ids: [Columns.hazmatPlacardRequiredIndicator.id!],
    },
    {
        label: "Post Accident",
        ids: [Columns.postAccidentIndicator.id!],
    },
    {
        label: Columns.isCleanIndicator.header as string,
        ids: [Columns.isCleanIndicator.id!],
    },
    {
        label: Columns.timeWeight.header as string,
        ids: [Columns.timeWeight.id!],
    },
    {
        label: Columns.totalPoints.header as string,
        ids: [Columns.totalPoints.id!],
    },
    {
        label: "Total OOS",
        ids: [Columns.totalOutOfService.id!],
    },
    {
        label: "Total Driver OOS",
        ids: [Columns.totalDriverOutOfService.id!],
    },
    {
        label: "Total Vehicle OOS",
        ids: [Columns.totalVehicleOutOfService.id!],
    },
    // ******

    {
        label: Columns.isUnsafeDrivingInspection.header as string,
        ids: [Columns.isUnsafeDrivingInspection.id!],
    },

    {
        label: Columns.isHoursOfServiceInspection.header as string,
        ids: [Columns.isHoursOfServiceInspection.id!],
    },

    {
        label: Columns.isControlledSubstanceInspection.header as string,
        ids: [Columns.isControlledSubstanceInspection.id!],
    },

    {
        label: Columns.isVehcileMaintenanceInspection.header as string,
        ids: [Columns.isVehcileMaintenanceInspection.id!],
    },

    {
        label: Columns.isDriverFitnessInspection.header as string,
        ids: [Columns.isDriverFitnessInspection.id!],
    },

    {
        label: Columns.isHazmatInspection.header as string,
        ids: [Columns.isHazmatInspection.id!],
    },
];

const AnimatedDataTable = animated(DataTable<MotorCarrier.IInspection>);

function useColumnVisibility(): [Record<string, boolean>, React.Dispatch<React.SetStateAction<Record<string, boolean>>>] {
    const [columnVisibility, setColumnVisibility] = useLocalStorage<Record<string, boolean>>("ia-inspection-table-column-visibility", {
        [Columns.addDate.id as string]: true,
        [Columns.removalDate.id as string]: true,
        [Columns.reportNumber.id as string]: true,
        [Columns.status.id as string]: false,
        [Columns.state.id as string]: true,
        [Columns.inspStartTime.id as string]: false,
        [Columns.inspEndTime.id as string]: false,
        [Columns.inspDuration.id as string]: false,
        [Columns.inspectionFacility.id as string]: false,
        [Columns.locationDescription.id as string]: false,
        [Columns.inspConfidenceLevel.id as string]: false,
        [Columns.isLocalEnforcementJurisdiction.id as string]: false,
        [Columns.trafficEnforced.id as string]: false,
        [Columns.sizeWeightEnforced.id as string]: false,
        [Columns.shipperName.id as string]: false,
        [Columns.shippingPaperNumber.id as string]: false,
        [Columns.unitVin.id as string]: true,
        [Columns.unitYear.id as string]: true,
        [Columns.unitMake.id as string]: true,
        [Columns.unitLicenseNumber.id as string]: false,
        [Columns.unitLicenseState.id as string]: false,
        [Columns.unitType.id as string]: false,
        [Columns.unitDecalNumber.id as string]: false,
        [Columns.unitWeight.id as string]: false,
        [Columns.unit2Vin.id as string]: false,
        [Columns.unit2Year.id as string]: false,
        [Columns.unit2Make.id as string]: false,
        [Columns.unit2LicenseNumber.id as string]: false,
        [Columns.unit2LicenseState.id as string]: false,
        [Columns.unit2Type.id as string]: false,
        [Columns.unit2DecalNumber.id as string]: false,
        [Columns.hazmatPlacardRequiredIndicator.id as string]: false,
        [Columns.postAccidentIndicator.id as string]: false,
        [Columns.isCleanIndicator.id as string]: true,
        [Columns.timeWeight.id as string]: false,
        [Columns.totalPoints.id as string]: true,
        [Columns.totalOutOfService.id as string]: true,
        [Columns.totalDriverOutOfService.id as string]: false,
        [Columns.totalVehicleOutOfService.id as string]: false,
        [Columns.isUnsafeDrivingInspection.id as string]: false,
        [Columns.isHoursOfServiceInspection.id as string]: false,
        [Columns.isControlledSubstanceInspection.id as string]: false,
        [Columns.isVehcileMaintenanceInspection.id as string]: false,
        [Columns.isDriverFitnessInspection.id as string]: false,
        [Columns.isHazmatInspection.id as string]: false,
    });

    React.useEffect(() => {
        setColumnVisibility((old) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newState: Record<string, any> = {};
            for (const key in old) {
                if (hideableColumns.some((c) => c.ids.includes(key))) {
                    newState[key] = old[key];
                }
            }
            return newState;
        });
    }, [setColumnVisibility]);

    return [columnVisibility, setColumnVisibility];
}

function Table(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const data = React.useMemo(() => motorCarrier.inspections.sortByDate("DESC").array(), [motorCarrier]);

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = useColumnVisibility();

    const table = useReactTable<MotorCarrier.IInspection>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        globalFilterFn: fuzzyFilter,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,
        state: {
            columnVisibility,
            columnFilters,
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
    });
    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    return (
        <div className="flex flex-col gap-2 overflow-auto p-1">
            <AnimatedDataTable style={spring} table={table} hideableColumns={hideableColumns} />
        </div>
    );
}

export function Inspections(): JSX.Element {
    return (
        <Section>
            <TitleGroup title="Inspections" description="All inspections within the report range." />

            <Table />
        </Section>
    );
}
