import { BusinessAuto, CoverageOption, InlandMarineEquipment as Tool, Tractor, Trailer } from "@deathstar/types/northstar";
import { DriverRow, EquipmentDriverChangeDto, EquipmentRow } from "@deathstar/types/waypoint";
import { Alert, InputField, Popover, PrimaryButton, PrimaryCell, SecondaryButton, useSnackbar } from "@deathstar/ui";
import {
    ArrowPathIcon,
    BuildingLibraryIcon,
    DocumentTextIcon,
    EllipsisVerticalIcon,
    MinusCircleIcon,
    PencilSquareIcon,
    ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { AccessorColumnDef, CellContext, DisplayColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import { EquipmentTypeString } from "../../api/queries/equipment";
import { Dialog } from "../../components/dialog/Dialog";

function createMobileCell<T extends Tractor | Trailer | BusinessAuto>(type: "tractors" | "trailers" | "autos") {
    return function MobileCell(info: CellContext<EquipmentRow<T>, unknown>) {
        const row = info.row.original;

        const [changeDialogOpen, setChangeDialogOpen] = useState(false);
        const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

        const attributes = [(row as unknown as Tractor).policySpare && "Spare", row.policyOwnerOperator && "Owner operator"].filter(
            Boolean
        );

        return (
            <>
                <Popover>
                    <Popover.Content>
                        <AutoIdButton type={type} row={info.row.original as EquipmentRow<Tractor>} />
                        <Popover.Item
                            onClick={() => {
                                setChangeDialogOpen(true);
                            }}
                            disabled={info.row.original.requiresApproval}
                        >
                            <PencilSquareIcon className="h-5 w-5" />
                            <span>Edit{info.row.original.requiresApproval ? " (pending)" : ""}</span>
                        </Popover.Item>
                        <Popover.Item
                            disabled={info.row.original.requiresApproval}
                            onClick={() => {
                                setRemovalDialogOpen(true);
                            }}
                        >
                            <MinusCircleIcon className="h-5 w-5" />
                            <span>Remove</span>
                        </Popover.Item>
                    </Popover.Content>
                    <Popover.Button className="w-full space-y-1 text-left text-stone-600">
                        <div className="flex justify-between gap-2">
                            <p className="line-clamp-1 font-medium text-stone-800">
                                Unit {row.unitNumber || row.vin?.slice(-4)?.toUpperCase() || `[${row.id}]`}
                            </p>
                            {row.requiresApproval && (
                                <p className="whitespace-nowrap rounded-full bg-yellow-50 px-2 text-yellow-700">
                                    Pending {row.coverageOptions?.length ? "change" : "addition"}
                                </p>
                            )}
                        </div>
                        <p className="uppercase tabular-nums">{row.vin || "[No VIN provided]"}</p>
                        <div className="flex gap-2">
                            <p className="text-sm">{row.year}</p>
                            <p className="text-sm">{row.make}</p>
                            <p className="text-sm capitalize">{row.type?.name}</p>
                        </div>
                        <div className="flex gap-2">
                            <p className="text-sm tabular-nums">
                                {row.policyAcv
                                    ? "ACV"
                                    : row.policyValue?.toLocaleString("en-us", {
                                          style: "currency",
                                          currency: "USD",
                                          maximumFractionDigits: 0,
                                      })}
                            </p>
                            {attributes.length ? `(${attributes.join(", ")})` : null}
                        </div>
                        <div>
                            <div className="flex gap-1">
                                <ShieldCheckIcon className="h-4 w-4 text-waypoint-blue" />
                                Coverages:
                            </div>
                            <div className="ml-5 flex flex-wrap gap-x-2 gap-y-1">
                                {row.coverageOptions?.length ? (
                                    <span className="text-stone-600">
                                        {row.coverageOptions
                                            ?.map((c) => c.name)
                                            .sort()
                                            .join(", ")}
                                    </span>
                                ) : (
                                    <span className="text-stone-500">[Not covered]</span>
                                )}
                            </div>
                        </div>
                        {row.additionalInterests?.length ? (
                            <div>
                                <div className="flex gap-1">
                                    <BuildingLibraryIcon className="h-4 w-4" />
                                    Additional interests:
                                </div>
                                <div className="ml-5">
                                    {row.additionalInterests.map((ai) => (
                                        <span key={ai.id}>
                                            {ai.additionalInterest?.name || "[Unknown]"}
                                            {" - "}
                                            {[
                                                ai.isAdditionalInsured && "Additional Insured",
                                                ai.isLossPayee && "Loss Payee",
                                                ai.isMortgagee && "Mortgagee",
                                                ai.isPrimaryAndNonContributory && "Primary and Non-Contributory",
                                                ai.isWaiverOfSubrogation && "Waiver of Subrogation",
                                            ]
                                                .filter(Boolean)
                                                .join(", ")}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </Popover.Button>
                </Popover>
                <ChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={info.row.original} type={type} />
                <RemovalDialog open={removalDialogOpen} onClose={() => setRemovalDialogOpen(false)} row={info.row.original} type={type} />
            </>
        );
    };
}

const mobileTractorCell: DisplayColumnDef<EquipmentRow<Tractor>> = {
    id: "mobile-only",
    cell: createMobileCell("tractors"),
    header: () => null,
    meta: { responsive: true, classes: { header: "hidden" } },
};

const mobileTrailerCell: DisplayColumnDef<EquipmentRow<Trailer>> = {
    id: "mobile-only",
    cell: createMobileCell("trailers"),
    meta: { responsive: true, classes: { header: "hidden" } },
};

const mobileBusinessAutoCell: DisplayColumnDef<EquipmentRow<BusinessAuto>> = {
    id: "mobile-only",
    cell: createMobileCell("autos"),
    meta: { responsive: true, classes: { header: "hidden" } },
};

const mobileToolCell: DisplayColumnDef<EquipmentRow<Tool>> = {
    id: "mobile-only",
    cell: function MobileToolCell(info) {
        const row = info.row.original;

        const [changeDialogOpen, setChangeDialogOpen] = useState(false);
        const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

        return (
            <>
                <Popover>
                    <Popover.Content>
                        <Popover.Item
                            onClick={() => {
                                setChangeDialogOpen(true);
                            }}
                            disabled={info.row.original.requiresApproval}
                        >
                            <PencilSquareIcon className="h-5 w-5" />
                            <span>Edit{info.row.original.requiresApproval ? " (pending)" : ""}</span>
                        </Popover.Item>
                        <Popover.Item
                            disabled={info.row.original.requiresApproval}
                            onClick={() => {
                                setRemovalDialogOpen(true);
                            }}
                        >
                            <MinusCircleIcon className="h-5 w-5" />
                            <span>Remove</span>
                        </Popover.Item>
                    </Popover.Content>
                    <Popover.Button className="w-full space-y-1 text-left text-stone-600">
                        <div className="flex justify-between gap-2">
                            <p className="line-clamp-1 font-medium text-stone-800">
                                {row.description || row.serialNumber?.slice(-4) || `[${row.id}]`}
                            </p>
                            {row.requiresApproval && (
                                <p className="whitespace-nowrap rounded-full bg-yellow-50 px-2 text-yellow-700">
                                    Pending {row.coverageOptions?.length ? "change" : "addition"}
                                </p>
                            )}
                        </div>
                        <p className="tabular-nums">{row.serialNumber || "[No S/N provided]"}</p>
                        <div className="flex gap-2">
                            <p className="text-sm">{row.year}</p>
                            <p className="text-sm capitalize">{row.make}</p>
                            <p className="text-sm capitalize">{row.model}</p>
                        </div>
                        <p className="text-sm tabular-nums">
                            {row.policyAcv
                                ? "ACV"
                                : row.policyValue?.toLocaleString("en-us", {
                                      style: "currency",
                                      currency: "USD",
                                      maximumFractionDigits: 0,
                                  })}
                        </p>
                        <div className="flex gap-2">
                            {row.coverageOptions?.length ? (
                                <span className="text-stone-600">
                                    {row.coverageOptions
                                        ?.map((c) => c.name)
                                        .sort()
                                        .join(", ")}
                                </span>
                            ) : (
                                <span className="text-stone-500">No coverages</span>
                            )}
                        </div>
                    </Popover.Button>
                </Popover>
                <ChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={info.row.original} type={"tools"} />
                <RemovalDialog
                    open={removalDialogOpen}
                    onClose={() => setRemovalDialogOpen(false)}
                    row={info.row.original}
                    type={"tools"}
                />
            </>
        );
    },
    meta: { responsive: true, classes: { header: "hidden" } },
};

const upcomingDate: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool> | DriverRow> = {
    id: "upcomingDate",
    accessorFn: (row) => row.policyUpcomingDate,
    header: () => null,
    cell: (info) => {
        if (info.getValue()) {
            const m = moment(info.getValue() as string | null);
            return (
                <Tooltip title={`Unit will be added on ${info.getValue()}`}>
                    <span className="cursor-pointer">{m.fromNow()}</span>
                </Tooltip>
            );
        }
        return null;
    },
    sortingFn: "datetime",
    enableGlobalFilter: false,
};

const pending: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool> | DriverRow> = {
    id: "pending",
    accessorFn: (row) => row.requiresApproval,
    header: () => null,
    cell: (info) => {
        const adding = !info.row.original.coverageOptions?.length;
        const unitTypeString = "vin" in info.row.original ? "unit" : "serialNumber" in info.row.original ? "tool" : "driver";
        return info.getValue() ? (
            <Tooltip
                title={
                    <div>
                        <p className="font-normal text-stone-500">
                            You have requested to{" "}
                            {adding ? `add this ${unitTypeString} to your coverage(s)` : `change this ${unitTypeString}`}:
                        </p>
                        <p className="font-normal text-stone-600">
                            {info.row.original.requestDate
                                ? "Date of change " + moment(info.row.original.requestDate).format("MM/DD/YYYY")
                                : ""}
                        </p>
                        <p className="font-medium text-stone-600">{info.row.original.requestComment || "[Unknown change]"}</p>
                    </div>
                }
            >
                <p className="whitespace-nowrap rounded-full bg-yellow-50 px-2 text-yellow-700">Pending {adding ? "addition" : "change"}</p>
            </Tooltip>
        ) : null;
    },
    enableGlobalFilter: false,
};

const unitNumber: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto>> = {
    id: "unitNumber",
    accessorFn: (row) => row.unitNumber,
    header: "Unit",
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    cell: PrimaryCell,
};

const year: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> = {
    id: "year",
    accessorFn: (row) => row.year,
    header: "Year",
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const make: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto>> = {
    id: "make",
    accessorFn: (row) => row.make,
    header: "Make",
    meta: { classes: { cell: "capitalize" } },
};

const model: AccessorColumnDef<EquipmentRow<Tool>> = {
    id: "model",
    accessorFn: (row) => row.model,
    header: "Model",
    meta: { classes: { cell: "capitalize" } },
};

const type: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto>> = {
    id: "type",
    accessorFn: (row) => row.type?.name,
    header: "Type",
    meta: { classes: { cell: "capitalize" } },
    sortingFn: "alphanumeric",
};

const vin: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto>> = {
    id: "vin",
    accessorFn: (row) => row.vin,
    header: "VIN",
    meta: { classes: { cell: "uppercase font-mono" } },
    sortingFn: "alphanumeric",
};

const serialNumber: AccessorColumnDef<EquipmentRow<Tool>> = {
    id: "serialNumber",
    accessorFn: (row) => row.serialNumber,
    header: "Serial #",
    meta: { classes: { cell: "uppercase font-mono" } },
    sortingFn: "alphanumeric",
};

const description: AccessorColumnDef<EquipmentRow<Tool>> = {
    id: "description",
    accessorFn: (row) => row.description,
    header: "Description",
    meta: { classes: { cell: "text-stone-700" } },
};

const value: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> = {
    id: "value",
    accessorFn: (row) =>
        row.policyAcv
            ? "ACV"
            : row.policyValue?.toLocaleString("en-us", {
                  currency: "USD",
                  style: "currency",
                  maximumFractionDigits: 0,
              }),
    header: "Value",
    meta: { align: "right", classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    enableGlobalFilter: false,
};

const coverages: DisplayColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> = {
    id: "coverages",
    cell: (info) => {
        const coverages = info.row.original.coverageOptions;
        if (coverages?.length) {
            return (
                <Tooltip
                    title={
                        coverages?.length ? (
                            <ul>
                                {coverages.map((c) => (
                                    <li key={c.name}>{c.name}</li>
                                ))}
                            </ul>
                        ) : (
                            ""
                        )
                    }
                >
                    <span>
                        {coverages
                            ?.map((option) => option.abbreviation || CoverageOption.getMetadata(option.id)?.defaultAbbreviation)
                            .sort()
                            .join(", ")}
                    </span>
                </Tooltip>
            );
        } else {
            return <span className="font-medium">[Not covered]</span>;
        }
    },
    header: "Coverages",
    enableGlobalFilter: false,
};

const additionalInterests: DisplayColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> = {
    id: "additionalInterests",
    cell: (info) => {
        const additionalInterests = info.row.original.additionalInterests;
        if (additionalInterests?.length) {
            return (
                <Tooltip
                    title={
                        <ul>
                            {additionalInterests.map((ai) => (
                                <li key={ai.id}>
                                    {ai.additionalInterest?.name || "[Unknown]"} -{" "}
                                    {[
                                        ai.isAdditionalInsured && "Additional Insured",
                                        ai.isLossPayee && "Loss Payee",
                                        ai.isMortgagee && "Mortgagee",
                                        ai.isPrimaryAndNonContributory && "Primary and Non-Contributory",
                                        ai.isWaiverOfSubrogation && "Waiver of Subrogation",
                                    ]
                                        .filter(Boolean)
                                        .join(", ")}
                                </li>
                            ))}
                        </ul>
                    }
                >
                    <BuildingLibraryIcon className="h-4 w-4" />
                </Tooltip>
            );
        } else {
            return null;
        }
    },
    header: "",
    enableGlobalFilter: false,
};

function AutoIdButton({ row, type }: { row: EquipmentRow<Tractor | Trailer | BusinessAuto>; type: "tractors" | "trailers" | "autos" }) {
    const { mutate: getAutoId, isLoading } = api.equipment.useGetAutoId(type, row.accountId, {
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(data);
            window.open(url, "_blank");
        },
        onError: () => {
            useSnackbar.add("Could not get Auto ID. Please try again later.", {
                variant: "error",
            });
        },
    });

    return (
        <Popover.Item onClick={() => getAutoId(row.id)} disabled={isLoading || !row.coverageOptions?.length} className="whitespace-nowrap">
            {isLoading ? <ArrowPathIcon className="h-5 w-5 animate-spin text-stone-400" /> : <DocumentTextIcon className="h-5 w-5" />}
            <span>Open auto ID{!row.coverageOptions?.length ? " (no coverages)" : ""}</span>
        </Popover.Item>
    );
}

function createActionsColumn<T extends Tractor | Trailer | BusinessAuto | Tool>(
    type: EquipmentTypeString
): DisplayColumnDef<EquipmentRow<T>> {
    const actionsCell: DisplayColumnDef<EquipmentRow<T>> = {
        id: "actions",
        cell: function ActionsCell(info: CellContext<EquipmentRow<T>, void>) {
            const [changeDialogOpen, setChangeDialogOpen] = useState(false);
            const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

            return (
                <>
                    <Popover>
                        <Popover.Content>
                            {type !== "tools" && <AutoIdButton type={type} row={info.row.original as EquipmentRow<Tractor>} />}
                            <Popover.Item
                                onClick={() => {
                                    setChangeDialogOpen(true);
                                }}
                                disabled={info.row.original.requiresApproval}
                            >
                                <PencilSquareIcon className="h-5 w-5" />
                                <span>Edit{info.row.original.requiresApproval ? " (pending)" : ""}</span>
                            </Popover.Item>
                            <Popover.Item
                                disabled={info.row.original.requiresApproval}
                                onClick={() => {
                                    setRemovalDialogOpen(true);
                                }}
                            >
                                <MinusCircleIcon className="h-5 w-5" />
                                <span>Remove</span>
                            </Popover.Item>
                        </Popover.Content>
                        <Popover.Button className="rounded p-1 hover:bg-stone-100">
                            <EllipsisVerticalIcon className="h-5 w-5" />
                        </Popover.Button>
                    </Popover>
                    <ChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={info.row.original} type={type} />
                    <RemovalDialog
                        open={removalDialogOpen}
                        onClose={() => setRemovalDialogOpen(false)}
                        row={info.row.original}
                        type={type}
                    />
                </>
            );
        },
    };
    return actionsCell;
}

function RemovalDialog({
    type,
    row,
    open,
    onClose,
}: {
    type: EquipmentTypeString;
    row: EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>;
    open: boolean;
    onClose(): void;
}) {
    const [removalDate, setRemovalDate] = useState(moment().format("YYYY-MM-DD"));

    const removeUnit = api.equipment.useRequestRemoval(type, row.accountId, {
        onSuccess: () => {
            onClose();
        },
    });

    const removalDateError = useMemo(() => {
        const m = moment(removalDate);
        if (!m.isValid()) {
            return <Alert variant="warning">Invalid date</Alert>;
        }
        if (m.isAfter(moment(), "day")) {
            return (
                <Alert variant="warning">
                    If you want to remove this unit at a future date, either come back here when you are ready or call our office
                </Alert>
            );
        }

        if (moment().date() >= 15 && m.isBefore(moment(), "month")) {
            return <Alert variant="warning">Date is too far in the past</Alert>;
        }

        if (moment().date() < 15 && m.isBefore(moment().subtract(1, "month"), "month")) {
            return <Alert variant="warning">Date is too far in the past</Alert>;
        }

        return null;
    }, [removalDate]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                //noop
            }}
        >
            <div className="space-y-8 p-4">
                <h1 className="text-xl font-bold">Are you sure?</h1>
                <p className="text-stone-700">Please confirm that you want to remove the following unit from your coverages:</p>
                <div className="ml-6 flex items-center gap-2">
                    <MinusCircleIcon className="h-4 w-4 text-red-600" />
                    <p>
                        {row.year} {row.make} {(row as EquipmentRow<Tractor>).vin || (row as EquipmentRow<Tool>).serialNumber}
                    </p>
                </div>
                <InputField value={removalDate} onChange={(e) => setRemovalDate(e.target.value)} type="date" label="Date removed" />
                {removalDateError}
                <div className="flex w-full justify-end gap-6">
                    <SecondaryButton onClick={() => onClose()} disabled={removeUnit.isLoading}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            removeUnit.mutate({ id: row.id, requestDate: removalDate });
                        }}
                        disabled={removeUnit.isLoading || !!removalDateError}
                    >
                        {removeUnit.isLoading ? (
                            <>
                                <CircularProgress size="1rem" classes={{ circle: "text-white" }} />
                                <span>Removing...</span>
                            </>
                        ) : (
                            "Remove"
                        )}
                    </PrimaryButton>
                </div>
            </div>
        </Dialog>
    );
}

function ChangeDialog({
    type,
    row,
    open,
    onClose,
}: {
    type: EquipmentTypeString;
    row: EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>;
    open: boolean;
    onClose(): void;
}) {
    const { mutate: requestChange, isLoading } = api.equipment.useRequestChange(type, row.accountId, {
        onSuccess: () => {
            onClose();
            useSnackbar.add("Request submitted", { variant: "success" });
        },
        onError: (error) => {
            if (error.status === 403) {
                useSnackbar.add("You do not have permission to manage drivers.", {
                    variant: "error",
                });
            } else {
                useSnackbar.add("Could not submit your request. Please try again later.", {
                    variant: "error",
                });
            }
        },
    });

    const form = useForm<EquipmentDriverChangeDto>({
        defaultValues: {
            requestComment: type === "tools" ? "Remove from Inland Marine" : "",
            requestDate: new Date().toISOString().substring(0, 10),
        },
    });

    const unitDescription =
        (row as Tractor).unitNumber ||
        (row as Tractor).vin?.slice(-4) ||
        (row as Tool).serialNumber?.slice(-4) ||
        (row as Tool).description;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="w-screen max-w-[95vw] sm:max-w-sm"
            afterEnter={() => form.setFocus("requestComment")}
        >
            <form
                className="w-full space-y-4 p-4"
                onSubmit={form.handleSubmit((data) => {
                    requestChange({ ...data, id: row.id });
                })}
            >
                <p className="text-stone-500">Change request for unit {unitDescription}</p>
                <InputField
                    required
                    label="Date of change"
                    {...form.register("requestDate", {
                        required: true,
                        validate: (value) => (value && moment(value).isSameOrAfter(moment(), "day")) || "Date must be today or later",
                    })}
                    type="date"
                    classes={{ inputContainer: "py-2" }}
                />
                <InputField
                    label="Change description"
                    {...form.register("requestComment", {
                        required: true,
                        maxLength: { value: 256, message: "Description cannot be more than 256 characters" },
                    })}
                    required
                    list="reason-list"
                />
                <datalist id="reason-list">
                    <option value="Remove spare rating"></option>
                    <option value="Add spare rating"></option>
                    <option value="Increase comp/collision deductible to..."></option>
                    <option value="Decrease comp/collision deductible to..."></option>
                    <option value="Correct an error..."></option>
                </datalist>
                <p className="text-sm text-stone-500">
                    Please be as verbose and clear as possible, providing all the necessary information to make the requested change.
                </p>
                <p className="text-red-600">
                    {Object.values(form.formState.errors)
                        .map((error) => error.message)
                        .join("; ")}
                </p>
                <PrimaryButton type="submit" className="float-right" disabled={isLoading || row.requiresApproval}>
                    Submit request
                </PrimaryButton>
            </form>
        </Dialog>
    );
}

const tractorActions = createActionsColumn<Tractor>("tractors");
const trailerActions = createActionsColumn<Trailer>("trailers");
const businessAutoActions = createActionsColumn<BusinessAuto>("autos");
const toolActions = createActionsColumn<Tool>("tools");

export default {
    mobileTractorCell,
    mobileTrailerCell,
    mobileBusinessAutoCell,
    mobileToolCell,
    upcomingDate,
    pending,
    unitNumber,
    year,
    make,
    model,
    type,
    vin,
    value,
    coverages,
    additionalInterests,
    serialNumber,
    description,
    tractorActions,
    trailerActions,
    businessAutoActions,
    toolActions,
    ChangeDialog,
    RemovalDialog,
};
