import { classNames } from "@deathstar/ui";
import { Dialog as HuiDialog, DialogBackdrop, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { Fragment } from "react";

export function Dialog({
    open,
    onClose,
    children,
    afterLeave,
    afterEnter,
    className,
}: {
    open: boolean;
    onClose(): void;
    children: React.ReactNode;
    afterLeave?(): void;
    afterEnter?(): void;
    className?: string;
}) {
    return (
        <Transition appear show={open} as={Fragment} afterLeave={afterLeave} afterEnter={afterEnter}>
            <HuiDialog as="div" onClose={onClose} open={open} static>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <DialogBackdrop className="fixed inset-0 z-40 bg-black bg-opacity-25" />
                </TransitionChild>

                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="fixed inset-0 z-50 overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex min-h-full items-center justify-center">
                            <DialogPanel
                                className={classNames(
                                    "mx-auto flex max-h-[calc(100vh-64px)] max-w-[calc(100vw-64px)] overflow-auto rounded-xl bg-white",
                                    className
                                )}
                            >
                                {children}
                            </DialogPanel>
                        </div>
                    </div>
                </TransitionChild>
            </HuiDialog>
        </Transition>
    );
}
