import { DateTime, memoize } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { Data } from "../Data";

import type { Calculation } from "../Calculation";
import type { Inspection } from "../Inspection/Inspection";
import type { MotorCarrier } from "../MotorCarrier";
import { Unit } from "../Unit/Unit";
import { ViolationType } from "./ViolationType";

// ========================================================================
export class Violation extends Data<Violation.Raw> implements Calculation.HasSeverityWeight, Calculation.HasTimeWeight {
    // ========================================================================
    static readonly #newIfWithinXMonths = 3;
    static readonly #expiringfIfAfterXMonths = 21;
    static Type = ViolationType;

    // ========================================================================
    static new(carrier: MotorCarrier, inspection: Inspection, raw: Violation.Raw): Violation {
        const newViolation = new Violation(carrier, raw);
        newViolation.#inspection = inspection;
        return newViolation;
    }

    // ========================================================================
    #inspection!: Inspection;
    #carrier: MotorCarrier;
    // ========================================================================
    private constructor(carrier: MotorCarrier, raw: Violation.Raw) {
        super({ carrier, raw });
        this.#carrier = carrier;
    }

    // ========================================================================
    get [Symbol.toStringTag](): string {
        return "Violation";
    }

    // ========================================================================
    get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    get isValid(): boolean {
        return this.get("IsValid");
    }

    // ========================================================================
    get isOutOfService(): boolean {
        return this.get("OutOfServiceIndicator");
    }

    // ========================================================================
    /**
     * The Violation ID
     */
    get id(): string {
        return `${this.get("InspectionUniqueId")}-${this.get("ViolationCode")}`;
    }
    // ========================================================================
    #category: FMCSA.ViolationCategoryType | undefined;
    #calculateCategory(): FMCSA.ViolationCategoryType {
        switch (this.get("ViolationOffender")) {
            case FMCSA.ViolationOffender.DRIVER:
            case FMCSA.ViolationOffender.CO_DRIVER:
                this.#category = FMCSA.ViolationCategoryType.DRIVER;
                break;
            case FMCSA.ViolationOffender.VEHICLE_MAIN_UNIT:
            case FMCSA.ViolationOffender.VEHICLE_SECONDARY_UNIT:
                this.#category = FMCSA.ViolationCategoryType.VEHICLE;
                break;
            default:
                this.#category = FMCSA.ViolationCategoryType.INVALID;
                break;
        }
        return this.#category;
    }

    // ========================================================================
    /**
     * If the Violations is included in the latest BASIC scores (based on the applicable date range)
     */
    get isCurrentlyScored(): boolean {
        return this.inspection.isCurrentlyScored;
    }

    // ========================================================================
    /**
     * The `Violation` category type
     */
    get category(): FMCSA.ViolationCategoryType {
        return this.#category ?? this.#calculateCategory();
    }

    // ========================================================================
    #type: Violation.Type | undefined;
    #calculateType(): Violation.Type {
        const { Category } = Unit;
        const offender = this.get("ViolationOffender");
        const { unit } = this;

        if (offender === FMCSA.ViolationOffender.DRIVER || offender === FMCSA.ViolationOffender.CO_DRIVER) {
            this.#type = Violation.Type.DRIVER;
        } else if (unit && unit.category === Category.TRAILER) {
            this.#type = Violation.Type.TRAILER;
        } else if (unit && unit.category === Category.TRACTOR) {
            this.#type = Violation.Type.TRACTOR;
        } else {
            this.#type = Violation.Type.UNKNOWN;
        }

        return this.#type;
    }
    /**
     * Returns the Type of Violation.
     * @returns {Violation.Type} The violation type
     */
    get type(): Violation.Type {
        return this.#type ?? this.#calculateType();
    }

    // ========================================================================
    #unit: Unit | undefined | null;
    #calculateUnit(): Unit | null {
        if (this.get("ViolationOffender") === FMCSA.ViolationOffender.VEHICLE_SECONDARY_UNIT) {
            this.#unit = this.#inspection.secondaryUnit;
        } else {
            this.#unit = this.#inspection.primaryUnit;
        }

        return this.#unit || null;
    }

    /**
     * The `Unit` associated with the `Violation`
     */
    get unit(): Unit | null {
        return this.#unit ?? this.#calculateUnit();
    }

    // ========================================================================
    /**
     * The Total Weight. Calculated as
     * ```
     * TimeWeight x SeverityWeight = TotalWeight
     * ```
     * The default time weight date is `carrier.dateRange.to`
     */
    getTotalWeight(options?: { timeWeightDate: DateTime; timeWeight?: never }): number;
    getTotalWeight(options: { timeWeightDate: DateTime; timeWeight?: never }): number;
    getTotalWeight(options: { timeWeightDate?: never; timeWeight: number }): number;
    getTotalWeight(
        { timeWeightDate, timeWeight }: { timeWeightDate?: DateTime; timeWeight?: number | null } = {
            timeWeightDate: this.carrier.dateRange.to,
            timeWeight: null,
        }
    ): number {
        return (timeWeight ?? this.getTimeWeight(timeWeightDate)) * this.severityWeight;
    }

    // ========================================================================
    /**
     * The Time Weight of the Violation relevant to a date; if no date is passed
     *  in the current time weight is used.
     */
    getTimeWeight(date = this.carrier.dateRange.to): number {
        return this.#inspection.getTimeWeight(date);
    }

    // ========================================================================
    #severityWeight: number | undefined;
    #calculateSeverityWeight(
        basicSeverityWeight = this.get("SeverityWeight"),
        isOutOfService = this.get("OutOfServiceIndicator") && this.inspection.get("TotalOutOfService") > 0
    ): number {
        if (this.isAdjudicated) return 1;
        const severityWeight = Number(basicSeverityWeight);
        if (globalThis.isNaN(severityWeight)) return 0;
        this.#severityWeight = isOutOfService && this.basic !== FMCSA.BasicName.CONTROLLED_SUBSTANCES ? severityWeight + 2 : severityWeight;
        return this.#severityWeight;
    }
    /**
     * The Severity Weight of the Violation
     *
     * NOTE: IF the result of the violation is OOS, 2 is added to the severity weight
     */
    get severityWeight(): number {
        if (!this.isValid) return 0;
        return this.#severityWeight ?? this.#calculateSeverityWeight();
    }

    // ========================================================================
    get hasExpiringWeight(): boolean {
        return this.getExpiringWeight() > 0;
    }

    // ========================================================================
    /**
     * If the violation was adjudicated, the severity weight is set to 1
     */
    get isAdjudicated(): boolean {
        const severityWeight = this.get("SeverityWeight");
        if (severityWeight > 1) return false;
        const codedSeverityWeight = this.get("CodedSeverityWeight");
        if (typeof codedSeverityWeight !== "number") return false;
        return severityWeight === 1 && severityWeight !== codedSeverityWeight;
    }

    // ========================================================================
    @memoize()
    getExpiringWeight({ expiringIfWithinNumberOfMonths } = { expiringIfWithinNumberOfMonths: 3 }): number {
        if (this.isExpiring) {
            return this.severityWeight;
        }

        const { to: date } = this.carrier.dateRange;

        const dateTimeWeightFallsFrom3To2 = DateTime.addMonths(this.date, 6);
        const dateTimeWeightFallsFrom2To1 = DateTime.addMonths(this.date, 12);
        const dateTimeWeightFallsFrom1To0 = DateTime.addMonths(this.date, 24);

        if (
            date.isWithinMonthsOfDate(dateTimeWeightFallsFrom3To2, expiringIfWithinNumberOfMonths) ||
            date.isWithinMonthsOfDate(dateTimeWeightFallsFrom2To1, expiringIfWithinNumberOfMonths) ||
            date.isWithinMonthsOfDate(dateTimeWeightFallsFrom1To0, expiringIfWithinNumberOfMonths)
        ) {
            return this.severityWeight;
        }
        return 0;
    }

    // ========================================================================
    /**
     * The inspection associated with the Violation
     */
    get inspection(): Inspection {
        return this.#inspection;
    }

    // ========================================================================
    /**
     * The date the Violation occurred
     */
    get date(): DateTime {
        return this.inspection.date;
    }

    // ========================================================================
    get basic(): FMCSA.BasicName {
        return this.get("Basic");
    }

    // ========================================================================
    get isExpiring(): boolean {
        return this.inspection.isExpiring;
    }

    // ========================================================================
    get isExpired(): boolean {
        return this.inspection.isExpired;
    }

    // ========================================================================
    get isNew(): boolean {
        return this.inspection.isNew;
    }

    // ========================================================================
    /**
     * Compares the target date with the Inspection Date, and returns true if the
     *  Violations is new.
     * @param targetDate Date to compare
     */
    isNewRelativeToDate(targetDate: Date): boolean {
        return DateTime.subtractMonths(targetDate, Violation.#newIfWithinXMonths).isBefore(this.date);
    }

    // ========================================================================
    /**
     * Compares the target date with the Inspection Date, and returns true if the
     *  Violations is expired or soon to expire
     * @param targetDate Date to compare
     */
    isExpiringRelativeToDate(targetDate: Date): boolean {
        return this.date.isBefore(DateTime.subtractMonths(targetDate, Violation.#expiringfIfAfterXMonths));
    }

    // ========================================================================
    json({ timeWeightDate }: { timeWeightDate: DateTime } = { timeWeightDate: this.carrier.dateRange.to }): Violation.JSON & Violation.Raw {
        return {
            ...super.json(),
            TotalWeight: this.getTotalWeight({ timeWeightDate }),
            TimeWeight: this.getTimeWeight(timeWeightDate),
            SeverityWeight: this.severityWeight,
            Unit: this.unit?.json() ?? null,
        };
    }

    // ========================================================================
    raw(): Violation.Raw {
        return super.json();
    }
}

export declare namespace Violation {
    export interface JSON {
        TotalWeight: number;
        TimeWeight: number;
        SeverityWeight: number;
        Unit: Unit.JSON | null;
    }

    export type Type = ViolationType;

    export interface Raw extends FMCSA.Violation {}
}
