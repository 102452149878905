export const AGENT_BIOS = {
    // Elizabeth
    emiller:
        "Elizabeth Miller has been with the Agency since 2014. Before coming to Navigator, she was a teacher, which gives her clients an advantage … Elizabeth can break down complicated concepts to help clients better understand their insurance. Not only that, but she listens to her clients' stories and finds creative ways to meet their needs.",

    // Jeff
    jmoseler:
        "Jeff Moseler has been in the logistics and trucking business for more than 20 years. He began by exporting highly technical equipment for a defense contractor, then moved into the complex insurance requirements that come with such a task. This long history has helped Jeff develop expertise in the trucking insurance industry, and he loves to share that knowledge with others who want quality, affordable insurance solutions for their fleet.",

    // Joe
    jhansen:
        "Before January 2020, Joe Hanson sold heavy trucks for a Kenworth dealership in the twin cities. Now, he's continued to be a skilled problem-solver. He works hard to creatively find solutions to simplify his clients' lives, while reducing their risk exposure.",

    // Rachel
    rsmith: "Rachel Smith has been with Navigator since 2018. She is our resident expert in new authority coverages, and she loves working with companies that have 1-5 units. When Rachel isn't finding you the best coverage available, she's at home running her baking business. Yum!",

    // Pam
    psiazik:
        "Pam Siazik was once a client of Navigator's, working specifically in safety, compliance, and recruiting for fleets. In 2012, she began working for Navigator and became our resident expert in asset protection and value. She also has a proven history of ensuring her clients receive the best insurance value available.",

    // Isaac
    imoss: "The responsibility of helping each client discover and develop a risk management program that is unique to their own company is a formidable obstacle, and Isaac Moss is up to the task. In 2017 he began working with trucking companies to do just that. He loves to collaborate with his clients and help their companies grow, with help from a sophisticated risk management program. In addition to this responsibility, Isaac is also husband and dad of two. When he is not talking about the trucking industry, he is pushing his kids on the swing set, fishing for bluegill on the dock, or going on a family walk to the park.",

    // Peter
    pbarton:
        "Peter Barton has been an agent at the Agency for more than a year now. Before Navigator, his area of expertise was in complex sales and contract negotiation. Now, his favorite thing is working with clients and being available and providing real, usable information so they can make correct, informed decisions to positively impact the business they worked so hard to build.",

    // Jon
    jonmoss:
        "Risk management might be boring to some, but not to Jon Moss! He has been in commercial insurance since 2014 — handling everything from trucking cargo claims to hurricane damage. In 2019, he brought that experience to Navigator. Jon carries in-depth knowledge of policy coverage, underwriting, and claims, but his favorite aspect of his role is knowing his clients and meeting their needs.",

    // Jacob
    jeven: "Jacob Even is our resident mid-size expert in the Midwest, he's always looking for ways to provide creative solutions with long-lasting results for his clients. In his spare time, though, you'll be sure to find Jacob out on the golf course.",

    // John
    jpowell:
        "Before coming to Navigator, John Powell worked in the world of supply chains and logistics. Starting in 2021, John began eating and breathing trucking insurance. He now thrives on the personal connection made with clients — born out of trust and shared value as he offers the best product available.",

    // Anne
    acannon:
        "Anne Cannon joined our team in 2023 and loves helping her customers get the best pricing available, filling a need in their business and offering outstanding customer service. She is known for going above and beyond for her clients, and we're grateful to have her on our team and on your side!",

    // Hannah
    hmchugh:
        "When it comes to trucking insurance, Hannah McHugh is our resident expert in owner operator coverages. She has been with Navigator for more than five years and knows the ins and outs of this industry better than most! When she is not working, Hannah creates through makeup artistry.",
};
