import type { AccountConfig } from "./AccountConfig";
import type { AccountCrossReference } from "./AccountCrossReference";
import type { Address } from "./Address";
import type { BindItem } from "./BindItem";
import type { BusinessAuto } from "./BusinessAuto";
import type { Commodity } from "./Commodity";
import type { Company } from "./Company";
import type { DriveDistance } from "./DriveDistance";
import type { Driver } from "./Driver";
import type { FileCategory } from "./FileCategory";
import type { HistoricalData } from "./HistoricalData";
import type { LossAnalysis } from "./LossAnalysis";
import type { Policy } from "./Policy";
import type { Property } from "./Property";
import type { Proposal } from "./Proposal";
import type { Quote } from "./Quote";
import type { QuoteGroup } from "./QuoteGroup";
import { SharepointDocument } from "./SharepointDocument";
import type { Submission } from "./Submission";
import type { Tractor } from "./Tractor";
import type { Trailer } from "./Trailer";
import type { User } from "./User";
import type { Widget } from "./Widget";

export enum AccountStatus {
    Client = "Client",
    Prospect = "Pospect",
    InRenewal = "InRenewal",
}

export class Account {
    public static Status = AccountStatus;
    id: string;

    parentId: string;

    ooProgramHolderId: string;

    rnManagerGuid: string;

    agentGuid: string;

    assistantGuid: string;

    creatorGuid: string;

    createdDate: Date;

    lastModifiedDate: Date;

    forceId: string;

    specialHandlingReason: string;

    specialHandlingCreatedByGuid: string;

    specialHandlingCreatedDate: Date;

    zendeskOrganizationId: string;

    // ====== RELATIONS ======

    specialHandlingCreatedBy: User;

    rnManager: User;

    agent: User;

    assistant: User | null;

    creator: User;

    addresses: Address[];

    businessAutos: BusinessAuto[];

    commodities: Commodity[];

    company: Company;

    driveDistances: DriveDistance[];

    drivers: Driver[];

    fileCategories: FileCategory[];

    historicalData: HistoricalData[];

    lossAnalyses: LossAnalysis[];

    proposals: Proposal[];

    quoteGroups: QuoteGroup[];

    quotes: Quote[];

    settings: AccountConfig;

    submissions: Submission[];

    tractors: Tractor[];

    trailers: Trailer[];

    widgets: Widget[];

    bindItems: BindItem[];

    properties: Property[];

    references: AccountCrossReference[];

    policies: Policy[];

    files: SharepointDocument[];

    parent: Account;

    ooProgramHolder: Account;

    descriptionOfOperations: string;

    children: Account[];
}
