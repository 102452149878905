import { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";
import { classNames } from "@deathstar/ui";
import { Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { ViolationBreakdown } from "../../Charts/ViolationBreakdown";
import { Context } from "../../Context";
import type { ITopViolationsBreakdownData } from "./TopViolationsBreakdown";

export function PanelHeader({ basic, basicData }: { basic: FMCSA.BasicName; basicData: ITopViolationsBreakdownData }): JSX.Element {
    return (
        <div className="flex items-center gap-8 pb-8">
            <div className="h-[200px] w-[200px]">
                <ViolationBreakdown data={basicData.groups} />
            </div>

            <div className="">
                <BasicMetadata basic={basic} />
            </div>
        </div>
    );
}

function BasicMetadata({ basic: basicName }: { basic: FMCSA.BasicName }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);
    const basic = motorCarrier.smsResults.latest.getBasic(basicName);
    const { alert, score, measure, threshold, safetyEventGroup } = basic;
    const totalRelevantInspections = basic.getRelevantInspections().total;
    const totalViolations = basic.getTotalNumberOfViolations();

    return (
        <div className="rounded-xl border-2 border-gray-300 bg-transparent">
            <Table size="small">
                <TableBody>
                    <TableRow
                        className={classNames({
                            "text-danger": alert,
                        })}
                    >
                        <TableCell className="text-lg">
                            <div className="flex gap-4">
                                <Typography className="text-lg">
                                    BASIC Score<span className="align-top text-sm">*</span>
                                </Typography>
                                <AlertBadge basic={basic} />
                            </div>
                        </TableCell>
                        <TableCell align="right" className="text-lg">
                            <Tooltip title={`Measure: ${MotorCarrier.round(measure, 2)}`}>
                                <span>{`${score || 0}%`}</span>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Safety Event Group</TableCell>
                        <TableCell align="right">
                            <SafetyEventGroupName safetyEventGroup={safetyEventGroup} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <Tooltip title="Alert Threshold">
                            <TableCell>Threshold</TableCell>
                        </Tooltip>
                        <TableCell align="right">
                            <span>{`${threshold}%`}</span>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <Tooltip title="Total Relevant Inspections">
                            <TableCell>Inspections</TableCell>
                        </Tooltip>
                        <TableCell align="right">
                            <span>{totalRelevantInspections}</span>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <Tooltip title="Total Violations">
                            <TableCell>Violations</TableCell>
                        </Tooltip>
                        <TableCell align="right">
                            <span>{totalViolations}</span>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
}

function SafetyEventGroupName({ safetyEventGroup }: { safetyEventGroup: MotorCarrier.SafetyEventGroup<unknown> }): JSX.Element {
    if (safetyEventGroup.isSegmented) {
        return <span>{`${safetyEventGroup.carrier.segment} ${safetyEventGroup.number}`}</span>;
    }
    return <span>{safetyEventGroup.number}</span>;
}

function AlertBadge({ basic }: { basic: MotorCarrier.IBasic }): JSX.Element | null {
    const title = React.useMemo(() => {
        if (basic.isScoreAboveThreshold && basic.hasAcuteCriticalViolation) {
            return "Score is above the threshold and has Acute/Critical Violations";
        }
        if (basic.isScoreAboveThreshold) {
            return "Score is above the threshold";
        }
        if (basic.hasAcuteCriticalViolation) {
            return "Has Acute/Critical Violations";
        }
        return "";
    }, [basic]);
    if (!basic.alert) return null;

    return (
        <Tooltip title={title}>
            <div className="flex w-fit items-center justify-center rounded-full bg-danger px-4 uppercase text-white">
                <Typography className="text-md">Alert</Typography>
            </div>
        </Tooltip>
    );
}
