import { OOProgramDetails } from "@deathstar/types/waypoint";
import { DataTable } from "@deathstar/ui";
import { ColumnDef, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import { Dialog } from "../../../components/dialog/Dialog";

export default function OOProgramInvitations({
    open,
    onClose,
    invitations,
}: {
    open: boolean;
    onClose(): void;
    invitations: OOProgramDetails["invitations"];
}) {
    const table = useReactTable({
        data: invitations,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: 6,
            },
        },
    });

    return (
        <Dialog open={open} onClose={onClose} className="w-full p-4 md:max-w-sm">
            <DataTable table={table} disableSearch />
        </Dialog>
    );
}

const columns: ColumnDef<OOProgramDetails["invitations"][number]>[] = [
    { accessorKey: "createdDate", cell: (info) => moment(info.getValue() as Date).format("MM/DD/YY"), header: "Date" },
    { accessorKey: "name" },
];
