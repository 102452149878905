import { Transform, Type } from "class-transformer";
import { IsBoolean, IsNumber, IsOptional, IsString, Max, Min, ValidateNested } from "class-validator";
import type Stripe from "stripe";

export enum SubscriptionTier {
    Standard = "standard",
    Premium = "premium",
    Enterprise = "enterprise",
}

export function getSubscriptionTierName(tier: SubscriptionTier) {
    switch (tier) {
        case SubscriptionTier.Standard:
            return "Standard";
        case SubscriptionTier.Premium:
            return "Premier";
        case SubscriptionTier.Enterprise:
            return "Enterprise";
        default:
            return "";
    }
}

export interface SubscriptionItemUsage {
    item: Stripe.SubscriptionItem;
    usage?: Stripe.UsageRecordSummary;
}

export interface ScheduledSubscription {
    plan: SubscriptionTier;
    startDate: string;
    scheduleId: string;
}

export interface PriceWithProduct extends Stripe.Price {
    product: Stripe.Product;
}

export interface PaymentMethodInfo {
    isDefault: boolean;
    description: string;
    type: Stripe.PaymentMethod["type"];
    id: string;
    name: string;
    endingIn: boolean;
    value: string;
    expires?: string;
}

export class PayBillFormData {
    @IsString()
    paymentMethod: string;

    @IsNumber()
    @Min(1)
    @Max(999_999_999)
    amount: number;

    @IsOptional()
    @IsString()
    description: string;

    @IsOptional()
    @IsString()
    invoiceNumber: string;
}

export type SubscriptionPlansData = Record<SubscriptionTier, PriceWithProduct[]>;

export interface BillingInformation {
    customerId: string;
    address?: Stripe.Address;
    email?: string;
    paymentMethods: PaymentMethodInfo[];
    coupon?: Stripe.Coupon;
    automaticBillingEnabled: boolean;
    plan?: SubscriptionTier;
}

export interface Payment {
    id: string;
    amountCents: number;
    created: number;
    status: Stripe.PaymentIntent["status"];
}

class StripeAddress {
    @IsString()
    line1: string;

    @IsString()
    line2: string;

    @IsString()
    city: string;

    @IsString()
    postal_code: string;

    @IsString()
    state: string;

    @IsString()
    country: string;
}

export class BillingInformationUpdateDto {
    @IsOptional()
    @ValidateNested()
    @Type(() => StripeAddress)
    address?: StripeAddress;

    @IsOptional()
    @IsString()
    email?: string;

    @IsOptional()
    @IsString()
    defaultPaymentMethod?: string;

    @IsOptional()
    @Transform(({ value }) => value === "true" || value === true)
    @IsBoolean()
    automaticBillingEnabled?: boolean;
}
