import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/reuse";
import { AccessorColumnDef, ColumnDef } from "@tanstack/react-table";
import { accessorFnGenerator } from "../utils/accessorFnGenerator";

const ascDateSorter = DateTime.Sorter("ASC");

const date: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "date",
    accessorFn: (row) => row.date.format("mm/dd/YYYY"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};

const reportNumber: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "reportNumber",
    header: "Report Number",
    accessorFn: (row) => row.get("ReportNumber"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const state: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "state",
    accessorFn: (row) => row.get("ReportState"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const city: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "city",
    accessorFn: (row) => row.get("City"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const county: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "county",
    accessorFn: (row) => row.county,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const location: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "location",
    header: "Location",
    accessorFn: (row) => row.get("Location"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const latitude: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "latitude",
    accessorFn: (row) => row.get("CountyLatitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const longitude: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "longitude",
    accessorFn: (row) => row.get("CountyLongitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitLicenseNumber: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitLicenseNumber",
    header: "License Number",
    accessorFn: (row) => row.get("UnitLicense"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitLicenseState: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitLicenseState",
    header: "License State",
    accessorFn: (row) => row.get("UnitLicenseState"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitType: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitType",
    header: "Type",
    accessorFn: (row) => row.get("UnitType"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitVin: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitVin",
    header: "VIN",
    accessorFn: (row) => row.get("UnitVin"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitYear: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitYear",
    header: "Year",
    accessorFn: (row) => row.unit?.year,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitMake: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitMake",
    header: "Make",
    accessorFn: (row) => row.unit?.make,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitHazmatPlacardIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitHazmatPlacard",
    header: "Hazmat Placard",
    accessorFn: (row) => accessorFnGenerator(row.get("UnitHazmatPlacard")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("UnitHazmatPlacard"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalFatalities: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "totalFatalities",
    header: "Fatalaties",
    accessorFn: (row) => row.get("TotalFatalities"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalInjuries: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "totalInjuries",
    header: "Injuries",
    accessorFn: (row) => row.get("TotalInjuries"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const towIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "towIndicator",
    header: "Tow",
    accessorFn: (row) => accessorFnGenerator(row.get("TowAway")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("TowAway"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const hazmatReleaseIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "hazmatIndicator",
    header: "Hazmat Released",
    accessorFn: (row) => accessorFnGenerator(row.get("HazmatReleased")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("HazmatReleased"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const federalRecordableIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "federalRecordableIndicator",
    header: "Federal Recordable",
    accessorFn: (row) => accessorFnGenerator(row.get("FederalRecordable")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("FederalRecordable"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const stateRecordableIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "stateRecordableIndicator",
    header: "State Recordable",
    accessorFn: (row) => accessorFnGenerator(row.get("StateRecordable")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("StateRecordable"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const lightCondition: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "lightCondition",
    header: "Light Condition",
    accessorFn: (row) => row.get("LightCondition"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const preventable: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "preventable",
    accessorFn: (row) => accessorFnGenerator(row.get("Preventable")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("Preventable"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const roadSurfaceCondition: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "roadSurfaceCondition",
    header: "Road Surface Condition",
    accessorFn: (row) => row.get("RoadSurfaceCondition"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const roadAccessControl: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "roadAccessControl",
    header: "Road Access Control",
    accessorFn: (row) => row.get("RoadAccessControl"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const weatherCondition: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "weatherCondition",
    header: "Weather Condition",
    accessorFn: (row) => row.get("WeatherCondition"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitWeightRating: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitWeightRating",
    header: "Unit Weight Rating",
    accessorFn: (row) => row.get("GrossUnitWeightRating"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalVehiclesInAccident: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "totalVehiclesInAccident",
    header: "Vehicles Involved",
    accessorFn: (row) => row.get("TotalVehiclesInAccident"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const reportTime: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "reportTime",
    header: "Report Time",
    accessorFn: (row) => row.get("ReportTime"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "datetime",
};
const cargoBodyType: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "cargoBodyType",
    header: "Cargo Body Type",
    accessorFn: (row) => row.get("CargoBodyType"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const trafficway: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "trafficway",
    accessorFn: (row) => row.get("Trafficway"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const reportingAgency: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "reportingAgency",
    header: "Reporting Agency",
    accessorFn: (row) => row.get("ReportingAgency"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const severityWeight: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "severityWeight",
    header: "Severity Weight",
    accessorFn: (row) => row.severityWeight,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const timeWeight: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "timeWeight",
    header: "Time Weight",
    accessorFn: (row) => row.getTimeWeight(),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalWeight: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "totalWeight",
    header: "Total Weight",
    accessorFn: (row) => row.getTimeWeight() * row.severityWeight,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

export const columns = [
    date,
    reportNumber,
    reportTime,
    state,
    city,
    county,
    location,
    latitude,
    longitude,
    totalFatalities,
    totalInjuries,
    totalVehiclesInAccident,
    towIndicator,
    severityWeight,
    timeWeight,
    totalWeight,
    hazmatReleaseIndicator,
    federalRecordableIndicator,
    stateRecordableIndicator,
    preventable,
    roadAccessControl,
    trafficway,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    unitLicenseState,
    unitType,
    unitWeightRating,
    unitHazmatPlacardIndicator,
    cargoBodyType,
    lightCondition,
    roadSurfaceCondition,
    weatherCondition,
    reportingAgency,
] as ColumnDef<MotorCarrier.ICrash>[];

export default {
    date,
    reportNumber,
    reportTime,
    state,
    city,
    county,
    location,
    latitude,
    longitude,
    totalFatalities,
    totalInjuries,
    totalVehiclesInAccident,
    towIndicator,
    severityWeight,
    timeWeight,
    totalWeight,
    hazmatReleaseIndicator,
    federalRecordableIndicator,
    stateRecordableIndicator,
    preventable,
    roadAccessControl,
    trafficway,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    unitLicenseState,
    unitType,
    unitWeightRating,
    unitHazmatPlacardIndicator,
    cargoBodyType,
    lightCondition,
    roadSurfaceCondition,
    weatherCondition,
    reportingAgency,
};
