import { Coverage, CoverageOption, MonthlyReport, MonthlyReportData, MonthlyReportQueryResult } from "@deathstar/types/northstar";
import { Alert, classNames, Collapse, InputField, PrimaryButton, useSnackbar } from "@deathstar/ui";
import {
    ArrowRightCircleIcon,
    CheckCircleIcon,
    ChevronRightIcon,
    CurrencyDollarIcon,
    InformationCircleIcon,
    MapIcon,
    TruckIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useIsFetching } from "react-query";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { Dialog } from "../../../components/dialog/Dialog";
import { queryClient } from "../../../util/queryClient";
import PaymentDialog from "../../billing/PaymentDialog";

const COUNTDOWN = 5;

export function MonthlyReportCalculations({ report, data }: { report: MonthlyReport; data: MonthlyReportData }) {
    const accountId = useAccountId();
    const isFetching = useIsFetching(api.monthlyReports.queryKeys.findForPolicy(accountId!, report.policyId));
    const { mutate: update } = api.monthlyReports.useUpdate(accountId!, report.policyId, report.year, report.month, {
        onMutate: (newData) => {
            if (
                queryClient.getQueryData(api.monthlyReports.queryKeys.findForMonth(accountId!, report.policyId, report.year, report.month))
            ) {
                queryClient.setQueryData<MonthlyReportQueryResult>(
                    api.monthlyReports.queryKeys.findForMonth(accountId!, report.policyId, report.year, report.month),
                    (oldData: MonthlyReportQueryResult | undefined) => {
                        if (!oldData) {
                            // this should never happen since we're checking if the querydata exists first
                            return oldData as unknown as MonthlyReportQueryResult;
                        }
                        return {
                            ...oldData,
                            report: { ...oldData.report, ...newData },
                        };
                    }
                );
            }
            if (mileageRef.current && newData.mileage !== undefined) {
                mileageRef.current.value = newData.mileage.toString();
            }
            if (revenueRef.current && newData.revenue !== undefined) {
                revenueRef.current.value = newData.revenue.toString();
            }
        },
        onError: () => {
            useSnackbar.add("An error occurred while updating your monthly report", { variant: "error" });
            queryClient.invalidateQueries(api.monthlyReports.queryKeys.findForPolicy(accountId!, report.policyId));
            if (mileageRef.current) {
                mileageRef.current.value = report.mileage.toString();
            }
            if (revenueRef.current) {
                revenueRef.current.value = report.revenue.toString();
            }
        },
    });
    const mileageRef = useRef<HTMLInputElement>(null);
    const revenueRef = useRef<HTMLInputElement>(null);
    const [paying, setPaying] = useState(false);
    // const { data: billingProfile } = api.billing.useProfile(accountId!);
    const submit = api.monthlyReports.useSubmit(accountId!, report.policyId, report.year, report.month, {
        onSuccess: () => {
            setConfirming(false);
            // if (data.endLayer.policy.billMethod === Policy.BillMethod.AGENCY && billingProfile && !billingProfile.automaticBillingEnabled) {
            //     setPaying(true);
            // }
        },
    });
    const [confirming, setConfirming] = useState(false);
    const [countdown, setCountdown] = useState(COUNTDOWN);

    const [expanded, setExpanded] = useState<Set<CoverageOption.Id>>(new Set());

    useEffect(() => {
        if (confirming) {
            const int = setInterval(() => {
                setCountdown((c) => {
                    if (c === 1) {
                        clearInterval(int);
                    }
                    return c - 1;
                });
            }, 1000);
            return () => {
                clearTimeout(int);
            };
        }
        return undefined;
    }, [confirming]);

    const hasMileageBasis = data.endLayer.policy.coverages.some((c) => c.basis === Coverage.Basis.MILEAGE);
    const hasRevenueBasis = data.endLayer.policy.coverages.some((c) => c.basis === Coverage.Basis.REVENUE);

    return (
        <div className="h-max w-max space-y-4 rounded border border-stone-300 p-2 text-sm">
            <div className="flex items-center gap-4">
                {hasMileageBasis &&
                    (report.isSubmitted || report.isFinalized ? (
                        <div className="flex items-center gap-1 text-sm">
                            <MapIcon className="h-4 w-4 text-stone-400" />
                            <span>
                                {report.mileage.toLocaleString("en-us")} <span className="text-stone-400">miles</span>
                            </span>
                        </div>
                    ) : (
                        <InputField
                            label={<span className="absolute ml-2 text-sm text-stone-500 small-caps">Mileage</span>}
                            classes={{ input: "mt-2", label: "relative", inputContainer: "!pb-1 border border-blue-600", root: "w-40" }}
                            startAdornment={<MapIcon className="mt-2 h-4 w-4 text-stone-600" />}
                            defaultValue={report.mileage}
                            onBlur={(e) => {
                                update({
                                    mileage: e.target.value ? Math.max(0, parseInt(e.target.value)) : 0,
                                });
                            }}
                            ref={mileageRef}
                            type="number"
                            min={0}
                        />
                    ))}
                {hasRevenueBasis &&
                    (report.isSubmitted || report.isFinalized ? (
                        <div className="flex items-center gap-1 text-sm">
                            <CurrencyDollarIcon className="h-4 w-4 text-stone-400" />
                            <span>
                                {report.revenue.toLocaleString("en-us", { style: "currency", currency: "USD", maximumFractionDigits: 0 })}{" "}
                                <span className="text-stone-400">revenue</span>
                            </span>
                        </div>
                    ) : (
                        <InputField
                            label={<span className="absolute ml-2 text-sm text-stone-500 small-caps">Revenue</span>}
                            classes={{ input: "mt-2", label: "relative", inputContainer: "!pb-1 border border-blue-600", root: "w-40" }}
                            startAdornment={<CurrencyDollarIcon className="mt-2 h-4 w-4 text-stone-600" />}
                            defaultValue={report.revenue}
                            onBlur={(e) => {
                                update({
                                    revenue: e.target.value ? Math.max(0, parseInt(e.target.value)) : 0,
                                });
                            }}
                            ref={revenueRef}
                            type="number"
                            min={0}
                        />
                    ))}
                {data.isMileageReporter && (
                    <div className="flex items-center gap-1 text-sm">
                        <TruckIcon className="h-4 w-4 text-stone-400" />
                        <span>
                            {data.equipment.activeTractors.length.toLocaleString("en-us", { maximumFractionDigits: 0 })}{" "}
                            <span className="text-stone-400">units</span>
                        </span>
                    </div>
                )}
                <div className="flex grow justify-end">
                    {report.isSubmitted ? (
                        <div className="flex w-max items-center gap-2 rounded-full bg-blue-50 px-2">
                            <CheckCircleIcon className="h-4 w-4 text-blue-600" />
                            <span className="-mt-0.5 text-sm text-blue-600 small-caps">
                                Submitted {moment(report.submittedDate).format("MM/DD/YY")}
                            </span>
                        </div>
                    ) : report.isFinalized ? (
                        <div className="flex w-max items-center gap-2 rounded-full bg-emerald-50 px-2">
                            <CheckCircleIcon className="h-4 w-4 text-emerald-600" />
                            <span className="-mt-0.5 text-sm text-emerald-600 small-caps">
                                Completed {moment(report.finalizedDate).format("MM/DD/YY")}
                            </span>
                        </div>
                    ) : (
                        <PrimaryButton
                            className="mt-4 w-44"
                            onClick={() => {
                                setConfirming(true);
                                setCountdown(COUNTDOWN);
                            }}
                            disabled={
                                submit.isLoading ||
                                (submit.isSuccess && !!isFetching) ||
                                (hasMileageBasis && !report.mileage) ||
                                (hasRevenueBasis && !report.revenue)
                            }
                        >
                            Submit
                            <ArrowRightCircleIcon className="h-4 w-4" />
                        </PrimaryButton>
                    )}
                </div>
            </div>
            <div>
                <table className="cols-spacing-2 w-full text-sm row-p-2 row-spacing-1 md:col-spacing-6">
                    <thead>
                        <tr className="text-xs text-stone-600 small-caps">
                            <th className="text-left font-normal">Coverage</th>
                            <th className="text-right font-normal">Est. total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(data.premiumByCoverage.entries()).map(([coverageOptionId, { subtotalPremium, rates }]) => (
                            <Fragment key={coverageOptionId}>
                                <tr
                                    className={rates.length > 0 ? "cursor-pointer" : ""}
                                    onClick={() => {
                                        if (rates.length > 0) {
                                            setExpanded((old) => {
                                                const newExpanded = new Set(old);
                                                if (old.has(coverageOptionId)) {
                                                    newExpanded.delete(coverageOptionId);
                                                } else {
                                                    newExpanded.add(coverageOptionId);
                                                }
                                                return newExpanded;
                                            });
                                        }
                                    }}
                                >
                                    <td className="text-left">
                                        <ChevronRightIcon
                                            className={classNames(
                                                "mr-2 inline h-4 w-4 transition-transform",
                                                expanded.has(coverageOptionId) && "rotate-90",
                                                rates.length === 0 && "opacity-0"
                                            )}
                                        />
                                        {CoverageOption.getMetadata(coverageOptionId).name}
                                    </td>
                                    <td className="text-right tabular-nums">
                                        {subtotalPremium.toLocaleString("en-us", { style: "currency", currency: "USD" })}
                                    </td>
                                </tr>
                                {rates.length > 0 && (
                                    <tr>
                                        <td colSpan={2} className="!pr-0">
                                            <Collapse isOpen={expanded.has(coverageOptionId)} containerProps={{ className: "w-full" }}>
                                                <table className="w-full border-l bg-blue-50/50 text-sm col-spacing-2 row-p-2 row-spacing-1 md:col-spacing-6">
                                                    <thead>
                                                        <tr className="text-xs text-stone-600 small-caps">
                                                            <th className="text-left font-normal">Description</th>
                                                            <th className="text-left font-normal">Basis</th>
                                                            <th className="text-right font-normal">Value</th>
                                                            <th className="text-right font-normal">Rate</th>
                                                            <th className="text-right font-normal">Est. total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rates.map((rate) => (
                                                            <tr key={`${coverageOptionId}-${rate.basis}-${rate.description}`}>
                                                                <td className="text-left">
                                                                    <span className="text-stone-500">
                                                                        {rate.description ||
                                                                            CoverageOption.getMetadata(coverageOptionId).name}
                                                                    </span>
                                                                </td>
                                                                <td className="text-left">{rate.basis}</td>
                                                                <td className="text-right tabular-nums">
                                                                    {rate.factor.toLocaleString(
                                                                        "en-us",
                                                                        rate.basis === Coverage.Basis.REVENUE ||
                                                                            rate.basis === Coverage.Basis.VALUES ||
                                                                            rate.basis === Coverage.Basis.OTHER
                                                                            ? {
                                                                                  style: "currency",
                                                                                  currency: "USD",
                                                                                  maximumFractionDigits: 0,
                                                                              }
                                                                            : {}
                                                                    )}
                                                                </td>
                                                                <td className="text-right tabular-nums">
                                                                    {(rate.basis === Coverage.Basis.VALUES ||
                                                                    rate.basis === Coverage.Basis.REVENUE
                                                                        ? rate.rate * 100
                                                                        : rate.rate
                                                                    ).toLocaleString("en-us", {
                                                                        maximumFractionDigits: 12,
                                                                    })}{" "}
                                                                    <span className="text-stone-400">
                                                                        {rate.basis === Coverage.Basis.MILEAGE
                                                                            ? "per mile"
                                                                            : rate.basis === Coverage.Basis.REVENUE ||
                                                                              rate.basis === Coverage.Basis.VALUES
                                                                            ? "per $100"
                                                                            : rate.basis === Coverage.Basis.UNITS
                                                                            ? `per ${
                                                                                  coverageOptionId ===
                                                                                  CoverageOption.Id.OCCUPATIONAL_ACCIDENT
                                                                                      ? "driver"
                                                                                      : "unit"
                                                                              }`
                                                                            : null}
                                                                    </span>
                                                                </td>
                                                                <td className="text-right tabular-nums">
                                                                    {rate.total.toLocaleString("en-us", {
                                                                        style: "currency",
                                                                        currency: "USD",
                                                                    })}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Collapse>
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        ))}
                    </tbody>
                    <tfoot className="border-t">
                        <tr className="text-stone-500">
                            <td className="text-right">Subtotal</td>
                            <td className="text-right tabular-nums">
                                {data.subtotalPremium.toLocaleString("en-us", { style: "currency", currency: "USD" })}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right align-top">Total due</td>
                            <td className="text-right tabular-nums">
                                <p>{data.totalPremium.toLocaleString("en-us", { style: "currency", currency: "USD" })}</p>
                                {data.minimumPremiumApplied > 0 && (
                                    <Tooltip
                                        title={
                                            <div className="font-normal">
                                                <p>This policy has the following minimum premiums:</p>
                                                {data.minimumPremium > 0 && (
                                                    <p>
                                                        <span className="font-medium">Policy minimum premium:</span>{" "}
                                                        {data.minimumPremium.toLocaleString("en-us", {
                                                            style: "currency",
                                                            currency: "USD",
                                                            maximumFractionDigits: 2,
                                                        })}
                                                    </p>
                                                )}
                                                {Array.from(data.premiumByCoverage.entries())
                                                    .filter(([_, c]) => c.minimumPremium > 0)
                                                    .map(([id, c]) => (
                                                        <p key={id}>
                                                            <span className="font-medium">{CoverageOption.getMetadata(id).name}:</span>{" "}
                                                            {c.minimumPremium.toLocaleString("en-us", {
                                                                style: "currency",
                                                                currency: "USD",
                                                                maximumFractionDigits: 2,
                                                            })}
                                                        </p>
                                                    ))}
                                            </div>
                                        }
                                    >
                                        <div className="flex items-center gap-2 text-xs text-stone-500">
                                            <p>Minimum premium applied</p>
                                            <InformationCircleIcon className="h-4 w-4" />
                                        </div>
                                    </Tooltip>
                                )}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <Dialog
                open={confirming}
                onClose={() => {
                    setConfirming(false);
                }}
            >
                <div className="p-4">
                    <h3 className="text-xl font-medium leading-6 text-navigator-primary-dark">Submit monthly report</h3>
                    <Alert variant="warning" className="mt-4 max-w-prose">
                        By submitting this report you are agreeing that the information presented is accurate and truthful to the best of
                        your knowledge.
                    </Alert>

                    <div className="mt-8 flex items-center justify-end gap-8">
                        <button
                            type="button"
                            className="px-2 py-1 text-stone-500"
                            onClick={() => {
                                setConfirming(false);
                            }}
                        >
                            Cancel
                        </button>

                        <PrimaryButton
                            type="button"
                            className="min-w-[96px]"
                            onClick={() => submit.mutate()}
                            disabled={countdown > 0 || submit.isLoading}
                        >
                            {countdown || "Confirm"}
                        </PrimaryButton>
                    </div>
                </div>
            </Dialog>
            <PaymentDialog
                open={paying}
                onClose={() => {
                    setPaying(false);
                }}
                defaultValues={{
                    amount: Math.round(data.totalPremium * 100) / 100,
                    description: `${moment()
                        .month(data.month - 1)
                        .year(data.year)
                        .format("MMMM YYYY")} report`,
                }}
            />
        </div>
    );
}
