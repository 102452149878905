import { Coverage, CoverageOption } from "@deathstar/types/northstar";
import { classNames } from "@deathstar/ui";
import { CheckIcon } from "@heroicons/react/24/outline";
import { uniq } from "lodash";
import { useRef } from "react";
import api from "../../api/api";
import { EquipmentTypeString } from "../../api/queries/equipment";
import { useAccountId } from "../../api/useAccountId";

export default function CoverageSelector({
    existingCoverages,
    onChange,
    dataType,
    onLoad,
}: {
    existingCoverages: Coverage["coverageOptionId"][];
    onChange(coverages: Coverage["coverageOptionId"][]): void;
    dataType: EquipmentTypeString | "drivers";
    onLoad?: (data: Coverage["coverageOptionId"][]) => void;
}) {
    const accountId = useAccountId();
    const hasLoaded = useRef(false);
    const { data } = api.policies.useFind(accountId!, "active", {
        select: (policies) =>
            uniq(
                policies
                    .flatMap((policy) =>
                        policy.coverages
                            .map((coverage) => coverage.coverageOptionId)
                            .filter((coverageOptionId) => {
                                switch (dataType) {
                                    case "tractors":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).tractors;
                                    case "trailers":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).trailers;
                                    case "autos":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).businessAutos;
                                    case "tools":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).tools;
                                    case "drivers":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).drivers;
                                    default:
                                        return false;
                                }
                            })
                    )
                    .sort()
            ),
        onSuccess: (data) => {
            if (!hasLoaded.current) {
                hasLoaded.current = true;
                onLoad?.(data);
            }
        },
    });

    return (
        <div className="w-max">
            <p>
                Desired coverages <span className="text-xs text-stone-500">(click to select one or more)</span>
            </p>
            {data?.map((coverageOptionId) => (
                <button
                    type="button"
                    key={coverageOptionId}
                    className={classNames(
                        "flex w-full items-center gap-2 rounded px-2 py-1 text-stone-500 disabled:opacity-50 enabled:hover:bg-stone-100",
                        existingCoverages.includes(coverageOptionId) ? "font-medium text-waypoint-blue" : ""
                    )}
                    onClick={() => {
                        if (existingCoverages.includes(coverageOptionId)) {
                            onChange(existingCoverages.filter((c) => c !== coverageOptionId));
                        } else {
                            onChange([...existingCoverages, coverageOptionId]);
                        }
                    }}
                >
                    <div className="h-4 w-4">{existingCoverages.includes(coverageOptionId) ? <CheckIcon className="h-4 w-4" /> : ""}</div>
                    {CoverageOption.getMetadata(coverageOptionId).name}
                </button>
            ))}
        </div>
    );
}
