import { Zendesk } from "@deathstar/types/waypoint";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

interface CreateCommentDto {
    html_body: string;
    files: File[] | null;
}

interface CreateRequestDto {
    ticket_form_id: string;
    subject: string;
    comment: {
        html_body: string;
        files: File[];
    };
    custom_fields: Record<number, unknown>;
}

export const zendesk = {
    queryKeys: {
        forms: (accountId: string) => [accountId, "zendesk", "forms"] as QueryKey,
        form: (accountId: string, formId: number) => [accountId, "zendesk", "forms", formId] as QueryKey,
        requests: (accountId: string) => [accountId, "zendesk", "requests"] as QueryKey,
        request: (accountId: string, requestId: number) => [accountId, "zendesk", "requests", requestId] as QueryKey,
        comments: (accountId: string, requestId: number) => [accountId, "zendesk", "requests", requestId, "comments"] as QueryKey,
        articles: (accountId: string) => [accountId, "zendesk", "articles"] as QueryKey,
    },

    getArticles: (accountId: string) => {
        return blazar.fetchJson<Zendesk.Article[]>(`/waypoint/orgs/${accountId}/help-center/articles/promoted`);
    },

    useArticles: (accountId: string, config?: UseQueryOptions<Zendesk.Article[], ResponseError>) =>
        useQuery<Zendesk.Article[], ResponseError>(
            zendesk.queryKeys.articles(accountId),
            () => {
                return zendesk.getArticles(accountId);
            },
            {
                staleTime: 30000,
                retry: (count, error) => {
                    return count < 3 && error.status !== 401 && error.status !== 403 && error.status !== 404 && error.status !== 429;
                },
                ...config,
            }
        ),

    getRequests: (accountId: string) => {
        return blazar.fetchJson<Zendesk.Request[]>(`/waypoint/orgs/${accountId}/requests`);
    },

    useRequests: (accountId: string, config?: UseQueryOptions<Zendesk.Request[], ResponseError>) =>
        useQuery<Zendesk.Request[], ResponseError>(
            zendesk.queryKeys.requests(accountId),
            () => {
                return zendesk.getRequests(accountId);
            },
            {
                staleTime: 30000,
                retry: (count, error) => {
                    return count < 3 && error.status !== 401 && error.status !== 403 && error.status !== 404 && error.status !== 429;
                },
                ...config,
            }
        ),

    getRequest: (accountId: string, requestId: number) => {
        return blazar.fetchJson<Zendesk.RequestData>(`/waypoint/orgs/${accountId}/requests/${requestId}`);
    },

    useRequest: (accountId: string, requestId: number) =>
        useQuery<Zendesk.RequestData, ResponseError>(
            zendesk.queryKeys.request(accountId, requestId),
            () => {
                return zendesk.getRequest(accountId, requestId);
            },
            {
                staleTime: 30000,
                retry: (count, error) => {
                    return count < 3 && error.status !== 403 && error.status !== 404 && error.status !== 429;
                },
            }
        ),

    getComments: (accountId: string, requestId: number) => {
        return blazar.fetchJson<Zendesk.RequestCommentsData>(`/waypoint/orgs/${accountId}/requests/${requestId}/comments`);
    },

    useComments: (accountId: string, requestId: number) =>
        useQuery<Zendesk.RequestCommentsData, ResponseError>(
            zendesk.queryKeys.comments(accountId, requestId),
            () => {
                return zendesk.getComments(accountId, requestId);
            },
            {
                staleTime: 30000,
                retry: (count, error) => {
                    return count < 3 && error.status !== 403 && error.status !== 404 && error.status !== 429;
                },
            }
        ),

    createRequest: async (accountId: string, request: CreateRequestDto) => {
        const formData = new FormData();
        if (request.comment.files) {
            for (const file of request.comment.files) {
                formData.append(`request[comment][files][]`, file);
            }
        }
        if (request.ticket_form_id) {
            formData.append("ticket_form_id", request.ticket_form_id);
        }
        formData.append("subject", request.subject);

        formData.append("comment[html_body]", request.comment.html_body);
        const customFields = Object.entries(request.custom_fields);
        for (let i = 0; i < customFields.length; i++) {
            const [id, value] = customFields[i];
            formData.append(`custom_fields[${i}][id]`, id);
            formData.append(`custom_fields[${i}][value]`, value as string);
        }

        const response = await blazar.fetch(`/waypoint/orgs/${accountId}/requests`, {
            method: "POST",
            body: formData,
        });
        return response.json();
    },

    useCreateRequest: (accountId: string, config?: UseMutationOptions<Zendesk.Request, ResponseError, CreateRequestDto>) => {
        return useMutation(
            (request: CreateRequestDto) => {
                return zendesk.createRequest(accountId, request);
            },
            {
                ...config,
                onSuccess: (...args) => {
                    queryClient.invalidateQueries(zendesk.queryKeys.requests(accountId));
                    config?.onSuccess?.(...args);
                },
            }
        );
    },

    createComment: async (accountId: string, requestId: number, comment: CreateCommentDto) => {
        const formData = new FormData();
        if (comment.files) {
            for (const file of comment.files) {
                formData.append(`files[]`, file);
            }
        }
        formData.append("html_body", comment.html_body);
        const response = await blazar.fetch(`/waypoint/orgs/${accountId}/requests/${requestId}/comments`, {
            method: "POST",
            body: formData,
        });
        return response.json();
    },

    useCreateComment: (
        accountId: string,
        requestId: number,
        config?: UseMutationOptions<Zendesk.RequestComment, ResponseError, CreateCommentDto>
    ) => {
        return useMutation<Zendesk.RequestComment, ResponseError, CreateCommentDto>(
            (comment) => {
                return zendesk.createComment(accountId, requestId, comment);
            },
            {
                ...config,
                onSuccess: (...args) => {
                    queryClient.invalidateQueries(zendesk.queryKeys.comments(accountId, requestId));
                    config?.onSuccess?.(...args);
                },
            }
        );
    },

    getForms: (accountId: string) => {
        return blazar.fetchJson<Zendesk.TicketForm[]>(`/waypoint/orgs/${accountId}/request-forms`);
    },

    useForms: (accountId: string) =>
        useQuery<Zendesk.TicketForm[], ResponseError>(
            zendesk.queryKeys.forms(accountId),
            () => {
                return zendesk.getForms(accountId);
            },
            {
                staleTime: Infinity,
                retry: (count, error) => {
                    return count < 3 && error.status !== 403 && error.status !== 404 && error.status !== 429;
                },
            }
        ),

    getForm: (accountId: string, formId: number) => {
        return blazar.fetchJson<Zendesk.TicketFormData>(`/waypoint/orgs/${accountId}/request-forms/${formId}`);
    },

    useForm: (accountId: string, formId: number, config?: UseQueryOptions<Zendesk.TicketFormData, ResponseError>) =>
        useQuery<Zendesk.TicketFormData, ResponseError>(
            zendesk.queryKeys.form(accountId, formId),
            () => {
                return zendesk.getForm(accountId, formId);
            },
            {
                staleTime: Infinity,
                retry: (count, error) => {
                    return count < 3 && error.status !== 403 && error.status !== 404 && error.status !== 429;
                },
                ...config,
            }
        ),
};
