import React from "react";
import NewInspectionIcon from "../../assets/icons/new-inspection-icon.png";
import { ChartContainer } from "../Charts/Container";
import { NewPointsChart } from "../Charts/NewPoints";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { Transition } from "../Transition";
import { Legend } from "./ExpiringPoints";
import { SummarySection } from "./SummarySection";

export function NewPoints(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const { violations } = motorCarrier.inspections.filterNew();

    return (
        <SummarySection leadingChart>
            <Transition.Fade>
                <ChartContainer className="aspect-video max-h-[40vh] min-w-[600px]">
                    <NewPointsChart motorCarrier={motorCarrier} />
                </ChartContainer>
            </Transition.Fade>

            <div>
                <TitleGroup
                    title="New Points"
                    variant="h4"
                    className="mb-8"
                    icon={<img src={NewInspectionIcon} alt="New Inspection Icon" className="h-[35px]" />}
                    description={`${violations.getTotalWeight()} points accumlated within the previous 90 days`}
                />

                <Legend leadingChart violations={violations} />
            </div>
        </SummarySection>
    );
}
