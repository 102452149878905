import { Account, Policy } from "../northstar";
import { User } from "./User";

export interface OOProgramMember {
    id: string;
    name: string;
    dba: string;
    dot: string;
    mc: string;
    ownerName: string;

    policy: {
        status: string;
        policies: Policy[];
    };
}

export enum OOProgramPolicyStatus {
    Active = "Active",
    Inactive = "Inactive",
    PendingCancellation = "Pending Cancellation",
}

export interface OOProgramDetails {
    members: OOProgramMember[];
    status: {
        insurance: {
            active: number;
            precancellation: number;
            inactive: number;
        };
    };
    invitations: Pick<OOProgramInvitation, "id" | "accountId" | "name" | "email" | "phone" | "createdDate" | "vin">[];
}

export interface OOProgramInvitation {
    id: number;

    accountId: string;

    name: string;

    email: string;

    phone: string;

    vin: string;

    license: string;

    createdById: string;

    createdDate: Date;

    isNotificationSent: boolean;

    createdBy: User;

    account: Account;
}
