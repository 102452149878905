import { Line } from "react-chartjs-2";

import type { MotorCarrier } from "@deathstar/motor-carrier";

import { FMCSA } from "@deathstar/types";
import { config } from "../ChartConfig";

export function HistoricalScoreChart({ motorCarrier }: { motorCarrier: MotorCarrier }): JSX.Element {
    const basics = Array.from(motorCarrier.smsResults.sortByDate("ASC"));

    const datasets = [
        {
            label: FMCSA.BasicName.HAZMAT,
            data: basics.map((basic) => (typeof basic.hazmat.score === "number" && basic.hazmat.score > 0 ? basic.hazmat.score : null)),
            backgroundColor: config.colors[FMCSA.BasicName.HAZMAT],
            borderColor: config.colors[FMCSA.BasicName.HAZMAT],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: config.colors[FMCSA.BasicName.HAZMAT],
            pointStyle: "triangle",
        },
        {
            label: FMCSA.BasicName.CONTROLLED_SUBSTANCES,
            data: basics.map((basic) =>
                typeof basic.controlledSubstances.score === "number" && basic.controlledSubstances.score > 0
                    ? basic.controlledSubstances.score
                    : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
            borderColor: config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
            pointStyle: "triangle",
        },
        {
            label: FMCSA.BasicName.DRIVER_FITNESS,
            data: basics.map((basic) =>
                typeof basic.driverFitness.score === "number" && basic.driverFitness.score > 0 ? basic.driverFitness.score : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.DRIVER_FITNESS],
            borderColor: config.colors[FMCSA.BasicName.DRIVER_FITNESS],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: config.colors[FMCSA.BasicName.DRIVER_FITNESS],
            pointStyle: "triangle",
        },
        {
            label: FMCSA.BasicName.VEHICLE_MAINTENANCE,
            data: basics.map((basic) =>
                typeof basic.vehicleMaintenance.score === "number" && basic.vehicleMaintenance.score > 0
                    ? basic.vehicleMaintenance.score
                    : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
            borderColor: config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
            pointStyle: "triangle",
        },
        {
            label: FMCSA.BasicName.HOS,
            data: basics.map((basic) =>
                typeof basic.hoursOfService.score === "number" && basic.hoursOfService.score > 0 ? basic.hoursOfService.score : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.HOS],
            borderColor: config.colors[FMCSA.BasicName.HOS],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: config.colors[FMCSA.BasicName.HOS],
            pointStyle: "triangle",
        },
        {
            label: FMCSA.BasicName.UNSAFE_DRIVING,
            data: basics.map((basic) =>
                typeof basic.unsafeDriving.score === "number" && basic.unsafeDriving.score > 0 ? basic.unsafeDriving.score : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
            borderColor: config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
            pointStyle: "triangle",
        },
        {
            label: FMCSA.BasicName.CRASH_INDICATOR,
            data: basics.map((basic) =>
                typeof basic.crashIndicator.score === "number" && basic.crashIndicator.score > 0 ? basic.crashIndicator.score : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.CRASH_INDICATOR],
            borderColor: config.colors[FMCSA.BasicName.CRASH_INDICATOR],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: config.colors[FMCSA.BasicName.CRASH_INDICATOR],
            pointStyle: "triangle",
        },
    ].filter((dataset) => {
        if (dataset.data.some((it) => (typeof it === "number" ? it > 0 : false))) {
            return dataset;
        }
        return null;
    });

    return (
        <Line
            className="h-full w-full"
            data={{
                labels: basics.map((basic) => basic.recordDate.getMonthString({ abbreviate: true })),
                datasets,
            }}
            options={{
                maintainAspectRatio: false,
                scales: {
                    y: {
                        suggestedMin: 0,
                        beginAtZero: config.chart.line.axis.y.beginAtZero,
                        min: config.chart.line.axis.y.min,
                        max: config.chart.line.axis.y.max,
                        ticks: {
                            format: {
                                style: "unit",
                                unit: "percent",
                            },
                            color: config.chart.line.labels.font.color,
                            // font: {
                            //     size: config.chart.line.labels.font.size,
                            // },
                        },
                        grid: config.chart.line.grid,
                    },
                    x: {
                        ticks: {
                            color: config.chart.line.labels.font.color,
                            // font: {
                            //     size: config.chart.line.labels.font.size,
                            // },
                        },
                        grid: config.chart.line.grid,
                    },
                },
                plugins: {
                    legend: {
                        display: config.chart.line.legend.display,
                    },
                },
            }}
        />
    );
}
