import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import NavigationTabs from "../../components/navigation-tabs/NavigationTabs";
import Title from "../../components/title/Title";
import MemberPermissions from "./members/MemberPermissions";
import SubscriptionSettings from "./subscription/SubscriptionSettings";

export default function Settings() {
    return (
        <Routes>
            <Route
                element={
                    <div className="space-y-8 p-4 lg:p-12">
                        <div>
                            <Title>Settings</Title>
                            <div className="mt-2 flex flex-col gap-4">
                                <NavigationTabs
                                    tabs={[
                                        {
                                            to: "members",
                                            label: "Members",
                                        },
                                        {
                                            to: "billing",
                                            label: "Billing",
                                        },
                                        {
                                            to: "subscription",
                                            label: "Subscription",
                                        },
                                    ]}
                                />

                                <div className="grow">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            >
                <Route index element={<Navigate to="members" />} />
                <Route path="members" element={<MemberPermissions />} />
                <Route path="subscription" element={<SubscriptionSettings />} />
                <Route
                    path="billing"
                    element={
                        <div>
                            <p>
                                The billing page has been moved! Check out the{" "}
                                <span className="px-2 font-medium text-waypoint-blue">
                                    <CurrencyDollarIcon className="-mt-1 mr-2 inline-block h-5 w-5" />
                                    Billing
                                </span>{" "}
                                item on the navigation menu
                            </p>
                        </div>
                    }
                />
            </Route>
        </Routes>
    );
}
