import { QueryClient } from "react-query";
import { ResponseError } from "../api/util/exceptions";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (count, error) => {
                if (error instanceof ResponseError) {
                    return count < 3 && error.status !== 401 && error.status !== 403 && error.status !== 404 && error.status !== 429;
                } else {
                    return count < 3;
                }
            },
        },
    },
});
