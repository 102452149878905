import { Transform } from "class-transformer";
import { IsBoolean, IsInt, IsISO8601, IsOptional, IsString } from "class-validator";
import {
    Account,
    BaseCertificateFormData,
    CertificateBuilderConfigItem,
    CertificateHolder,
    MasterCertificatePolicy,
    Tractor,
    Trailer,
    User,
} from ".";

export class MasterCertificate extends BaseCertificateFormData {
    static GetBuilderOptions(certificate: MasterCertificate): CertificateBuilderConfigItem[] {
        return [
            !!certificate.hideCoverageWorkersCompensation && { id: CertificateBuilderConfigItem.Id.HideCoverageWC },
            !!certificate.hideCoverageExcessLiability && { id: CertificateBuilderConfigItem.Id.HideCoverageXSAL },
            !!certificate.hideCoverageExcessCargo && { id: CertificateBuilderConfigItem.Id.HideCoverageXSC },
            !!certificate.hideCoveragePhysicalDamage && { id: CertificateBuilderConfigItem.Id.HideCoveragePD },
            !!certificate.hideCoverageCargo && { id: CertificateBuilderConfigItem.Id.HideCoverageCargo },
            !!certificate.hideCoverageOccupationalAccident && { id: CertificateBuilderConfigItem.Id.HideCoverageOCAC },
            !!certificate.hideCoveragePollution && { id: CertificateBuilderConfigItem.Id.HideCoveragePOLL },
            !!certificate.hideBlanketedAdditionalInterest && { id: CertificateBuilderConfigItem.Id.HideBlanketAI },
            !!certificate.hideAtOneLossAtAnyTerminal && { id: CertificateBuilderConfigItem.Id.HideAtOneLossAtAnyTerminal },
            !!certificate.hideNonTruckingCheckbox && { id: CertificateBuilderConfigItem.Id.HideNonTruckingCheckbox },
            !!certificate.hideHiredPhysicalDamage && { id: CertificateBuilderConfigItem.Id.HideHiredPhysicalDamage },
            !!certificate.hideWorkersCompensationExcludedPersons && {
                id: CertificateBuilderConfigItem.Id.HideWorkersCompensationExcludedPersons,
            },
            !!certificate.hideTrailerInterchange && { id: CertificateBuilderConfigItem.Id.HideTrailerInterchange },
        ].filter(Boolean);
    }
    @IsOptional()
    @IsInt()
    id: number;

    @IsOptional()
    @IsISO8601()
    createDate: Date;

    @IsOptional()
    @IsISO8601()
    updateDate: Date;

    @IsOptional()
    @IsISO8601()
    deleteDate: Date;

    @IsString()
    createdByGuid: string;
    createdBy: User;

    @IsOptional()
    @IsString()
    updatedByGuid: string;
    updatedBy: User;

    @IsOptional()
    @IsString()
    deletedByGuid: string;
    deletedBy: User;

    @IsString()
    accountId: string;

    @IsString()
    description: string;

    @IsOptional()
    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    isRetired: boolean;

    @IsOptional()
    @IsISO8601()
    retiredDate: Date;

    @IsString()
    retiredByGuid: string;
    retiredBy: User;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    disableHolderSyncOnCreation: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    disableHolderSyncOnPolicyChange: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideCoverageWorkersCompensation: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideCoverageExcessLiability: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideCoverageExcessCargo: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideCoveragePhysicalDamage: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideCoverageCargo: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideCoverageOccupationalAccident: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideCoveragePollution: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideBlanketedAdditionalInterest: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideAtOneLossAtAnyTerminal: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideNonTruckingCheckbox: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideHiredPhysicalDamage: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideWorkersCompensationExcludedPersons: boolean;

    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    hideTrailerInterchange: boolean;

    @IsOptional()
    @IsString()
    insuredAdditionalRemarks: string;

    @IsString({ each: true })
    policyIds: MasterCertificatePolicy[];

    @IsString()
    authorizedByGuid: string;

    holders: CertificateHolder[];
    account: Account;
    authorizedBy: User;
    lastSentDate: Date;

    coveragesString: string;
    primaryEffectiveDate: string;
    primaryExpirationDate: string;
    primaryFirstNamedInsured: string;
    coverageOptionIds: number[];
    availableUnits: { tractors: Tractor[]; trailers: Trailer[] };
    availableBuilderConfigIds: CertificateBuilderConfigItem["id"][];
}

export interface CertificateWithHolderCount extends MasterCertificate {
    holderCount: number;
}
