import { classNames } from "@deathstar/ui";
import { Section } from "../Components/Section";

export function SummarySection({
    children,
    className,
    leadingChart,
}: {
    children: React.ReactNode;
    className?: string;
    leadingChart?: boolean;
}): JSX.Element {
    return (
        <Section
            className={classNames("grid grid-cols-[minmax(400px,_1fr)_1fr] ", className, {
                // "grid grid-cols-[200px_minmax(900px,_1fr)_100px] gap-4": leadingChart,
                "grid-cols-[minmax(1fr,_400px)_1fr]": leadingChart,
                "text-right": !leadingChart,
            })}
        >
            {children}
        </Section>
    );
}
