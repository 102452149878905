import {
    AttributeOption,
    Coverage,
    CoverageOption,
    Driver,
    MonthlyReport,
    MonthlyReportChanges,
    MonthlyReportCoveragePremium,
    MonthlyReportData,
    MonthlyReportDataUnit,
    PolicyLayer,
    PolicyLayerComparison,
    Tractor,
    Trailer,
} from "@deathstar/types/northstar";
import { sortBy } from "lodash";
import moment from "moment";

export class MonthlyReportHelper {
    /** Get the units that will affect the premium of the policy, taking into account the 15 day rule if necessary */
    public static getFinalUnits(
        data: Pick<MonthlyReportData, "month" | "year"> & {
            equipment: Pick<MonthlyReportData["equipment"], "tractors" | "trailers" | "drivers">;
            endLayer: { policy: Pick<MonthlyReportData["endLayer"]["policy"], "attributes"> };
        },
        coverage: Pick<Coverage, "coverageOptionId"> & {
            linkedTractors: Pick<Coverage["linkedTractors"][number], "tractorId">[];
            linkedTrailers: Pick<Coverage["linkedTrailers"][number], "trailerId">[];
            linkedDrivers: Pick<Coverage["linkedDrivers"][number], "driverId">[];
        }
    ): {
        tractors: MonthlyReportDataUnit<Tractor>[];
        trailers: MonthlyReportDataUnit<Trailer>[];
        drivers: MonthlyReportDataUnit<Driver>[];
    } {
        const is15DayRuleApplied = data.endLayer.policy.attributes.some(
            (att) => att.attributeOptionId === AttributeOption.Id.FIFTEEN_DAY_RULE_APPLIES
        );
        const fifteenthDayOfMonth = moment()
            .date(15)
            .month(data.month - 1)
            .year(data.year);

        let tractors: MonthlyReportDataUnit<Tractor>[] = [];
        let trailers: MonthlyReportDataUnit<Trailer>[] = [];
        let drivers: MonthlyReportDataUnit<Driver>[] = [];

        if (is15DayRuleApplied) {
            tractors = data.equipment.tractors.filter((t) => {
                const isActive = coverage.linkedTractors.some((lt) => lt.tractorId === t.id);
                const isRemovedOnOrBefore15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isSameOrBefore(fifteenthDayOfMonth, "day")
                );
                const isRemovedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );
                const isAddedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "new") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );

                if (isRemovedOnOrBefore15th && !isActive) return false;
                if (isAddedAfter15th && isActive) return false;
                if (isRemovedAfter15th && !isActive) return true;
                return isActive;
            });

            trailers = data.equipment.trailers.filter((t) => {
                const isActive = coverage.linkedTrailers.some((lt) => lt.trailerId === t.id);
                const isRemovedOnOrBefore15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isSameOrBefore(fifteenthDayOfMonth, "day")
                );
                const isRemovedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );
                const isAddedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "new") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );

                if (isRemovedOnOrBefore15th && !isActive) return false;
                if (isAddedAfter15th && isActive) return false;
                if (isRemovedAfter15th && !isActive) return true;
                return isActive;
            });

            drivers = data.equipment.drivers.filter((t) => {
                const isActive = coverage.linkedDrivers.some((lt) => lt.driverId === t.id);
                const isRemovedOnOrBefore15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isSameOrBefore(fifteenthDayOfMonth, "day")
                );
                const isRemovedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );
                const isAddedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "new") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );

                if (isRemovedOnOrBefore15th && !isActive) return false;
                if (isAddedAfter15th && isActive) return false;
                if (isRemovedAfter15th && !isActive) return true;
                return isActive;
            });
        } else {
            tractors = data.equipment.tractors.filter((t) => coverage.linkedTractors.some((lt) => lt.tractorId === t.id));
            trailers = data.equipment.trailers.filter((t) => coverage.linkedTrailers.some((lt) => lt.trailerId === t.id));
            drivers = data.equipment.drivers.filter((t) => coverage.linkedDrivers.some((lt) => lt.driverId === t.id));
        }

        return {
            tractors,
            trailers,
            drivers,
        };
    }

    public static getPremiumByCoverage(
        report: Pick<MonthlyReport, "revenue" | "mileage">,
        data: Pick<MonthlyReportData, "endLayer" | "month" | "year" | "equipment">
    ): Map<CoverageOption.Id, MonthlyReportCoveragePremium> {
        const result = new Map<CoverageOption.Id, MonthlyReportCoveragePremium>();
        for (const coverage of data.endLayer.policy.coverages) {
            const coveragePremium: MonthlyReportCoveragePremium = {
                minimumPremium: 0,
                minimumPremiumApplied: 0,
                totalPremium: 0,
                subtotalPremium: 0,
                rates: [],
            };

            const { tractors, trailers, drivers } = MonthlyReportHelper.getFinalUnits(data, coverage as Required<Coverage>);

            const ratings: { description: string; basis: Coverage["basis"]; rate: number }[] = [];

            if (coverage.compositeRatings?.length) {
                for (const rating of coverage.compositeRatings) {
                    ratings.push({ description: rating.description, rate: rating.rate, basis: rating.basis });
                }
            } else {
                ratings.push({ description: "", rate: coverage.rate, basis: coverage.basis });
            }

            for (const rating of ratings) {
                const rate =
                    rating.basis === Coverage.Basis.REVENUE || rating.basis === Coverage.Basis.VALUES
                        ? (rating.rate || 0) / 100
                        : rating.basis === Coverage.Basis.OTHER
                        ? 1
                        : rating.rate || 0;
                let factor: number;

                if (rating.basis === Coverage.Basis.UNITS) {
                    if (coverage.coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT) {
                        factor = drivers.length;
                    } else {
                        if (rating.description.toLowerCase() === "tractors") {
                            factor = tractors.length;
                        } else if (rating.description.toLowerCase() === "trailers") {
                            factor = trailers.length;
                        } else {
                            factor = tractors.length + trailers.length;
                        }
                    }
                } else if (rating.basis === Coverage.Basis.VALUES) {
                    if (rating.description.toLowerCase() === "tractors") {
                        factor = tractors.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0);
                    } else if (rating.description.toLowerCase() === "trailers") {
                        factor = trailers.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0);
                    } else {
                        factor =
                            tractors.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0) +
                            trailers.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0);
                    }
                } else if (rating.basis === Coverage.Basis.MILEAGE) {
                    factor = report.mileage;
                } else if (rating.basis === Coverage.Basis.REVENUE) {
                    factor = report.revenue;
                } else if (rating.basis === Coverage.Basis.OTHER) {
                    factor = rating.rate || 0;
                } else {
                    continue;
                }
                const total = rate * factor;
                coveragePremium.rates.push({
                    description: rating.description,
                    basis: rating.basis,
                    rate,
                    factor,
                    total,
                });
            }

            coveragePremium.subtotalPremium = coveragePremium.rates.reduce((acc, r) => acc + r.total, 0);

            const minimumPremiumAttribute = coverage.attributes.find(
                (att) => att.attributeOptionId === AttributeOption.Id.MINIMUM_MONTHLY_PREMIUM_COVERAGE
            );
            if (minimumPremiumAttribute) {
                const minimumPremium = minimumPremiumAttribute.valueNumber || 0;
                coveragePremium.minimumPremium = minimumPremium;
                const minimumPremiumApplied = Math.max(0, minimumPremium - coveragePremium.subtotalPremium);
                coveragePremium.minimumPremiumApplied = minimumPremiumApplied;
            }

            coveragePremium.totalPremium = coveragePremium.subtotalPremium + coveragePremium.minimumPremiumApplied;

            result.set(coverage.coverageOptionId, coveragePremium);
        }
        return result;
    }

    public static getAvailableMonthsAfterJune2024(effectiveDate: Date, expirationDate: Date): { month: number; year: number }[] {
        const startDate = moment.utc(effectiveDate);
        const endDate = moment.utc(expirationDate);
        const months: { month: number; year: number }[] = [];
        while (
            startDate.isSameOrBefore(endDate, "month") &&
            (startDate.isBefore(moment(), "month") || (startDate.isSame(moment(), "month") && moment().date() >= 20))
        ) {
            if (startDate.isAfter(moment.utc("2024-06-01"), "month")) {
                months.push({
                    month: startDate.month() + 1,
                    year: startDate.year(),
                });
            }
            startDate.add(1, "month");
        }
        return months;
    }

    public static getAllAvailableMonths(effectiveDate: Date, expirationDate: Date): { month: number; year: number }[] {
        const startDate = moment.utc(effectiveDate);
        const endDate = moment.utc(expirationDate);
        const months: { month: number; year: number }[] = [];
        while (
            startDate.isSameOrBefore(endDate, "month") &&
            (startDate.isBefore(moment(), "month") || (startDate.isSame(moment(), "month") && moment().date() >= 20))
        ) {
            months.push({
                month: startDate.month() + 1,
                year: startDate.year(),
            });
            startDate.add(1, "month");
        }
        return months;
    }

    /**
     *
     * @param layers an array of all available PolicyLayers for the policy
     * @param month the month, where January is 1 and December is 12
     * @param year the year
     * @returns an array of layers that are to be used when calculating the changes for the given month. if exists, the earliest layer (the last in the array) will be from a month before the provided month, so as to provide a base for comparison for the first layer of the month. it is possible that fewer than two layers will be returned.
     */
    public static getLayersForMonth(layers: PolicyLayer[], month: number, year: number, finalizedDate: Date | null): PolicyLayer[] {
        layers = finalizedDate ? layers.filter((layer) => layer.createdDate.getTime() <= finalizedDate.getTime()) : layers;
        // if (layers.length < 2) {
        //     return [];
        // }
        const m = moment()
            .month(month - 1)
            .year(year);
        let earliestLayer = layers[layers.length - 1];
        for (let i = layers.length - 2; i > 1; i--) {
            if (moment.utc(layers[i].effectiveDate).isSameOrAfter(m, "month")) {
                break;
            }
            earliestLayer = layers[i];
        }
        let latestLayer = layers[0];
        for (let i = 0; i < layers.length - 1; i++) {
            latestLayer = layers[i];
            if (layers[i].effectiveDate.getTime() <= earliestLayer.effectiveDate.getTime()) {
                break;
            }
            if (moment.utc(layers[i].effectiveDate).isSameOrBefore(m, "month")) {
                break;
            }
        }

        return layers.filter(
            (layer) =>
                layer.effectiveDate.getTime() >= earliestLayer.effectiveDate.getTime() &&
                layer.effectiveDate.getTime() <= latestLayer.effectiveDate.getTime()
        );
    }

    /**
     *
     * @param layers The array of layers to compare.
     * @param equipment The equipment to link to the changes.
     */
    private static getChanges(layers: PolicyLayer[], equipment: PolicyLayerComparison.IEquipment): MonthlyReportChanges {
        const result: MonthlyReportChanges = {
            tractors: [],
            trailers: [],
            drivers: [],
        };

        if (layers.length < 2) {
            return result;
        }

        for (let i = layers.length - 1; i > 0; i--) {
            const baseLayer = layers[i];
            const comparisonLayer = layers[i - 1];
            const comparison = PolicyLayer.compare(
                {
                    baseLayer,
                    comparisonLayer,
                },
                {
                    data: {
                        equipment,
                    },
                    separateEquipment: true,
                    accounting: false,
                    additionalInterests: false,
                    coverages: false,
                }
            );

            const changes = Array.from(comparison.equipment.getChangeList().values());

            for (const change of changes) {
                if ("driverId" in change.entity) {
                    result.drivers.push({
                        date: comparisonLayer.effectiveDate,
                        unitId: change.entity.driverId,
                        changesByCoverage: change.changeMap,
                        isNewToPolicy: !!change.entity.comparison?.isNewToPolicy,
                        isRemovedFromPolicy: !!change.entity.comparison?.isDeletedFromPolicy,
                    });
                } else if ("tractorId" in change.entity) {
                    result.tractors.push({
                        date: comparisonLayer.effectiveDate,
                        unitId: change.entity.tractorId,
                        changesByCoverage: change.changeMap,
                        isNewToPolicy: !!change.entity.comparison?.isNewToPolicy,
                        isRemovedFromPolicy: !!change.entity.comparison?.isDeletedFromPolicy,
                    });
                } else if ("trailerId" in change.entity) {
                    result.trailers.push({
                        date: comparisonLayer.effectiveDate,
                        unitId: change.entity.trailerId,
                        changesByCoverage: change.changeMap,
                        isNewToPolicy: !!change.entity.comparison?.isNewToPolicy,
                        isRemovedFromPolicy: !!change.entity.comparison?.isDeletedFromPolicy,
                    });
                }
            }
        }

        return result;
    }

    public static getMonthlyReportData(
        report: Pick<MonthlyReport, "year" | "month" | "mileage" | "revenue" | "finalizedDate">,
        layers: PolicyLayer[],
        equipment: PolicyLayerComparison.IEquipment
    ): MonthlyReportData {
        const layersForMonth = MonthlyReportHelper.getLayersForMonth(layers, report.month, report.year, report.finalizedDate);
        const startLayer = layersForMonth[layersForMonth.length - 1];
        const endLayer = layersForMonth[0];

        const changes = MonthlyReportHelper.getChanges(layersForMonth, equipment);
        const result: MonthlyReportData = {
            month: report.month,
            year: report.year,
            startLayer,
            endLayer,
            subtotalPremium: 0,
            totalPremium: 0,
            minimumPremium: 0,
            minimumPremiumApplied: 0,
            premiumByCoverage: new Map(),
            isUnitReporter: layersForMonth.some((l) =>
                l.policy.coverages.some(
                    (c) =>
                        Coverage.isComposite(l.policy, c) &&
                        (c.basis === Coverage.Basis.UNITS ||
                            c.basis === Coverage.Basis.VALUES ||
                            (c.compositeRatings &&
                                c.compositeRatings.some((cr) => cr.basis === Coverage.Basis.UNITS || cr.basis === Coverage.Basis.VALUES)))
                )
            ),
            isMileageReporter: layersForMonth.some((l) =>
                l.policy.coverages.some(
                    (c) =>
                        Coverage.isComposite(l.policy, c) &&
                        (c.basis === Coverage.Basis.MILEAGE ||
                            (c.compositeRatings && c.compositeRatings.some((cr) => cr.basis === Coverage.Basis.MILEAGE)))
                )
            ),
            equipment: {
                tractors: equipment.tractors.map((u) => ({
                    ...u,
                    changes: [],
                    isNewToPolicy: false,
                    dateAddedToPolicy: null,
                    isRemovedFromPolicy: false,
                    dateRemovedFromPolicy: null,
                    isNewToCoverage: new Map(),
                    isRemovedFromCoverage: new Map(),
                    isACV: false,
                    isSpare: false,
                    statedValue: null,
                    experience: undefined as never,
                })),
                trailers: equipment.trailers.map((u) => ({
                    ...u,
                    changes: [],
                    isNewToPolicy: false,
                    dateAddedToPolicy: null,
                    isRemovedFromPolicy: false,
                    dateRemovedFromPolicy: null,
                    isNewToCoverage: new Map(),
                    isRemovedFromCoverage: new Map(),
                    isACV: false,
                    isSpare: false,
                    statedValue: null,
                    experience: undefined as never,
                })),
                drivers: equipment.drivers.map((u) => ({
                    ...u,
                    changes: [],
                    isNewToPolicy: false,
                    dateAddedToPolicy: null,
                    isRemovedFromPolicy: false,
                    dateRemovedFromPolicy: null,
                    isNewToCoverage: new Map(),
                    isRemovedFromCoverage: new Map(),
                    isACV: undefined as never,
                    isSpare: undefined as never,
                    statedValue: undefined as never,
                    experience: null,
                })),
                activeTractors: [],
                activeTrailers: [],
                activeDrivers: [],
            },
        };

        for (const change of changes.drivers) {
            result.equipment.drivers.find((d) => d.id === change.unitId)?.changes.push(change);
        }
        for (const change of changes.tractors) {
            result.equipment.tractors.find((t) => t.id === change.unitId)?.changes.push(change);
        }
        for (const change of changes.trailers) {
            result.equipment.trailers.find((t) => t.id === change.unitId)?.changes.push(change);
        }

        result.equipment.drivers = result.equipment.drivers
            .filter((driver) => driver.changes.length > 0 || MonthlyReportHelper.isDriverOnPolicy(driver, endLayer))
            .map((unit) => {
                const isRemovedFromPolicy = unit.changes.some((change) => change.isRemovedFromPolicy);
                return {
                    ...unit,
                    experience: isRemovedFromPolicy ? getDriverExperience(unit, startLayer) : getDriverExperience(unit, endLayer),
                    isNewToPolicy: unit.changes.some((change) => change.isNewToPolicy),
                    dateAddedToPolicy: unit.changes.find((change) => change.isNewToPolicy)?.date || null,
                    isRemovedFromPolicy,
                    dateRemovedFromPolicy: unit.changes.find((change) => change.isRemovedFromPolicy)?.date || null,
                    isRemovedFromCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "delete")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isNewToCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "new")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                };
            });
        result.equipment.tractors = result.equipment.tractors
            .filter((tractor) => tractor.changes.length > 0 || MonthlyReportHelper.isTractorOnPolicy(tractor, endLayer))
            .map((unit) => {
                const isSpare = endLayer.policy.coverages.some((coverage) =>
                    coverage.attributes.some(
                        (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.UNIT_SPARE_RATED
                    )
                );
                const endLayerAPD = endLayer.policy.coverages.find(
                    (cov) => cov.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE
                );
                const isACV = endLayerAPD?.attributes.some(
                    (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE
                );
                const statedValue = endLayerAPD?.attributes.find(
                    (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.STATED_VALUE
                )?.valueNumber;

                const startLayerAPD = startLayer.policy.coverages.find(
                    (cov) => cov.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE
                );
                const previousIsACV = startLayerAPD?.attributes.some(
                    (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE
                );
                const previousStatedValue = startLayerAPD?.attributes.find(
                    (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.STATED_VALUE
                )?.valueNumber;

                const isRemovedFromPolicy = unit.changes.some((change) => change.isRemovedFromPolicy);

                return {
                    ...unit,
                    isNewToPolicy: unit.changes.some((change) => change.isNewToPolicy),
                    dateAddedToPolicy: unit.changes.find((change) => change.isNewToPolicy)?.date || null,
                    isRemovedFromPolicy,
                    dateRemovedFromPolicy: unit.changes.find((change) => change.isRemovedFromPolicy)?.date || null,
                    isRemovedFromCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "delete")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isNewToCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "new")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isSpare: !!isSpare,
                    isACV: isRemovedFromPolicy ? !!previousIsACV : !!isACV,
                    statedValue: isRemovedFromPolicy ? previousStatedValue || null : statedValue || null,
                };
            });
        result.equipment.trailers = result.equipment.trailers
            .filter((trailer) => trailer.changes.length > 0 || MonthlyReportHelper.isTrailerOnPolicy(trailer, endLayer))
            .map((unit) => {
                const isSpare = endLayer.policy.coverages.some((coverage) =>
                    coverage.attributes.some(
                        (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.UNIT_SPARE_RATED
                    )
                );
                const endLayerAPD = endLayer.policy.coverages.find(
                    (cov) => cov.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE
                );
                const isACV = endLayerAPD?.attributes.some(
                    (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE
                );
                const statedValue = endLayerAPD?.attributes.find(
                    (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.STATED_VALUE
                )?.valueNumber;

                const startLayerAPD = startLayer.policy.coverages.find(
                    (cov) => cov.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE
                );
                const previousIsACV = startLayerAPD?.attributes.some(
                    (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE
                );
                const previousStatedValue = startLayerAPD?.attributes.find(
                    (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.STATED_VALUE
                )?.valueNumber;

                const isRemovedFromPolicy = unit.changes.some((change) => change.isRemovedFromPolicy);

                return {
                    ...unit,
                    isNewToPolicy: unit.changes.some((change) => change.isNewToPolicy),
                    dateAddedToPolicy: unit.changes.find((change) => change.isNewToPolicy)?.date || null,
                    isRemovedFromPolicy,
                    dateRemovedFromPolicy: unit.changes.find((change) => change.isRemovedFromPolicy)?.date || null,
                    isRemovedFromCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "delete")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isNewToCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "new")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isSpare: !!isSpare,
                    isACV: isRemovedFromPolicy ? !!previousIsACV : !!isACV,
                    statedValue: isRemovedFromPolicy ? previousStatedValue || null : statedValue || null,
                };
            });

        result.equipment.activeTractors = result.equipment.tractors.filter((t) => MonthlyReportHelper.isTractorOnPolicy(t, endLayer));
        result.equipment.activeTrailers = result.equipment.trailers.filter((t) => MonthlyReportHelper.isTrailerOnPolicy(t, endLayer));
        result.equipment.activeDrivers = result.equipment.drivers.filter((d) => MonthlyReportHelper.isDriverOnPolicy(d, endLayer));

        result.premiumByCoverage = MonthlyReportHelper.getPremiumByCoverage(report, result);
        result.subtotalPremium = Array.from(result.premiumByCoverage.values())
            .flat()
            .reduce((acc, r) => acc + r.subtotalPremium, 0);

        result.minimumPremium =
            endLayer.policy.attributes.find((att) => att.attributeOptionId === AttributeOption.Id.MINIMUM_MONTHLY_PREMIUM)?.valueNumber ||
            0;

        result.totalPremium = result.subtotalPremium;

        if (Array.from(result.premiumByCoverage.values()).some((p) => p.minimumPremiumApplied > 0)) {
            result.minimumPremiumApplied = Array.from(result.premiumByCoverage.values())
                .map((p) => p.minimumPremiumApplied)
                .reduce((acc, val) => acc + val, 0);
            result.totalPremium += result.minimumPremiumApplied;
        }

        if (result.minimumPremium && result.totalPremium < result.minimumPremium) {
            result.minimumPremiumApplied = result.minimumPremium - result.totalPremium;
            result.totalPremium = result.minimumPremium;
        }

        return result;
    }

    public static getUnitsForCoverage(
        data: MonthlyReportData,
        unitType: "tractors" | "trailers" | "drivers",
        coverageId?: CoverageOption.Id
    ) {
        const unitsFilteredByCoverage = (
            unitType === "tractors"
                ? data.equipment.tractors.filter((tractor) => {
                      if (!coverageId) {
                          return true;
                      }
                      return data.endLayer.policy.coverages.some(
                          (cov) =>
                              cov.coverageOptionId === coverageId &&
                              (cov.linkedTractors.some((lt) => lt.tractorId === tractor.id) ||
                                  tractor.changes.some((change) => change.changesByCoverage.has(coverageId)))
                      );
                  })
                : unitType === "trailers"
                ? data.equipment.trailers.filter((unit) => {
                      if (!coverageId) {
                          return true;
                      }
                      return data.endLayer.policy.coverages.some(
                          (cov) =>
                              cov.coverageOptionId === coverageId &&
                              (cov.linkedTrailers.some((lt) => lt.trailerId === unit.id) ||
                                  unit.changes.some((change) => change.changesByCoverage.has(coverageId)))
                      );
                  })
                : data.equipment.drivers.filter((driver) => {
                      if (!coverageId) {
                          return true;
                      }
                      return data.endLayer.policy.coverages.some(
                          (cov) =>
                              cov.coverageOptionId === coverageId &&
                              (cov.linkedDrivers.some((ld) => ld.driverId === driver.id) ||
                                  driver.changes.some((change) => change.changesByCoverage.has(coverageId)))
                      );
                  })
        ) as MonthlyReportDataUnit<Tractor | Trailer | Driver>[];

        return sortBy(
            unitsFilteredByCoverage,
            (x) => (coverageId ? x.isNewToCoverage.has(coverageId) : x.isNewToPolicy),
            (x) => (coverageId ? x.isRemovedFromCoverage.has(coverageId) : x.isRemovedFromPolicy),
            (x) => x.changes.length > 0
        ).reverse();
    }
    public static isDriverOnPolicy(driver: Driver, layer: PolicyLayer): boolean {
        return layer && layer.policy.coverages.some((cov) => cov.linkedDrivers.some((ld) => ld.driverId === driver.id));
    }
    public static isTractorOnPolicy(tractor: Tractor, layer: PolicyLayer): boolean {
        return layer && layer.policy.coverages.some((cov) => cov.linkedTractors.some((lt) => lt.tractorId === tractor.id));
    }
    public static isTrailerOnPolicy(trailer: Trailer, layer: PolicyLayer): boolean {
        return layer && layer.policy.coverages.some((cov) => cov.linkedTrailers.some((lt) => lt.trailerId === trailer.id));
    }
}

function getDriverExperience(unit: Driver, layer: PolicyLayer): number | null {
    for (const coverage of layer.policy.coverages) {
        if (coverage.linkedDrivers.some((ld) => ld.driverId === unit.id)) {
            const exp = coverage.attributes.find(
                (attr) => attr.driverId === unit.id && attr.attributeOptionId === AttributeOption.Id.DRIVER_YEARS_EXPERIENCE
            );
            if (exp) {
                return exp.valueNumber || 0;
            }
        }
    }
    return null;
}
