import { AzureStorageBrowser, AzureStorageBrowserContext } from "@deathstar/ui";
import { Outlet, Route, Routes, useSearchParams } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import NavigationTabs from "../../components/navigation-tabs/NavigationTabs";
import AdviceLanding from "./AdviceLanding";
import Request from "./Request";
import Requests from "./Requests";

export default function Landing() {
    const [searchParams, setSearchParams] = useSearchParams();
    const accountId = useAccountId();
    return (
        <Routes>
            <Route
                element={
                    <div className="flex grow flex-col gap-8 p-4 lg:p-12">
                        <NavigationTabs
                            tabs={[
                                { to: "", label: "About" },
                                { to: "requests", label: "Questions" },
                                { to: "files", label: "Files" },
                            ]}
                        />
                        <Outlet />
                    </div>
                }
            >
                <Route index element={<AdviceLanding />} />
                <Route path="requests" element={<Requests />} />
                <Route path="requests/:requestId" element={<Request />} />
                <Route
                    path="files"
                    element={
                        <AzureStorageBrowserContext.Provider
                            value={{
                                root: "/Ask Anything",
                                path: searchParams.get("path") || "",
                                handlePathChange: (path: string) => {
                                    if (path) {
                                        setSearchParams({ path });
                                    } else {
                                        setSearchParams({});
                                    }
                                },
                                api: {
                                    useDirectory: (path, config) =>
                                        api.files.useGet(
                                            accountId!,
                                            path,
                                            //@ts-expect-error Dumb TS. Not sure why it doesn't like this
                                            config
                                        ),
                                    useCreateDirectory: (config) => api.files.useCreateDirectory(accountId!, config),
                                    useDelete: (config) => api.files.useDelete(accountId!, config),
                                    useDownload: (config) => api.files.useDownload(accountId!, config),
                                    useMove: (config) => api.files.useMove(accountId!, config),
                                    useUpload: (config) => api.files.useUpload(accountId!, config),
                                },
                            }}
                        >
                            <AzureStorageBrowser />
                        </AzureStorageBrowserContext.Provider>
                    }
                />
            </Route>
        </Routes>
    );
}
