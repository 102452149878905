import { useQuery } from "react-query";
import { useAccountId } from "../../api/useAccountId";
import { blazar } from "../../api/util/blazar";
import Banner from "../../components/banner/Banner";

/**
 * A banner for warning the user that this account has not yet completd the migration to Waypoint and their active policy
 * data is still in the AMS Portal.
 */
export default function OldClientWarningBanner() {
    const accountId = useAccountId();
    const { data } = useQuery(
        ["account", accountId!, "migrated"],
        () => blazar.fetchJson<{ migrated: boolean }>(`waypoint/orgs/${accountId!}/migrated`),
        { staleTime: Infinity }
    );
    return data?.migrated === false ? (
        <Banner variant="info" className="sticky top-0 z-20 grow-0">
            Your organization has not been fully migrated to Waypoint and some information may be missing. Your migration will be completed
            upon renewal of your insurance.{" "}
            <a className="font-medium text-navigator-primary-dark underline" href="https://clientportal.vertafore.com/">
                Click here
            </a>{" "}
            to access your current portal.
        </Banner>
    ) : null;
}
