import { Alert, AlertProps } from "@deathstar/ui";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";

interface BannerProps extends Omit<AlertProps, "ref"> {
    /** If set to `true`, the banner cannot be closed */
    persist?: boolean;
}
export default function Banner({ persist, ...props }: BannerProps) {
    const [open, setOpen] = useState(true);

    return (
        <Transition
            as={Fragment}
            appear
            show={open}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <Alert
                {...props}
                action={
                    !persist && (
                        <button title="Close banner" className="rounded-full p-1 hover:bg-stone-500/10" onClick={() => setOpen(false)}>
                            <XMarkIcon className="h-4 w-4" />
                        </button>
                    )
                }
            />
        </Transition>
    );
}
