import { OrganizationInviteCode } from "@deathstar/types/waypoint";
import { InputField, useSnackbar } from "@deathstar/ui";
import { CircularProgress } from "@material-ui/core";
import api from "../../api/api";

export default function InviteCodeInput({ onAccept }: { onAccept: (code: OrganizationInviteCode) => void }) {
    const acceptInvite = api.inviteCodes.useAccept({
        onError: (error) => {
            if (error.status === 404) {
                useSnackbar.add("Invalid invite code", { variant: "error" });
            } else {
                useSnackbar.add("Please try again later", { variant: "error" });
            }
        },
        onSuccess: (code) => {
            onAccept(code);
        },
    });

    return (
        <InputField
            disabled={acceptInvite.isLoading || acceptInvite.isSuccess}
            onChange={(e) => {
                e.currentTarget.value = e.currentTarget.value.toUpperCase().slice(0, OrganizationInviteCode.CODE_LENGTH);
                if (e.currentTarget.value.length === OrganizationInviteCode.CODE_LENGTH) {
                    acceptInvite.mutate(e.currentTarget.value);
                }
            }}
            autoComplete="new-password"
            label="Waypoint invite code"
            classes={{ root: "w-[256px]", input: "text-xl", label: "small-caps text-sm" }}
            endAdornment={
                <div className="h-8 w-8">
                    {acceptInvite.isLoading || acceptInvite.isSuccess ? (
                        <CircularProgress size="2rem" classes={{ circle: "text-waypoint-blue" }} />
                    ) : null}
                </div>
            }
        />
    );
}
