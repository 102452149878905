import { MemberWithPermissions } from "@deathstar/types/waypoint";
import { Checkbox, DataTable } from "@deathstar/ui";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";

export default function PermissionsTable({ userId }: { userId: string }) {
    const accountId = useAccountId();
    const { data } = api.members.useGet(accountId!, userId);

    const tableData = useMemo(() => data?.permissions || [], [data]);

    const columns = useMemo<ColumnDef<MemberWithPermissions["permissions"][number]>[]>(
        () => [
            {
                id: "name",
                accessorKey: "displayName",
                header: "Permission",
            },
            {
                id: "granted",
                accessorKey: "granted",
                header: "Granted",
                cell: ({ row, getValue }) => (
                    <Checkbox
                        disabled={data?.admin}
                        defaultChecked={getValue() as boolean}
                        onChange={async (e) => {
                            const granted = e.target.checked;
                            try {
                                await api.members.updatePermission(accountId!, userId, row.original.id, granted);
                            } catch (error) {
                                e.target.checked = !granted;
                            }
                        }}
                        classes={{ root: "justify-center" }}
                    />
                ),
            },
        ],
        [accountId, data?.admin, userId]
    );

    const table = useReactTable({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    return (
        <div className="p-4">
            <p className="mb-2 font-medium">Permissions - {data?.displayName || data?.email}</p>
            <DataTable table={table} />
        </div>
    );
}
