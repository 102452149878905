import { CoverageOption, Policy } from "@deathstar/types/northstar";
import moment from "moment";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { PolicyWithBillingInfo } from "../../policies/policies-list/PoliciesList";

export default function MonthlyReportPolicySelector({
    getFilteredPolicies,
}: {
    getFilteredPolicies?: (policies: PolicyWithBillingInfo[]) => PolicyWithBillingInfo[];
}) {
    const accountId = useAccountId();
    const navigate = useNavigate();
    const { data } = api.policies.useFind(accountId!, "active", {
        select: (policies) => {
            const composites = policies
                .filter((policy) => Policy.isComposite(policy))

                // do not show WC policies, for now
                .filter((policy) => !policy.coverages.some((c) => c.coverageOptionId === CoverageOption.Id.WORKERS_COMPENSATION));
            if (getFilteredPolicies) {
                return getFilteredPolicies(composites);
            }
            return composites;
        },
    });

    useEffect(() => {
        if (data?.length === 1) {
            navigate(`./${data[0].id}`);
        }
    }, [data, navigate]);

    return (
        <div className="ml-4 mt-6 lg:mt-12">
            <p className="text-stone-500">Choose a policy</p>
            <hr />
            <ul className="mt-4 space-y-4">
                {data?.map((policy) => (
                    <li key={policy.id}>
                        <Link to={`./${policy.id}`}>
                            <div className="max-w-prose space-y-2 rounded border p-2 text-sm hover:bg-stone-50">
                                <p>
                                    #{policy.number} <span className="text-stone-500">with</span> {policy.writingCompany?.name}
                                </p>
                                <p>
                                    {moment(policy.effectiveDate).format("MM/DD/YY")} - {moment(policy.expirationDate).format("MM/DD/YY")}
                                </p>
                                <p>{policy.coverages.map((c) => CoverageOption.getMetadata(c.coverageOptionId).name).join(", ")}</p>
                            </div>
                        </Link>
                    </li>
                ))}
                {data?.length === 0 && <li className="">No reportable policies found</li>}
            </ul>
        </div>
    );
}
