import { CoverageOption } from "@deathstar/types/northstar";
import { ActionButton, classNames } from "@deathstar/ui";
import { ChevronDoubleLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import Loader from "../../../components/loader/Loader";

export default function MonthlyReportSelector() {
    const accountId = useAccountId();
    const policyId = useParams().policyId;

    const { data, isLoading } = api.monthlyReports.useFindForPolicy(accountId!, policyId!, { enabled: !!policyId });

    if (isLoading) {
        return <Loader />;
    }

    if (!data) {
        return (
            <div className="space-y-4">
                <div className="flex gap-4">
                    <p>Policy not found</p>
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="my-2 text-sm">
                <p className="font-medium">Policy #{data.policyNumber}</p>
                <p>{data.covereageOptionIds.map((c) => CoverageOption.getMetadata(c).name).join(", ")}</p>
                <p>{data.writingCompanyName}</p>
            </div>
            <div className="flex items-center gap-4">
                <Link to="./../..">
                    <ActionButton>
                        <ChevronDoubleLeftIcon className="h-4 w-4" />
                        Back
                    </ActionButton>
                </Link>
            </div>
            <div>
                <p className="text-xs text-stone-500">Current reports</p>
                <ul className="w-max rounded border">
                    {data.availableMonths.length ? (
                        data.availableMonths.map(({ month, year }) => {
                            const report = data.reports.find((report) => report.year === year && report.month === month);
                            return (
                                <li key={`${year}-${month}`} className="border-b last:border-b-0">
                                    <Link
                                        to={`${year}/${month}`}
                                        className={classNames(
                                            "grid grid-cols-[150px,1fr,3rem] items-center gap-2 rounded py-2 pl-2 pr-4 hover:bg-stone-50"
                                        )}
                                    >
                                        {report ? (
                                            report.isFinalized ? (
                                                <span className="w-max rounded-full bg-emerald-50 px-2 text-xs text-emerald-600 small-caps">
                                                    Completed
                                                </span>
                                            ) : report.isSubmitted ? (
                                                <span className="w-max rounded-full bg-blue-50 px-2 text-xs text-blue-600 small-caps">
                                                    Submitted
                                                </span>
                                            ) : (
                                                <span className="w-max rounded-full bg-orange-50 px-2 text-xs text-orange-600 small-caps">
                                                    Awaiting your review
                                                </span>
                                            )
                                        ) : (
                                            <span className="w-max rounded-full bg-orange-50 px-2 text-xs text-orange-600 small-caps">
                                                Awaiting your review
                                            </span>
                                        )}
                                        <p className="">
                                            {moment()
                                                .year(year)
                                                .month(month - 1)
                                                .format("MMMM YYYY")}
                                        </p>
                                        <div className="flex justify-end">
                                            <ChevronRightIcon className="h-4 w-4 text-stone-500" />
                                        </div>
                                    </Link>
                                </li>
                            );
                        })
                    ) : (
                        <li className="py-2 pl-2 pr-4">
                            <p className="w-screen max-w-prose text-center text-stone-500">No reports available</p>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}
