import {
    Attribute,
    AttributeOption,
    BusinessAuto,
    Company,
    Coverage,
    CoverageOption,
    Driver,
    InlandMarineEquipment,
    Policy,
    PolicyAdditionalInterestLinkedBusinessAuto,
    PolicyAdditionalInterestLinkedProperty,
    PolicyAdditionalInterestLinkedTool,
    PolicyAdditionalInterestLinkedTractor,
    PolicyAdditionalInterestLinkedTrailer,
    Property,
    Tractor,
    Trailer,
} from "@deathstar/types/northstar";
import { Img } from "@deathstar/ui";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { uniqBy } from "lodash";
import moment from "moment";
import { Fragment } from "react";
import { blazar } from "../../../api/util/blazar";

interface PolicyDecPageProps {
    policy: Policy;
    properties: Property[];
    company: Pick<Company, "dot">;
}
export default function PolicyDecPage({ policy, properties, company }: PolicyDecPageProps) {
    const headerInfo: { label: string; value: React.ReactNode }[] = [
        { label: "Policy #", value: policy.number },
        {
            label: "Term",
            value: `${moment.utc(policy.effectiveDate).format("MM/DD/YYYY")} - ${moment.utc(policy.expirationDate).format("MM/DD/YYYY")}`,
        },
        { label: "DOT #", value: company.dot },
        { label: "Coverages", value: Policy.getDescription(policy) },
        { label: "Parent company", value: policy.parentCompany?.name },
        { label: "Writing company", value: policy.writingCompany?.name },
        { label: "Bill method/plan", value: policy.billMethod },
        { label: "Remarks", value: policy.remarks },
    ];

    const primaryMailing = properties.find((p) => p.types.find((t) => t.name === "Primary Mailing"));

    const allUnitAttributes = policy.coverages
        .map((coverage) => coverage.attributes.filter((a) => Attribute.isEquipmentOrDriverAttribute(a)))
        .flat();
    const tractors = uniqBy(policy.coverages.map((c) => c.linkedTractors!.map((l) => l.tractor as Tractor)).flat(), "id");
    const trailers = uniqBy(policy.coverages.map((c) => c.linkedTrailers!.map((l) => l.trailer as Trailer)).flat(), "id");
    const autos = uniqBy(policy.coverages.map((c) => c.linkedBusinessAutos!.map((l) => l.businessAuto as BusinessAuto)).flat(), "id");
    const tools = uniqBy(policy.coverages.map((c) => c.linkedTools!.map((l) => l.tool as InlandMarineEquipment)).flat(), "id");
    const drivers = uniqBy(policy.coverages.map((c) => c.linkedDrivers!.map((l) => l.driver as Driver)).flat(), "id");

    const isComposite = Policy.isReporter(policy);
    const isScheduled = !!(!isComposite && (tractors.length || trailers.length || autos.length));
    const isSir = policy.attributes.some((attribute) => attribute.attributeOptionId === AttributeOption.Id.SELF_INSURED_RETENTION);

    const users =
        policy.agentId === policy.accountManagerId
            ? [{ ...policy.agent, role: "Agent / Account Manager" }]
            : [
                  { ...policy.agent, role: "Agent" },
                  { ...policy.accountManager, role: "Account Manager" },
              ];

    return (
        <div className="flex h-full w-max flex-col gap-8 p-4 text-left print:w-[8.5in]">
            <div className="flex justify-between">
                <div>
                    <div className="flex justify-between gap-2 text-lg font-bold">
                        <span>{policy.firstNamedInsured}</span>
                        <span>{primaryMailing ? addressToString(primaryMailing) : null}</span>
                    </div>
                    <table className="col-spacing-4 row-p-1 row-spacing-1">
                        <tbody>
                            {headerInfo.map(({ label, value }) => (
                                <tr key={label} className="even:bg-stone-50">
                                    <td className="text-stone-700">{label}</td>
                                    <td>{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="p-4">
                    <div className="justify-end gap-1 space-y-4">
                        <div className="grid w-full grid-cols-[max-content,max-content] items-center gap-4">
                            {users.map((user) => (
                                <Fragment key={user.upn}>
                                    <Img
                                        src={`${blazar.getBaseUrl()}api/waypoint/personnel/${user.upn}/img`}
                                        className="w-14 rounded"
                                        title={user.displayName || undefined}
                                    >
                                        <div
                                            className="flex h-12 w-12 items-center justify-center rounded bg-gray-300"
                                            title={user.displayName || undefined}
                                        >
                                            <span>{((user.givenName?.[0] || "") + (user.surname?.[0] || "")).toUpperCase()}</span>
                                        </div>
                                    </Img>
                                    <div>
                                        <p>{user.displayName}</p>
                                        <p className="text-sm text-stone-500">{user.role}</p>
                                        <p className="text-sm text-stone-500">{user.mail}</p>
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span className="text-lg font-bold">Policy attributes</span>
                <hr className="mb-4" />
                <div className="ml-6 flex flex-wrap gap-2 text-sm">
                    {isScheduled ? <span className="rounded bg-stone-100 px-2 py-1">Scheduled</span> : null}
                    {policy.attributes.map((attribute) => (
                        <span className="rounded bg-stone-100 px-2 py-1" key={attribute.id}>
                            {attribute.attributeOption?.name}
                        </span>
                    ))}
                </div>
            </div>
            <div>
                <span className="text-lg font-bold">Additional Interests</span>
                <hr className="mb-4" />
                {policy.additionalInterests.length ? (
                    <table className="ml-6 text-sm col-spacing-2 row-p-1">
                        <thead>
                            <tr>
                                <th className="text-left text-stone-600">Name</th>
                                <th className="text-left text-stone-600">Type</th>
                                <th className="text-left text-stone-600">Address</th>
                                <th className="text-left text-stone-600">Coverage</th>
                                <th className="text-left text-stone-600">Units</th>
                            </tr>
                        </thead>
                        <tbody>
                            {policy.additionalInterests.map((ai) => {
                                const relatedVins = [
                                    ...(ai.linkedTractors?.map(
                                        (t) => "VIN " + (t as PolicyAdditionalInterestLinkedTractor).tractor?.vin?.slice(-4)
                                    ) || []),
                                    ...(ai.linkedTrailers?.map(
                                        (t) => "VIN " + (t as PolicyAdditionalInterestLinkedTrailer).trailer?.vin?.slice(-4)
                                    ) || []),
                                    ...(ai.linkedBusinessAutos?.map(
                                        (t) => "VIN " + (t as PolicyAdditionalInterestLinkedBusinessAuto).businessAuto?.vin?.slice(-4)
                                    ) || []),
                                    ...(ai.linkedTools?.map(
                                        (t) => "SN " + (t as PolicyAdditionalInterestLinkedTool).tool?.serialNumber?.slice(-4)
                                    ) || []),
                                    ...(ai.linkedProperties?.map(
                                        (t) =>
                                            "LOC " +
                                            (t as PolicyAdditionalInterestLinkedProperty).property?.locationNumber +
                                            "-" +
                                            (t as PolicyAdditionalInterestLinkedProperty).property?.buildingCode
                                    ) || []),
                                ];
                                return (
                                    <tr key={ai.id}>
                                        <td>{ai.additionalInterest?.name}</td>
                                        <td>
                                            {ai.isLossPayee
                                                ? "Loss Payee"
                                                : ai.isAdditionalInsured
                                                ? "Addtl. Insur."
                                                : ai.isMortgagee
                                                ? "Mortgagee"
                                                : ai.isMotorCarrier
                                                ? "Motor Carrier"
                                                : ""}
                                        </td>
                                        <td>{ai.additionalInterest ? addressToString(ai.additionalInterest) : null}</td>
                                        <td>
                                            {ai.coveragesAppliedTo
                                                ?.map(({ coverageOptionId }) => {
                                                    const coverage = policy.coverages.find(
                                                        (coverage) => coverage.coverageOptionId === coverageOptionId
                                                    );
                                                    if (coverage) {
                                                        return Coverage.getAbbreviation({
                                                            coverage,
                                                            policyDescription: policy.description,
                                                        });
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                                .filter(Boolean)
                                                .join(", ")}
                                        </td>
                                        <td>
                                            {relatedVins
                                                .filter(Boolean)
                                                .map((vin) => vin?.slice(-4))
                                                .join(", ")}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <p className="ml-8 text-sm text-gray-500">None</p>
                )}
            </div>
            <div>
                <span className="text-lg font-bold">Locations</span>
                <hr className="mb-4" />
                <table className="ml-6 text-sm col-spacing-2 row-p-1">
                    <thead>
                        <tr>
                            <th className="text-left text-stone-600">Number</th>
                            <th className="text-left text-stone-600">Type</th>
                            <th className="text-left text-stone-600">Address</th>
                            <th className="text-left text-stone-600">Coverages</th>
                            <th className="text-left text-stone-600">Class. code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {properties.map((property) => (
                            <tr key={property.id} className="row-spacing-1 even:bg-stone-50">
                                <td>
                                    {property.locationNumber || "-"} / {property.buildingCode || "-"}
                                </td>
                                <td>{property.typeString}</td>
                                <td>{addressToString(property)}</td>
                                <td>
                                    {policy.coverages
                                        .filter((coverage) => coverage.scheduleOfHazards?.some((h) => h.propertyId === property.id))
                                        .map((coverage) => Coverage.getAbbreviation({ coverage, policyDescription: policy.description }))
                                        .join(", ")}
                                </td>
                                <td>
                                    {
                                        policy.coverages
                                            .map((coverage) => coverage.scheduleOfHazards)
                                            .flat()
                                            .find((h) => h?.propertyId === property.id && h.code)?.code
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
                <span className="text-lg font-bold">Coverages</span>
                <hr className="mb-4" />
                <ul className="space-y-4">
                    {policy.coverages.map((coverage) => (
                        <li key={coverage.id}>
                            <p>
                                {coverage.coverageOption?.name} (
                                {Coverage.getAbbreviation({ coverage, policyDescription: policy.description })})
                            </p>
                            <div className="my-1 ml-6 flex flex-wrap gap-2">
                                {coverage.attributes
                                    .filter((attr) => !Attribute.isEquipmentOrDriverAttribute(attr))
                                    .map((attr) => (
                                        <span key={attr.id} className="rounded bg-stone-100 px-2 text-xs text-stone-600">
                                            {attr.attributeOption?.name}
                                        </span>
                                    ))}
                            </div>
                            {coverage.limits.length ? (
                                <table className="ml-6 table-fixed text-sm col-spacing-2 row-p-1 row-spacing-1">
                                    <thead>
                                        <tr>
                                            <th className="w-64 text-left text-stone-600">Name</th>
                                            <th className="w-48 text-left text-stone-600">Limit(s)</th>
                                            <th className="w-32 text-left text-stone-600">
                                                {!isSir ||
                                                coverage.attributes.some(
                                                    (attribute) =>
                                                        attribute.attributeOptionId ===
                                                        AttributeOption.Id.SELF_INSURED_RETENTION_DOES_NOT_APPLY
                                                )
                                                    ? "Deductible"
                                                    : "SIR"}
                                            </th>
                                            <th className="w-32 text-left text-stone-600">Symbols</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {coverage.limits.map((limit) => {
                                            const limit1 = toUsd(limit.limit1Money) || limit.limit1Text || "";

                                            const limit2 = toUsd(limit.limit2Money) || limit.limit2Text || "";
                                            return (
                                                <Fragment key={limit.id}>
                                                    <tr className="even:bg-stone-50">
                                                        <td>{limit.coverageLimitOption.name}</td>
                                                        <td>{`${limit1}${limit2 ? " / " + limit2 : ""}`}</td>
                                                        <td>{toUsd(limit.deductibleMoney) || limit.deductibleText}</td>
                                                        <td>
                                                            {limit.attributes
                                                                ?.filter((attr) => Attribute.isSymbolAttribute(attr))
                                                                .map((attr) => attr.attributeOption?.name)
                                                                .join(", ")}
                                                        </td>
                                                    </tr>
                                                    {limit.modifications?.map((modification) => {
                                                        const modLimit1 = toUsd(modification.limit1Money) || modification.limit1Text || "";

                                                        const modLimit2 = toUsd(modification.limit2Money) || modification.limit2Text || "";
                                                        return (
                                                            <tr key={modification?.id} className="even:bg-stone-50">
                                                                <td className="!pl-8">
                                                                    <ChevronRightIcon className="-mt-1 inline h-3 w-3 text-stone-600" />
                                                                    {modification.modificationOption?.name}
                                                                </td>
                                                                <td>{`${modLimit1}${modLimit2 ? " / " + modLimit2 : ""}`}</td>
                                                                <td>
                                                                    {toUsd(modification.deductibleMoney) || modification.deductibleText}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        );
                                                    })}
                                                </Fragment>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="ml-8 text-sm text-gray-500">No limits found</p>
                            )}
                            {coverage.coverageOptionId === CoverageOption.Id.WORKERS_COMPENSATION ? (
                                <div className="ml-6 space-y-2">
                                    <div>
                                        <span className="text-sm">Rates</span>
                                        <table className="ml-6 table-fixed text-sm col-spacing-2 row-p-1 row-spacing-1">
                                            <thead>
                                                <tr>
                                                    <th className="text-left font-medium text-stone-600">Class</th>
                                                    <th className="text-left font-medium text-stone-600">State</th>
                                                    <th className="text-left font-medium text-stone-600">Code</th>
                                                    <th className="text-right font-medium text-stone-600">Payroll</th>
                                                    <th className="text-right font-medium text-stone-600">Rate</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {coverage.rates?.classifications.map((cl) => (
                                                    <tr key={cl.id}>
                                                        <td>{cl.description}</td>
                                                        <td>{cl.state}</td>
                                                        <td>{cl.code}</td>
                                                        <td className="text-right tabular-nums">{toUsd(cl.payroll)}</td>
                                                        <td className="text-right tabular-nums">{cl.rate}%</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <span className="text-sm">Owners</span>
                                        <table className="ml-6 table-fixed text-sm col-spacing-2 row-p-1 row-spacing-1">
                                            <thead>
                                                <tr>
                                                    <th className="text-left font-medium text-stone-600">Name</th>
                                                    <th className="text-left font-medium text-stone-600">DOB</th>
                                                    <th className="text-left font-medium text-stone-600">Ownership</th>
                                                    <th className="text-right font-medium text-stone-600">Included</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {coverage.rates?.owners.map((cl) => (
                                                    <tr key={cl.id}>
                                                        <td>{cl.name}</td>
                                                        <td>{moment.utc(cl.dateOfBirth).format("MM/DD/YYYY")}</td>
                                                        <td className="text-right tabular-nums">{cl.percentage}%</td>
                                                        <td>{cl.include ? "Y" : "N"}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}
                        </li>
                    ))}
                </ul>
            </div>

            <div>
                <span className="text-lg font-bold">Vehicles</span>
                <hr className="mb-4" />
                {tractors.length || trailers.length || autos.length ? (
                    <table className="ml-6 table-fixed text-sm col-spacing-2 row-p-1 row-spacing-1">
                        <thead>
                            <tr>
                                <th className="w-32 text-left text-stone-600">Unit</th>
                                <th className="w-32 text-left text-stone-600">Year</th>
                                <th className="w-32 text-left text-stone-600">Make</th>
                                <th className="w-32 text-left text-stone-600">Type</th>
                                <th className="w-32 text-left text-stone-600">VIN</th>
                                <th className="w-32 text-right text-stone-600">Value</th>
                                <th className="w-32 text-right text-stone-600">Comp.</th>
                                <th className="w-32 text-right text-stone-600">Collision</th>
                                <th className="text-right text-stone-600">Spare</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tractors.map((t) => (
                                <EquipmentRow key={t.id} unit={t} unitId={{ tractorId: t.id }} attributes={allUnitAttributes} />
                            ))}
                            {trailers.map((t) => (
                                <EquipmentRow key={t.id} unit={t} unitId={{ trailerId: t.id }} attributes={allUnitAttributes} />
                            ))}
                            {autos.map((t) => (
                                <EquipmentRow key={t.id} unit={t} unitId={{ businessAutoId: t.id }} attributes={allUnitAttributes} />
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="ml-8 text-sm text-gray-500">None</p>
                )}
            </div>

            <div>
                <span className="text-lg font-bold">Other Equipment</span>
                <hr className="mb-4" />
                {tools.length ? (
                    <table className="ml-6 table-fixed text-sm col-spacing-2 row-p-1 row-spacing-1">
                        <thead>
                            <tr>
                                <th className="w-32 text-left text-stone-600">Year</th>
                                <th className="w-32 text-left text-stone-600">Make</th>
                                <th className="w-32 text-left text-stone-600">Model</th>
                                <th className="w-32 text-left text-stone-600">S/N</th>
                                <th className="w-32 text-right text-stone-600">Value</th>
                                <th className="w-32 text-left text-stone-600">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tools.map((t) => (
                                <tr key={t.id}>
                                    <td>{t.year}</td>
                                    <td>{t.make}</td>
                                    <td>{t.model}</td>
                                    <td>{t.serialNumber}</td>
                                    <td className="text-right tabular-nums">
                                        {getUnitAttribute(
                                            allUnitAttributes,
                                            { toolId: t.id },
                                            AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE
                                        )
                                            ? "ACV"
                                            : toUsd(
                                                  getUnitAttribute(allUnitAttributes, { toolId: t.id }, AttributeOption.Id.STATED_VALUE)
                                                      ?.valueNumber
                                              )}
                                    </td>
                                    <td>{t.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="ml-8 text-sm text-gray-500">None</p>
                )}
            </div>

            <div>
                <span className="text-lg font-bold">Drivers</span>
                <hr className="mb-4" />
                {drivers.length ? (
                    <table className="ml-6 table-auto text-sm col-spacing-2 row-p-1 row-spacing-1">
                        <thead>
                            <tr>
                                <th className="text-left text-stone-600">Name</th>
                                <th className="text-left text-stone-600">License</th>
                                <th className="text-left text-stone-600">State</th>
                                <th className="text-right text-stone-600">DOB</th>
                                <th className="text-right text-stone-600">DOH</th>
                            </tr>
                        </thead>
                        <tbody>
                            {drivers.map((driver) => (
                                <tr key={driver.id}>
                                    <td>{driver.name}</td>
                                    <td>{driver.license}</td>
                                    <td className="uppercase">{driver.state}</td>
                                    <td className="text-right tabular-nums">
                                        {driver.dob ? moment.utc(driver.dob).format("MM/DD/YYYY") : ""}
                                    </td>
                                    <td className="text-right tabular-nums">
                                        {driver.doh ? moment.utc(driver.doh).format("MM/DD/YYYY") : ""}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="ml-8 text-sm text-gray-500">None</p>
                )}
            </div>
        </div>
    );
}

function EquipmentRow({
    unit,
    unitId,
    attributes,
}: {
    unit: Tractor | Trailer | BusinessAuto;
    unitId: { tractorId?: number; trailerId?: number; businessAutoId?: number };
    attributes: Attribute[];
}) {
    const comp = getUnitAttribute(attributes, unitId, AttributeOption.Id.UNIT_COMPREHENSIVE);
    const collision = getUnitAttribute(attributes, unitId, AttributeOption.Id.UNIT_COLLISION);
    const spareRating = getUnitAttribute(attributes, unitId, AttributeOption.Id.UNIT_SPARE_RATED);
    return (
        <tr>
            <td>{unit.unitNumber}</td>
            <td>{unit.year}</td>
            <td>{unit.make}</td>
            <td className="capitalize">{unit.type?.name}</td>
            <td>{unit.vin}</td>
            <td className="text-right tabular-nums">
                {getUnitAttribute(attributes, unitId, AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE)
                    ? "ACV"
                    : toUsd(getUnitAttribute(attributes, unitId, AttributeOption.Id.STATED_VALUE)?.valueNumber)}
            </td>
            <td className="text-right tabular-nums">{comp ? (comp.valueNumber ? toUsd(comp.valueNumber) : "Y") : "N"}</td>
            <td className="text-right tabular-nums">{collision ? (collision.valueNumber ? toUsd(collision.valueNumber) : "Y") : "N"}</td>
            <td className="text-right tabular-nums">{spareRating ? "Y" : "N"}</td>
        </tr>
    );
}

function getUnitAttribute(
    attributes: Attribute[],
    unitId: { tractorId?: number; trailerId?: number; toolId?: number; businessAutoId?: number },
    attributeOptionId: AttributeOption.Id
): Attribute | undefined {
    return attributes.find(
        (a) =>
            a.attributeOptionId === attributeOptionId &&
            ((unitId.tractorId && a.tractorId === unitId.tractorId) ||
                (unitId.trailerId && a.trailerId === unitId.trailerId) ||
                (unitId.toolId && a.toolId === unitId.toolId) ||
                (unitId.businessAutoId && a.businessAutoId === unitId.businessAutoId))
    );
}

function addressToString(
    address: ({ street1: string } | { street: string }) & { street2: string; city: string; state: string; zip: string }
) {
    const street1 = "street" in address ? address.street : address.street1;
    return `${street1}${address.street2 ? " " + address.street2 : ""}, ${address.city}, ${address.state} ${address.zip}`;
}

function toUsd(amount: number | null | undefined) {
    return amount?.toLocaleString("en-us", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
    });
}
