import { Typography } from "@material-ui/core";
import React from "react";
import { ChartContainer } from "../Charts/Container";
import { HistoricalScoreChart } from "../Charts/HistoricalScore";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { Transition } from "../Transition";
import { SummarySection } from "./SummarySection";

export function HistoricalScores(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    return (
        <SummarySection>
            <div className="mr-16 flex flex-col">
                <TitleGroup
                    title={
                        <Typography variant="h4">
                            Historical BASIC scores
                            <span className="align-top text-[20px]">*</span>
                        </Typography>
                    }
                    align="right"
                    className="mb-8"
                    description="Score calculations charted across the previous 12 months."
                />
                <div className="grid h-full">
                    <Typography className="self-end" variant="body2">
                        * Hover over line to view score at specific point
                    </Typography>
                </div>
            </div>

            <Transition.Fade>
                <ChartContainer className="aspect-video max-h-[40vh] min-w-[600px]">
                    <HistoricalScoreChart motorCarrier={motorCarrier} />
                </ChartContainer>
            </Transition.Fade>
        </SummarySection>
    );
}
