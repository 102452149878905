import { DataTable } from "@deathstar/ui";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useMutation } from "react-query";
import { blazar } from "../../api/util/blazar";
import Subtitle from "../../components/title/Subtitle";
import Title from "../../components/title/Title";
import environment from "../../environment";
import { ResourceFile, resourceFiles } from "./resourceFiles";

export default function Resources() {
    const assetsDir = environment.resources.baseUrl;
    const { mutate: downloadZip, isLoading } = useMutation(async () => {
        const res = await blazar.fetch("/waypoint/resources/zip");
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Resources.zip";
        document.body.appendChild(a);
        a.click();
        a.remove();
    });

    const table = useReactTable({
        data: resourceFiles,
        columns: useMemo<ColumnDef<ResourceFile>[]>(
            () => [
                { accessorKey: "name" },
                {
                    accessorKey: "type",
                    enableGlobalFilter: false,
                    cell: (info) => <span className="text-stone-600">{info.getValue() as string}</span>,
                },
            ],
            []
        ),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: DataTable.fuzzyFilter,
    });

    return (
        <div className="space-y-6">
            <Title>Resources</Title>
            <Subtitle>
                Access documents we recommend for driver and equipment management, sample contracts, and template company policies
            </Subtitle>
            <DataTable
                table={table}
                onRowClick={(row) => {
                    window.open(assetsDir + row.path, "_blank");
                }}
                searchPlaceholder="Search..."
                actions={[
                    {
                        icon: <ArrowDownTrayIcon className="h-4 w-4" />,
                        label: "Download all",
                        onClick: () => downloadZip(),
                        getIsDisabled: () => isLoading,
                        getIsLoading: () => isLoading,
                    },
                ]}
            />
        </div>
    );
}
