import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { Menu, MenuItem } from "@material-ui/core";
import { Table } from "@tanstack/react-table";
import React from "react";
import { ActionButton } from "../actionButton/ActionButton";
import { Checkbox } from "../checkbox/checkbox";
import { classNames } from "../classNames/classNames";

export interface IHideableColumn {
    label: string;
    ids: string[];
}

export function ColumnEditor<T>({
    table,
    className,
    hideableColumns,
}: {
    table: Table<T>;
    className?: string;
    hideableColumns: IHideableColumn[];
}): JSX.Element {
    const [columnSelectAnchor, setColumnSelectAnchor] = React.useState<HTMLElement | null>(null);
    const totalHideableColumns = React.useMemo(() => {
        return hideableColumns.reduce((total, opt) => total + opt.ids.length, 0);
    }, [hideableColumns]);

    return (
        <div className={classNames("flex grow justify-end", className)}>
            <Menu
                open={!!columnSelectAnchor}
                anchorEl={columnSelectAnchor}
                onClose={() => setColumnSelectAnchor(null)}
                classes={{ paper: "text-sm" }}
            >
                {hideableColumns.map((col) => (
                    <MenuItem
                        className="gap-2 text-sm"
                        key={col.ids[0]}
                        onClick={() => {
                            table.setColumnVisibility((old) => {
                                const result = { ...old };
                                for (const id of col.ids) {
                                    const exists = id in old;
                                    result[id] = exists ? !old[id] : false;
                                }
                                return result;
                            });
                        }}
                    >
                        <Checkbox
                            readOnly
                            classes={{ root: "pointer-events-none", input: "w-4 h-4" }}
                            checked={table.getState().columnVisibility[col.ids[0]] ?? true}
                        />
                        {col.label}
                    </MenuItem>
                ))}
            </Menu>
            <ActionButton onClick={(e) => setColumnSelectAnchor(e.currentTarget)}>
                <div className="flex items-center gap-2">
                    <Cog6ToothIcon className="h-4 w-4" />
                    {"Edit columns "}
                    <span className="text-gray-400">
                        ({totalHideableColumns - Object.values(table.getState().columnVisibility).filter((v) => !v).length}/
                        {totalHideableColumns})
                    </span>
                </div>
            </ActionButton>
        </div>
    );
}
