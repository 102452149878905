import { WaypointDirectory } from "@deathstar/types/waypoint";
import React from "react";
import type { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from "react-query";

interface AzureStorageAPIError {
    message: string;
}

abstract class AzureStorageAPI {
    abstract useDirectory(
        path: string,
        config?: UseQueryOptions<WaypointDirectory, AzureStorageAPIError>
    ): UseQueryResult<WaypointDirectory, AzureStorageAPIError>;
    abstract useUpload(
        config?: UseMutationOptions<void, AzureStorageAPIError, { path: string; files: File[] }>
    ): UseMutationResult<void, AzureStorageAPIError, { path: string; files: File[] }>;
    abstract useDownload(
        config?: UseMutationOptions<File, AzureStorageAPIError, { path: string; splitSize?: number }>
    ): UseMutationResult<File, AzureStorageAPIError, { path: string; splitSize?: number }>;
    abstract useCreateDirectory(
        config?: UseMutationOptions<void, AzureStorageAPIError, { name: string; path: string }>
    ): UseMutationResult<void, AzureStorageAPIError, { name: string; path: string }>;
    abstract useMove(
        config?: UseMutationOptions<void, AzureStorageAPIError, { source: string; target: string }>
    ): UseMutationResult<void, AzureStorageAPIError, { source: string; target: string }>;
    abstract useDelete(
        config?: UseMutationOptions<void, AzureStorageAPIError, string>
    ): UseMutationResult<void, AzureStorageAPIError, string>;
}

export interface AzureStorageBrowserContextData {
    root: string;
    path: string;
    handlePathChange(path: string): void;

    api: AzureStorageAPI;
}

export const AzureStorageBrowserContext = React.createContext<AzureStorageBrowserContextData>({} as AzureStorageBrowserContextData);
