import { FMCSA } from "@deathstar/types";
import { config } from "./ChartConfig";

export function BasicColorSwatch({ basic }: { basic: FMCSA.BasicName }): JSX.Element {
    return (
        <div
            className="flex h-5 w-5 rounded-full"
            style={{
                backgroundColor: config.colors[basic as FMCSA.BasicName.HAZMAT],
            }}
        />
    );
}
