import { AttributeOption, CoverageOption } from "@deathstar/types/northstar";
import { OOProgramMember, OOProgramPolicyStatus } from "@deathstar/types/waypoint";
import { ActionButton, classNames, DataTable, Popover, useSnackbar } from "@deathstar/ui";
import useBoop from "@deathstar/ui/hooks/useBoop";
import {
    ArrowTopRightOnSquareIcon,
    BuildingOffice2Icon,
    CalendarDaysIcon,
    EllipsisVerticalIcon,
    EnvelopeIcon,
    HashtagIcon,
    MinusCircleIcon,
    PlusIcon,
    ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import { CellContext, ColumnDef, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { animated } from "react-spring";
import api from "../../../api/api";
import { AccountIdProvider, useAccountId } from "../../../api/useAccountId";
import PolicyViewDialog from "../../policies/policy-view/PolicyViewDialog";
import OOProgramInvitations from "./OOProgramInvitations";
import OOProgramInviteForm from "./OOProgramInviteForm";

export default function OOProgram() {
    const accountId = useAccountId();
    const { data: policies } = api.policies.useFind(accountId!, "active");
    const { data, isLoading } = api.ooProgram.useGet(accountId!);

    const [inviteFormOpen, setInviteFormOpen] = useState(false);
    const [invitationsOpen, setInvitationsOpen] = useState(false);

    const navigate = useNavigate();
    const memoizedData = useMemo(() => data?.members || [], [data]);

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: DataTable.fuzzyFilter,
    });

    const invite = api.ooProgram.useInvite({
        onSuccess: () => {
            setInviteFormOpen(false);
        },
    });

    const invitationCount = data?.invitations.length || null;

    const [boopStyle, boop] = useBoop({ y: 3, scale: 1.01 });
    const hasLoadedInvitations = useRef<number | null>(null);

    useEffect(() => {
        if (hasLoadedInvitations.current !== null && invitationCount && invitationCount > hasLoadedInvitations.current) {
            boop();
        }
        hasLoadedInvitations.current = invitationCount;
    }, [boop, invitationCount]);

    const ownerOperatorPolicy = policies?.find((policy) =>
        policy.attributes.some(
            (attr) =>
                attr.attributeOptionId === AttributeOption.Id.CONTRACTOR_BENEFITS_ADMIN ||
                attr.attributeOptionId === AttributeOption.Id.OWNER_OPERATOR_WAYPOINT_PROGRAM
        )
    );

    return (
        <div className="w-full">
            <div className="w-max space-y-6">
                {ownerOperatorPolicy && (
                    <div className="mt-4 w-full rounded-lg border p-2">
                        <div className="mb-2 flex items-center justify-between gap-8">
                            <p className="text-waypoint-blue small-caps">Company program policy</p>
                            {ownerOperatorPolicy.attributes.some(
                                (attr) => attr.attributeOptionId === AttributeOption.Id.CONTRACTOR_BENEFITS_ADMIN
                            ) && (
                                <span className="rounded-full border px-2 text-xs">
                                    <a
                                        href="https://cba.focussi.com/"
                                        rel="noopener noreferrer"
                                        className="text-blue-600 hover:text-blue-800"
                                    >
                                        Managed in CBA <ArrowTopRightOnSquareIcon className="mb-1 ml-1 inline-block h-3 w-3" />
                                    </a>
                                </span>
                            )}
                        </div>
                        <div className="text-sm">
                            <div className="grid w-max grid-cols-2 gap-x-4 gap-y-1">
                                <p>
                                    <HashtagIcon className="-mt-1 mr-2 inline-block h-4 w-4 shrink-0 text-orange-400" />
                                    {ownerOperatorPolicy.number}
                                </p>

                                <p className="whitespace-nowrap tabular-nums">
                                    <CalendarDaysIcon className="-mt-1 mr-2 inline-block h-4 w-4 text-orange-400" />
                                    {moment.utc(ownerOperatorPolicy.effectiveDate).format("MM/DD/YY")} -{" "}
                                    {moment.utc(ownerOperatorPolicy.expirationDate).format("MM/DD/YY")}
                                </p>
                                <p className="whitespace-nowrap">
                                    <BuildingOffice2Icon className="-mt-1 mr-2 inline-block h-4 w-4 text-waypoint-orange" />
                                    {ownerOperatorPolicy.writingCompany?.name || "Unknown"}
                                </p>
                            </div>
                            <p className="mt-2">
                                {ownerOperatorPolicy.coverages
                                    .map((coverage) => CoverageOption.getMetadata(coverage.coverageOptionId).name)
                                    .join(", ")}
                            </p>
                        </div>
                    </div>
                )}
                <div className="w-full rounded-lg border bg-white p-2">
                    <div className="flex justify-between">
                        <p className="text-waypoint-blue small-caps">Individual policy monitoring</p>
                        <button
                            className="flex items-center gap-2 rounded-full border px-2 text-xs text-blue-600 hover:text-blue-800"
                            onClick={() => setInviteFormOpen(true)}
                        >
                            <PlusIcon className="h-4 w-4" />
                            Invite participant
                        </button>
                    </div>
                    <p className="my-2 max-w-prose text-sm text-stone-600">
                        Monitor the individual coverage of any independent contractor who drives under your authority and purchases an
                        individual policy through Navigator
                    </p>
                    <div className="grid w-full grid-cols-3">
                        <div className="flex items-baseline gap-2">
                            <span className="text-lg font-bold text-waypoint-blue">{data?.members.length || 0}</span>
                            <span>Participants joined</span>
                        </div>
                        <div className="flex items-baseline gap-2">
                            <span
                                className={classNames(
                                    "text-lg font-bold",
                                    (data?.status.insurance.inactive || 0) + (data?.status.insurance.precancellation || 0)
                                        ? "text-waypoint-orange"
                                        : "text-stone-500"
                                )}
                            >
                                {(data?.status.insurance.inactive || 0) + (data?.status.insurance.precancellation || 0)}
                            </span>
                            <span>Cancellation alerts</span>
                        </div>
                        <div className="flex justify-end">
                            <animated.div style={boopStyle}>
                                <ActionButton onClick={() => setInvitationsOpen(true)}>
                                    <EnvelopeIcon className="h-4 w-4" />
                                    {invitationCount || 0} invited
                                </ActionButton>
                            </animated.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <DataTable<OOProgramMember>
                    table={table}
                    onRowClick={(row) => {
                        navigate(`/oo/${row.id}/policies/active`);
                    }}
                    isLoading={isLoading}
                    noDataLabel="No participants found"
                />
            </div>
            <OOProgramInviteForm
                open={inviteFormOpen}
                onClose={() => setInviteFormOpen(false)}
                onSubmit={(data) => {
                    invite.mutate({
                        accountId: accountId!,
                        ...data,
                    });
                }}
            />
            <OOProgramInvitations open={invitationsOpen} onClose={() => setInvitationsOpen(false)} invitations={data?.invitations || []} />
        </div>
    );
}

const columns: ColumnDef<OOProgramMember>[] = [
    {
        header: "Name",
        accessorKey: "name",
    },
    {
        header: "Owner",
        accessorKey: "ownerName",
    },
    {
        header: "Insurance",
        accessorKey: "policy.status",
        cell: (info) => (
            <span>
                <span
                    className={
                        info.getValue() === OOProgramPolicyStatus.Active
                            ? "text-emerald-700"
                            : info.getValue() === OOProgramPolicyStatus.PendingCancellation
                            ? "text-orange-600"
                            : "text-red-700"
                    }
                >
                    {info.getValue() as string}
                </span>
                <span className="text-stone-500">
                    {" / "}
                    {info.row.original.policy.policies
                        .map((p) => p.coverages.map((c) => c.coverageOptionId))
                        .flat()
                        .map((o) => CoverageOption.getMetadata(o).name)
                        .join(", ")}
                </span>
            </span>
        ),
    },

    {
        header: "Actions",
        cell: ActionsCell,
    },
];

function ActionsCell(info: CellContext<OOProgramMember, unknown>) {
    const accountId = useAccountId();
    const remove = api.ooProgram.useRemove(accountId!, {
        onSuccess: () => {
            useSnackbar.add("Removal request sent", { variant: "success", autoHideDuration: 7500, persist: true });
        },
    });
    const [open, setOpen] = useState(false);
    return (
        <div
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <Popover>
                <Popover.Button className="rounded-full p-2 text-stone-500 hover:bg-black/5">
                    <EllipsisVerticalIcon className="h-4 w-4" />
                </Popover.Button>
                <Popover.Content>
                    <Popover.Item onClick={() => setOpen(true)} disabled={!info.row.original.policy.policies.length}>
                        <ShieldCheckIcon className="h-4 w-4 text-waypoint-blue" />
                        View dec page
                    </Popover.Item>
                    <Popover.Item
                        onClick={() => {
                            remove.mutate(info.row.original.id);
                        }}
                    >
                        <MinusCircleIcon className="h-4 w-4 text-red-700" />
                        Request to remove from program
                    </Popover.Item>
                    <p className="ml-8 mt-4 text-xs text-stone-500">Removing a participant will not cancel their coverage</p>
                </Popover.Content>
            </Popover>
            <AccountIdProvider accountId={info.row.original.id}>
                <PolicyViewDialog policyId={info.row.original.policy.policies[0]?.id} open={open} onClose={() => setOpen(false)} />
            </AccountIdProvider>
        </div>
    );
}
