import {
    BillingInformation,
    BillingInformationUpdateDto,
    License,
    PayBillFormData,
    Payment,
    SubscriptionItemUsage,
    SubscriptionPlansData,
    SubscriptionTier,
} from "@deathstar/types/waypoint";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const billing = {
    createBillingSession: (accountId: string) => {
        return blazar.fetchJson<{ sessionUrl: string }>(`waypoint/orgs/${accountId}/billing/session`, {
            method: "POST",
        });
    },

    getProfile: (accountId: string) => blazar.fetchJson<BillingInformation>(`waypoint/orgs/${accountId}/billing`),

    getBillingPlans: (accountId: string) => blazar.fetchJson<SubscriptionPlansData>(`waypoint/orgs/${accountId}/billing/plans`),

    getLicense: (accountId: string) => blazar.fetchJson<License>(`waypoint/orgs/${accountId}/license`),

    getPaymentHistory: (accountId: string) => blazar.fetchJson<Payment[]>(`waypoint/orgs/${accountId}/billing/history`),

    getUsage: (accountId: string, subscriptionId: string) =>
        blazar.fetchJson<SubscriptionItemUsage[]>(`waypoint/orgs/${accountId}/license/${subscriptionId}/usage`),

    getScheduledSubscription: (accountId: string) =>
        blazar.fetchJson<{ plan: SubscriptionTier; startDate: string; scheduleId: string } | null>(
            `waypoint/orgs/${accountId}/license/scheduled`
        ),

    cancelScheduledSubscription: (accountId: string, scheduleId: string) =>
        blazar.fetch(`waypoint/orgs/${accountId}/license/scheduled/${scheduleId}`, { method: "DELETE" }),

    /** If the customer does not have a default payment method a sessionUrl will be returned to redirect the user to */
    subscribe: (accountId: string, plan: SubscriptionTier) =>
        blazar.fetchJson<{ sessionUrl?: string } | undefined>(`waypoint/orgs/${accountId}/license`, {
            method: "POST",
            body: JSON.stringify({ plan }),
        }),

    unsubscribe: (accountId: string) =>
        blazar.fetchJson(`waypoint/orgs/${accountId}/license`, {
            method: "DELETE",
        }),

    updateProfile: (accountId: string, changes: BillingInformationUpdateDto) => {
        return blazar.fetchJson<void>(`waypoint/orgs/${accountId}/billing`, {
            method: "PATCH",
            body: JSON.stringify(changes),
        });
    },

    useUpdateProfile: (accountId: string, config?: UseMutationOptions<void, ResponseError, BillingInformationUpdateDto>) => {
        return useMutation<void, ResponseError, BillingInformationUpdateDto>(
            (changes: BillingInformationUpdateDto) => billing.updateProfile(accountId, changes),
            {
                ...config,
                onSuccess: (...args) => {
                    queryClient.invalidateQueries(billing.queryKeys.profile(accountId));
                    config?.onSuccess?.(...args);
                },
            }
        );
    },

    applyPromoCode: (accountId: string, code: string) => {
        return blazar.fetchJson(`waypoint/orgs/${accountId}/billing/promotion`, {
            method: "POST",
            body: JSON.stringify({ code }),
        });
    },
    createSetupIntent: (accountId: string) => {
        return blazar.fetchJson<{ clientSecret: string }>(`waypoint/orgs/${accountId}/billing/setup`, {
            method: "POST",
        });
    },

    removePaymentMethod: (accountId: string, paymentMethodId: string) => {
        return blazar.fetchJson<void>(`waypoint/orgs/${accountId}/billing/payment-methods/${paymentMethodId}`, {
            method: "DELETE",
        });
    },

    createAndPayInvoice: (accountId: string, body: PayBillFormData) => {
        return blazar.fetchJson<void>(`waypoint/orgs/${accountId}/billing/pay`, { method: "POST", body: JSON.stringify(body) });
    },

    createProfile: (accountId: string) => {
        return blazar.fetchJson(`waypoint/orgs/${accountId}/billing`, {
            method: "POST",
        });
    },

    queryKeys: {
        profile: (accountId: string) => ["billing", accountId, "profile"] as QueryKey,
        license: (accountId: string) => ["billing", accountId, "license"] as QueryKey,
        paymentHistory: (accountId: string) => ["billing", accountId, "payment-history"] as QueryKey,
        usage: (accountId: string, subscriptionId: string) => ["billing", accountId, "license", subscriptionId, "usage"] as QueryKey,
        billingPlans: (accountId: string) => ["billing", accountId, "plans"] as QueryKey,
        scheduledSubscription: (accountId: string) => ["billing", accountId, "scheduled-subscription"] as QueryKey,
    },

    useProfile: (accountId: string, config?: UseQueryOptions<BillingInformation, ResponseError, BillingInformation>) =>
        useQuery(billing.queryKeys.profile(accountId), () => billing.getProfile(accountId), {
            staleTime: 60_000,
            refetchOnWindowFocus: ({ state }) => state.status !== "error",
            ...config,
        }),

    useLicense: (accountId: string) =>
        useQuery(billing.queryKeys.license(accountId), () => billing.getLicense(accountId), {
            staleTime: 15_000,
        }),

    usePaymentHistory: (accountId: string) =>
        useQuery(billing.queryKeys.paymentHistory(accountId), () => billing.getPaymentHistory(accountId), {
            staleTime: 30_000,
        }),

    useUsage: (accountId: string, subscriptionId: string, config?: UseQueryOptions<SubscriptionItemUsage[]>) =>
        useQuery(billing.queryKeys.usage(accountId, subscriptionId), () => billing.getUsage(accountId, subscriptionId), {
            staleTime: 15_000,
            ...config,
        }),

    useBillingPlans: (accountId: string) =>
        useQuery(billing.queryKeys.billingPlans(accountId), () => billing.getBillingPlans(accountId), { staleTime: 30_000 }),

    useScheduledSubscription: (accountId: string) =>
        useQuery(billing.queryKeys.scheduledSubscription(accountId), () => billing.getScheduledSubscription(accountId), {
            staleTime: 30_000,
        }),

    useSetupIntent: (accountId: string, config?: UseMutationOptions) => useMutation(() => billing.createSetupIntent(accountId), config),

    useCreateAndPayInvoice: (accountId: string, config?: UseMutationOptions<void, void, PayBillFormData>) =>
        useMutation<void, void, PayBillFormData>((body: PayBillFormData) => billing.createAndPayInvoice(accountId, body), config),

    useCreateProfile: (accountId: string, config?: UseMutationOptions) => useMutation(() => billing.createProfile(accountId), config),
};
