/**
 * Get the fee structure for the given payment method type.
 */
export function getMethodFee(method: "card" | "us_bank_account") {
    if (method === "us_bank_account") {
        return { fixed: 160, percent: 0 };
    } else {
        return {
            fixed: 30,
            percent: 0.029,
        };
    }
}

/**
 * @param amount The desired amount in cents to collect after Stripe fees are taken out.
 * @param method The payment method being used. This determines the fees that will be taken out.
 * @returns The total amount in cents to charge the customer.
 */
export function getDesiredAmountWithFees(amount: number, method: "card" | "us_bank_account"): number {
    const fee = getMethodFee(method);

    const totalAmount = Math.round((amount + fee.fixed) / (1 - fee.percent));

    if (method === "us_bank_account") {
        if (totalAmount - amount > 500) {
            return amount + 500;
        }
    }

    return totalAmount;
}

/**
 * @param amount The bill amount in cents
 * @param method The payment method being used. This determines the fees that will be taken out.
 * @returns The fee in cents that will be taken out of the bill amount.
 */
export function getFeeAmount(amount: number, method: "card" | "us_bank_account"): number {
    const fee = getMethodFee(method);
    return Math.round(fee.fixed + fee.percent * amount);
}

/**
 * Get the amount that should be added to the bill amount to cover the Stripe fees. This method takes into account
 * that the fees are taken out of the total amount (including the amount that this method returns).
 *
 * In other words, use this method for adding the fee to the bill amount, not for getting the fee amount from the total;
 * for that the `getFeeAmount` method can be used.
 * @param amount
 * @param method
 */
export function getDesiredFeeAmount(amount: number, method: "card" | "us_bank_account"): number {
    const fee = getMethodFee(method);
    return Math.round(fee.fixed + fee.percent * ((amount + fee.fixed) / (1 - fee.percent)));
}

/**
 * Get whether or not the fee should be included in the total. Navigator passes CC fees on to the customer, but
 * does not pass on ACH fees, for instance.
 */
export function shouldIncludeFeeInTotal(method: "card" | "us_bank_account") {
    switch (method) {
        case "card":
            return true;
        case "us_bank_account":
            return false;
    }
}
