import { DriverOfTheYear, DriverOfTheYearDto } from "@deathstar/types/waypoint";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const driverOfTheYear = {
    queryKeys: {
        find: (accountId: string) => [accountId, "driver-of-the-year"] as QueryKey,
    },

    find: (accountId: string) => {
        return blazar.fetchJson<DriverOfTheYear[]>(`/waypoint/orgs/${accountId}/driver-of-the-year`);
    },

    useFind: <T = DriverOfTheYear[]>(accountId: string, config?: UseQueryOptions<DriverOfTheYear[], ResponseError, T>) =>
        useQuery<DriverOfTheYear[], ResponseError, T>(driverOfTheYear.queryKeys.find(accountId), () => driverOfTheYear.find(accountId), {
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            ...config,
        }),

    create: async (accountId: string, body: DriverOfTheYearDto) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/driver-of-the-year`, {
            method: "POST",
            body: JSON.stringify(body),
        });
    },

    useCreate: (accountId: string, config?: UseMutationOptions<void, ResponseError, DriverOfTheYearDto>) => {
        return useMutation<void, ResponseError, DriverOfTheYearDto>(
            (data) => {
                return driverOfTheYear.create(accountId, data);
            },
            {
                ...config,
                onSuccess: (...args) => {
                    queryClient.invalidateQueries(driverOfTheYear.queryKeys.find(accountId));
                    config?.onSuccess?.(...args);
                },
            }
        );
    },
};
