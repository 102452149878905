import { Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { Context } from "./Context";

export function Navigation(): JSX.Element {
    const { activeTab, setActiveTab } = React.useContext(Context);

    return (
        <Paper square elevation={0} className="rounded-t-lg border-b border-gray-600">
            <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(_, value: number) => {
                    setActiveTab(value);
                }}
                aria-label="disabled tabs example"
            >
                <Tab label="Summary" />
                <Tab label="Insights" />
                <Tab label="Crashes" />
                <Tab label="Inspections" />
                <Tab label="Violations" />
            </Tabs>
        </Paper>
    );
}
