import { InputField, PrimaryButton } from "@deathstar/ui";
import { useForm } from "react-hook-form";
import { Dialog } from "../../../components/dialog/Dialog";

export default function OOProgramInviteForm({
    open,
    onClose,
    onSubmit,
}: {
    open: boolean;
    onClose(): void;
    onSubmit(values: OOProgramInviteFormValues): void;
}) {
    const form = useForm<OOProgramInviteFormValues>({
        defaultValues: {
            name: "",
            phone: "",
            email: "",
            vin: "",
            license: "",
        },
    });

    return (
        <Dialog open={open} onClose={onClose} className="w-full p-4 md:max-w-md">
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4">
                <InputField classes={{ inputContainer: "py-2" }} label="Name" {...form.register("name")} />
                <InputField classes={{ inputContainer: "py-2" }} label="Phone" {...form.register("phone")} />
                <InputField classes={{ inputContainer: "py-2" }} label="Email" {...form.register("email")} />
                <InputField classes={{ inputContainer: "py-2" }} label="VIN #" {...form.register("vin")} />
                <InputField classes={{ inputContainer: "py-2" }} label="License #" {...form.register("license")} />

                <div className="flex flex-row-reverse">
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </Dialog>
    );
}

interface OOProgramInviteFormValues {
    name: string;
    phone: string;
    email: string;

    vin: string;
    license: string;
}
