import { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";
import { classNames } from "@deathstar/ui";
import { Typography } from "@material-ui/core";
import React from "react";
import ExpiringInspectionIcon from "../../assets/icons/expiring-inspection-icon.png";
import { BasicColorSwatch } from "../BasicColorSwatch";
import { config } from "../ChartConfig";
import { ChartContainer } from "../Charts/Container";
import { ExpiringPointsChart } from "../Charts/ExpiringPoints";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { Transition } from "../Transition";
import { SummarySection } from "./SummarySection";

export function ExpiringPoints(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);
    const { violations } = motorCarrier.inspections.filterExpiringWeight();

    return (
        <SummarySection>
            <div>
                <TitleGroup
                    title="Expiring Points"
                    align="right"
                    variant="h4"
                    className="mb-8"
                    icon={<img src={ExpiringInspectionIcon} alt="Expiring Inspection Icon" className="h-[35px]" />}
                    description={`${violations.getTotalExpiringWeight()} points falling off within the next 90 days`}
                />

                <Legend expiring violations={violations} />
            </div>

            <Transition.Fade>
                <ChartContainer className="aspect-video max-h-[40vh] min-w-[600px]">
                    <ExpiringPointsChart motorCarrier={motorCarrier} />
                </ChartContainer>
            </Transition.Fade>
        </SummarySection>
    );
}

export function Legend({
    violations,
    className,
    leadingChart,
    expiring,
}: {
    violations: MotorCarrier.IViolations;
    className?: string;
    leadingChart?: boolean;
    expiring?: boolean;
}): JSX.Element | null {
    if (!violations.total) return null;

    const { hazmat, controlledSubstances, driverFitness, vehicleMaintenance, hoursOfService, unsafeDriving } = violations.filterByBasics();

    const legendData = [
        {
            name: FMCSA.BasicName.HAZMAT,
            score: expiring ? hazmat.getTotalExpiringWeight() : hazmat.getTotalWeight(),
            color: config.colors[FMCSA.BasicName.HAZMAT],
        },
        {
            name: FMCSA.BasicName.CONTROLLED_SUBSTANCES,
            score: expiring ? controlledSubstances.getTotalExpiringWeight() : controlledSubstances.getTotalWeight(),
            color: config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
        },
        {
            name: FMCSA.BasicName.DRIVER_FITNESS,
            score: expiring ? driverFitness.getTotalExpiringWeight() : driverFitness.getTotalWeight(),
            color: config.colors[FMCSA.BasicName.DRIVER_FITNESS],
        },
        {
            name: FMCSA.BasicName.VEHICLE_MAINTENANCE,
            score: expiring ? vehicleMaintenance.getTotalExpiringWeight() : vehicleMaintenance.getTotalWeight(),
            color: config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
        },
        {
            name: FMCSA.BasicName.HOS,
            score: expiring ? hoursOfService.getTotalExpiringWeight() : hoursOfService.getTotalWeight(),
            color: config.colors[FMCSA.BasicName.HOS],
        },
        {
            name: FMCSA.BasicName.UNSAFE_DRIVING,
            score: expiring ? unsafeDriving.getTotalExpiringWeight() : unsafeDriving.getTotalWeight(),
            color: config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
        },
    ].filter(({ score }) => score > 0);

    return (
        <div
            className={classNames("flex flex-col", className, {
                "items-baseline": leadingChart,
                "items-end": !leadingChart,
            })}
        >
            <table>
                <tbody>
                    {legendData.map((legend, i) => (
                        <tr key={legend.name}>
                            <td>
                                <BasicColorSwatch basic={legend.name} />
                            </td>
                            <td className="px-4">
                                <Transition.Slide
                                    delay={i * 200}
                                    className="text-left"
                                    SlideProps={{
                                        direction: leadingChart ? "left" : "right",
                                    }}
                                >
                                    <Typography>{legend.name}</Typography>
                                </Transition.Slide>
                            </td>
                            <td>
                                <Transition.Fade delay={(i + 1) * 200 + legendData.length * 200}>
                                    <Typography>{legend.score}</Typography>
                                </Transition.Fade>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
