import { memoize } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { Crashes } from "../../Crashes/Crashes";
import { MotorCarrier } from "../../MotorCarrier";
import { SafetyEventGroup } from "../../SafetyEventGroup/SafetyEventGroup";
import { Basic } from "../Basic";

export class CrashIndicator extends Basic<SafetyEventGroup.GroupNames.CrashIndicator> {
    readonly isBestAddressedRoadside = false;

    // ========================================================================
    static get thresholdDefinition(): Basic.IThresholdDefinition {
        return {
            passenger: 50,
            hazmat: 60,
            general: 65,
        };
    }

    // ========================================================================
    constructor(options: CrashIndicator.Options) {
        super({
            ...options,
            safetyEventGroup: new SafetyEventGroup.Group.CrashIndicator(options.carrier, options.totalApplicableCrashes),
            basicName: FMCSA.BasicName.CRASH_INDICATOR,
            relevantInspectionDefinition: null,
        });
    }

    // ========================================================================
    get averagePowerUnits(): number {
        return this.carrier.averagePowerUnits;
    }

    // ========================================================================
    @memoize()
    get utilizationFactor(): number {
        return Basic.calculateUtilizationFactor(this.carrier);
    }

    // ========================================================================
    @memoize()
    getRelevantCrashes(): Crashes {
        return this.smsResult.crashes.filterByIncludedInSms();
    }

    // ========================================================================
    @memoize()
    getTotalWeightOfApplicableCrashes(): number {
        return this.getRelevantCrashes().getTotalWeight({ timeWeightDate: this.snapshotDate });
    }

    // ========================================================================
    @memoize()
    getTotalCrashesWithinYear(): number {
        return this.smsResult.crashes
            .filterByDateRange(MotorCarrier.calculateDateRange({ snapshotDate: this.snapshotDate }))
            .filterByIncludedInSms().total;
    }

    // ========================================================================
    override getVariableData(): Record<string, string | number | null | undefined> {
        return {
            totalApplicableCrashes: this.getRelevantCrashes().total,
            totalWeightOfApplicableCrashes: this.getTotalWeightOfApplicableCrashes(),
            averageMilesTraveledPerUnit: this.carrier.averageMilesTraveledPerUnit,
            averagePowerUnits: this.averagePowerUnits,
            segment: this.carrier.segment,
            utilizationFactor: this.utilizationFactor,
        };
    }

    // ========================================================================
    override getThresholdDefinition(): Basic.IThresholdDefinition {
        return CrashIndicator.thresholdDefinition;
    }

    // ========================================================================
    override getFmcsaCalculatedMeasure(): number | null {
        return typeof this.rawSmsData?.CrashIndicatorMeasure === "number" ? this.rawSmsData?.CrashIndicatorMeasure : null;
    }

    // ========================================================================
    protected override calculateMeasure(): number {
        if (!this.getRelevantCrashes().total) return 0;

        const totalWeightOfApplicableCrashes = this.getTotalWeightOfApplicableCrashes();

        return totalWeightOfApplicableCrashes / (this.averagePowerUnits * this.utilizationFactor);
    }

    // ========================================================================
    protected getMeasure(): number {
        if (typeof this.rawSmsData?.CrashIndicatorMeasure === "number" && !this.forceCalculateBasicMeasures) {
            return this.rawSmsData?.CrashIndicatorMeasure;
        }

        return this.calculateMeasure();
    }

    // ========================================================================
    protected override async getScore(): Promise<number | null> {
        if (typeof this.rawSmsData?.CrashIndicatorScore === "number" && !this.forceCalculateBasicScores) {
            return this.rawSmsData?.CrashIndicatorScore;
        }

        return this.calculateScore();
    }

    // ========================================================================
    protected override async calculateScore(): Promise<number | null> {
        const { totalApplicableCrashes } = this.smsResult;
        if (this.measure === 0 || this.safetyEventGroup.number === 0 || !this.safetyEventGroup.name || totalApplicableCrashes < 2) return 0;

        /*
            Get the total number of applicable crashes within previous 12 months
        */
        const totalCrashesWithinPreviousYear = this.getTotalCrashesWithinYear();

        if (totalCrashesWithinPreviousYear === 0) return 0;

        return this.fetchSmsScore();
    }
}

export namespace CrashIndicator {
    export interface Options extends Basic.BaseOptions {
        totalApplicableCrashes: number;
    }
}
