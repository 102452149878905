import { MotorCarrier } from "@deathstar/motor-carrier";
import { DataTable, fuzzyFilter } from "@deathstar/ui";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import React from "react";
import { animated, useSpring } from "react-spring";
import { Panel } from "../../Components/Panel";
import { Section } from "../../Components/Section";
import { TitleGroup } from "../../Components/TitleGroup";
import { Context } from "../../Context";
import { columns } from "./Columns";
import { Table as TopDriverScoreContributorsTable } from "./Drivers";
import { Table as TopTractorScoreContributorsTable } from "./Tractors";
import { Table as TopTrailerScoreContributorsTable } from "./Trailers";

const AnimatedDataTable = animated(DataTable<MotorCarrier.PrimaryScoreContributor>);

function Table({ totalItemsPerTable }: { totalItemsPerTable: number }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const data = React.useMemo(() => motorCarrier.getPrimaryScoreContributors(totalItemsPerTable), [motorCarrier, totalItemsPerTable]);

    const table = useReactTable({
        data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: fuzzyFilter,
    });
    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    return <AnimatedDataTable style={spring} table={table} />;
}

export function PrimaryScoreContributorsTable(): JSX.Element {
    const [activeTab, setActiveTab] = React.useState(0);
    const [totalItemsPerTable, _setTotalItemsPerTable] = React.useState(10);

    return (
        <Section>
            <TitleGroup
                title="Top Score Contributors"
                description="This insight helps identify equipment and drivers that contribute the most points toward CSA scores. It can assist you with determining specific maintenance issues like brakes, lighting and tires, or consistent driver issues like inaccurate logs, speeding or mobile device use."
            />

            <div className="flex gap-8">
                <Paper square elevation={0} className="mt-14">
                    <Tabs
                        value={activeTab}
                        orientation="vertical"
                        className="place-center"
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(_, value: number) => {
                            setActiveTab(value);
                        }}
                        aria-label="disabled tabs example"
                    >
                        <Tab label="All" />
                        <Tab label="Drivers" />
                        <Tab label="Tractors" />
                        <Tab label="Trailers" />
                    </Tabs>
                </Paper>

                <Panel panelIndex={0} activeTabIndex={activeTab}>
                    <Table totalItemsPerTable={totalItemsPerTable} />
                </Panel>

                <Panel panelIndex={1} activeTabIndex={activeTab}>
                    <TopDriverScoreContributorsTable totalItemsPerTable={totalItemsPerTable} />
                </Panel>

                <Panel panelIndex={2} activeTabIndex={activeTab}>
                    <TopTractorScoreContributorsTable totalItemsPerTable={totalItemsPerTable} />
                </Panel>

                <Panel panelIndex={3} activeTabIndex={activeTab}>
                    <TopTrailerScoreContributorsTable totalItemsPerTable={totalItemsPerTable} />
                </Panel>
            </div>
        </Section>
    );
}
