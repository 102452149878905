import { memoize } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { Inspection } from "../../Inspection/Inspection";
import { Inspections } from "../../Inspections/Inspections";
import { SafetyEventGroup } from "../../SafetyEventGroup/SafetyEventGroup";
import { Basic } from "../Basic";

export class Hazmat extends Basic<SafetyEventGroup.GroupNames.Hazmat> {
    readonly isBestAddressedRoadside = true;

    // ========================================================================
    static get thresholdDefinition(): Basic.IThresholdDefinition {
        return {
            passenger: 80,
            hazmat: 80,
            general: 80,
        };
    }

    // ========================================================================
    constructor(options: Hazmat.Options) {
        super({
            ...options,
            safetyEventGroup: new SafetyEventGroup.Group.Hazmat(options.carrier, options.totalPlacardableHazmatInspections),
            basicName: FMCSA.BasicName.HAZMAT,
            relevantInspectionDefinition: {
                levels: [1, 2, 5, 6],
                type: Inspection.Type.VEHICLE,
            },
        });
    }

    // ========================================================================
    override getThresholdDefinition(): Basic.IThresholdDefinition {
        return Hazmat.thresholdDefinition;
    }

    // ========================================================================
    override getFmcsaCalculatedMeasure(): number | null {
        return typeof this.rawSmsData?.HazmatMeasure === "number" ? this.rawSmsData?.HazmatMeasure : null;
    }

    // ========================================================================
    protected override getMeasure(): number {
        if (typeof this.rawSmsData?.HazmatMeasure === "number" && !this.forceCalculateBasicMeasures) {
            return this.rawSmsData?.HazmatMeasure;
        }

        return this.calculateMeasure();
    }

    // ========================================================================
    protected override async getScore(): Promise<number | null> {
        if (typeof this.rawSmsData?.HazmatScore === "number" && !this.forceCalculateBasicScores) {
            return this.rawSmsData?.HazmatScore;
        }

        return this.calculateScore();
    }

    // ========================================================================
    override getVariableData(): Record<string, string | number> {
        return {
            totalWeightOfApplicableViolations: this.getTotalWeightOfApplicableViolations(),
            totalTimeWeightOfRelevantInspections: this.getTotalTimeWeightOfRelevantInspections(),
        };
    }

    // ========================================================================
    @memoize()
    override getRelevantInspections(): Inspections {
        return super.getRelevantInspections().filterByHazmatPlacardRequired();
    }

    // ========================================================================
    protected override async calculateScore(): Promise<number | null> {
        if (
            this.measure === 0 ||
            this.safetyEventGroup.number === 0 ||
            !this.safetyEventGroup.name ||
            this.smsResult.totalPlacardableHazmatInspections < 5
        )
            return 0;

        /*
            Get the total number of Hazmat violations
        */
        const totalHazmatViolations = this.getTotalNumberOfViolations();

        if (totalHazmatViolations === 0) return 0;

        /*
            Get the total number of inspections with an Hazmat violation
        */
        const totalHazmatInspectionsWithViolation = this.getTotalInspectionsWithViolation();

        if (totalHazmatInspectionsWithViolation < 5) return 0;

        /*
            Get the number of Hazmat violations within the previous 12 months.
        */
        const totalHazmatViolationsWithinPreviousYear = this.getTotalViolationsWithinYear();

        if (totalHazmatViolationsWithinPreviousYear === 0) {
            const latestRelevantVehicleInspectionIncludesViolation = this.getLatestRelevantInspectionIncludesViolation();

            if (!latestRelevantVehicleInspectionIncludesViolation) return 0;
        }

        return this.fetchSmsScore();
    }
}

export namespace Hazmat {
    export interface Options extends Basic.BaseOptions {
        totalPlacardableHazmatInspections: number;
    }
}
