import { SecondaryButton } from "@deathstar/ui";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Badge } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import { useEffect, useRef } from "react";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import api from "../../api/api";
import iconSrc from "../../assets/images/wp_icon.svg";
import UncaughtException from "../../components/error-screens/UncaughtException";
import Loader from "../../components/loader/Loader";
import NavigationTabs from "../../components/navigation-tabs/NavigationTabs";
import MyInvites from "../../features/settings/profile/MyInvites";
import { UserProfileForm } from "../../features/settings/profile/UserProfileForm";

export default function Profile() {
    const { data: user, isLoading, isIdle } = api.user.useMe();
    const errorBoundary = useRef<ErrorBoundary>(null);

    const url = window.location.href;
    useEffect(() => {
        errorBoundary.current?.resetErrorBoundary();
    }, [url]);

    if (isLoading || isIdle) {
        return <Loader />;
    } else if (!user) {
        return <UncaughtException />;
    } else {
        return (
            <Routes>
                <Route
                    element={
                        <div className="space-y-4 p-4 lg:p-12">
                            <div className="flex items-center gap-8">
                                <Link to="/sol">
                                    <img className="w-10" src={iconSrc} alt="Waypoint icon" />
                                </Link>
                                <Link to="/sol">
                                    <SecondaryButton className="h-max">
                                        <ChevronLeftIcon className="h-5 w-5" />
                                        Back to your organization
                                    </SecondaryButton>
                                </Link>
                            </div>
                            <NavigationTabs
                                tabs={[
                                    { to: "", label: "Profile" },
                                    {
                                        to: "invites",
                                        label: (
                                            <Badge
                                                badgeContent={user.invitations.length}
                                                variant="dot"
                                                classes={{ badge: "bg-waypoint-orange animate-pulse" }}
                                                overlap="rectangular"
                                            >
                                                <span>Invitations</span>
                                            </Badge>
                                        ),
                                    },
                                ]}
                            />
                            <ErrorBoundary fallback={<UncaughtException />} ref={errorBoundary}>
                                <Outlet />
                            </ErrorBoundary>
                        </div>
                    }
                >
                    <Route index element={<UserProfileForm user={user} />} />
                    <Route path="invites" element={<MyInvites />} />
                </Route>
            </Routes>
        );
    }
}
