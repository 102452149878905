import { BillingInformation, getSubscriptionTierName, License, SubscriptionPlansData, SubscriptionTier } from "@deathstar/types/waypoint";
import { InputField, useSnackbar } from "@deathstar/ui";
import { sortBy, uniqBy } from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import api from "../../../../api/api";
import { useAccountId } from "../../../../api/useAccountId";
import SimpleConfirmation from "../../../../components/simple-confirmation/SimpleConfirmation";
import { queryClient } from "../../../../util/queryClient";
import { getPlanIndex, getProductUsage, unitAmountToCurrency } from "./billingUtil";
import ConfirmationMessage from "./ConfirmationMessage";
import FlatPrice from "./FlatPrice";
import PlanHeader from "./PlanHeader";
import ProductIncluded from "./ProductIncluded";
import ProductUnavailable from "./ProductUnavailable";
import TieredPrice from "./TieredPrice";

export default function PricingTable({
    profile,
    plans,
    license,
}: {
    profile: BillingInformation;
    plans: SubscriptionPlansData;
    license?: License | null;
}) {
    const accountId = useAccountId();

    const [search] = useSearchParams();
    const searchPurchase = search.get("purchase") as SubscriptionTier;

    const { data: scheduledSubscription } = api.billing.useScheduledSubscription(accountId!);
    const subscriptionId = license?.stripeSubscriptionId;
    const { data: usage } = api.billing.useUsage(accountId!, subscriptionId!, { enabled: !!subscriptionId });

    const refetchLicense = useCallback(() => queryClient.refetchQueries(api.billing.queryKeys.license(accountId!)), [accountId]);
    const refetchProfile = useCallback(() => queryClient.refetchQueries(api.billing.queryKeys.profile(accountId!)), [accountId]);

    // use querystring of `?purchase={{PLAN_NAME}}` to start with the purchase dialog open
    const [purchasing, setPurchasing] = useState<{ plan: SubscriptionTier; isDowngrade?: boolean } | null>(
        getPlanIndex(searchPurchase) >= 0
            ? {
                  plan: searchPurchase,
                  isDowngrade: getPlanIndex(license?.plan as SubscriptionTier) > getPlanIndex(searchPurchase),
              }
            : null
    );
    const [cancelling, setCancelling] = useState(false);

    const subscribe = useCallback(
        async (plan: SubscriptionTier) => {
            if (accountId && plan) {
                const response = await api.billing.subscribe(accountId, plan);
                if (response && response.sessionUrl) {
                    window.location.href = response.sessionUrl;
                } else {
                    useSnackbar.add("Your subscription has been received but may take a few minutes to be resolved.", { variant: "info" });
                    setPurchasing(null);
                    setTimeout(() => {
                        refetchLicense();
                    }, 1000);
                }
            }
        },
        [accountId, refetchLicense]
    );

    const cancelSubscription = useCallback(async () => {
        await api.billing.unsubscribe(accountId!);
        useSnackbar.add("Your cancel request has been received but may take a few minutes to be resolved.", { variant: "info" });
        setCancelling(false);
        setTimeout(() => {
            refetchLicense();
        }, 1000);
    }, [accountId, refetchLicense]);

    const products = uniqBy(plans.standard.map((price) => price.product).concat(plans.premium.map((price) => price.product)), "id");

    const byProduct = sortBy(
        products.map((product) => ({
            product,
            prices: {
                [SubscriptionTier.Standard]: plans[SubscriptionTier.Standard]?.find((price) => price.product.id === product.id),
                [SubscriptionTier.Premium]: plans[SubscriptionTier.Premium]?.find((price) => price.product.id === product.id),
                [SubscriptionTier.Enterprise]: plans[SubscriptionTier.Enterprise]?.find((price) => price.product.id === product.id),
            },
        })),
        "metadata.order"
    );

    const [spring] = useSpring(
        {
            from: { tableOpacity: 0, couponX: -20, couponOpacity: 0, tbodyOpacity: 0, tbodyY: -20 },
            to: [
                { tableOpacity: 1, couponX: -20, couponOpacity: 0, tbodyOpacity: 0, tbodyY: -20 },
                { tableOpacity: 1, couponX: -20, couponOpacity: 0, tbodyOpacity: 1, tbodyY: 0 },
                { tableOpacity: 1, couponX: 0, couponOpacity: 1, tbodyOpacity: 1, tbodyY: 0 },
            ],
        },
        []
    );

    const coupon = license?.stripeSubscription?.discount?.coupon || profile.coupon;
    const defaultPaymentMethod = profile.paymentMethods.find((pm) => pm.isDefault) || profile.paymentMethods[0];

    return (
        <>
            <animated.table style={{ opacity: spring.tableOpacity }} className="border-separate col-spacing-4 row-p-4 row-spacing-2">
                <colgroup>
                    <col></col>
                    <col
                        className={
                            license?.plan === SubscriptionTier.Standard
                                ? "rounded-lg border-2 border-waypoint-orange bg-waypoint-orange bg-opacity-[7%]"
                                : ""
                        }
                    ></col>
                    <col
                        className={
                            license?.plan === SubscriptionTier.Premium
                                ? "rounded-lg border-2 border-waypoint-orange bg-waypoint-orange bg-opacity-[7%] outline-2 ring-2"
                                : ""
                        }
                    ></col>
                    <col
                        className={
                            license?.plan === SubscriptionTier.Enterprise
                                ? "rounded-lg border-2 border-waypoint-orange bg-waypoint-orange bg-opacity-[7%]"
                                : ""
                        }
                    ></col>
                </colgroup>
                <thead>
                    <tr>
                        <th className="text-left text-sm font-normal">
                            {coupon ? (
                                <animated.div
                                    style={{ opacity: spring.couponOpacity, x: spring.couponX }}
                                    className="rounded-lg border border-stone-100 p-2 shadow-lg"
                                >
                                    <p className="text-stone-700 small-caps">
                                        {license?.stripeSubscription?.discount ? "Active discount" : "Promotion"}
                                    </p>
                                    <hr className="mb-4" />
                                    <p>{coupon.name}</p>
                                    <p className="text-right text-lg font-medium text-emerald-600">
                                        {coupon.amount_off
                                            ? unitAmountToCurrency(coupon.amount_off, coupon.currency!) + " off"
                                            : coupon.percent_off
                                            ? coupon.percent_off + "% off"
                                            : null}
                                    </p>
                                </animated.div>
                            ) : (
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        const code = (e.currentTarget.elements.namedItem("code") as HTMLInputElement)?.value;
                                        if (code) {
                                            api.billing
                                                .applyPromoCode(accountId!, code)
                                                .then(() => {
                                                    useSnackbar.add("Coupon applied.", { variant: "success" });
                                                    refetchProfile();
                                                })
                                                .catch((err) => {
                                                    useSnackbar.add(err.message, { variant: "error" });
                                                });
                                        }
                                    }}
                                >
                                    <InputField name="code" placeholder="Client access code" />
                                    <button className="float-right px-2 py-1 text-blue-600 hover:text-blue-900" type="submit">
                                        Apply
                                    </button>
                                </form>
                            )}
                        </th>
                        <th className="rounded-t-lg">
                            <PlanHeader
                                plan={SubscriptionTier.Standard}
                                price={byProduct[0].prices.standard!}
                                license={license}
                                coupon={profile.coupon}
                                scheduled={scheduledSubscription}
                                classes={{
                                    card: "bg-waypoint-blue-light/20 text-stone-700",
                                    buttonSubscribe: "!bg-waypoint-blue !text-white hover:!bg-waypoint-blue-dark",
                                    buttonDowngrade: "!border-stone-300 !text-stone-500",
                                    buttonUpgrade: "!bg-waypoint-blue hover:!bg-waypoint-blue-dark",
                                    buttonCancel: "!border-stone-300 !text-stone-500",
                                }}
                                onCancel={async (byPassConfirmation) => {
                                    if (byPassConfirmation) {
                                        cancelSubscription();
                                    } else {
                                        setCancelling(true);
                                    }
                                }}
                                onCancelScheduled={async () => {
                                    if (scheduledSubscription) {
                                        await api.billing.cancelScheduledSubscription(accountId!, scheduledSubscription.scheduleId);
                                    }
                                    queryClient.refetchQueries(api.billing.queryKeys.scheduledSubscription(accountId!));
                                }}
                                onSubscribe={async (byPassConfirmation) => {
                                    if (byPassConfirmation) {
                                        await subscribe(SubscriptionTier.Standard);
                                    } else {
                                        setPurchasing({ plan: SubscriptionTier.Standard });
                                    }
                                }}
                                onDowngrade={() => {
                                    setPurchasing({ plan: SubscriptionTier.Standard, isDowngrade: true });
                                }}
                            />
                        </th>
                        <th className="rounded-t-lg">
                            <PlanHeader
                                plan={SubscriptionTier.Premium}
                                license={license}
                                price={byProduct[0].prices.premium!}
                                coupon={profile.coupon}
                                scheduled={scheduledSubscription}
                                classes={{
                                    card: "bg-waypoint-blue text-white",
                                    buttonSubscribe: "!bg-white !text-waypoint-blue hover:!bg-stone-100",
                                    buttonDowngrade: "!bg-white !text-waypoint-blue hover:!bg-stone-100",
                                    buttonUpgrade: "!bg-white !text-waypoint-blue hover:!bg-stone-100",
                                    buttonCancel: "!border-stone-300 !text-stone-200 !ring-stone-300",
                                }}
                                onCancel={async (byPassConfirmation) => {
                                    if (byPassConfirmation) {
                                        cancelSubscription();
                                    } else {
                                        setCancelling(true);
                                    }
                                }}
                                onCancelScheduled={async () => {
                                    if (scheduledSubscription) {
                                        await api.billing.cancelScheduledSubscription(accountId!, scheduledSubscription.scheduleId);
                                    }
                                    queryClient.refetchQueries(api.billing.queryKeys.scheduledSubscription(accountId!));
                                }}
                                onSubscribe={async (byPassConfirmation) => {
                                    if (byPassConfirmation) {
                                        await subscribe(SubscriptionTier.Premium);
                                    } else {
                                        setPurchasing({ plan: SubscriptionTier.Premium });
                                    }
                                }}
                                onDowngrade={() => {
                                    setPurchasing({ plan: SubscriptionTier.Premium, isDowngrade: true });
                                }}
                            />
                        </th>
                        <th className="rounded-t-lg">
                            <PlanHeader
                                plan={SubscriptionTier.Enterprise}
                                license={license}
                                price={byProduct[0].prices.enterprise!}
                                coupon={profile.coupon}
                                scheduled={scheduledSubscription}
                                classes={{
                                    card: "bg-gradient-to-tl from-waypoint-orange to-waypoint-blue text-white",
                                    buttonSubscribe: "!bg-white !text-waypoint-blue hover:!bg-stone-100 !ring-waypoint-blue",
                                    buttonDowngrade: "!bg-white !text-waypoint-blue hover:!bg-stone-100 !ring-waypoint-blue",
                                    buttonUpgrade: "!bg-white !text-waypoint-blue hover:!bg-stone-100 !ring-waypoint-blue",
                                    buttonCancel: "!border-stone-300 !text-stone-200",
                                }}
                                onCancel={async (byPassConfirmation) => {
                                    if (byPassConfirmation) {
                                        cancelSubscription();
                                    } else {
                                        setCancelling(true);
                                    }
                                }}
                                onCancelScheduled={async () => {
                                    if (scheduledSubscription) {
                                        await api.billing.cancelScheduledSubscription(accountId!, scheduledSubscription.scheduleId);
                                    }
                                    queryClient.refetchQueries(api.billing.queryKeys.scheduledSubscription(accountId!));
                                }}
                                onSubscribe={async (byPassConfirmation) => {
                                    if (byPassConfirmation) {
                                        await subscribe(SubscriptionTier.Enterprise);
                                    } else {
                                        setPurchasing({ plan: SubscriptionTier.Enterprise });
                                    }
                                }}
                                onDowngrade={() => {
                                    setPurchasing({ plan: SubscriptionTier.Enterprise, isDowngrade: true });
                                }}
                            />
                        </th>
                    </tr>
                </thead>
                <animated.tbody style={{ y: spring.tbodyY, opacity: spring.tbodyOpacity }} className="[&>tr:last-child>td]:rounded-b-lg">
                    {byProduct.slice(1).map(({ product, prices }) => (
                        <tr key={product.id} className="text-stone-600 even:bg-stone-400/5">
                            <FeatureLabel>{product.name}</FeatureLabel>

                            <td>
                                <div className="px-4">
                                    {prices.standard ? (
                                        prices.standard.tiers_mode ? (
                                            <div className="flex justify-between">
                                                <TieredPrice price={prices.standard} />
                                                {license && license.plan === SubscriptionTier.Standard && (
                                                    <p className="text-sm text-waypoint-blue">
                                                        <span className="font-mono font-bold">
                                                            {getProductUsage(usage || [], prices.standard) || 0}
                                                        </span>{" "}
                                                        used
                                                    </p>
                                                )}
                                            </div>
                                        ) : (
                                            <FlatPrice price={prices.standard} />
                                        )
                                    ) : (
                                        <ProductUnavailable />
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className="px-4">
                                    {prices.premium ? (
                                        prices.premium.tiers_mode ? (
                                            <div className="flex justify-between">
                                                <TieredPrice price={prices.premium} />
                                                {license && license.plan === SubscriptionTier.Premium && (
                                                    <p className="text-sm text-waypoint-blue">
                                                        <span className="font-mono font-bold">
                                                            {getProductUsage(usage || [], prices.premium) || 0}
                                                        </span>{" "}
                                                        used
                                                    </p>
                                                )}
                                            </div>
                                        ) : (
                                            <FlatPrice price={prices.premium} />
                                        )
                                    ) : (
                                        <ProductUnavailable />
                                    )}
                                </div>
                            </td>
                            <td className="rounded-r-lg">
                                <div className="px-4">
                                    {prices.enterprise ? (
                                        prices.enterprise.tiers_mode ? (
                                            <div className="flex justify-between">
                                                <TieredPrice price={prices.enterprise} />
                                                {license && license.plan === SubscriptionTier.Enterprise && (
                                                    <p className="text-sm text-waypoint-blue">
                                                        <span className="font-mono font-bold">
                                                            {getProductUsage(usage || [], prices.enterprise) || 0}
                                                        </span>{" "}
                                                        used
                                                    </p>
                                                )}
                                            </div>
                                        ) : (
                                            <FlatPrice price={prices.enterprise} />
                                        )
                                    ) : (
                                        <ProductUnavailable />
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Compliance questions</FeatureLabel>

                        <td className="rounded-l-lg">
                            <div className="px-4">Response within 48 hours</div>
                        </td>
                        <td>
                            <div className="px-4">Response within 24 hours</div>
                        </td>
                        <td className="rounded-r-lg">
                            <div className="px-4">Response within 24 hours</div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Legal questions</FeatureLabel>

                        <td className="rounded-l-lg">
                            <div className="px-4">Response within 48 hours</div>
                        </td>
                        <td>
                            <div className="px-4">Response within 24 hours</div>
                        </td>
                        <td className="rounded-r-lg">
                            <div className="px-4">Response within 24 hours</div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>MCS-150 Processing</FeatureLabel>

                        <td className="rounded-l-lg">
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                        <td className="rounded-r-lg">
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Driver rewards program</FeatureLabel>

                        <td className="rounded-l-lg">
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td className="rounded-r-lg">
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Conditional rating repair</FeatureLabel>

                        <td className="rounded-l-lg">
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td className="rounded-r-lg">
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Mock DOT audit</FeatureLabel>

                        <td className="rounded-l-lg">
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td className="rounded-r-lg">
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Driver file review</FeatureLabel>

                        <td className="rounded-l-lg">
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td className="rounded-r-lg">
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                </animated.tbody>
            </animated.table>
            <SimpleConfirmation
                open={!!purchasing}
                onConfirm={() => subscribe(purchasing!.plan)}
                onClose={() => setPurchasing(null)}
                title={`${getSubscriptionTierName((purchasing?.plan || "") as SubscriptionTier)} Subscription`}
                confirmLabel={defaultPaymentMethod ? "Subscribe" : "Continue"}
                description={
                    purchasing ? (
                        <>
                            {defaultPaymentMethod && (
                                <>
                                    <p>
                                        Are you sure you want to {purchasing.isDowngrade ? "downgrade" : "subscribe"} to the{" "}
                                        {getSubscriptionTierName(purchasing.plan)} plan?
                                    </p>
                                    <br />
                                </>
                            )}

                            {purchasing.isDowngrade && license?.stripeSubscription ? (
                                <p>
                                    Your current subscription will continue until{" "}
                                    {moment(license.stripeSubscription.current_period_end * 1000).format("MM/DD/YYYY")}, then your chosen
                                    plan will activate.
                                </p>
                            ) : (
                                <p>
                                    <ConfirmationMessage
                                        defaultPaymentMethod={defaultPaymentMethod}
                                        price={byProduct[0].prices[purchasing.plan]!}
                                        coupon={coupon}
                                    />
                                </p>
                            )}
                        </>
                    ) : null
                }
            />
            <SimpleConfirmation
                open={cancelling}
                onConfirm={cancelSubscription}
                onClose={() => setCancelling(false)}
                title="Cancel subscription"
                description={
                    <>
                        <p>Are you sure you want to cancel your subscription?</p>
                        <br />

                        <p>
                            Your access will continue until{" "}
                            {license?.stripeSubscription?.current_period_end
                                ? moment(license.stripeSubscription.current_period_end * 1000).format("MMMM D, YYYY")
                                : "the end of the current billing period"}
                            .
                        </p>
                    </>
                }
            />
        </>
    );
}

function FeatureLabel({ children }: { children: React.ReactNode }) {
    return <td className="whitespace-nowrap rounded-l-lg font-medium text-stone-900">{children}</td>;
}
