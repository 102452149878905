import { PaymentMethodInfo, PriceWithProduct } from "@deathstar/types/waypoint";
import Stripe from "stripe";
import { getAmountOff, unitAmountToCurrency } from "./billingUtil";

export default function ConfirmationMessage({
    defaultPaymentMethod,
    price,
    coupon,
}: {
    defaultPaymentMethod: PaymentMethodInfo | null;
    price: PriceWithProduct;
    coupon?: Stripe.Coupon | null;
}) {
    const unitAmount = price.unit_amount!;
    const amountOff = coupon ? getAmountOff(unitAmount, coupon) : 0;
    if (amountOff >= unitAmount) {
        return (
            <span>
                You will <span className="font-bold">not</span> be charged for this plan at this time due to your applied discount. If you
                exceed your usage limits, you may be billed for overages.
            </span>
        );
    }
    if (!defaultPaymentMethod) {
        return <span>You will be forwarded to a payment page to review and complete your purchase.</span>;
    }
    return (
        <span>
            Your default payment method ({defaultPaymentMethod.description}) will be charged{" "}
            {unitAmountToCurrency(unitAmount, price.currency, coupon)}.
        </span>
    );
}
