import { MotorCarrier } from "@deathstar/motor-carrier";
import { DataTable, fuzzyFilter } from "@deathstar/ui";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { animated, useSpring } from "react-spring";
import { useLocalStorage } from "web-api-hooks";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import Columns, { columns } from "./Columns";
import { Filters } from "./Filters";

const hideableColumns: DataTable.HideableColumn[] = [
    {
        label: "DataQ",
        ids: [Columns.isDataQ.id!],
    },
    {
        label: "Add Date",
        ids: [Columns.addDate.id!],
    },
    {
        label: "Removal Date",
        ids: [Columns.removalDate.id!],
    },
    {
        label: "Unit: VIN",
        ids: [Columns.unitVin.id!],
    },
    {
        label: "Unit: Year",
        ids: [Columns.unitYear.id!],
    },
    {
        label: "Unit: Make",
        ids: [Columns.unitMake.id!],
    },
    {
        label: "Unit: License",
        ids: [Columns.unitLicenseNumber.id!],
    },
    // {
    //     label: "Unit: Number",
    //     ids: [Columns.unitNumber.id!],
    // },
    {
        label: "Unit: Type",
        ids: [Columns.unitType.id!],
    },
    {
        label: "Offender",
        ids: [Columns.offender.id!],
    },

    {
        label: "BASIC",
        ids: [Columns.basic.id!],
    },
    {
        label: "OOS",
        ids: [Columns.oosIndicator.id!],
    },
    {
        label: "Group",
        ids: [Columns.groupDescription.id!],
    },
    {
        label: "Section",
        ids: [Columns.sectionDescription.id!],
    },
    {
        label: "Description",
        ids: [Columns.description.id!],
    },

    {
        label: "Severity Weight",
        ids: [Columns.severityWeight.id!],
    },
    {
        label: "Time Weight",
        ids: [Columns.timeWeight.id!],
    },
    {
        label: "Total Weight",
        ids: [Columns.totalWeight.id!],
    },
    {
        label: "Weight Calculation",
        ids: [Columns.weightCalculation.id!],
    },
    {
        label: "Violation Code",
        ids: [Columns.violationCode.id!],
    },
    // {
    //     label: "Citation Number",
    //     ids: [Columns.citationNumber.id!],
    // },
    {
        label: "County",
        ids: [Columns.county.id!],
    },
    {
        label: "Latitude",
        ids: [Columns.latitude.id!],
    },
    {
        label: "Longitude",
        ids: [Columns.longitude.id!],
    },

    // {
    //     label: "Defect Verification",
    //     ids: [Columns.defectVerification.id!],
    // },
    // {
    //     label: "Category",
    //     ids: [Columns.violationCategory.id!],
    // },
    // {
    //     label: "Category Description",
    //     ids: [Columns.violationCategoryDescription.id!],
    // },
];

const AnimatedDataTable = animated(DataTable<MotorCarrier.IViolation>);

function useColumnVisibility(): [Record<string, boolean>, React.Dispatch<React.SetStateAction<Record<string, boolean>>>] {
    const [columnVisibility, setColumnVisibility] = useLocalStorage<Record<string, boolean>>("ia-violation-table-column-visibility", {
        [Columns.addDate.id as string]: true,
        [Columns.removalDate.id as string]: true,
        [Columns.unitVin.id as string]: true,
        [Columns.unitYear.id as string]: true,
        [Columns.unitMake.id as string]: true,
        [Columns.unitLicenseNumber.id as string]: false,
        // [Columns.unitNumber.id as string]: false,
        [Columns.unitType.id as string]: false,
        [Columns.offender.id as string]: false,

        [Columns.basic.id as string]: true,
        [Columns.oosIndicator.id as string]: true,
        [Columns.groupDescription.id as string]: true,
        [Columns.sectionDescription.id as string]: false,
        [Columns.description.id as string]: false,

        [Columns.severityWeight.id as string]: true,
        [Columns.timeWeight.id as string]: true,
        [Columns.totalWeight.id as string]: true,
        [Columns.weightCalculation.id as string]: false,

        [Columns.violationCode.id as string]: false,
        // [Columns.citationNumber.id as string]: false,
        [Columns.county.id as string]: false,
        [Columns.latitude.id as string]: false,
        [Columns.longitude.id as string]: false,

        // [Columns.defectVerification.id as string]: false,
        // [Columns.violationCategory.id as string]: false,
        // [Columns.violationCategoryDescription.id as string]: false,
    });

    React.useEffect(() => {
        setColumnVisibility((old) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newState: Record<string, any> = {};
            for (const key in old) {
                if (hideableColumns.some((c) => c.ids.includes(key))) {
                    newState[key] = old[key];
                }
            }
            return newState;
        });
    }, [setColumnVisibility]);

    return [columnVisibility, setColumnVisibility];
}

function Table(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const data = React.useMemo(() => motorCarrier.violations.sortByDate("DESC").array(), [motorCarrier]);

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = useColumnVisibility();

    const table = useReactTable<MotorCarrier.IViolation>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        globalFilterFn: fuzzyFilter,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,
        state: {
            columnVisibility,
            columnFilters,
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
    });

    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    return (
        <div className="flex flex-col gap-2 overflow-auto p-1">
            <AnimatedDataTable style={spring} table={table} hideableColumns={hideableColumns} filters={<Filters table={table} />} />
        </div>
    );
}

export function Violations(): JSX.Element {
    return (
        <Section>
            <TitleGroup title="Violations" description="All violations within the report range." />

            <Table />
        </Section>
    );
}
