import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import type { MotorCarrier } from "../../../MotorCarrier";
import type { Violation } from "../../../Violation/Violation";
import { Violations } from "../../Violations";
import type { Breakdown } from "../Breakdown";
import { BASIC } from "./BASIC";

export class UnsafeDriving extends BASIC<UnsafeDriving.GroupNames, UnsafeDriving.SubGroupNames> {
    constructor(breakdown: Breakdown, carrier: MotorCarrier, date: DateTime, unsafeDrivingViolations: Violations) {
        if (!Array.from(unsafeDrivingViolations).every((viol) => viol.basic === FMCSA.BasicName.UNSAFE_DRIVING)) {
            throw new Error("Not all violations are Unsafe Driving violations");
        }

        const violations: Record<string, Violation[]> = {
            speeding2: [],
            speeding3: [],
            speeding4: [],
            speedingRelated: [],
            dangerousDriving: [],
            recklessDriving: [],
            texting: [],
            phoneCall: [],
            seatBelt: [],
            hmRelated: [],
            other: [],
        };

        Array.from(unsafeDrivingViolations).forEach((violation) => {
            const description = violation.get("GroupDescription");

            switch (description) {
                case "Speeding 2":
                    violations.speeding2.push(violation);
                    break;
                case "Speeding 3":
                    violations.speeding3.push(violation);
                    break;
                case "Speeding 4":
                    violations.speeding4.push(violation);
                    break;
                case "Speeding Related":
                    violations.speedingRelated.push(violation);
                    break;
                case "Dangerous Driving":
                    violations.dangerousDriving.push(violation);
                    break;
                case "Reckless Driving":
                    violations.dangerousDriving.push(violation);
                    break;
                case "Texting":
                    violations.texting.push(violation);
                    break;
                case "Phone Call":
                    violations.phoneCall.push(violation);
                    break;
                case "Seat Belt":
                    violations.seatBelt.push(violation);
                    break;
                case "HM Related":
                    violations.hmRelated.push(violation);
                    break;
                default:
                    violations.other.push(violation);
                    break;
            }
        });

        super(breakdown, carrier, date, unsafeDrivingViolations, [
            {
                name: "Speeding",
                subGroups: [
                    { name: "Speed (6 - 10)", violations: Violations.of(carrier, violations.speeding2) },
                    { name: "Speed (11 - 14)", violations: Violations.of(carrier, violations.speeding3) },
                    { name: "Speed (15+/Work Zn)", violations: Violations.of(carrier, violations.speeding4) },
                    { name: "Other", violations: Violations.of(carrier, violations.speedingRelated) },
                ],
            },
            {
                name: "Dangerous",
                subGroups: [
                    { name: "Dangerous Driving", violations: Violations.of(carrier, violations.dangerousDriving) },
                    { name: "Reckless Driving", violations: Violations.of(carrier, violations.recklessDriving) },
                ],
            },
            {
                name: "Driver Activity",
                subGroups: [
                    { name: "Phone Call", violations: Violations.of(carrier, violations.phoneCall) },
                    { name: "Seat Belt", violations: Violations.of(carrier, violations.seatBelt) },
                    { name: "Texting", violations: Violations.of(carrier, violations.texting) },
                ],
            },
            {
                name: "Other",
                subGroups: [
                    { name: "HM Related", violations: Violations.of(carrier, violations.hmRelated) },
                    { name: "Other", violations: Violations.of(carrier, violations.other) },
                ],
            },
        ]);
    }

    // ========================================================================
    get(groupName: "Speeding"): Breakdown.Group<UnsafeDriving.GroupNames, UnsafeDriving.SubGroupNames.Speeding>;
    get(groupName: "Dangerous"): Breakdown.Group<UnsafeDriving.GroupNames, UnsafeDriving.SubGroupNames.Dangerous>;
    get(groupName: "Driver Activity"): Breakdown.Group<UnsafeDriving.GroupNames, UnsafeDriving.SubGroupNames.DriverActivity>;
    get(groupName: "Other"): Breakdown.Group<UnsafeDriving.GroupNames, UnsafeDriving.SubGroupNames.Other>;
    get(groupName: UnsafeDriving.GroupNames): Breakdown.Group<UnsafeDriving.GroupNames, UnsafeDriving.SubGroupNames> {
        return super.get(groupName);
    }

    // ========================================================================
    getHistory(): UnsafeDriving[] {
        return this.breakdown.getHistory().unsafeDriving;
    }
}

export namespace UnsafeDriving {
    export type GroupNames = "Speeding" | "Dangerous" | "Driver Activity" | "Other";

    export namespace SubGroupNames {
        export type Speeding = "Speed (6 - 10)" | "Speed (11 - 14)" | "Speed (15+/Work Zn)" | "Other";

        export type Dangerous = "Dangerous Driving" | "Reckless Driving";

        export type DriverActivity = "Texting" | "Phone Call" | "Seat Belt";

        export type Other = "HM Related" | "Other";
    }

    export type SubGroupNames = SubGroupNames.Speeding | SubGroupNames.Dangerous | SubGroupNames.DriverActivity | SubGroupNames.Other;
}
