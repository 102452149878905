import { MonthlyReportHelper } from "@deathstar/reuse";
import {
    Coverage,
    CoverageOption,
    Driver,
    MonthlyReportData,
    MonthlyReportDataUnit,
    PolicyAdditionalInterest,
    Tractor,
    Trailer,
} from "@deathstar/types/northstar";
import { DriverRow, EquipmentRow } from "@deathstar/types/waypoint";
import { ActionButton, classNames, InputField, Popover, Tabs, TabsProps } from "@deathstar/ui";
import {
    ArrowTurnDownRightIcon,
    BuildingLibraryIcon,
    EllipsisVerticalIcon,
    MagnifyingGlassCircleIcon,
    MinusCircleIcon,
    PencilSquareIcon,
    PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "@material-ui/core";
import { matchSorter, rankings } from "match-sorter";
import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { ChangeDialog as DriverChangeDialog, RemovalDialog as DriverRemovalDialog } from "../../drivers/DriverColumns";
import { NewDriverDialog } from "../../drivers/NewDriverDialog";
import EquipmentColumns from "../EquipmentColumns";
import { NewUnitDialog } from "../NewUnitDialog";

export function MonthlyReportUnitsTable({ data, isFinalized }: { data: MonthlyReportData; isFinalized: boolean }) {
    const hasOCAC = data.endLayer.policy.coverages.some((cov) => cov.coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT);
    const [typeSelected, setTypeSelected] = useState<"tractors" | "trailers" | "drivers">(() =>
        data.equipment.tractors.length > 0 ? "tractors" : data.equipment.trailers.length > 0 ? "trailers" : hasOCAC ? "drivers" : "tractors"
    );
    const units = MonthlyReportHelper.getUnitsForCoverage(data, typeSelected, undefined);
    const [adding, setAdding] = useState(false);
    const [search, setSearch] = useState("");

    const filtered = useMemo(
        () =>
            search
                ? matchSorter(units, search, {
                      keys: ["vin", "make", (x) => (x as MonthlyReportDataUnit<Tractor>).type?.name, "name", "license"],
                      threshold: rankings.CONTAINS,
                  })
                : units,
        [units, search]
    );

    return (
        <div className="grow space-y-4 overflow-x-auto rounded border border-stone-300 p-2">
            <div className="sticky left-0 flex items-start justify-between gap-4">
                <div className="flex items-center gap-4">
                    <Tabs<"tractors" | "trailers" | "drivers">
                        tabs={
                            [
                                data.equipment.tractors.length > 0 && {
                                    label: () => (
                                        <span className="rounded px-2 py-1 transition-colors hover:bg-black/5">
                                            Tractors ({data.equipment.activeTractors.length})
                                        </span>
                                    ),
                                    value: "tractors",
                                },
                                data.equipment.trailers.length > 0 && {
                                    label: () => (
                                        <span className="rounded px-2 py-1 transition-colors hover:bg-black/5">
                                            Trailers ({data.equipment.activeTrailers.length})
                                        </span>
                                    ),
                                    value: "trailers",
                                },
                                hasOCAC && {
                                    label: () => (
                                        <span className="rounded px-2 py-1 transition-colors hover:bg-black/5">
                                            Drivers ({data.equipment.activeDrivers.length})
                                        </span>
                                    ),
                                    value: "drivers",
                                },
                            ].filter(Boolean) as TabsProps<"tractors" | "trailers" | "drivers">["tabs"]
                        }
                        value={typeSelected}
                        onChange={(value) => {
                            setTypeSelected(value);
                        }}
                        className="rounded-lg bg-stone-100/60 px-2 py-1 font-light"
                    />
                    <p className="font-light text-waypoint-blue-dark">
                        {hasOCAC ? "Driver" : "Equipment"} list for{" "}
                        {moment()
                            .month(data.month - 1)
                            .year(data.year)
                            .format("MMMM YYYY")}
                    </p>
                </div>
                <ActionButton onClick={() => setAdding(true)}>
                    <PlusCircleIcon className="h-4 w-4 shrink-0" />
                    <span>{typeSelected === "tractors" ? "New tractor" : typeSelected === "trailers" ? "New trailer" : "New driver"}</span>
                </ActionButton>
            </div>
            <div className="flex justify-end">
                <InputField
                    classes={{ inputContainer: "py-0.5", root: "w-40 text-sm" }}
                    startAdornment={<MagnifyingGlassCircleIcon className="h-4 w-4 text-stone-400" />}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <table id="unit-table" className="w-full text-sm col-spacing-2 row-p-2 row-spacing-2">
                <thead>
                    <tr className="text-left text-xs text-stone-600 small-caps [&>th]:font-normal">
                        <th></th>
                        {typeSelected === "tractors" || typeSelected === "trailers" ? (
                            <>
                                <th>Year</th>
                                <th>Make</th>
                                <th>Type</th>
                                <th>VIN</th>
                                <th className="text-right">Value</th>
                                <th>Coverages</th>
                                <Tooltip title="Additional Interests">
                                    <th>AI</th>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <th>Name</th>
                                <th>License</th>
                                <th className="text-right">DOB</th>
                                <th>Coverages</th>
                            </>
                        )}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {typeSelected === "tractors"
                        ? (filtered as MonthlyReportDataUnit<Tractor | Trailer>[]).map((unit) => (
                              <TractorTrailerTableRow
                                  key={unit.id}
                                  unit={unit}
                                  isFinalized={isFinalized}
                                  type={typeSelected}
                                  coverages={data.endLayer.policy.coverages.filter((c) =>
                                      c.linkedTractors?.some((t) => t.tractorId === unit.id)
                                  )}
                                  additionalInterests={data.endLayer.policy.additionalInterests.filter((ai) =>
                                      ai.linkedTractors?.some((lt) => lt.tractorId === unit.id)
                                  )}
                              />
                          ))
                        : typeSelected === "trailers"
                        ? (filtered as MonthlyReportDataUnit<Tractor | Trailer>[]).map((unit) => (
                              <TractorTrailerTableRow
                                  key={unit.id}
                                  unit={unit}
                                  isFinalized={isFinalized}
                                  type={typeSelected}
                                  coverages={data.endLayer.policy.coverages.filter((c) =>
                                      c.linkedTrailers?.some((t) => t.trailerId === unit.id)
                                  )}
                                  additionalInterests={data.endLayer.policy.additionalInterests.filter((ai) =>
                                      ai.linkedTrailers?.some((lt) => lt.trailerId === unit.id)
                                  )}
                              />
                          ))
                        : (filtered as MonthlyReportDataUnit<Driver>[]).map((driver) => (
                              <DriverTableRow
                                  key={driver.id}
                                  unit={driver}
                                  isFinalized={isFinalized}
                                  coverages={data.endLayer.policy.coverages.filter((c) =>
                                      c.linkedDrivers?.some((d) => d.driverId === driver.id)
                                  )}
                              />
                          ))}
                </tbody>
            </table>
            {(typeSelected === "tractors" || typeSelected === "trailers") && (
                <NewUnitDialog
                    open={adding}
                    onClose={() => setAdding(false)}
                    unitType={typeSelected}
                    ownerOperator={data.endLayer.policy.coverages.some(
                        (c) => c.coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT
                    )}
                />
            )}

            {typeSelected === "drivers" && (
                <NewDriverDialog
                    open={adding}
                    onClose={() => setAdding(false)}
                    ownerOperator={data.endLayer.policy.coverages.some(
                        (c) => c.coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT
                    )}
                />
            )}
        </div>
    );
}

function TractorTrailerTableRow({
    unit,
    isFinalized,
    type,
    coverages,
    additionalInterests,
}: {
    unit: MonthlyReportDataUnit<Tractor | Trailer>;
    isFinalized: boolean;
    type: "tractors" | "trailers";
    coverages: Coverage[];
    additionalInterests: PolicyAdditionalInterest[];
}) {
    const isAdded = unit.isNewToPolicy;
    const isRemoved = unit.isRemovedFromPolicy;
    const isAddedAndRemoved = isAdded && isRemoved;
    const changes = unit.changes;

    return (
        <Fragment key={unit.id}>
            <tr
                className={classNames(
                    isAddedAndRemoved
                        ? "bg-yellow-400/10"
                        : isAdded
                        ? "bg-emerald-400/10"
                        : isRemoved
                        ? "bg-red-400/10"
                        : changes.length > 0
                        ? "bg-yellow-400/10"
                        : ""
                )}
            >
                <td className="whitespace-nowrap text-center">{changes.length > 0 && <ChangeListTooltip unit={unit} />}</td>
                <td className="whitespace-nowrap">{unit.year}</td>
                <td className="whitespace-nowrap">{unit.make}</td>
                <td className="whitespace-nowrap capitalize">{unit.type?.name}</td>
                <td className="whitespace-nowrap tabular-nums">
                    <Tooltip title={unit.vin || ""} className="uppercase">
                        <span className="uppercase">{unit.vin?.slice(-4)}</span>
                    </Tooltip>
                </td>
                <td className="whitespace-nowrap text-right tabular-nums">
                    {unit.isACV
                        ? "ACV"
                        : unit.statedValue?.toLocaleString("en-us", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                          })}
                </td>
                <td>{coverages.map((c) => CoverageOption.getMetadata(c.coverageOptionId).defaultAbbreviation).join(", ")}</td>
                <td>
                    {additionalInterests.length ? (
                        <Tooltip
                            title={
                                <ul>
                                    {additionalInterests.map((ai) => (
                                        <li key={ai.id}>
                                            {ai.additionalInterest?.name || "[Unknown]"} -{" "}
                                            {[
                                                ai.isAdditionalInsured && "Additional Insured",
                                                ai.isLossPayee && "Loss Payee",
                                                ai.isMortgagee && "Mortgagee",
                                                ai.isPrimaryAndNonContributory && "Primary and Non-Contributory",
                                                ai.isWaiverOfSubrogation && "Waiver of Subrogation",
                                            ]
                                                .filter(Boolean)
                                                .join(", ")}
                                        </li>
                                    ))}
                                </ul>
                            }
                        >
                            <BuildingLibraryIcon className="h-4 w-4" />
                        </Tooltip>
                    ) : null}
                </td>
                <td>
                    <TractorTrailerActions unit={unit} type={type} />
                </td>
            </tr>
            {!isFinalized && unit.requestComment && <CommentRow unit={unit} />}
        </Fragment>
    );
}

function DriverTableRow({
    unit,
    isFinalized,
    coverages,
}: {
    unit: MonthlyReportDataUnit<Driver>;
    isFinalized: boolean;
    coverages: Coverage[];
}) {
    const isAdded = unit.isNewToPolicy;
    const isRemoved = unit.isRemovedFromPolicy;
    const isAddedAndRemoved = isAdded && isRemoved;
    const changes = unit.changes;

    return (
        <Fragment key={unit.id}>
            <tr
                className={classNames(
                    isAddedAndRemoved
                        ? "bg-yellow-400/5"
                        : isAdded
                        ? "bg-emerald-400/5"
                        : isRemoved
                        ? "bg-red-400/5"
                        : changes.length > 0
                        ? "bg-yellow-400/5"
                        : ""
                )}
            >
                <td>{changes.length > 0 && <ChangeListTooltip unit={unit} />}</td>
                <td>{unit.name}</td>
                <td>{unit.license}</td>
                <td className="text-right tabular-nums">{unit.dob ? moment(unit.dob).format("MM/DD/YYYY") : ""}</td>
                <td>{coverages.map((c) => CoverageOption.getMetadata(c.coverageOptionId).defaultAbbreviation).join(", ")}</td>
                <td>
                    <DriverActions unit={unit} />
                </td>
            </tr>
            {!isFinalized && unit.requestComment && <CommentRow unit={unit} />}
        </Fragment>
    );
}

function CommentRow({ unit }: { unit: MonthlyReportDataUnit<Tractor | Trailer | Driver> }) {
    return (
        <tr>
            <td>
                <div className="flex justify-center">
                    <ArrowTurnDownRightIcon className="h-4 w-4 text-stone-500" />
                </div>
            </td>
            <td colSpan={5}>
                <span className="mr-2 whitespace-nowrap rounded-full bg-yellow-50 px-2 text-xs text-yellow-700 small-caps">
                    Pending change:
                </span>
                <span className="text-xs italic">{unit.requestComment}</span>
            </td>
        </tr>
    );
}

function ChangeListTooltip({ unit }: { unit: MonthlyReportDataUnit<Tractor | Trailer | Driver> }) {
    const isAdded = unit.isNewToPolicy;
    const isRemoved = unit.isRemovedFromPolicy;
    const isAddedAndRemoved = isAdded && isRemoved;
    return (
        <Tooltip
            placement="right-start"
            classes={{
                tooltip: "bg-white rounded text-black border border-stone-100 p-3 shadow-lg !max-w-none font-normal",
            }}
            title={
                <table className="col-spacing-2">
                    <thead>
                        <tr>
                            <th className="text-left font-normal text-stone-500">Date</th>
                            <th className="text-left font-normal text-stone-500">Coverage</th>
                            <th className="text-left font-normal text-stone-500">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {unit.changes
                            .flatMap((change) =>
                                Array.from(change.changesByCoverage.entries()).flatMap(([coverageOptionId, c]) =>
                                    c.changes.map((ch) => [change.date, coverageOptionId, ch] as const)
                                )
                            )
                            .sort((a, b) => b[0].getTime() - a[0].getTime())
                            .map(([date, coverageOptionId, change], i) => (
                                <tr key={i}>
                                    <td className="text-left">{moment(date, "YYYY-MM-DD").format("MM/DD/YY")}</td>
                                    <td>{CoverageOption.getMetadata(coverageOptionId).defaultAbbreviation}</td>
                                    <td className="text-left">{change.description}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            }
        >
            {isAddedAndRemoved ? (
                <span className="cursor-default text-yellow-600">△</span>
            ) : isAdded ? (
                <span className="cursor-default text-emerald-600">+</span>
            ) : isRemoved ? (
                <span className="cursor-default text-red-600">-</span>
            ) : unit.changes.length > 0 ? (
                <span className="cursor-default text-yellow-600">△</span>
            ) : (
                <span></span>
            )}
        </Tooltip>
    );
}

function TractorTrailerActions({ unit, type }: { unit: EquipmentRow<Tractor | Trailer>; type: "tractors" | "trailers" }) {
    const [changeDialogOpen, setChangeDialogOpen] = useState(false);
    const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

    return (
        <>
            <Popover>
                <Popover.Content>
                    <Popover.Item
                        onClick={() => {
                            setChangeDialogOpen(true);
                        }}
                        disabled={unit.requiresApproval}
                    >
                        <PencilSquareIcon className="h-5 w-5" />
                        <span>Edit{unit.requiresApproval ? " (pending)" : ""}</span>
                    </Popover.Item>
                    <Popover.Item
                        disabled={unit.requiresApproval}
                        onClick={() => {
                            setRemovalDialogOpen(true);
                        }}
                    >
                        <MinusCircleIcon className="h-5 w-5" />
                        <span>Remove</span>
                    </Popover.Item>
                </Popover.Content>
                <Popover.Button className="rounded p-1 hover:bg-stone-100">
                    <EllipsisVerticalIcon className="h-5 w-5" />
                </Popover.Button>
            </Popover>
            <EquipmentColumns.ChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={unit} type={type} />
            <EquipmentColumns.RemovalDialog open={removalDialogOpen} onClose={() => setRemovalDialogOpen(false)} row={unit} type={type} />
        </>
    );
}

function DriverActions({ unit }: { unit: DriverRow }) {
    const [changeDialogOpen, setChangeDialogOpen] = useState(false);
    const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

    return (
        <>
            <Popover>
                <Popover.Content>
                    <Popover.Item
                        onClick={() => {
                            setChangeDialogOpen(true);
                        }}
                        disabled={unit.requiresApproval}
                    >
                        <PencilSquareIcon className="h-5 w-5" />
                        <span>Edit{unit.requiresApproval ? " (pending)" : ""}</span>
                    </Popover.Item>
                    <Popover.Item
                        disabled={unit.requiresApproval}
                        onClick={() => {
                            setRemovalDialogOpen(true);
                        }}
                    >
                        <MinusCircleIcon className="h-5 w-5" />
                        <span>Remove</span>
                    </Popover.Item>
                </Popover.Content>
                <Popover.Button className="rounded p-1 hover:bg-stone-100">
                    <EllipsisVerticalIcon className="h-5 w-5" />
                </Popover.Button>
            </Popover>
            <DriverChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={unit} />
            <DriverRemovalDialog open={removalDialogOpen} onClose={() => setRemovalDialogOpen(false)} row={unit} />
        </>
    );
}
