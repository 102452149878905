enum PermissionId {
    MEMBERS_READ = "read:members",
    MEMBERS_CREATE = "create:members",
    MEMBERS_DELETE = "delete:members",
    PERMISSIONS_UPDATE = "update:permissions",
    POLICIES_READ = "read:policies",
    CERTIFICATES_READ = "read:certificates",
    CERTIFICATES_SEND = "send:certificates",
    DRIVERS_READ = "read:drivers",
    DRIVERS_MANAGE = "manage:drivers",
    EQUIPMENT_READ = "read:equipment",
    EQUIPMENT_MANAGE = "manage:equipment",
    BILLING_MANAGE = "manage:billing",
    QUESTIONS_READ = "read:questions",
    QUESTIONS_CREATE = "create:questions",
    FILES_READ = "read:files",
    FILES_MANAGE = "manage:files",
}

const Roles = Object.freeze({
    Admin: Object.freeze(Object.values(PermissionId)),
    ReadonlyAdmin: Object.freeze([
        PermissionId.MEMBERS_READ,
        PermissionId.POLICIES_READ,
        PermissionId.CERTIFICATES_READ,
        PermissionId.DRIVERS_READ,
        PermissionId.EQUIPMENT_READ,
        PermissionId.QUESTIONS_READ,
        PermissionId.FILES_READ,
        PermissionId.BILLING_MANAGE,
        PermissionId.MEMBERS_READ,
    ]),
    InsuranceContact: Object.freeze([
        PermissionId.CERTIFICATES_READ,
        PermissionId.CERTIFICATES_SEND,
        PermissionId.DRIVERS_READ,
        PermissionId.EQUIPMENT_READ,
        PermissionId.QUESTIONS_READ,
        PermissionId.FILES_READ,
    ]),
    AskAnything: Object.freeze([
        PermissionId.BILLING_MANAGE,
        PermissionId.QUESTIONS_CREATE,
        PermissionId.QUESTIONS_READ,
        PermissionId.FILES_READ,
        PermissionId.FILES_MANAGE,
    ]),
    Default: Object.freeze([
        PermissionId.CERTIFICATES_READ,
        PermissionId.DRIVERS_READ,
        PermissionId.EQUIPMENT_READ,
        PermissionId.QUESTIONS_READ,
        PermissionId.FILES_READ,
    ]),
});

const OOProgramPermissions = Object.freeze([
    PermissionId.DRIVERS_MANAGE,
    PermissionId.EQUIPMENT_MANAGE,
    PermissionId.DRIVERS_READ,
    PermissionId.EQUIPMENT_READ,
    PermissionId.CERTIFICATES_READ,
    PermissionId.CERTIFICATES_SEND,
    PermissionId.POLICIES_READ,
]);

export class Permission {
    static Roles = Roles;
    static OOProgramPermissions = OOProgramPermissions;
    static Id = PermissionId;

    id: PermissionId;
    displayName: string;
}
