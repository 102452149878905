import { Account } from "@deathstar/types/northstar";
import { Account as SalesforceAccount } from "@deathstar/types/salesforce";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const organization = {
    queryKeys: {
        getById: (accountId: string) => ["organization", accountId] as QueryKey,
        getType: (accountId: string) => ["organization", accountId, "type"] as QueryKey,
    },

    getById: (accountId: string) => {
        return blazar.fetchJson<Account>(`waypoint/orgs/${accountId}`);
    },

    useById: (accountId: string, config?: UseQueryOptions<Account, ResponseError>) =>
        useQuery(organization.queryKeys.getById(accountId), () => organization.getById(accountId), {
            staleTime: 30_000,
            ...config,
        }),

    getType: (accountId: string) => {
        return blazar.fetchJson<{ type: SalesforceAccount["RecordTypeId"] }>(`waypoint/orgs/${accountId}/type`);
    },

    useType: (accountId: string, config?: UseQueryOptions<{ type: SalesforceAccount["RecordTypeId"] }, ResponseError>) =>
        useQuery(organization.queryKeys.getType(accountId), () => organization.getType(accountId), {
            staleTime: Infinity,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            ...config,
        }),
};
