import { Typography } from "@material-ui/core";
import React from "react";
import { BasicColorSwatch } from "../BasicColorSwatch";
import { ChartContainer } from "../Charts/Container";
import { CurrentScoreChart } from "../Charts/CurrentScore";
import { ColorLegend } from "../ColorLegend";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { Transition } from "../Transition";
import { SummarySection } from "./SummarySection";

export function CurrentScores(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    return (
        <SummarySection leadingChart>
            <Transition.Fade>
                <ChartContainer className="">
                    <CurrentScoreChart motorCarrier={motorCarrier} />
                </ChartContainer>
            </Transition.Fade>

            <div className="ml-16">
                <TitleGroup
                    title={
                        <Typography variant="h4">
                            BASIC Scores Today
                            <span className="align-top text-[20px]">*</span>
                        </Typography>
                    }
                    className="mb-8"
                    description="Calculated based on public census, inspection, and crash data from the previous two years."
                />
                <div className="self-center">
                    <ColorLegend motorCarrier={motorCarrier}>
                        {(legendArr) => {
                            return (
                                <table>
                                    <tbody>
                                        {legendArr.map((legend, i) => (
                                            <tr key={legend.name} className="">
                                                <td>
                                                    <BasicColorSwatch basic={legend.name} />
                                                </td>
                                                <td className="px-4">
                                                    <Transition.Slide
                                                        delay={i * 200}
                                                        SlideProps={{
                                                            direction: "left",
                                                        }}
                                                    >
                                                        <Typography>{legend.name}</Typography>
                                                    </Transition.Slide>
                                                </td>
                                                <td className="text-right">
                                                    <Transition.Fade delay={(i + 1) * 200 + legendArr.length * 200}>
                                                        <Typography>{legend.score.formatted}</Typography>
                                                    </Transition.Fade>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            );
                        }}
                    </ColorLegend>
                </div>
            </div>
        </SummarySection>
    );
}
