import { Doughnut } from "react-chartjs-2";

import { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";

import { config } from "../ChartConfig";
import { EmptyDoughnutChart } from "./EmptyDoughnut";

const TITLE = "New Points";

export function NewPointsChart({ motorCarrier }: { motorCarrier: MotorCarrier }): JSX.Element {
    const { violations } = motorCarrier.inspections.filterNew();

    if (violations.total === 0) {
        return <EmptyDoughnutChart />;
    }
    const { hazmat, controlledSubstances, driverFitness, vehicleMaintenance, hoursOfService, unsafeDriving } = violations.filterByBasics();

    return (
        <Doughnut
            className="h-full w-full"
            data={{
                labels: [
                    FMCSA.BasicName.HAZMAT,
                    FMCSA.BasicName.CONTROLLED_SUBSTANCES,
                    FMCSA.BasicName.DRIVER_FITNESS,
                    FMCSA.BasicName.VEHICLE_MAINTENANCE,
                    FMCSA.BasicName.HOS,
                    FMCSA.BasicName.UNSAFE_DRIVING,
                ],
                datasets: [
                    {
                        label: TITLE,
                        data: [
                            hazmat.getTotalWeight(),
                            controlledSubstances.getTotalWeight(),
                            driverFitness.getTotalWeight(),
                            vehicleMaintenance.getTotalWeight(),
                            hoursOfService.getTotalWeight(),
                            unsafeDriving.getTotalWeight(),
                        ],
                        backgroundColor: [
                            config.colors[FMCSA.BasicName.HAZMAT],
                            config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
                            config.colors[FMCSA.BasicName.DRIVER_FITNESS],
                            config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
                            config.colors[FMCSA.BasicName.HOS],
                            config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
                        ],
                        borderWidth: 0,
                    },
                ],
            }}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: "top",
                    },
                    title: {
                        display: false,
                        text: TITLE,
                    },
                },
            }}
        />
    );
}
