import { FMCSA } from "@deathstar/types";
import { Typography } from "@material-ui/core";
import { HistoricalViolationBreakdownChart } from "../../Charts/HistoricalViolationBreakdownChart";
import { Transition } from "../../Transition";
import { PanelHeader } from "./PanelHeader";
import type { ITopViolationsBreakdownData } from "./TopViolationsBreakdown";

interface IPanelProps {
    data: Record<FMCSA.BasicName, ITopViolationsBreakdownData>;
    basic: FMCSA.BasicName;
}

export function PanelContent({ data, basic }: IPanelProps): JSX.Element {
    const basicData = data[basic];

    return (
        <>
            <PanelHeader basic={basic} basicData={basicData} />

            <div className="flex w-full flex-1 flex-col">
                <div className="grid grid-cols-4 gap-4">
                    {basicData.groups.map((grp) => {
                        return (
                            <div
                                key={grp.name}
                                className="grid w-full grid-cols-[1fr_30px] items-end overflow-hidden rounded-lg border border-2 border-gray-300"
                            >
                                <div className="grid grid-rows-[min-content_10ch_minmax(150px,1fr)] gap-2 p-4">
                                    <div className="flex justify-between border-b-2 border-gray-300 pb-2">
                                        <Typography>{grp.name}</Typography>
                                        <Typography>{grp.score}%</Typography>
                                    </div>
                                    <ul>
                                        {grp.subGroups.map((subGrp) => {
                                            return (
                                                <div key={subGrp.name} className="flex justify-between">
                                                    <Typography>{subGrp.name}</Typography>
                                                    <Typography>{subGrp.score}%</Typography>
                                                </div>
                                            );
                                        })}
                                    </ul>
                                    <div>
                                        <HistoricalViolationBreakdownChart data={grp.historical} color={grp.color} />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        height: `${grp.score}%`,
                                    }}
                                >
                                    <Transition.Grow
                                        className="h-full"
                                        GrowProps={{
                                            style: {
                                                transformOrigin: "bottom",
                                                transitionDuration: "750ms",
                                            },
                                            timeout: 750,
                                        }}
                                    >
                                        <div
                                            className="h-full w-full"
                                            style={{
                                                backgroundColor: grp.color,
                                            }}
                                        ></div>
                                    </Transition.Grow>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
