import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { UnauthorizedError } from "../../../api/util/exceptions";
import Unauthorized from "../../../components/error-screens/Unauthorized";
import UncaughtException from "../../../components/error-screens/UncaughtException";
import Loader from "../../../components/loader/Loader";
import PricingTable from "./pricing-table/PricingTable";

export default function SubscriptionSettings() {
    const accountId = useAccountId();

    const { data: profile, error: profileError } = api.billing.useProfile(accountId!);
    const { data: billingPlans, error: billingPlansError } = api.billing.useBillingPlans(accountId!);
    const { data: license, isLoading: isLoadingLicense, error: licenseError } = api.billing.useLicense(accountId!);

    if (billingPlansError) {
        if (billingPlansError instanceof UnauthorizedError) {
            return <Unauthorized />;
        }

        return <UncaughtException />;
    }

    if (licenseError) {
        if (licenseError instanceof UnauthorizedError) {
            return <Unauthorized />;
        }

        return <UncaughtException />;
    }

    if (profileError) {
        if (profileError instanceof UnauthorizedError) {
            return <Unauthorized />;
        }

        return <UncaughtException />;
    }
    return (
        <div>
            <div className="space-y-4">
                {!profile || isLoadingLicense || !billingPlans ? (
                    <Loader />
                ) : (
                    <div className="py-8">
                        <PricingTable profile={profile} plans={billingPlans} license={license} />
                    </div>
                )}
            </div>
        </div>
    );
}
