import { AutoIdAccountOption } from "@deathstar/types/northstar";
import { Popover, useActionState, useSnackbar } from "@deathstar/ui";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { capitalize } from "lodash";
import moment from "moment";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { useAccountId } from "../../api/useAccountId";
import { blazar } from "../../api/util/blazar";
import { ThrottleError } from "../../api/util/exceptions";
import { ActionButton } from "../../components/action-button/ActionButton";
import NavigationTabs from "../../components/navigation-tabs/NavigationTabs";
import Title from "../../components/title/Title";

export default function EquipmentHeader() {
    const pages = ["tractors", "trailers", "autos", "tools"];

    const accountId = useAccountId();
    const { data } = useQuery(["auto-id-options", accountId], () =>
        blazar.fetchJson<AutoIdAccountOption[]>(`waypoint/orgs/${accountId}/auto-id/options`)
    );

    const [getAutoIDs, state] = useActionState(
        useCallback(
            async (option: AutoIdAccountOption) => {
                const response = await blazar.fetch(`waypoint/orgs/${accountId}/auto-id/policy`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        policyId: option.id,
                        policyCreatedDate: option.createdDate,
                    }),
                });
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `Auto IDs.zip`;
                a.click();
            },
            [accountId]
        ),
        {
            onError(e) {
                console.error(e);
                if (e instanceof ThrottleError) {
                    useSnackbar.add(
                        `You have reached the maximum number of Auto ID downloads. Please try again in ${e.retryAfter} seconds.`,
                        {
                            variant: "error",
                            autoHideDuration: 10000,
                        }
                    );
                } else {
                    useSnackbar.add("Could not get Auto IDs. Please try again later.", { variant: "error" });
                }
            },
        }
    );

    return (
        <div className="mb-2">
            <div className="mb-2 flex items-start justify-between">
                <Title>Equipment</Title>

                <div className="flex items-center gap-4">
                    <Popover>
                        <Popover.Button as={ActionButton}>
                            <DocumentTextIcon className="h-4 w-4 shrink-0" />
                            <span>Generate Auto IDs</span>
                        </Popover.Button>
                        <Popover.Content>
                            <p className="text-sm text-stone-600">Generate Auto IDs for:</p>
                            {data?.map((option) => (
                                <Popover.Item
                                    key={option.id}
                                    onClick={() => {
                                        getAutoIDs(option);
                                    }}
                                    disabled={state.isDisabled}
                                >
                                    <span>{option.coverages}</span>
                                    <span className="tabular-nums">{moment.utc(option.effectiveDate).format("MM/DD/YY")}</span>
                                </Popover.Item>
                            ))}
                            {state.isLoading ? (
                                <div className="absolute inset-0 flex items-center justify-center rounded bg-black/10">
                                    <CircularProgress size="2rem" />
                                </div>
                            ) : null}
                        </Popover.Content>
                    </Popover>
                </div>
            </div>

            <NavigationTabs
                tabs={pages.map((page) => ({
                    to: page,
                    label: capitalize(page),
                }))}
            />
        </div>
    );
}
