import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/reuse";
import { NationalAverageOutOfServiceRate } from "@deathstar/types/fmcsa";
import { useQuery, UseQueryResult } from "react-query";
import { blazar } from "../util/blazar";

export const alphaCentaurus = {
    async findLatestSmsResultsDate(): Promise<DateTime> {
        const result: { date: string } = await blazar.fetchJson(`/alpha-centaurus/latestSmsResultsDate`);
        return DateTime.fromString(result.date, "DD-MMM-YYYY");
    },

    async findLatestSnapshotDate(): Promise<DateTime> {
        const result: { date: string } = await blazar.fetchJson(`/alpha-centaurus/latestSnapshotDate`);
        return DateTime.fromString(result.date, "DD-MMM-YYYY");
    },

    async findSnapshotDateHistorical(): Promise<MotorCarrier.IFetcher.fetchSnapshotDateHistorical.Return[]> {
        return blazar.fetchJson(`/alpha-centaurus/snapshotDateHistorical`, {
            method: "GET",
        });
    },

    useFindSnapshotDateHistorical(): UseQueryResult<MotorCarrier.IFetcher.fetchSnapshotDateHistorical.Return[], Error> {
        return useQuery<MotorCarrier.IFetcher.fetchSnapshotDateHistorical.Return[], Error>(
            ["alpha-centaurus", "findSnapshotDateHistorical"],
            () => this.findSnapshotDateHistorical()
        );
    },

    useFindLatestSmsResultsDate(): UseQueryResult<DateTime, Error> {
        return useQuery<DateTime, Error>(["alpha-centaurus", "findLatestSmsResultsDate"], () => this.findLatestSmsResultsDate());
    },

    findEnforcementCases({ dot }: { dot: number }): Promise<MotorCarrier.Raw.EnforcementCase[]> {
        return blazar.fetchJson(`/alpha-centaurus/closed-enforcement-cases/${dot}`, {
            method: "GET",
        });
    },

    useFindEnforcementCases(options: { dot: number }): UseQueryResult<MotorCarrier.Raw.EnforcementCase[], Error> {
        return useQuery<MotorCarrier.Raw.EnforcementCase[], Error>(["alpha-centaurus", "findEnforcementCases", options], () =>
            this.findEnforcementCases(options)
        );
    },

    async findScore(options: MotorCarrier.IFetcher.fetchSmsScore.Options): Promise<number> {
        const searchParams = new URLSearchParams();
        searchParams.append("recordDate", new DateTime(options.recordDate).format("YYYY-MM-DD"));
        searchParams.append("safetyEventGroupName", options.safetyEventGroupName);
        searchParams.append("measure", options.measure.toString());
        const result = await blazar.fetch(`/alpha-centaurus/sms-score?${searchParams.toString()}`);
        const rawScore = await result.text();
        return parseInt(rawScore);
    },

    useFindScore(options: MotorCarrier.IFetcher.fetchSmsScore.Options): UseQueryResult<number, Error> {
        return useQuery<number, Error>(["alpha-centaurus", "findScore", options], () => this.findScore(options));
    },

    findSmsData(options: { dot: number; dates: Date[] }): Promise<MotorCarrier.Raw.SmsData[]> {
        return blazar.fetchJson(`/alpha-centaurus/sms-data/${options.dot}`, {
            method: "POST",
            body: JSON.stringify({
                recordDates: options.dates,
            }),
        });
    },

    useFindSmsData(options: { dot: number; dates: Date[] }): UseQueryResult<MotorCarrier.Raw.SmsData[], Error> {
        return useQuery<MotorCarrier.Raw.SmsData[], Error>(["alpha-centaurus", "findSmsData", options], () => this.findSmsData(options));
    },

    findNationalAverageOutOfServiceRates(options: { dates: Date[] }): Promise<NationalAverageOutOfServiceRate[]> {
        return blazar.fetchJson(`/alpha-centaurus/national-average-out-of-service-rates`, {
            method: "POST",
            body: JSON.stringify({
                recordDates: options.dates,
            }),
        });
    },

    useFindNationalAverageOutOfServiceRates(options: { dates: Date[] }): UseQueryResult<NationalAverageOutOfServiceRate[], Error> {
        return useQuery<NationalAverageOutOfServiceRate[], Error>(
            ["alpha-centaurus", "findNationalAverageOutOfServiceRates", options],
            () => this.findNationalAverageOutOfServiceRates(options)
        );
    },

    findUnit(options: { vin: string }): Promise<MotorCarrier.Raw.Unit | undefined> {
        return blazar.fetchJson(`/alpha-centaurus/units/${options.vin}`);
    },

    useFindUnit(options: { vin: string }): UseQueryResult<MotorCarrier.Raw.Unit | undefined, Error> {
        return useQuery<MotorCarrier.Raw.Unit | undefined, Error>(["alpha-centaurus", "findUnit", options], () => this.findUnit(options));
    },

    findUnits(options: { vins: string[] }): Promise<MotorCarrier.Raw.Unit[]> {
        return blazar.fetchJson(`/alpha-centaurus/units`, {
            method: "POST",
            body: JSON.stringify({
                vins: options.vins,
            }),
        });
    },

    useFindUnits(options: { vins: string[] }): UseQueryResult<MotorCarrier.Raw.Unit[], Error> {
        return useQuery<MotorCarrier.Raw.Unit[], Error>(["alpha-centaurus", "findUnits", options], () => this.findUnits(options));
    },

    findCensus({ dot }: { dot: number }): Promise<MotorCarrier.Raw.Census> {
        return blazar.fetchJson(`/alpha-centaurus/census/${dot}`);
    },

    useFindCensus(options: { dot: number }): UseQueryResult<MotorCarrier.Raw.Census, Error> {
        return useQuery<MotorCarrier.Raw.Census, Error>(["alpha-centaurus", "findCensus", options], () => this.findCensus(options));
    },

    findCarrier({ dot }: MotorCarrier.IFetcher.fetchBulk.Options): Promise<MotorCarrier.IFetcher.fetchBulk.Return> {
        return blazar.fetchJson(`/alpha-centaurus/carrier/${dot}`);
    },

    useFindCarrier(options: MotorCarrier.IFetcher.fetchBulk.Options): UseQueryResult<MotorCarrier.IFetcher.fetchBulk.Return, Error> {
        return useQuery<MotorCarrier.IFetcher.fetchBulk.Return, Error>(["alpha-centaurus", "carrier", options], () =>
            this.findCarrier(options)
        );
    },

    findAuthorityHistory({ dot }: { dot: number }): Promise<MotorCarrier.Raw.AuthorityHistory[]> {
        return blazar.fetchJson(`/alpha-centaurus/authority-history/${dot}`);
    },

    useFindAuthorityHistory(options: { dot: number }): UseQueryResult<MotorCarrier.Raw.AuthorityHistory[], Error> {
        return useQuery<MotorCarrier.Raw.AuthorityHistory[], Error>(["alpha-centaurus", "findAuthorityHistory", options], () =>
            this.findAuthorityHistory(options)
        );
    },

    findCensusHistorical({
        dot,
        dateRange,
    }: {
        dot: number;
        dateRange: MotorCarrier.IDateRange;
    }): Promise<MotorCarrier.Raw.CensusHistorical[]> {
        const searchParams = new URLSearchParams();
        searchParams.append("from", dateRange.from.toISOString());
        searchParams.append("to", dateRange.to.toISOString());
        return blazar.fetchJson(`/alpha-centaurus/census-historical/${dot}?${searchParams.toString()}`);
    },

    useFindCensusHistorical(options: {
        dot: number;
        dateRange: MotorCarrier.IDateRange;
    }): UseQueryResult<MotorCarrier.Raw.CensusHistorical[], Error> {
        return useQuery<MotorCarrier.Raw.CensusHistorical[], Error>(["alpha-centaurus", "findCensusHistorical", options], () =>
            this.findCensusHistorical(options)
        );
    },

    findInspections({ dot, dateRange }: { dot: number; dateRange: MotorCarrier.IDateRange }): Promise<MotorCarrier.Raw.Inspection[]> {
        const searchParams = new URLSearchParams();
        searchParams.append("from", dateRange.from.toISOString());
        searchParams.append("to", dateRange.to.toISOString());
        return blazar.fetchJson(`/alpha-centaurus/inspections/${dot}?${searchParams.toString()}`);
    },

    useFindInspections(options: { dot: number; dateRange: MotorCarrier.IDateRange }): UseQueryResult<MotorCarrier.Raw.Inspection[], Error> {
        return useQuery<MotorCarrier.Raw.Inspection[], Error>(["alpha-centaurus", "findInspections", options], () =>
            this.findInspections(options)
        );
    },

    async findViolations(options: MotorCarrier.IFetcher.fetchViolations.Options): Promise<MotorCarrier.Raw.Violation[]> {
        return blazar.fetchJson(`/alpha-centaurus/violations/`, {
            method: "POST",
            body: JSON.stringify(options),
        });
    },

    useFindViolations(options: MotorCarrier.IFetcher.fetchViolations.Options): UseQueryResult<MotorCarrier.Raw.Violation[], Error> {
        return useQuery<MotorCarrier.Raw.Violation[], Error>(["alpha-centaurus", "findViolations", options], () =>
            this.findViolations(options)
        );
    },

    findCrashes({ dot, dateRange }: { dot: number; dateRange: MotorCarrier.IDateRange }): Promise<MotorCarrier.Raw.Crash[]> {
        const searchParams = new URLSearchParams();
        searchParams.append("from", dateRange.from.toISOString());
        searchParams.append("to", dateRange.to.toISOString());
        return blazar.fetchJson(`/alpha-centaurus/crashes/${dot}?${searchParams.toString()}`);
    },

    useFindCrashes(options: { dot: number; dateRange: MotorCarrier.IDateRange }): UseQueryResult<MotorCarrier.Raw.Crash[], Error> {
        return useQuery<MotorCarrier.Raw.Crash[], Error>(["alpha-centaurus", "findCrashes", options], () => this.findCrashes(options));
    },
};
