import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import tw, { styled } from "twin.macro";
import { config } from "../../ChartConfig";
import { Section } from "../../Components/Section";
import { TitleGroup } from "../../Components/TitleGroup";
import { Context } from "../../Context";
import { PanelContent } from "./PanelContent";

export const Panel = styled.div(({ panelId, activePanelId }: { panelId: FMCSA.BasicName; activePanelId: FMCSA.BasicName }) => [
    tw`w-full flex flex-col gap-4 items-start p-4 pl-8`,
    panelId !== activePanelId && tw`hidden`,
]);

function getLabel(basic: FMCSA.BasicName): string {
    switch (basic) {
        case FMCSA.BasicName.CONTROLLED_SUBSTANCES:
            return "Control Subst";
        case FMCSA.BasicName.VEHICLE_MAINTENANCE:
            return "Vehicle Maint";
        default:
            return basic;
    }
}

export function TopViolationsBreakdown(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);
    const basicOrder = React.useMemo(() => config.order.slice(1), []);
    const [activeTab, setActiveTab] = React.useState<FMCSA.BasicName>(basicOrder[0]);

    const data = useTopViolationsBreakdownData(motorCarrier);

    return (
        <Section>
            <TitleGroup
                title="Top Violations Breakdown"
                description="This insight helps identify what behaviors may be contributing to your CSA Violations. Whether it’s Lights, Braking, Speeding or Form and Manner violations, you’ll be able to tailor your safety program to address unique characteristics of your operation."
            />

            <div className="flex gap-8">
                <Paper square elevation={0} className="mt-14 !bg-transparent">
                    <Tabs
                        value={basicOrder.findIndex((i) => i === activeTab)}
                        orientation="vertical"
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(_, value: number) => {
                            setActiveTab(basicOrder[value]);
                        }}
                        aria-label="disabled tabs example"
                    >
                        {Object.values(data)
                            .sort((a, b) => a.sortOrder - b.sortOrder)
                            .map(({ basic }) => (
                                <Tab key={basic} label={getLabel(basic)} />
                            ))}
                    </Tabs>
                </Paper>

                <Panel panelId={FMCSA.BasicName.UNSAFE_DRIVING} activePanelId={activeTab}>
                    <PanelContent data={data} basic={FMCSA.BasicName.UNSAFE_DRIVING} />
                </Panel>

                <Panel panelId={FMCSA.BasicName.HOS} activePanelId={activeTab}>
                    <PanelContent data={data} basic={FMCSA.BasicName.HOS} />
                </Panel>

                <Panel panelId={FMCSA.BasicName.CONTROLLED_SUBSTANCES} activePanelId={activeTab}>
                    <PanelContent data={data} basic={FMCSA.BasicName.CONTROLLED_SUBSTANCES} />
                </Panel>

                <Panel panelId={FMCSA.BasicName.VEHICLE_MAINTENANCE} activePanelId={activeTab}>
                    <PanelContent data={data} basic={FMCSA.BasicName.VEHICLE_MAINTENANCE} />
                </Panel>

                <Panel panelId={FMCSA.BasicName.DRIVER_FITNESS} activePanelId={activeTab}>
                    <PanelContent data={data} basic={FMCSA.BasicName.DRIVER_FITNESS} />
                </Panel>

                <Panel panelId={FMCSA.BasicName.HAZMAT} activePanelId={activeTab}>
                    <PanelContent data={data} basic={FMCSA.BasicName.HAZMAT} />
                </Panel>
            </div>
        </Section>
    );
}

export interface ITopViolationsBreakdownData {
    basic: FMCSA.BasicName;
    color: string;
    score: number;
    totalViolations: number;
    oos: boolean;
    sortOrder: number;
    groups: {
        name: string;
        score: number;
        color: string;
        historical: {
            score: number;
            label: DateTime.MonthAbbreviations;
        }[];
        subGroups: {
            name: string;
            score: number;
        }[];
    }[];
}

function useTopViolationsBreakdownData(carrier: MotorCarrier): Record<FMCSA.BasicName, ITopViolationsBreakdownData> {
    return React.useMemo(() => {
        const violationsByBasic = carrier.violations.filterByBasics();

        const breakdowns = carrier.violations.getBreakdowns({ numberOfHistoricalBreakdowns: 6 }).getLatest();

        return config.violationOrder
            .map((basic) => {
                const smsResult = carrier.smsResults.latest.getBasic(basic);
                return {
                    basic,
                    color: config.colors[basic],
                    groups: breakdowns[basic].getTopFourGroups(),
                    score: smsResult.score ?? 0,
                    totalViolations: violationsByBasic[basic].total,
                    oos: Boolean(smsResult.alert),
                };
            })
            .map((options) => {
                // Generate the colors for each Group in a WeakMap
                const groupColors = ["", "99", "65", "30"].map((offset) => `${options.color}${offset}`);
                const groupColorMap = new WeakMap<MotorCarrier.Violations.IBreakdown.IGroup<string, string>, string>();

                options.groups.forEach((group, idx) => {
                    groupColorMap.set(group, groupColors[idx]);
                });

                return {
                    ...options,
                    groupColorMap,
                };
            })
            .map((options) => {
                // Generate the doughnut chart options
                return {
                    ...options,
                    doughnutOptions: options.groups.map((group) => ({
                        color: options.groupColorMap.get(group)!,
                        score: group.score,
                    })),
                };
            })

            .map((options) => {
                // Transform the BASIC groups array into required interface
                return {
                    ...options,
                    groups: options.groups.map((group) => {
                        return {
                            name: getGroupName(group.name),
                            score: group.score,
                            color: options.groupColorMap.get(group)!,
                            historical: group
                                .getHistory()
                                .map((historical) => ({
                                    score: historical.score,
                                    label: historical.date.getMonthAbbreviation(),
                                }))
                                .reverse(),
                            subGroups: group.subGroups.map((subGroup) => ({
                                name: getSubGroupName(subGroup.name),
                                score: subGroup.score,
                            })),
                        };
                    }),
                };
            })
            .reduce((acc, curr, i) => {
                acc[curr.basic] = {
                    ...curr,
                    sortOrder: i,
                };
                return acc;
            }, {} as Record<FMCSA.BasicName, ITopViolationsBreakdownData>);
    }, [carrier.smsResults.latest, carrier.violations]);
}

// ========================================================================
function getSubGroupName(subGroupName: MotorCarrier.Violations.SubGroupNames): string {
    switch (subGroupName) {
        case "Wheels, Studs, Clamps, Etc.":
            return "Wheels/Studs/Clamps";
        case "License-related: High":
            return "High";
        case "License-related: Medium":
            return "Medium";
        case "License-related: Low":
            return "Low";
        case "Clearance Identification Lamps/Other":
            return "Clearance Lamps/Other";
        case "Brake OOS":
            return "Brakes, OOS";
        case "Brakes Out of Adjustment":
            return "Brakes, Adjustment";
        case "Brakes Other":
            return "Brakes, Other";
        case "Other Log/Form & Manner":
            return "Form & Manner";
        case "Documentation Related":
            return "Documentation";
        case "General Driver Qualification":
            return "General";
        case "Incomplete/Wrong Log":
            return "Incomplete/Wrong";
        case "False Log":
            return "False";
        case "Hours":
            return "Duty Period";
        case "Hours Nominal":
            return "Nominal";
        case "Improper Load Securement":
            return "Load Securement";
        case "Securement Device/General":
            return "Securement Device";
        case "Failure to Prevent Movement":
            return "Movement Prevention";
        case "Windshield, Glass, Markings":
            return "Glass/Markings";
        default:
            return subGroupName;
    }
}

// ========================================================================
function getGroupName(groupName: MotorCarrier.Violations.GroupNames): string {
    switch (groupName) {
        case "Brakes/Steering/Suspension":
            return "Brakes/Steering";
        case "General Driver Qualification":
            return "Qualification";
        case "Preventative Measures":
            return "Preventative";
        case "Hours Related":
            return "Hours";
        case "Log Related":
            return "Log";
        case "Cargo Securement":
            return "Cargo";
        default:
            return groupName;
    }
}
