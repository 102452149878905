import { MonthlyReportHelper } from "@deathstar/reuse";
import { MonthlyReportData } from "@deathstar/types/northstar";
import { ActionButton, classNames, printElement, SecondaryButton } from "@deathstar/ui";
import { ChevronDoubleLeftIcon, PlusCircleIcon, PrinterIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { useMemo, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { NotFoundError } from "../../../api/util/exceptions";
import Loader from "../../../components/loader/Loader";
import { MonthlyReportCalculations } from "./MonthlyReportCalculations";
import { MonthlyReportStatic } from "./MonthlyReportStatic";
import { MonthlyReportUnitsTable } from "./MonthlyReportUnitsTable";

export default function MonthlyReportView() {
    const accountId = useAccountId();
    const { policyId, year: yearStr, month: monthStr } = useParams();
    const month = monthStr ? parseInt(monthStr, 10) : 0;
    const year = yearStr ? parseInt(yearStr, 10) : 0;

    const m = moment()
        .month(month - 1)
        .year(year);

    const { data, isLoading, isFetching } = api.monthlyReports.useFindForMonth(accountId!, policyId!, year, month, {
        enabled: !!policyId,
        staleTime: 60_000,
        retry: (count, e) => (e instanceof NotFoundError ? false : count < 3),
    });

    const monthlyReportData = useMemo<MonthlyReportData | null>(() => {
        if (data && data.layers.length > 0) {
            return MonthlyReportHelper.getMonthlyReportData(data.report, data.layers, data.equipment);
        }
        return null;
    }, [data]);

    const create = api.monthlyReports.useCreate(accountId!, policyId!, year, month);
    const ref = useRef<HTMLDivElement>(null);

    if (isLoading) {
        return <Loader />;
    }

    if (!data) {
        return (
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <Link to="./../..">
                            <ActionButton>
                                <ChevronDoubleLeftIcon className="h-4 w-4" />
                                Back
                            </ActionButton>
                        </Link>
                        <p className="text-lg font-medium">{m.format("MMMM YYYY")}</p>
                    </div>
                </div>
                <div className="flex w-full flex-col items-center gap-8 rounded border p-4">
                    Your report for {m.format("MMMM")} {year} has not been created yet.
                    <SecondaryButton
                        onClick={() => {
                            create.mutate();
                        }}
                        disabled={create.isLoading || (create.isSuccess && isFetching)}
                    >
                        {create.isLoading || (create.isSuccess && isFetching) ? (
                            <CircularProgress classes={{ root: "!text-current !h-4 !w-4" }} />
                        ) : (
                            <PlusCircleIcon className="h-4 w-4" />
                        )}
                        Create {m.format("MMMM")} report
                    </SecondaryButton>
                </div>
            </div>
        );
    }

    if (!monthlyReportData) {
        return <Loader />;
    }

    return (
        <div className="space-y-8">
            <div className="hidden">
                <MonthlyReportStatic report={data.report} data={monthlyReportData} ref={ref} />
            </div>
            <div>
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <Link to="./../..">
                            <ActionButton>
                                <ChevronDoubleLeftIcon className="h-4 w-4" />
                                Back
                            </ActionButton>
                        </Link>
                        <p className="text-lg font-medium">{m.format("MMMM YYYY")}</p>
                    </div>
                    <div className="flex items-center gap-6">
                        <p className="text-sm">Policy #{data.layers[0]?.policy.number}</p>
                        <ActionButton
                            onClick={() => {
                                if (ref.current) {
                                    printElement(ref.current);
                                }
                            }}
                        >
                            <PrinterIcon className="h-4 w-4" />
                            Print
                        </ActionButton>
                    </div>
                </div>
            </div>
            <div className={classNames("flex flex-col gap-4", monthlyReportData.isUnitReporter && "lg:flex-row-reverse")}>
                <MonthlyReportCalculations report={data.report} data={monthlyReportData} />
                {monthlyReportData.isUnitReporter && (
                    <MonthlyReportUnitsTable data={monthlyReportData} isFinalized={data.report.isFinalized} />
                )}
            </div>
        </div>
    );
}
