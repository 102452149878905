import { getSubscriptionTierName, License, PriceWithProduct, ScheduledSubscription, SubscriptionTier } from "@deathstar/types/waypoint";
import { classNames, PrimaryButton, SecondaryButton } from "@deathstar/ui";
import moment from "moment";
import Stripe from "stripe";
import PlanPriceWithDiscount from "./PlanPriceWithDiscount";

export default function PlanHeader({
    plan,
    license,
    price,
    coupon,
    scheduled,
    classes,
    onCancel,
    onSubscribe,
    onCancelScheduled,
    onDowngrade,
}: {
    onCancel(byPassConfirmation?: boolean): Promise<void>;
    onSubscribe(byPassConfirmation?: boolean): Promise<void>;
    onCancelScheduled(): Promise<void>;
    onDowngrade(): void;
    plan: SubscriptionTier;
    license?: License | null;
    price: PriceWithProduct;
    coupon?: Stripe.Coupon | null;
    scheduled?: ScheduledSubscription | null;
    classes: {
        card: string;
        buttonSubscribe: string;
        buttonDowngrade: string;
        buttonUpgrade: string;
        buttonCancel: string;
    };
}) {
    const plans = [SubscriptionTier.Standard, SubscriptionTier.Premium, SubscriptionTier.Enterprise];

    const isDowngrade = license && plans.indexOf(license.plan) > plans.indexOf(plan);
    return (
        <div>
            <p className="h-8 text-center text-xl font-normal text-stone-600 small-caps">
                {license?.plan === plan
                    ? license?.expireDate
                        ? `Active until ${moment(license.expireDate).format("MM/DD/YYYY")}`
                        : "Current plan"
                    : scheduled?.plan === plan
                    ? `Starts on ${moment(scheduled.startDate).format("MM/DD/YYYY")}`
                    : null}
            </p>
            <div className={classNames("flex w-screen max-w-xs flex-col gap-4 rounded-lg p-4 text-left", classes.card)}>
                <span className="text-lg capitalize">{getSubscriptionTierName(plan)}</span>
                <PlanPriceWithDiscount price={price} coupon={coupon} />

                {license?.plan === plan ? (
                    license.expireDate ? (
                        <PrimaryButton
                            onClick={() => {
                                onSubscribe(true);
                            }}
                            className={classes.buttonSubscribe}
                        >
                            Reactivate
                        </PrimaryButton>
                    ) : (
                        <SecondaryButton
                            onClick={() => {
                                onCancel(false);
                            }}
                            className={classes.buttonCancel}
                        >
                            Cancel
                        </SecondaryButton>
                    )
                ) : scheduled?.plan === plan ? (
                    <SecondaryButton
                        onClick={() => {
                            onCancelScheduled();
                        }}
                        className={classes.buttonCancel}
                    >
                        Cancel
                    </SecondaryButton>
                ) : license ? (
                    isDowngrade ? (
                        <SecondaryButton
                            onClick={() => {
                                onDowngrade();
                            }}
                            className={classes.buttonDowngrade}
                        >
                            Downgrade
                        </SecondaryButton>
                    ) : (
                        <PrimaryButton
                            onClick={() => {
                                onSubscribe(false);
                            }}
                            className={classes.buttonUpgrade}
                        >
                            Upgrade
                        </PrimaryButton>
                    )
                ) : (
                    <PrimaryButton
                        onClick={() => {
                            onSubscribe(false);
                        }}
                        className={classes.buttonSubscribe}
                    >
                        Get started
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
}
