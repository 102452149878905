namespace PaymentPlanEnum {
    export enum Id {
        TWO = "08DCB36D-506A-4EB2-A1D5-FEA2915BBB70",
        THREE = "AADF1952-95DC-4A85-A34A-84F921EA9A18",
        FOUR = "3C9F8468-9562-4EDC-B7D2-77AB712624E5",
        FIVE = "F6FB41FD-B95C-4407-9F3A-E1C8471A91D3",
        SIX = "7EAEF48D-D8E6-4A89-B7AE-88C6233C4C66",
        SEVEN = "0D55112C-97CF-4ADC-B999-2BDD2C686A6E",
        EIGHT = "43EA7E0C-6042-4322-8F69-E7EC2ECDD46C",
        NINE = "156C7690-2A18-438C-B93C-37936D43E686",
        TEN = "CE410D29-CCF3-4940-B77F-00E2754DD090",
        ELEVEN = "72C08CEC-862A-42C1-A199-0C369FF711D5",
        BI_MONTHLY = "398C0974-2D06-446F-ADFA-F2865543881D",
        MONTHLY = "3A69F666-75B3-4EBE-A8B0-FEA0A407948C",
        QUARTERLY = "163F6B31-321D-485A-A7BD-6F79601F9F82",
        SEMI_ANNUAL = "E0B3C1C6-3E50-46F2-B708-9F298B48D2CA",
        ANNUAL = "6F1EDA48-AFCF-4832-8507-AC597338F3B6",
        FULL = "1E6D2375-0A94-4332-A47E-2576915D6C6E",
        PREMIUM_FINANCE = "33086019-8EE2-400A-B5E0-E87FA4679444",
        OTHER = "89463594-13DD-4B96-989A-B6E4FFBF9702",
    }
    export enum Type {
        TWO = "Two",
        THREE = "Three",
        FOUR = "Four",
        FIVE = "Five",
        SIX = "Six",
        SEVEN = "Seven",
        EIGHT = "Eight",
        NINE = "Nine",
        TEN = "Ten",
        ELEVEN = "Eleven",
        BI_MONTHLY = "Bi-Monthly",
        MONTHLY = "Monthly",
        QUARTERLY = "Querterly",
        SEMI_ANNUAL = "Semi-Annual",
        ANNUAL = "Annual",
        FULL = "Full",
        PREMIUM_FINANCE = "Premium Finance",
        OTHER = "Other",
    }
}

export class PaymentPlan {
    static Id = PaymentPlanEnum.Id;
    static Type = PaymentPlanEnum.Type;
    static FULL = {
        id: PaymentPlanEnum.Id.FULL,
        type: PaymentPlanEnum.Type.FULL,
        acordCode: "FL",
        description: "Full Pay",
    } as const;

    id: string;
    acordCode: string;
    description: string;
    type: PaymentPlan.Type;
}

export namespace PaymentPlan {
    export type Id = PaymentPlanEnum.Id;
    export type Type = PaymentPlanEnum.Type;
}
