import { CoverageOption, Tractor, Trailer } from "@deathstar/types/northstar";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import Unauthorized from "../../components/error-screens/Unauthorized";
import UncaughtException from "../../components/error-screens/UncaughtException";
import NavigationTabs from "../../components/navigation-tabs/NavigationTabs";
import Title from "../../components/title/Title";
import DriversTable from "../drivers/DriversTable";
import MonthlyReports from "../equipment/monthly-reports/MonthlyReports";
import TractorsTable from "../equipment/TractorsTable";
import TrailersTable from "../equipment/TrailersTable";
import { PolicyWithBillingInfo } from "../policies/policies-list/PoliciesList";
import OOProgram from "./program/OOProgram";

function filterOutNonOCACPolicies(policies: PolicyWithBillingInfo[]): PolicyWithBillingInfo[] {
    return policies.filter((p) => p.coverages.some((c) => c.coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT));
}

export default function OnwerOperators() {
    const accountId = useAccountId();
    const drivers = api.drivers.useFind(accountId!, {
        enabled: !!accountId,
        select: (rows) => rows.filter((row) => row.policyOwnerOperator),
    });
    const tractors = api.equipment.useFind<Tractor>("tractors", accountId!, {
        enabled: !!accountId,
        select: (rows) => rows.filter((row) => row.policyOwnerOperator),
    });
    const trailers = api.equipment.useFind<Trailer>("trailers", accountId!, {
        enabled: !!accountId,
        select: (rows) => rows.filter((row) => row.policyOwnerOperator),
    });

    return (
        <Routes>
            <Route path="/reporting/*" element={<MonthlyReports getFilteredPolicies={filterOutNonOCACPolicies} />} />
            <Route
                element={
                    <div className="p-4 lg:p-12">
                        <div className="mb-2">
                            <div className="mb-2 flex justify-between">
                                <Title>Owner operators</Title>
                            </div>
                        </div>
                        <NavigationTabs
                            tabs={[
                                { label: "Policies", to: "policies" },
                                { label: "Drivers", to: "drivers" },
                                { label: "Tractors", to: "tractors" },
                                { label: "Trailers", to: "trailers" },
                            ]}
                        />
                        <Outlet />
                    </div>
                }
            >
                <Route index element={<Navigate to="policies" />} />
                <Route
                    path="drivers"
                    element={
                        drivers.isError ? (
                            drivers.error.status === 403 ? (
                                <Unauthorized />
                            ) : (
                                <UncaughtException />
                            )
                        ) : (
                            <DriversTable data={drivers.data} ownerOperator />
                        )
                    }
                />
                <Route
                    path="tractors"
                    element={
                        tractors.isError ? (
                            tractors.error.status === 403 ? (
                                <Unauthorized />
                            ) : (
                                <UncaughtException />
                            )
                        ) : (
                            <TractorsTable data={tractors.data} hideMonthlyReport ownerOperators />
                        )
                    }
                />
                <Route
                    path="trailers"
                    element={
                        trailers.isError ? (
                            trailers.error.status === 403 ? (
                                <Unauthorized />
                            ) : (
                                <UncaughtException />
                            )
                        ) : (
                            <TrailersTable data={trailers.data} hideMonthlyReport ownerOperators />
                        )
                    }
                />
                <Route path="policies" element={<OOProgram />} />
            </Route>
        </Routes>
    );
}
