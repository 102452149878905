import { classNames } from "@deathstar/ui";
import { Tooltip } from "@material-ui/core";
import { PriceWithProduct, unitAmountToCurrency } from "./billingUtil";

export default function TieredPrice({ price }: { price: PriceWithProduct }) {
    const tiers = price.tiers;
    if (tiers?.[0].up_to === null) {
        return <p>Unlimited</p>;
    }
    return (
        <Tooltip title={<TieredPriceTooltip price={price} />}>
            <p className={classNames("w-max cursor-pointer underline decoration-dotted")}>
                {!tiers?.[0].flat_amount && !tiers?.[0].unit_amount
                    ? `First ${tiers?.[0].up_to} included`
                    : `${unitAmountToCurrency(tiers?.[0].unit_amount, price.currency)} per {price.product?.unit_label || "unit"} per{" "}
                {price.recurring?.interval}`}
            </p>
        </Tooltip>
    );
}

export function TieredPriceTooltip({ price }: { price: PriceWithProduct }) {
    return (
        <div className="space-y-2">
            {price.tiers?.map((tier, i) => (
                <p key={i}>
                    {i === 0 ? "First " + tier.up_to + " " : tier.up_to ? "Next " + tier.up_to + " " : "Then "}
                    {!tier.flat_amount && !tier.unit_amount ? (
                        "included"
                    ) : (
                        <>
                            {tier.flat_amount !== null ? `${unitAmountToCurrency(tier.flat_amount, price.currency)} + ` : null}
                            {tier.unit_amount !== null
                                ? `${(tier.unit_amount / 100).toLocaleString("en-us", {
                                      style: "currency",
                                      currency: price.currency,
                                      maximumFractionDigits: 0,
                                  })} per ${price.product?.unit_label || "unit"}`
                                : null}
                        </>
                    )}
                </p>
            ))}
        </div>
    );
}
