import React, { forwardRef } from "react";
import tw from "twin.macro";

export const primaryButtonClasses =
    "flex items-center justify-center gap-2 px-4 py-2 text-white bg-blue-700 rounded shadow enabled:hover:brightness-90 enabled:active:brightness-100 active:shadow-none focus:ring-2 ring-blue-700 ring-offset-1 enabled:hover:shadow-lg outline-none disabled:shadow-none disabled:pointer-events-none disabled:opacity-50";

export const PrimaryButton = forwardRef<
    HTMLButtonElement,
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>(({ css, ...props }, ref) => {
    return (
        <button
            {...props}
            css={[
                {
                    transitionProperty: "box-shadow, color, background-color, border-color, text-decoration-color, fill-stroke",
                    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                    transitionDuration: "150ms",
                },
                tw`${primaryButtonClasses}`,
                css,
            ]}
            ref={ref}
        ></button>
    );
});
