import { DriverRow } from "@deathstar/types/waypoint";
import { ActionButton, DataTable, fuzzyFilter } from "@deathstar/ui";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import Loader from "../../components/loader/Loader";
import { columns } from "./DriverColumns";
import { NewDriverDialog } from "./NewDriverDialog";

const AnimatedDataTable = animated(DataTable<DriverRow>);

export default function DriversTable({
    data,
    hideMonthlyReport,
    ownerOperator,
}: {
    data: DriverRow[] | undefined;
    hideMonthlyReport?: boolean;
    ownerOperator?: boolean;
}) {
    const [adding, setAdding] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([]);

    const memoizedData = useMemo(() => data || [], [data]);

    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    const sortingState = useMemo(() => {
        return [{ id: "pending", desc: true }, ...sorting];
    }, [sorting]);

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: fuzzyFilter,
        state: {
            sorting: sortingState,
        },
        initialState: {
            pagination: {
                pageSize: 30,
            },
        },
        onSortingChange: setSorting,
    });

    return (
        <div>
            {data ? (
                <>
                    <AnimatedDataTable
                        style={spring}
                        table={table}
                        actions={[
                            {
                                label: "Add driver",
                                onClick() {
                                    setAdding(true);
                                },
                                icon: <PlusIcon className="h-5 w-5" />,
                            },
                            !hideMonthlyReport && (
                                <Link to="./../reporting">
                                    <ActionButton>Monthly reporting</ActionButton>
                                </Link>
                            ),
                        ]}
                    />
                    <NewDriverDialog open={adding} onClose={() => setAdding(false)} ownerOperator={!!ownerOperator} />
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
