import { Permission, Zendesk } from "@deathstar/types/waypoint";
import { classNames, DataTable, PrimaryButton } from "@deathstar/ui";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-ui/core";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { Dialog } from "../../components/dialog/Dialog";
import dummyRequests from "./dummy-requests.json";
import NewRequestForm from "./NewRequestForm";
import RequestStatus from "./RequestStatus";

const dummyData = dummyRequests.map((req) => {
    const created_at = moment().subtract(3 + Math.random() * 10, "days");
    const updated_at = moment.min(moment(), moment(created_at).add(1 + Math.random() * 10, "days"));
    return {
        ...req,
        created_at: created_at.toISOString(),
        updated_at: updated_at.toISOString(),
    };
});

const columns: ColumnDef<Zendesk.Request>[] = [
    {
        id: "mobile-only",
        cell: (info) => {
            const row = info.row.original;

            return (
                <div className="space-y-2">
                    <p className="line-clamp-1 font-medium">{row.subject || row.description}</p>
                    <div className="flex gap-2">
                        <RequestStatus status={row.status} statusCategory={row.status_category} />
                        <p className="line-clamp-1 text-stone-500">Activity {moment(row.updated_at).fromNow()}</p>
                    </div>
                </div>
            );
        },
        meta: { responsive: true },
    },
    {
        accessorKey: "status",
        cell: (info) => (
            <RequestStatus status={info.getValue() as Zendesk.Request["status"]} statusCategory={info.row.original.status_category} />
        ),
        enableGlobalFilter: false,
        sortingFn: (a, b) => {
            const statusCategories: Zendesk.Request["status_category"][] = ["pending", "new", "open", "hold", "solved"];
            return statusCategories.indexOf(a.original.status_category) - statusCategories.indexOf(b.original.status_category);
        },
    },
    {
        id: "subject",
        accessorFn: (row) => row.subject || row.description,
    },
    {
        accessorKey: "created_at",
        header: "Created",
        cell: (info) => (
            <Tooltip title={moment(info.getValue() as string).format("M/D/YY hh:mm A")}>
                <span>{moment(info.getValue() as string).fromNow()}</span>
            </Tooltip>
        ),
        enableGlobalFilter: false,
    },
    {
        accessorKey: "updated_at",
        header: "Last activity",
        cell: (info) =>
            info.getValue() !== info.row.original.created_at && (
                <Tooltip title={moment(info.getValue() as string).format("M/D/YY hh:mm A")}>
                    <span>{moment(info.getValue() as string).fromNow()}</span>
                </Tooltip>
            ),
        enableGlobalFilter: false,
    },
];

export default function Requests() {
    const accountId = useAccountId();
    const { data: license } = api.billing.useLicense(accountId!);

    const hasAccess = !!license;

    const navigate = useNavigate();
    const { data, isLoading } = api.zendesk.useRequests(accountId!, {
        enabled: hasAccess,
    });

    const [formOpen, setFormOpen] = useState(false);

    const { mutate: createRequest, isLoading: isCreatingRequest } = api.zendesk.useCreateRequest(accountId!, {
        onSuccess: (data) => {
            navigate("./" + data.id);
        },
    });

    const memoizedData = useMemo(() => (hasAccess ? data || [] : dummyData), [data, hasAccess]);

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: DataTable.fuzzyFilter,
        initialState: {
            sorting: [{ id: "updated_at", desc: true }],
        },
    });

    const { data: canCreateQuestions } = api.user.useHasPermission(accountId!, Permission.Id.QUESTIONS_CREATE);

    return (
        <>
            {!hasAccess && (
                <div className="absolute left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-8 rounded-lg bg-white p-8 shadow-lg">
                    <p className="whitespace-nowrap">This is a subscriber-only feature</p>
                    <Link to={`/sol/${accountId}/settings/subscription`}>
                        <PrimaryButton className="whitespace-nowrap">Activate</PrimaryButton>
                    </Link>
                </div>
            )}
            <div className={classNames(!hasAccess && "pointer-events-none blur-[1px]")}>
                <DataTable
                    actions={[
                        {
                            icon: <PlusIcon className="h-4 w-4" />,
                            label: "New question",
                            onClick: () => setFormOpen(true),
                            getIsDisabled: () => !canCreateQuestions,
                        },
                    ]}
                    table={table}
                    onRowClick={(row) => {
                        navigate("./" + row.id);
                    }}
                    isLoading={isLoading}
                />

                <Dialog open={formOpen} onClose={() => setFormOpen(false)}>
                    <NewRequestForm
                        onSubmit={(data) => {
                            createRequest(data);
                        }}
                        isLoading={isCreatingRequest}
                    />
                </Dialog>
            </div>
        </>
    );
}
