import { MonthlyReport, MonthlyReportPolicyData, MonthlyReportQueryResult } from "@deathstar/types/northstar";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const monthlyReports = {
    queryKeys: {
        all: (accountId: string) => [accountId, "monthlyReports"] as QueryKey,
        findForPolicy: (accountId: string, policyId: string) => [accountId, "monthlyReports", policyId] as QueryKey,
        findForMonth: (accountId: string, policyId: string, year: number, month: number) =>
            [accountId, "monthlyReports", policyId, year, month] as QueryKey,
    },

    async findForPolicy(accountId: string, policyId: string): Promise<MonthlyReportPolicyData> {
        const data = await blazar.fetchJson<MonthlyReportPolicyData>(`/waypoint/orgs/${accountId}/policies/${policyId}/monthly-reports`);
        return blazar.changeDateStringsToDateObjects(data);
    },

    useFindForPolicy(accountId: string, policyId: string, config?: UseQueryOptions<MonthlyReportPolicyData, ResponseError>) {
        return useQuery<MonthlyReportPolicyData, ResponseError>(
            monthlyReports.queryKeys.findForPolicy(accountId, policyId),
            () => monthlyReports.findForPolicy(accountId, policyId),
            config
        );
    },

    async findForMonth(accountId: string, policyId: string, year: number, month: number): Promise<MonthlyReportQueryResult> {
        const data = await blazar.fetchJson<MonthlyReportQueryResult>(
            `/waypoint/orgs/${accountId}/policies/${policyId}/monthly-reports/${year}/${month}`
        );
        return blazar.changeDateStringsToDateObjects(data);
    },

    useFindForMonth(
        accountId: string,
        policyId: string,
        year: number,
        month: number,
        config?: UseQueryOptions<MonthlyReportQueryResult, ResponseError>
    ) {
        return useQuery<MonthlyReportQueryResult, ResponseError>(
            monthlyReports.queryKeys.findForMonth(accountId, policyId, year, month),
            () => monthlyReports.findForMonth(accountId, policyId, year, month),
            config
        );
    },

    async update(
        accountId: string,
        policyId: string,
        year: number,
        month: number,
        data: Partial<Pick<MonthlyReport, "mileage" | "revenue">>
    ) {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/policies/${policyId}/monthly-reports/${year}/${month}`, {
            method: "PATCH",
            body: JSON.stringify(data),
        });
    },

    /** Does not automatically invalidate the query and refresh the data */
    useUpdate(
        accountId: string,
        policyId: string,
        year: number,
        month: number,
        config?: UseMutationOptions<void, ResponseError, Partial<Pick<MonthlyReport, "mileage" | "revenue">>>
    ) {
        return useMutation<void, ResponseError, Partial<Pick<MonthlyReport, "mileage" | "revenue">>>(
            (data) => monthlyReports.update(accountId, policyId, year, month, data),
            config
        );
    },

    async create(accountId: string, policyId: string, year: number, month: number) {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/policies/${policyId}/monthly-reports/${year}/${month}`, {
            method: "POST",
        });
    },

    useCreate: (accountId: string, policyId: string, year: number, month: number, config?: UseMutationOptions<void, ResponseError>) => {
        return useMutation<void, ResponseError>(() => monthlyReports.create(accountId, policyId, year, month), {
            ...config,
            onSuccess: (...args) => {
                config?.onSuccess?.(...args);
                queryClient.invalidateQueries(monthlyReports.queryKeys.findForPolicy(accountId, policyId));
            },
        });
    },

    async submit(accountId: string, policyId: string, year: number, month: number) {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/policies/${policyId}/monthly-reports/${year}/${month}/submit`, {
            method: "POST",
        });
    },

    useSubmit: (accountId: string, policyId: string, year: number, month: number, config?: UseMutationOptions<void, ResponseError>) => {
        return useMutation<void, ResponseError>(() => monthlyReports.submit(accountId, policyId, year, month), {
            ...config,
            onSuccess: (...args) => {
                config?.onSuccess?.(...args);
                queryClient.invalidateQueries(monthlyReports.queryKeys.findForPolicy(accountId, policyId));
            },
        });
    },
};
