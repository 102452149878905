import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { Crashes } from "../Crashes/Crashes";
import { Inspection } from "../Inspection/Inspection";
import { Inspections } from "../Inspections/Inspections";

import { MotorCarrier } from "../MotorCarrier";
import { Basic } from "./Basic";
import { ControlledSubstances } from "./ControlledSubstances/ControlledSubstances";
import { CrashIndicator } from "./CrashIndicator/CrashIndicator";
import { DriverFitness } from "./DriverFitness/DriverFitness";
import { Hazmat } from "./Hazmat/Hazmat";
import { HoursOfService } from "./HoursOfService/HoursOfService";
import { UnsafeDriving } from "./UnsafeDriving/UnsafeDriving";
import { VehicleMaintenance } from "./VehicleMaintenance/VehicleMaintenance";

export class SmsResult {
    // ========================================================================
    static empty(date: Date | string, carrier: MotorCarrier): SmsResult {
        const recordDate = new DateTime(date).last().freeze();
        const snapshotDate = MotorCarrier.getSnapshotDateFromRecordDate(carrier, recordDate).freeze();
        const emptySmsData: MotorCarrier.Raw.SmsData = {
            Date: recordDate.toISOString(),
            ControlledSubstanceMeasure: 0,
            ControlledSubstanceScore: 0,
            CrashIndicatorMeasure: 0,
            CrashIndicatorScore: 0,
            DriverFitnessMeasure: 0,
            DriverFitnessScore: 0,
            HazmatMeasure: 0,
            HazmatScore: 0,
            HoursOfServiceMeasure: 0,
            HoursOfServiceScore: 0,
            UnsafeDrivingMeasure: 0,
            UnsafeDrivingScore: 0,
            VehicleMaintenanceMeasure: 0,
            VehicleMaintenanceScore: 0,
            IssAlgorithm: null,
            IssGroupNumber: null,
            IssScore: null,
        };

        return new SmsResult({
            recordDate,
            snapshotDate,
            carrier,
            forceCalculateBasicMeasures: false,
            forceCalculateBasicScores: false,
            rawSmsData: emptySmsData,
        });
    }

    // ========================================================================
    static async new(options: Omit<SmsResult.Options, "forceCalculateBasicMeasures" | "forceCalculateBasicScores">): Promise<SmsResult> {
        const smsResult = new SmsResult({
            ...options,
            forceCalculateBasicMeasures: !options.rawSmsData ? true : options.carrier.config.forceCalculateBasicMeasures ?? false,
            forceCalculateBasicScores: !options.rawSmsData ? true : options.carrier.config.forceCalculateBasicMeasures ?? false,
        });
        await smsResult.#calculate();
        return smsResult;
    }

    // ========================================================================
    readonly totalVehicleInspections: number;
    readonly totalDriverInspections: number;
    readonly totalUnsafeDrivingInspectionsWithViolation: number;
    readonly totalControlledSubstanceInspectionsWithViolation: number;
    readonly totalPlacardableHazmatInspections: number;
    readonly totalApplicableCrashes: number;
    readonly totalDriverOutOfServiceInspections: number;
    readonly totalVehicleOutOfServiceInspections: number;
    readonly dateRange: MotorCarrier.IDateRange;
    // ========================================================================
    #smsResult: {
        driverFitness: DriverFitness;
        vehicleMaintenance: VehicleMaintenance;
        controlledSubstances: ControlledSubstances;
        hazmat: Hazmat;
        unsafeDriving: UnsafeDriving;
        hoursOfService: HoursOfService;
        crashIndicator: CrashIndicator;
    };
    #inspections: Inspections;
    #inspetionsWithinPreviousYear: Inspections;
    #applicableCrashes: Crashes;
    #carrier: MotorCarrier;
    #recordDate: DateTime;
    #snapshotDate: DateTime;
    #rawSmsData: MotorCarrier.Raw.SmsData | null;
    private constructor({
        recordDate,
        snapshotDate,
        carrier,
        forceCalculateBasicMeasures,
        forceCalculateBasicScores,
        rawSmsData,
    }: SmsResult.Options) {
        this.#carrier = carrier;
        this.#recordDate = recordDate;
        this.#snapshotDate = snapshotDate;
        this.#rawSmsData = rawSmsData;

        this.dateRange = MotorCarrier.calculateDateRange({
            snapshotDate,
        });

        this.#inspections = carrier.inspections.filterByDateRange(this.dateRange);
        this.#inspetionsWithinPreviousYear = carrier.inspections.filterByDateRange({
            from: DateTime.subtractDays(snapshotDate, 365),
            to: snapshotDate,
        });
        this.#applicableCrashes = carrier.crashes.filterByDateRange(this.dateRange).filterByIncludedInSms();

        this.totalVehicleInspections = this.#inspections.filterByType(Inspection.Type.VEHICLE).total;
        this.totalDriverInspections = this.#inspections.filterByType(Inspection.Type.DRIVER).total;
        this.totalUnsafeDrivingInspectionsWithViolation = this.#inspections.violations.filterByBasic(
            FMCSA.BasicName.UNSAFE_DRIVING
        ).inspections.total;
        this.totalControlledSubstanceInspectionsWithViolation = this.#inspections.violations.filterByBasic(
            FMCSA.BasicName.CONTROLLED_SUBSTANCES
        ).inspections.total;
        this.totalApplicableCrashes = this.#applicableCrashes.total;
        this.totalPlacardableHazmatInspections = this.#inspections.filterByHazmatPlacardRequired().total;
        this.totalDriverOutOfServiceInspections = this.#inspections.filterByType(Inspection.Type.DRIVER).filterByOutOfService().total;
        this.totalVehicleOutOfServiceInspections = this.#inspections.filterByType(Inspection.Type.VEHICLE).filterByOutOfService().total;

        this.#smsResult = {
            driverFitness: new DriverFitness({
                carrier,
                recordDate,
                snapshotDate,
                smsResult: this,
                rawSmsData: rawSmsData,
                totalDriverInspections: this.totalDriverInspections,
                forceCalculateBasicMeasures,
                forceCalculateBasicScores,
            }),
            vehicleMaintenance: new VehicleMaintenance({
                carrier,
                recordDate,
                snapshotDate,
                smsResult: this,
                rawSmsData: rawSmsData,
                totalVehicleInspections: this.totalVehicleInspections,
                forceCalculateBasicMeasures,
                forceCalculateBasicScores,
            }),
            hoursOfService: new HoursOfService({
                carrier,
                recordDate,
                snapshotDate,
                smsResult: this,
                rawSmsData: rawSmsData,
                totalDriverInspections: this.totalDriverInspections,
                forceCalculateBasicMeasures,
                forceCalculateBasicScores,
            }),
            hazmat: new Hazmat({
                carrier,
                recordDate,
                snapshotDate,
                smsResult: this,
                rawSmsData: rawSmsData,
                totalPlacardableHazmatInspections: this.totalPlacardableHazmatInspections,
                forceCalculateBasicMeasures,
                forceCalculateBasicScores,
            }),
            controlledSubstances: new ControlledSubstances({
                carrier,
                recordDate,
                snapshotDate,
                smsResult: this,
                rawSmsData: rawSmsData,
                totalControlledSubstanceInspectionsWithViolation: this.totalControlledSubstanceInspectionsWithViolation,
                forceCalculateBasicMeasures,
                forceCalculateBasicScores,
            }),
            unsafeDriving: new UnsafeDriving({
                carrier,
                recordDate,
                snapshotDate,
                smsResult: this,
                rawSmsData: rawSmsData,
                totalUnsafeDrivingInspectionsWithViolation: this.totalUnsafeDrivingInspectionsWithViolation,
                forceCalculateBasicMeasures,
                forceCalculateBasicScores,
            }),
            crashIndicator: new CrashIndicator({
                carrier,
                recordDate,
                snapshotDate,
                smsResult: this,
                rawSmsData: rawSmsData,
                totalApplicableCrashes: this.totalApplicableCrashes,
                forceCalculateBasicMeasures,
                forceCalculateBasicScores,
            }),
        };
    }

    // ========================================================================
    get<K extends keyof MotorCarrier.Raw.SmsData>(key: K): MotorCarrier.Raw.SmsData[K] | null {
        return this.#rawSmsData?.[key] ?? null;
    }

    // ========================================================================
    async #calculate(): Promise<void> {
        await Promise.all([
            this.#smsResult.controlledSubstances.calculate(),
            this.#smsResult.crashIndicator.calculate(),
            this.#smsResult.driverFitness.calculate(),
            this.#smsResult.hazmat.calculate(),
            this.#smsResult.hoursOfService.calculate(),
            this.#smsResult.unsafeDriving.calculate(),
            this.#smsResult.vehicleMaintenance.calculate(),
        ]);
    }

    // ========================================================================
    get crashes(): Crashes {
        return this.#applicableCrashes;
    }

    // ========================================================================
    get inspections(): Inspections {
        return this.#inspections;
    }

    // ========================================================================
    get inspectionsWithinPreviousYear(): Inspections {
        return this.#inspetionsWithinPreviousYear;
    }

    // ========================================================================
    get recordDate(): DateTime {
        return this.#recordDate;
    }

    // ========================================================================
    get snapshotDate(): DateTime {
        return this.#snapshotDate;
    }

    // ========================================================================
    get driverFitness(): DriverFitness {
        return this.#smsResult.driverFitness;
    }

    // ========================================================================
    get vehicleMaintenance(): VehicleMaintenance {
        return this.#smsResult.vehicleMaintenance;
    }

    // ========================================================================
    get controlledSubstances(): ControlledSubstances {
        return this.#smsResult.controlledSubstances;
    }

    // ========================================================================
    get hazmat(): Hazmat {
        return this.#smsResult.hazmat;
    }

    // ========================================================================
    get unsafeDriving(): UnsafeDriving {
        return this.#smsResult.unsafeDriving;
    }

    // ========================================================================
    get hoursOfService(): HoursOfService {
        return this.#smsResult.hoursOfService;
    }

    // ========================================================================
    get crashIndicator(): CrashIndicator {
        return this.#smsResult.crashIndicator;
    }

    // ========================================================================
    getTotalBasicsOverZero(): number {
        return [
            this.driverFitness,
            this.vehicleMaintenance,
            this.controlledSubstances,
            this.hazmat,
            this.unsafeDriving,
            this.hoursOfService,
            this.crashIndicator,
        ].reduce((total, basic) => {
            if (!basic.score) return total;
            return total + 1;
        }, 0);
    }

    // ========================================================================
    getTotalBasicsOverThreshold(): number {
        return [
            this.driverFitness,
            this.vehicleMaintenance,
            this.controlledSubstances,
            this.hazmat,
            this.unsafeDriving,
            this.hoursOfService,
            this.crashIndicator,
        ].reduce((total, basic) => {
            if (basic.isScoreAboveThreshold) return total + 1;
            return total;
        }, 0);
    }

    // ========================================================================
    getTotalBasicsOverThresholdBestAddressedRoadside(): number {
        return [
            this.driverFitness,
            this.vehicleMaintenance,
            this.controlledSubstances,
            this.hazmat,
            this.unsafeDriving,
            this.hoursOfService,
            this.crashIndicator,
        ].reduce((total, basic) => {
            if (basic.isScoreAboveThreshold && basic.isBestAddressedRoadside) return total + 1;
            return total;
        }, 0);
    }

    // ========================================================================
    getBasic(basic: FMCSA.BasicName.UNSAFE_DRIVING): Basic<UnsafeDriving>;
    getBasic(basic: FMCSA.BasicName.HOS): Basic<HoursOfService>;
    getBasic(basic: FMCSA.BasicName.VEHICLE_MAINTENANCE): Basic<VehicleMaintenance>;
    getBasic(basic: FMCSA.BasicName.CONTROLLED_SUBSTANCES): Basic<ControlledSubstances>;
    getBasic(basic: FMCSA.BasicName.HAZMAT): Basic<Hazmat>;
    getBasic(basic: FMCSA.BasicName.DRIVER_FITNESS): Basic<DriverFitness>;
    getBasic(basic: FMCSA.BasicName.CRASH_INDICATOR): Basic<CrashIndicator>;
    getBasic(basic: FMCSA.BasicName): Basic<unknown>;
    getBasic(basic: FMCSA.BasicName): Basic<unknown> {
        switch (basic) {
            case FMCSA.BasicName.UNSAFE_DRIVING:
                return this.unsafeDriving;
            case FMCSA.BasicName.HOS:
                return this.hoursOfService;
            case FMCSA.BasicName.VEHICLE_MAINTENANCE:
                return this.vehicleMaintenance;
            case FMCSA.BasicName.CONTROLLED_SUBSTANCES:
                return this.controlledSubstances;
            case FMCSA.BasicName.HAZMAT:
                return this.hazmat;
            case FMCSA.BasicName.DRIVER_FITNESS:
                return this.driverFitness;
            case FMCSA.BasicName.CRASH_INDICATOR:
                return this.crashIndicator;
            default:
                throw new Error(`Unknown BASIC: ${basic}`);
        }
    }

    // ========================================================================
    json(): SmsResult.JSON {
        return {
            date: this.recordDate.toISOString(),
            driverFitness: this.driverFitness.json(),
            vehicleMaintenance: this.vehicleMaintenance.json(),
            controlledSubstances: this.controlledSubstances.json(),
            hazmat: this.hazmat.json(),
            unsafeDriving: this.unsafeDriving.json(),
            hoursOfService: this.hoursOfService.json(),
            crashIndicator: this.crashIndicator.json(),
        };
    }
}

// ========================================================================
export namespace SmsResult {
    export interface Options {
        recordDate: DateTime;
        snapshotDate: DateTime;
        carrier: MotorCarrier;
        forceCalculateBasicMeasures: boolean;
        forceCalculateBasicScores: boolean;
        rawSmsData: MotorCarrier.Raw.SmsData | null;
    }

    export interface JSON {
        date: string;
        driverFitness: Basic.JSON;
        vehicleMaintenance: Basic.JSON;
        controlledSubstances: Basic.JSON;
        hazmat: Basic.JSON;
        unsafeDriving: Basic.JSON;
        hoursOfService: Basic.JSON;
        crashIndicator: Basic.JSON;
    }
}
