import { Paper } from "@material-ui/core";
import { CurrentScores } from "./CurrentScores";
import { ExpiringPoints } from "./ExpiringPoints";
import { FmcsaSnapshot } from "./FmcsaSnapshot";
import { HistoricalScores } from "./HistoricalScores";
import { NewPoints } from "./NewPoints";

export function Summary(): JSX.Element {
    return (
        <Paper className="flex flex-col rounded-b-lg" elevation={0}>
            <FmcsaSnapshot />
            <CurrentScores />
            <HistoricalScores />
            <NewPoints />
            <ExpiringPoints />
        </Paper>
    );
}
