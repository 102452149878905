/**
 * A helper for rendering a dropzone compatible with custom DropzoneItems
 *
 * We keep the dropEffect in a separate state because of a Chrome bug which prevents dropEffect from being set properly.
 * It was hard to find good documentation on this bug, but it seems others have run into it as well.
 * https://stackoverflow.com/questions/19010257/event-datatransfer-dropeffect-in-chrome
 *
 */
import React, { useCallback, useMemo } from "react";
import createStore from "zustand";

export interface IDropzoneItem {
    type: string;
    data: unknown;
}
type DropEffect = "copy" | "link" | "move" | "none";
type EffectAllowed = "none" | "copy" | "copyLink" | "copyMove" | "link" | "linkMove" | "move" | "all" | "uninitialized";

type DropzoneStoreState = {
    activeDropzoneId: string | null;
    setActiveDropzoneId(activeDropzoneId: string | null): void;
    dropEffect: DropEffect;
    setDropEffect(dropEffect: DropEffect): void;
    reset(): void;
};
const useDropzoneStore = createStore<DropzoneStoreState>((set) => ({
    activeDropzoneId: null,
    setActiveDropzoneId(activeDropzoneId: string | null) {
        set({ activeDropzoneId });
    },
    dropEffect: "move",
    setDropEffect(dropEffect: DropEffect) {
        set({ dropEffect });
    },
    reset() {
        set({ dropEffect: "move" });
    },
}));

type DropzoneFnProps = {
    isDragActive: boolean;
    getProps(): {
        onDragEnter(e: React.DragEvent<HTMLElement>): void;
        onDragLeave(e: React.DragEvent<HTMLElement>): void;
        onDrop(e: React.DragEvent<HTMLElement>): void;
        onDragOver(e: React.DragEvent<HTMLElement>): void;
    };
    handleFileSelect(e: React.ChangeEvent<HTMLInputElement>): void;
};

export type DropzoneProps = {
    /** An ID unique to this specific Dropzone. Used if multiple Dropzones are stacked on top of each other */
    id: string;
    /** `event` will be `null` if a file was uploaded via an input instead of a drag event */
    onDrop?(files: File[], item: IDropzoneItem | null, event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>): void;
    onFileDrop?(files: File[]): void;
    onItemDrop?(item: IDropzoneItem): void;
    accept?: {
        files?: string[];
        items?: string[];
    };
    dropEffect?: DropEffect;
    disabled?: boolean;
    clickable?: boolean;
    children({ isDragActive, getProps }: DropzoneFnProps): JSX.Element;
};

export function Dropzone({ id, accept, dropEffect, disabled, onDrop, onFileDrop, onItemDrop, children }: DropzoneProps): JSX.Element {
    const store = useDropzoneStore();

    const onDragEnterCb = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.persist();
            e.stopPropagation();
            const dropzoneStore = useDropzoneStore.getState();
            if (disabled || !isDropEffectAllowed(dropzoneStore.dropEffect, e.dataTransfer.effectAllowed as EffectAllowed)) {
                return;
            }

            dropzoneStore.setActiveDropzoneId(id);
        },
        [disabled, id]
    );

    const onDragLeaveCb = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            const related = e.relatedTarget as Element;
            const dropzoneStore = useDropzoneStore.getState();
            if (dropzoneStore.activeDropzoneId !== id) {
                // prevent the dropzone from unsetting the active dropzone at the same time another dropzone is setting it
                return;
            }
            if (!related) {
                // If there is no related target, then we must have left the window
                dropzoneStore.setActiveDropzoneId(null);
            } else {
                // need to check whether the relatedTarget is a real element or if it is part of the shadow DOM
                // any element here should be part of the document, so we can check if its root node is the document or not
                // as a way of determining if it is a shadow element
                const relatedElement = related.getRootNode() !== document ? (related.getRootNode() as ShadowRoot).host : related;
                if (!e.currentTarget.contains(relatedElement)) {
                    dropzoneStore.setActiveDropzoneId(null);
                }
            }
        },
        [id]
    );

    const acceptedFileExtensions = accept?.files;
    const acceptedItems = accept?.items;

    const onDropCb = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            store.reset();
            if (disabled) {
                return;
            }
            const dropzoneStore = useDropzoneStore.getState();

            dropzoneStore.setActiveDropzoneId(null);
            const files = Array.from(e.dataTransfer.files).filter(
                (file) => !acceptedFileExtensions || new RegExp(acceptedFileExtensions.join("|"), "i").exec(parseFileName(file.name)[1])
            ) as File[];

            const parsedItem: IDropzoneItem = e.dataTransfer.types.includes("application/json")
                ? JSON.parse(e.dataTransfer.getData("application/json"))
                : null;

            let item: IDropzoneItem | null = null;
            if (parsedItem && parsedItem.data && parsedItem.type) {
                // ensure it acts as a DropzoneItem
                // check if the dropped item's type is in our accept array
                item = acceptedItems ? (acceptedItems.includes(parsedItem.type) ? parsedItem : null) : parsedItem;
            }

            if (onDrop) {
                onDrop(files, item, e);
            }
            if (files.length && onFileDrop) {
                onFileDrop(files);
            }
            if (item && onItemDrop) {
                onItemDrop(item);
            }
        },
        [acceptedFileExtensions, acceptedItems, disabled, onDrop, onFileDrop, onItemDrop, store]
    );

    const onDragOverCb = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            e.dataTransfer.dropEffect = dropEffect || "copy";

            const dropzoneStore = useDropzoneStore.getState();
            // see the comment at the top for why this is necessary
            if (dropzoneStore.dropEffect !== (dropEffect || "copy")) {
                dropzoneStore.setDropEffect(dropEffect || "copy");
            }
        },
        [dropEffect]
    );

    const handleFileSelect = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
                const files = Array.from(e.target.files).filter((file) => {
                    const extension = file.name.match(/.*(\.[a-zA-Z0-9]+)/)?.[1];
                    if (file) {
                        if (acceptedFileExtensions) {
                            if (extension) {
                                return acceptedFileExtensions.includes(extension);
                            }
                            return false;
                        }
                        return true;
                    }
                    return false;
                }) as File[];
                if (onDrop) {
                    onDrop(files, null, e);
                }
                if (files.length && onFileDrop) {
                    onFileDrop(files);
                }
            }
        },
        [acceptedFileExtensions, onDrop, onFileDrop]
    );

    const getProps = useMemo(() => {
        return () => ({
            onDragEnter: onDragEnterCb,
            onDragLeave: onDragLeaveCb,
            onDrop: onDropCb,
            onDragOver: onDragOverCb,
        });
    }, [onDragEnterCb, onDragLeaveCb, onDragOverCb, onDropCb]);

    return useMemo(
        () => children({ isDragActive: useDropzoneStore.getState().activeDropzoneId === id, getProps, handleFileSelect }),
        [children, getProps, handleFileSelect, id]
    );
}

function isDropEffectAllowed(dropEffect: DropEffect, effectAllowed: EffectAllowed): boolean {
    if (effectAllowed === "uninitialized" || effectAllowed === "all") return true;
    switch (dropEffect) {
        case "copy": {
            return effectAllowed === "copy" || effectAllowed === "copyLink" || effectAllowed === "copyMove";
        }
        case "move": {
            return effectAllowed === "move" || effectAllowed === "linkMove" || effectAllowed === "copyMove";
        }
        case "link": {
            return effectAllowed === "link" || effectAllowed === "copyLink" || effectAllowed === "linkMove";
        }
        case "none": {
            return false;
        }
    }
}

type DropzoneItemProps = {
    draggable: true;
    onDragStart(e: React.DragEvent<HTMLElement>): void;
};

interface GetDropzoneItemProps<T extends IDropzoneItem> {
    item: T;
    dragImage?: Element;
    effectAllowed?: EffectAllowed;
    /**
     * @example otherData: [['DownloadURL', 'application/pdf:file_name.pdf:pdf_uri_goes_here']]
     */
    otherData?: [mimeType: string, data: string][];
}

export function getDropzoneItemProps<T extends IDropzoneItem>({
    item,
    dragImage,
    effectAllowed,
    otherData,
}: GetDropzoneItemProps<T>): DropzoneItemProps {
    return {
        draggable: true,
        onDragStart(e) {
            if (dragImage) {
                e.dataTransfer.setDragImage(dragImage, 0, 0);
            }
            e.dataTransfer.setData("application/json", JSON.stringify(item));
            e.dataTransfer.effectAllowed = effectAllowed || "copy";

            if (otherData) {
                for (const [mimeType, data] of otherData) {
                    e.dataTransfer.setData(mimeType, data);
                }
            }
        },
    };
}

function parseFileName(fileName: string): [string, string] {
    const match = fileName.match(/(.*)(\.[a-zA-Z0-9]+)/);
    if (match) {
        return [match[1], match[2]];
    }
    return [fileName, ""];
}
