import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { InputAdornment, TextFieldProps } from "@material-ui/core";
import type { DataTableProps } from "./DataTable";
import { Input } from "./Input";

export function Search<T>({ table, ...props }: Pick<DataTableProps<T>, "table"> & Omit<TextFieldProps, "value" | "onChange">): JSX.Element {
    const tableState = table.getState();

    return (
        <Input
            {...props}
            value={tableState.globalFilter || ""}
            onChange={(e) => table.setGlobalFilter(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <MagnifyingGlassIcon className="h-4 w-4" />
                    </InputAdornment>
                ),
            }}
        />
    );
}
