import { Member, Permission } from "@deathstar/types/waypoint";
import { DataTable, Popover, useSnackbar } from "@deathstar/ui";
import {
    AdjustmentsHorizontalIcon,
    ArrowDownCircleIcon,
    ArrowUpCircleIcon,
    EllipsisVerticalIcon,
    MinusCircleIcon,
    PlusIcon,
} from "@heroicons/react/24/outline";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { ResponseError, UnauthorizedError } from "../../../api/util/exceptions";
import { Dialog } from "../../../components/dialog/Dialog";
import Unauthorized from "../../../components/error-screens/Unauthorized";
import UncaughtException from "../../../components/error-screens/UncaughtException";
import { queryClient } from "../../../util/queryClient";
import InviteUserDialog from "./InviteUserDialog";
import PermissionsTable from "./PermissionsTable";

export default function MembersTable() {
    const accountId = useAccountId();

    const { data, error, isError } = api.members.useList(accountId!, {
        enabled: !!accountId!,
    });

    const tableData = useMemo(() => data || [], [data]);

    // use two bits of state here so that the dialog animation is smoother
    const [editingUserOpen, setEditingUserOpen] = useState(false);
    const [editingUserId, setEditingUserId] = useState("");

    const [addingMember, setAddingMember] = useState(false);

    useEffect(() => {
        setEditingUserOpen(!!editingUserId);
    }, [editingUserId]);

    const columns = useMemo<ColumnDef<Member>[]>(
        () => [
            {
                id: "mobile",
                header: () => null,
                cell: ({ row }) => (
                    <Popover>
                        <Popover.Button className="w-full">
                            <div className="text-left">
                                <div className="flex justify-between">
                                    <p>{row.original.admin ? "Admin" : "Member"}</p>
                                    <p className="text-xs text-stone-500">{moment(row.original.createdDate).format("MM/DD/YYYY")}</p>
                                </div>
                                <p>{row.original.email}</p>
                                <p>{row.original.displayName === row.original.email ? "" : row.original.displayName}</p>
                            </div>
                        </Popover.Button>

                        <Popover.Content>
                            <Actions row={row.original} setEditingUserId={setEditingUserId} />
                        </Popover.Content>
                    </Popover>
                ),
                meta: { responsive: true, classes: { header: "hidden" } },
            },
            {
                id: "admin",
                accessorKey: "admin",
                header: "Role",
                cell: ({ getValue }) => (getValue() ? <span className="font-bold text-waypoint-blue-dark">Admin</span> : "Member"),
            },
            {
                id: "email",
                accessorKey: "email",
                header: "Email",
            },
            {
                id: "displayName",
                accessorKey: "displayName",
                header: "Name",
            },
            {
                id: "createDate",
                accessorKey: "createdDate",
                header: "Joined",
                cell: ({ getValue }) => moment(getValue() as string).format("MM/DD/YYYY"),
            },
            {
                id: "actions",
                cell: ({ row }) => (
                    <Popover>
                        <Popover.Button className="rounded-lg p-2 enabled:hover:bg-stone-100">
                            <EllipsisVerticalIcon className="h-4 w-4" />
                        </Popover.Button>
                        <Popover.Content className="w-max">
                            <Actions row={row.original} setEditingUserId={setEditingUserId} />
                        </Popover.Content>
                    </Popover>
                ),
                meta: { align: "right" },
            },
        ],
        []
    );

    const table = useReactTable({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),

        initialState: {
            pagination: {
                pageSize: 20,
            },
        },
    });

    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    if (isError) {
        if (error instanceof UnauthorizedError) {
            return <Unauthorized />;
        }
        return <UncaughtException />;
    }

    return (
        <animated.div style={spring}>
            <DataTable
                table={table}
                actions={[{ icon: <PlusIcon className="h-4 w-4" />, label: "Invite someone", onClick: () => setAddingMember(true) }]}
            />
            <Dialog
                open={editingUserOpen}
                onClose={() => setEditingUserOpen(false)}
                afterLeave={() => setEditingUserId("")}
                className="overflow-y-auto"
            >
                <PermissionsTable userId={editingUserId} />
            </Dialog>
            <InviteUserDialog open={addingMember} onClose={() => setAddingMember(false)} />
        </animated.div>
    );
}

function Actions({ setEditingUserId, row }: { setEditingUserId: (id: string) => void; row: Member }) {
    const { data: currentUser } = api.user.useMe();
    const accountId = useAccountId();

    const isCurrentUserAdmin = !!currentUser?.memberships?.some((m) => m.accountId === accountId! && m.admin);
    const { data: canRemoveMember } = api.user.useHasPermission(accountId!, Permission.Id.MEMBERS_DELETE);
    return (
        <>
            <Popover.Item onClick={() => setEditingUserId(row.id)}>
                <AdjustmentsHorizontalIcon className="h-4 w-4" />
                View permissions
            </Popover.Item>
            {row.admin ? (
                <Popover.Item
                    disabled={!isCurrentUserAdmin}
                    onClick={async () => {
                        try {
                            await api.members.demote(accountId!, row.id);
                            queryClient.invalidateQueries(api.members.queryKeys.list(accountId!));
                        } catch (error) {
                            let message = "An error occurred. Please try again later";
                            if (error instanceof ResponseError) {
                                message = error.message;
                            }
                            useSnackbar.add(message, { variant: "error" });
                        }
                    }}
                >
                    <ArrowDownCircleIcon className="h-4 w-4" />
                    Demote to member
                </Popover.Item>
            ) : (
                <Popover.Item
                    disabled={!isCurrentUserAdmin}
                    onClick={async () => {
                        try {
                            await api.members.promote(accountId!, row.id);
                            queryClient.invalidateQueries(api.members.queryKeys.list(accountId!));
                        } catch (error) {
                            let message = "An error occurred. Please try again later";
                            if (error instanceof ResponseError) {
                                message = error.message;
                            }
                            useSnackbar.add(message, { variant: "error" });
                        }
                    }}
                >
                    <ArrowUpCircleIcon className="h-4 w-4" />
                    Promote to admin
                </Popover.Item>
            )}
            <hr className="my-2" />
            <Popover.Item
                className="!text-red-600"
                disabled={row.admin || !canRemoveMember}
                onClick={async () => {
                    try {
                        await api.members.remove(accountId!, row.id);
                        queryClient.invalidateQueries(api.members.queryKeys.list(accountId!));
                    } catch (error) {
                        let message = "An error occurred. Please try again later";
                        if (error instanceof ResponseError) {
                            message = error.message;
                        }
                        useSnackbar.add(message, { variant: "error" });
                    }
                }}
            >
                <MinusCircleIcon className="h-4 w-4" />
                Remove from organization
            </Popover.Item>
        </>
    );
}
