import React from "react";
import { useSpring } from "react-spring";

// Code used from Josh Comeau via https://www.joshwcomeau.com/react/boop

interface BoopConfig {
    x?: number;
    y?: number;
    rotation?: number;
    scale?: number;
    timing?: number;
    springConfig?: {
        tension?: number;
        friction?: number;
    };
}

/**
 * A hook for creating "Boop" animations using React-Spring. See https://www.joshwcomeau.com/react/boop
 * for insight into what a "Boop" animation is and how the code works.
 */
export default function useBoop({
    x = 0,
    y = 0,
    rotation = 0,
    scale = 1,
    timing = 150,
    springConfig = {
        tension: 300,
        friction: 10,
    },
}: BoopConfig) {
    const [isBooped, setIsBooped] = React.useState(false);
    const style = useSpring({
        willChange: "transform",
        rotate: isBooped ? `${rotation}deg` : "0deg",
        translateX: isBooped ? `${x}px` : "0px",
        translateY: isBooped ? `${y}px` : "0px",
        scale: isBooped ? `${scale}` : "1",
        config: springConfig,
    });
    React.useEffect(() => {
        if (!isBooped) {
            return () => {
                // no cleanup necessary
            };
        }
        const timeoutId = window.setTimeout(() => {
            setIsBooped(false);
        }, timing);
        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [isBooped, timing]);
    const trigger = React.useCallback(() => {
        setIsBooped(true);
    }, []);
    return [style, trigger] as const;
}
