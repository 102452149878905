import type { CSSObject } from "@emotion/react";
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { forwardRef } from "react";
import tw from "twin.macro";
import { classNames } from "../classNames/classNames";

type Variant = "success" | "info" | "warning" | "error";
type Shadow = keyof typeof shadowMap;

export type AlertProps = {
    variant?: Variant;
    icon?: React.ReactNode;
    action?: React.ReactNode;
    shadow?: Shadow;
    css?: CSSObject;
} & React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement>;

const shadowMap = {
    none: tw`shadow-none`,
    sm: tw`shadow-sm`,
    md: tw`shadow-md`,
    lg: tw`shadow-lg`,
    xl: tw`shadow-xl`,
};

const iconMapping: Record<Variant, (props: React.ComponentProps<"svg">) => JSX.Element> = {
    error: ExclamationCircleIcon,
    warning: ExclamationTriangleIcon,
    success: CheckCircleIcon,
    info: InformationCircleIcon,
};

const styles = {
    container: ({ variant, shadow }: AlertProps) => [
        tw`rounded p-4 grid grid-cols-[max-content 1fr max-content] items-center gap-4 w-full`,
        shadowMap[shadow || "none"],
        variant === "warning"
            ? tw`text-yellow-900 bg-[hsl(43deg 100% 93%)]`
            : variant === "success"
            ? tw`text-green-900 bg-[hsl(120deg 38% 95%)]`
            : variant === "error"
            ? tw`text-red-900 bg-[hsl(0deg 80% 96%)]`
            : tw`text-blue-900 bg-[hsl(198deg 86% 95%)]`,
    ],
    icon: ({ variant }: AlertProps) => [
        tw`flex-shrink-0 w-5 h-5`,
        variant === "warning"
            ? tw`text-yellow-500`
            : variant === "success"
            ? tw`text-green-500`
            : variant === "error"
            ? tw`text-red-500`
            : tw`text-blue-500`,
    ],
    text: (_props: AlertProps) => [tw`flex-grow text-sm`],
};

export const Alert = forwardRef<HTMLDivElement, AlertProps>(function (props, ref): JSX.Element {
    const { variant, icon, children, action, ...rest } = props;
    const Icon = iconMapping[variant || "info"];

    return (
        <div
            {...rest}
            css={[styles.container(props), props.css]}
            className={classNames("transition-all duration-200", props.className)}
            ref={ref}
        >
            {icon || <Icon css={styles.icon(props)} />}
            <p css={styles.text(props)}>{children}</p>
            {action}
        </div>
    );
});
