import { PriceWithProduct, SubscriptionItemUsage, SubscriptionTier } from "@deathstar/types/waypoint";
import Stripe from "stripe";

export function getAmountOff(unitAmount: number, coupon: Pick<Stripe.Coupon, "amount_off" | "percent_off">): number {
    return coupon.amount_off ? coupon.amount_off : coupon.percent_off ? (unitAmount * coupon.percent_off) / 100 : 0;
}

export function unitAmountToCurrency(
    unitAmount: number | undefined | null,
    currency: string | undefined,
    coupon?: Pick<Stripe.Coupon, "amount_off" | "percent_off"> | null
) {
    if (unitAmount === undefined || unitAmount === null) return null;
    const amountOff = coupon ? getAmountOff(unitAmount, coupon) : 0;
    return ((unitAmount - amountOff) / 100).toLocaleString("en-us", {
        style: "currency",
        currency,
        maximumFractionDigits: 0,
    });
}

export function getProductUsage(usages: SubscriptionItemUsage[], price: PriceWithProduct): number | null {
    return usages.find(({ item }) => (item.price.product as Stripe.Product).id === price.product.id)?.usage?.total_usage ?? null;
}

export function getPlanIndex(plan: SubscriptionTier): number {
    return [SubscriptionTier.Standard, SubscriptionTier.Premium, SubscriptionTier.Enterprise].indexOf(plan);
}
