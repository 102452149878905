import { memoize } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { Inspection } from "../../Inspection/Inspection";
import { SafetyEventGroup } from "../../SafetyEventGroup/SafetyEventGroup";
import { Basic } from "../Basic";

export class HoursOfService extends Basic<SafetyEventGroup.GroupNames.HoursOfService> {
    readonly isBestAddressedRoadside = true;

    // ========================================================================
    static get thresholdDefinition(): Basic.IThresholdDefinition {
        return {
            passenger: 50,
            hazmat: 60,
            general: 65,
        };
    }

    // ========================================================================
    constructor(options: HoursOfService.Options) {
        super({
            ...options,
            safetyEventGroup: new SafetyEventGroup.Group.HoursOfService(options.carrier, options.totalDriverInspections),
            basicName: FMCSA.BasicName.HOS,
            relevantInspectionDefinition: {
                levels: [1, 2, 3, 6],
                type: Inspection.Type.DRIVER,
            },
        });
    }

    // ========================================================================
    @memoize()
    override getThresholdDefinition(): Basic.IThresholdDefinition {
        return HoursOfService.thresholdDefinition;
    }

    // ========================================================================
    override getFmcsaCalculatedMeasure(): number | null {
        return typeof this.rawSmsData?.HoursOfServiceMeasure === "number" ? this.rawSmsData?.HoursOfServiceMeasure : null;
    }

    // ========================================================================
    protected override getMeasure(): number {
        if (typeof this.rawSmsData?.HoursOfServiceMeasure === "number" && !this.forceCalculateBasicMeasures) {
            return this.rawSmsData?.HoursOfServiceMeasure;
        }

        return this.calculateMeasure();
    }

    // ========================================================================
    protected override async getScore(): Promise<number | null> {
        if (typeof this.rawSmsData?.HoursOfServiceScore === "number" && !this.forceCalculateBasicScores) {
            return this.rawSmsData?.HoursOfServiceScore;
        }

        return this.calculateScore();
    }

    // ========================================================================
    override getVariableData(): Record<string, string | number> {
        return {
            totalViolations: this.getTotalNumberOfViolations(),
            totalWeightOfApplicableViolations: this.getTotalWeightOfApplicableViolations(),
            totalTimeWeightOfRelevantInspections: this.getTotalTimeWeightOfRelevantInspections(),
        };
    }

    // ========================================================================
    protected override async calculateScore(): Promise<number | null> {
        if (
            this.measure === 0 ||
            this.safetyEventGroup.number === 0 ||
            !this.safetyEventGroup.name ||
            this.smsResult.totalDriverInspections < 3
        ) {
            return 0;
        }

        /*
            Get the total number of Hours of Service violations
        */
        const totalHoursOfServiceViolations = this.getTotalNumberOfViolations();

        if (totalHoursOfServiceViolations === 0) return 0;

        /*
            Get the total number of inspections with an Hours of Service violation
        */
        const totalHoursOfServiceInspectionsWithViolation = this.getTotalInspectionsWithViolation();

        if (totalHoursOfServiceInspectionsWithViolation < 3) return 0;

        const totalHoursOfServiceViolationsWithinPreviousYear = this.getTotalViolationsWithinYear();

        if (totalHoursOfServiceViolationsWithinPreviousYear === 0) {
            const latestRelevantDriverInspectionIncludesViolation = this.getLatestRelevantInspectionIncludesViolation();

            if (!latestRelevantDriverInspectionIncludesViolation) return 0;
        }

        return this.fetchSmsScore();
    }
}

export namespace HoursOfService {
    export interface Options extends Basic.BaseOptions {
        totalDriverInspections: number;
    }
}
