import type { Account } from "./Account";
import { Coverage } from "./Coverage";
import { CoverageLinkedTractor } from "./CoverageLinkedTractor";
import { CoverageOption } from "./CoverageOption";
import type { EquipmentType } from "./EquipmentType";
import { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";
import { Property } from "./Property";
import { User } from "./User";
import { Vehicle } from "./Vehicle";

export enum VinStatus {
    Unchecked = 0,
    Valid = 1,
    Invalid = 2,
}

export class Tractor {
    id: number;
    createDate: Date;
    updateDate: Date;
    createdByGuid: string;
    createdBy: User;
    submit: boolean;
    isDeleted: boolean;
    accountId: string;
    year: number | null;
    make: string | null;
    typeId: EquipmentType["id"];
    type: EquipmentType;
    vin: string | null;
    acv: boolean;
    comprehensive: boolean;
    collision: boolean;
    value: number | null;
    ownerOperator: boolean | null;
    vinValid: number | null;
    spare: boolean;
    unitNumber: string | null;
    comments: string | null;
    requiresApproval: boolean;
    requestComment: string;
    requestDate: string;
    isRequestNotificationSent: boolean;
    createdByWaypointUserId: string;
    wpUserConfirmationIsNotPpvVanPickup: boolean;

    account?: Account;
    coverageLinks?: CoverageLinkedTractor[];
    additionalInterests?: PolicyAdditionalInterest[];

    propertyId: number;
    property?: Property;

    policyUpcomingDate?: string | null | undefined;
    policyValue?: number | null | undefined;
    policyOwnerOperator?: boolean | null | undefined;
    policySpare?: boolean | null | undefined;
    policyAcv?: boolean | null | undefined;
    policyComprehensive?: boolean | null | undefined;
    policyCollision?: boolean | null | undefined;
    statuses?: AccountEquipmentStatus[];
    coverageOptions?: CoverageOption[];

    toString(): string {
        return Vehicle.toString(this);
    }
}

export enum AccountEquipmentStatus {
    Covered = 1,
    UpcomingCovered = 2,
    Submission = 3,
    Hidden = 4,
}

export interface AccountEquipment<T> {
    units: (T & {
        statuses: AccountEquipmentStatus[];
        coverageOptions?: CoverageOption[];
    })[];
    coverages: Coverage[];
}
