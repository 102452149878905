import { MotorCarrier } from "@deathstar/motor-carrier";
import { DataTable, fuzzyFilter } from "@deathstar/ui";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { animated, useSpring } from "react-spring";
import { useLocalStorage } from "web-api-hooks";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import Columns, { columns } from "./Columns";

const hideableColumns: DataTable.HideableColumn[] = [
    {
        label: "Date",
        ids: [Columns.date.id!],
    },
    {
        label: "Report Number",
        ids: [Columns.reportNumber.id!],
    },
    {
        label: "Report Time",
        ids: [Columns.reportTime.id!],
    },
    {
        label: "State",
        ids: [Columns.state.id!],
    },
    {
        label: "City",
        ids: [Columns.city.id!],
    },
    {
        label: "County",
        ids: [Columns.county.id!],
    },
    {
        label: "Location",
        ids: [Columns.location.id!],
    },
    {
        label: "Latitude",
        ids: [Columns.latitude.id!],
    },
    {
        label: "Longitude",
        ids: [Columns.longitude.id!],
    },
    {
        label: "Fatalaties",
        ids: [Columns.totalFatalities.id!],
    },
    {
        label: "Injuries",
        ids: [Columns.totalInjuries.id!],
    },
    {
        label: "Vehicles Involved",
        ids: [Columns.totalVehiclesInAccident.id!],
    },
    {
        label: "Tow",
        ids: [Columns.towIndicator.id!],
    },
    {
        label: "Severity Weight",
        ids: [Columns.severityWeight.id!],
    },
    {
        label: "Time Weight",
        ids: [Columns.timeWeight.id!],
    },
    {
        label: "Total Weight",
        ids: [Columns.totalWeight.id!],
    },
    {
        label: "Hazmat Released",
        ids: [Columns.hazmatReleaseIndicator.id!],
    },
    {
        label: "Federal Recordable",
        ids: [Columns.federalRecordableIndicator.id!],
    },
    {
        label: "State Recordable",
        ids: [Columns.stateRecordableIndicator.id!],
    },
    {
        label: "Preventable",
        ids: [Columns.preventable.id!],
    },
    {
        label: "Road Access Control",
        ids: [Columns.roadAccessControl.id!],
    },
    {
        label: "Trafficway",
        ids: [Columns.trafficway.id!],
    },
    {
        label: "Unit: VIN",
        ids: [Columns.unitVin.id!],
    },
    {
        label: "Unit: Year",
        ids: [Columns.unitYear.id!],
    },
    {
        label: "Unit: Make",
        ids: [Columns.unitMake.id!],
    },
    {
        label: "Unit: License",
        ids: [Columns.unitLicenseNumber.id!],
    },
    {
        label: "Unit: State",
        ids: [Columns.unitLicenseState.id!],
    },
    {
        label: "Unit: Type",
        ids: [Columns.unitType.id!],
    },
    {
        label: "Unit: Weight Rating",
        ids: [Columns.unitWeightRating.id!],
    },
    {
        label: "Unit: Hazmat Placard",
        ids: [Columns.unitHazmatPlacardIndicator.id!],
    },
    {
        label: "Unit: Cargo Body TYpe",
        ids: [Columns.cargoBodyType.id!],
    },
    {
        label: "Conditions: Light",
        ids: [Columns.lightCondition.id!],
    },
    {
        label: "Conditions: Road Surface",
        ids: [Columns.roadSurfaceCondition.id!],
    },
    {
        label: "Conditions: Weather",
        ids: [Columns.weatherCondition.id!],
    },
    {
        label: "Reporting Agency",
        ids: [Columns.reportingAgency.id!],
    },
];

const AnimatedDataTable = animated(DataTable<MotorCarrier.ICrash>);

function useColumnVisibility(): [Record<string, boolean>, React.Dispatch<React.SetStateAction<Record<string, boolean>>>] {
    const [columnVisibility, setColumnVisibility] = useLocalStorage<Record<string, boolean>>("ia-crash-table-column-visibility", {
        [Columns.date.id as string]: true,
        [Columns.reportNumber.id as string]: true,
        [Columns.reportTime.id as string]: false,
        [Columns.state.id as string]: true,
        [Columns.city.id as string]: false,
        [Columns.county.id as string]: false,
        [Columns.location.id as string]: false,
        [Columns.latitude.id as string]: false,
        [Columns.longitude.id as string]: false,
        [Columns.totalFatalities.id as string]: true,
        [Columns.totalInjuries.id as string]: true,
        [Columns.totalVehiclesInAccident.id as string]: false,
        [Columns.towIndicator.id as string]: true,
        [Columns.severityWeight.id as string]: true,
        [Columns.timeWeight.id as string]: true,
        [Columns.totalWeight.id as string]: true,
        [Columns.hazmatReleaseIndicator.id as string]: true,
        [Columns.federalRecordableIndicator.id as string]: true,
        [Columns.stateRecordableIndicator.id as string]: false,
        [Columns.preventable.id as string]: true,
        [Columns.roadAccessControl.id as string]: false,
        [Columns.trafficway.id as string]: false,
        [Columns.unitLicenseNumber.id as string]: false,
        [Columns.unitLicenseState.id as string]: false,
        [Columns.unitType.id as string]: false,
        [Columns.unitVin.id as string]: true,
        [Columns.unitYear.id as string]: false,
        [Columns.unitMake.id as string]: false,
        [Columns.unitWeightRating.id as string]: false,
        [Columns.unitHazmatPlacardIndicator.id as string]: false,
        [Columns.cargoBodyType.id as string]: false,
        [Columns.lightCondition.id as string]: false,
        [Columns.roadSurfaceCondition.id as string]: false,
        [Columns.weatherCondition.id as string]: false,
        [Columns.reportingAgency.id as string]: false,
    });

    React.useEffect(() => {
        setColumnVisibility((old) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newState: Record<string, any> = {};
            for (const key in old) {
                if (hideableColumns.some((c) => c.ids.includes(key))) {
                    newState[key] = old[key];
                }
            }
            return newState;
        });
    }, [setColumnVisibility]);

    return [columnVisibility, setColumnVisibility];
}

function Table(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const data = React.useMemo(() => motorCarrier.crashes.scored.sortByDate("DESC").array(), [motorCarrier]);

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = useColumnVisibility();

    const table = useReactTable<MotorCarrier.ICrash>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        autoResetPageIndex: false,
        globalFilterFn: fuzzyFilter,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,
        state: {
            columnVisibility,
            columnFilters,
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
    });
    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    return (
        <div className="flex flex-col gap-2 overflow-auto p-1">
            <AnimatedDataTable style={spring} table={table} hideableColumns={hideableColumns} />
        </div>
    );
}

export function Crashes(): JSX.Element {
    return (
        <Section>
            <TitleGroup title="Crashes" description="All crashes within the report range." />

            <Table />
        </Section>
    );
}
