import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/reuse";
import { NationalAverageOutOfServiceRate } from "@deathstar/types/fmcsa";
import { useMutation } from "react-query";
import api from "../../api/api";

export function useMotorCarrierFetcher(): MotorCarrier.IFetcher & { isLoading: boolean } {
    const { mutateAsync: fetchLatestSmsResultsDate, isLoading: isLoadingLatestSmsResultsDate } = useMutation(async (): Promise<Date> => {
        return api.alphaCentaurus.findLatestSmsResultsDate();
    });
    const { mutateAsync: fetchLatestSnapshotDate, isLoading: isLoadingLatestSnapshotDate } = useMutation(async (): Promise<Date> => {
        return api.alphaCentaurus.findLatestSnapshotDate();
    });
    const { mutateAsync: fetchCensus, isLoading: isLoadingCensus } = useMutation(
        async ({ dot }: { dot: number }): Promise<MotorCarrier.Raw.Census> => {
            return api.alphaCentaurus.findCensus({ dot });
        }
    );
    const { mutateAsync: fetchCarrierData, isLoading: isLoadingCarrierData } = useMutation(
        async (options: MotorCarrier.IFetcher.fetchBulk.Options): Promise<MotorCarrier.IFetcher.fetchBulk.Return | null> => {
            const carrierData = await api.alphaCentaurus.findCarrier(options);
            if (!carrierData) return null;
            carrierData.crashes = carrierData.crashes.map((crash) => ({
                ...crash,
                ReportDate: new Date(crash.ReportDate).toISOString(),
            })) as MotorCarrier.Raw.Crash[];
            carrierData.inspections = carrierData.inspections.map((i) => ({
                ...i,
                InspectionDate: new Date(i.InspectionDate).toISOString(),
            })) as MotorCarrier.Raw.Inspection[];
            carrierData.enforcementCases = carrierData.enforcementCases.map((cs) => ({
                ...cs,
                ClosedDate: new Date(cs.ClosedDate).toISOString(),
            })) as MotorCarrier.Raw.EnforcementCase[];

            return carrierData;
        }
    );
    const { mutateAsync: fetchAuthorityHistory, isLoading: isLoadingAuthorityHistory } = useMutation(
        async ({ dot }: { dot: number }): Promise<MotorCarrier.Raw.AuthorityHistory[]> => {
            return api.alphaCentaurus.findAuthorityHistory({ dot });
        }
    );
    const { mutateAsync: fetchInspections, isLoading: isLoadingInspections } = useMutation(
        async (options: {
            dot: number;
            dateRange: {
                from: DateTime;
                to: DateTime;
            };
        }): Promise<MotorCarrier.Raw.Inspection[]> => {
            const inspecitons = await api.alphaCentaurus.findInspections(options);
            if (!inspecitons?.length) return [];
            return inspecitons.map((i) => ({
                ...i,
                InspectionDate: new Date(i.InspectionDate).toISOString(),
            })) as MotorCarrier.Raw.Inspection[];
        }
    );
    const { mutateAsync: fetchEnforcementCases, isLoading: isLoadingEnforcementCases } = useMutation(
        async (options: { dot: number }): Promise<MotorCarrier.Raw.EnforcementCase[]> => {
            const cases = await api.alphaCentaurus.findEnforcementCases(options);
            if (!cases?.length) return [];
            return cases.map((cs) => ({
                ...cs,
                ClosedDate: new Date(cs.ClosedDate).toISOString(),
            })) as MotorCarrier.Raw.EnforcementCase[];
        }
    );
    const { mutateAsync: fetchViolations, isLoading: isLoadingViolations } = useMutation(
        async (options: MotorCarrier.IFetcher.fetchViolations.Options): Promise<Map<number, MotorCarrier.Raw.Violation[]>> => {
            const violations = (await api.alphaCentaurus.findViolations(options)) || [];
            return violations.reduce(MotorCarrier.rawViolationsReducer, new Map<number, MotorCarrier.Raw.Violation[]>());
        }
    );
    const { mutateAsync: fetchCrashes, isLoading: isLoadingCrashes } = useMutation(
        async (options: {
            dot: number;
            dateRange: {
                from: DateTime;
                to: DateTime;
            };
        }): Promise<MotorCarrier.Raw.Crash[]> => {
            const crashes = await api.alphaCentaurus.findCrashes(options);
            if (!crashes?.length) return [];
            return crashes.map((crash) => ({
                ...crash,
                ReportDate: new Date(crash.ReportDate).toISOString(),
            })) as MotorCarrier.Raw.Crash[];
        }
    );
    const { mutateAsync: fetchCensusHistorical, isLoading: isLoadingCensusHistorical } = useMutation(
        async (options: {
            dot: number;
            dateRange: {
                from: DateTime;
                to: DateTime;
            };
        }): Promise<Map<string, MotorCarrier.Raw.CensusHistorical>> => {
            const censusHistorical = await api.alphaCentaurus.findCensusHistorical(options);
            return MotorCarrier.rawCensusHistoricalMapper(options.dateRange, censusHistorical);
        }
    );
    const { mutateAsync: fetchSmsData, isLoading: isLoadingSmsData } = useMutation(
        async (options: { dot: number; dates: Date[] }): Promise<MotorCarrier.Raw.SmsData[]> => {
            return api.alphaCentaurus.findSmsData(options);
        }
    );
    const { mutateAsync: fetchNationalAverageOutOfServiceRates, isLoading: isLoadingNationalAverageOutOfServiceRates } = useMutation(
        async (options: { dates: Date[] }): Promise<NationalAverageOutOfServiceRate[]> => {
            return api.alphaCentaurus.findNationalAverageOutOfServiceRates(options);
        }
    );
    const { mutateAsync: fetchScore, isLoading: isLoadingScore } = useMutation(
        async (options: MotorCarrier.IFetcher.fetchSmsScore.Options): Promise<number> => {
            return api.alphaCentaurus.findScore(options);
        }
    );
    const { mutateAsync: fetchUnit, isLoading: isLoadingUnit } = useMutation(
        async (options: { vin: string }): Promise<MotorCarrier.Raw.Unit | undefined> => {
            return api.alphaCentaurus.findUnit(options);
        }
    );
    const { mutateAsync: fetchUnits, isLoading: isLoadingUnits } = useMutation(
        async (options: { vins: string[] }): Promise<MotorCarrier.Raw.Unit[]> => {
            return api.alphaCentaurus.findUnits(options);
        }
    );
    const { mutateAsync: fetchSnapshotDateHistorical, isLoading: isLoadingSnapshotDateHistorical } = useMutation(
        async (): Promise<MotorCarrier.IFetcher.fetchSnapshotDateHistorical.Return[]> => {
            return api.alphaCentaurus.findSnapshotDateHistorical();
        }
    );

    return {
        fetchLatestSmsResultsDate() {
            return fetchLatestSmsResultsDate();
        },
        fetchLatestSnapshotDate() {
            return fetchLatestSnapshotDate();
        },
        fetchCensus(dot: number) {
            return fetchCensus({ dot });
        },
        fetchAuthorityHistory(dot: number) {
            return fetchAuthorityHistory({ dot });
        },
        fetchInspections(dot: number, { dateRange }: { dateRange: MotorCarrier.IDateRange }) {
            return fetchInspections({ dot, dateRange });
        },
        fetchEnforcementCases(dot: number) {
            return fetchEnforcementCases({ dot });
        },
        fetchViolations(options: MotorCarrier.IFetcher.fetchViolations.Options): Promise<Map<number, MotorCarrier.Raw.Violation[]>> {
            return fetchViolations(options);
        },
        fetchCrashes(dot: number, { dateRange }: { dateRange: MotorCarrier.IDateRange }) {
            return fetchCrashes({ dot, dateRange });
        },
        fetchCensusHistorical(dot: number, { dateRange }: { dateRange: MotorCarrier.IDateRange }) {
            return fetchCensusHistorical({ dot, dateRange });
        },
        fetchSmsScore(options: MotorCarrier.IFetcher.fetchSmsScore.Options): Promise<number> {
            return fetchScore(options);
        },
        fetchSmsData(dot, dates) {
            return fetchSmsData({
                dot,
                dates,
            });
        },
        fetchNationalAverageOutOfServiceRates(dates) {
            return fetchNationalAverageOutOfServiceRates({
                dates,
            });
        },
        fetchUnit(vin) {
            return fetchUnit({ vin });
        },
        fetchUnits(vins) {
            return fetchUnits({ vins });
        },
        fetchBulk(options) {
            return fetchCarrierData(options);
        },
        fetchSnapshotDateHistorical() {
            return fetchSnapshotDateHistorical();
        },
        isLoading:
            isLoadingLatestSmsResultsDate ||
            isLoadingLatestSnapshotDate ||
            isLoadingCensus ||
            isLoadingAuthorityHistory ||
            isLoadingInspections ||
            isLoadingViolations ||
            isLoadingCrashes ||
            isLoadingUnit ||
            isLoadingUnits ||
            isLoadingCensusHistorical ||
            isLoadingSmsData ||
            isLoadingNationalAverageOutOfServiceRates ||
            isLoadingScore ||
            isLoadingCarrierData ||
            isLoadingEnforcementCases ||
            isLoadingSnapshotDateHistorical,
    };
}
