import { PrimaryScoreContributorsTable } from "./PrimaryScoreContributorsTable/PrimaryScoreContributorsTable";
import { TopViolationsBreakdown } from "./TopViolationsBreakdown/TopViolationsBreakdown";

export function Insights(): JSX.Element {
    return (
        <div>
            <PrimaryScoreContributorsTable />
            <TopViolationsBreakdown />
        </div>
    );
}
